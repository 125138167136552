import {ApolloClient, ApolloLink, from, HttpLink} from "@apollo/client"
import {ApolloCache} from "@apollo/client/cache/core/cache"
import store from "store/store"

const createApolloClient = (authToken: string | null, cache: ApolloCache<any>) => {
  const authHeaders = authToken ? {Authorization: `Bearer ${authToken}`} : {}
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({headers}: {headers: Record<string, any>}) => {
      if (!headers) {
        headers = {"x-hasura-role": "admin"}
      }
      return {
        headers: {
          ...authHeaders,
          ...headers,
        },
      }
    })
    return forward(operation)
  })
  return new ApolloClient({
    link: from([
      authLink,
      new HttpLink({
        uri: (store.getState().config.appleUrl || sessionStorage.getItem("apple_url")) as string,
      }),
    ]),
    cache: cache,
  })
}

export default createApolloClient
