import React, {useState} from "react"
import styles from "../InsuredPersonScreen.module.css"
import BaseForm from "app/common/components/Form"
import _ from "lodash"
import AdminServices from "services/AdminServices"
import {PORTAL_PATH} from "app/portal/config/routes"
import {formSchema, option} from "../schema"
import {message} from "antd"
import {useHistory} from "react-router-dom"

export type InsuredPersonTypeCreate = {
  id?: string
  name: string
  paperId: string
  paperIssuedAt?: string
  paperIssuedPlace?: string
  gender: number
  dob?: string
  phone?: string
  email?: string
  createdAt?: string
  updatedAt?: string
}

const InsuredPersonCreateScreen = (props: {isReturn: () => void}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<InsuredPersonTypeCreate | undefined>()
  const history = useHistory()

  const handleChange = (key: string, value: string) => {
    const newData = _.clone(data)
    if (newData) {
      if (value) {
        newData[key] = value
        setData(newData)
      } else {
        delete newData[key]
        setData(newData)
      }
    }
  }
  const createInsuredPerson = async () => {
    setLoading(true)
    if (data) {
      const response = await AdminServices.createInsuredPerson(data)
      if (response.isSuccess) {
        message.success("Tạo InsuredPerson thành công")
        if (response.data) {
          history.push(PORTAL_PATH.INSURED_PERSON_DETAIL.replace(":id", response.data))
        }
      } else {
        message.error(response.messages)
      }
    }
    setLoading(false)
  }
  const onSave = async () => {
    await createInsuredPerson()
  }

  const goToList = () => {
    const {isReturn} = props
    if (!isReturn) {
      history.push(PORTAL_PATH.INSURED_PERSON_LIST)
    } else {
      isReturn()
    }
  }
  return (
    <div className={styles.container}>
      <BaseForm
        title={""}
        className={styles.containerForm}
        schemas={formSchema}
        option={option}
        loading={loading}
        onChange={(key: string, value: string) => handleChange(key, value)}
        data={data || {}}
        onSave={() => onSave()}
        onCancel={() => goToList()}
      />
    </div>
  )
}

export default InsuredPersonCreateScreen
