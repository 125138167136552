import React, {useState} from "react"
import styles from "./PolicyCoPaymentScreen.module.css"
import {Checkbox, Input, message, Select, Spin, Tabs} from "antd"
import ButtonColor from "antd-button-color"
import {CheckOutlined, PlusOutlined} from "@ant-design/icons"
import {useHistory} from "react-router"
import {useParams} from "react-router-dom"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"
import PlanServices from "services/PlanServices"
import Benefit from "./components/Benefit"
import MedicalProvider from "./components/MedicaProvider"
import CoPaymentServices from "services/CoPaymentServices"
import _ from "lodash"
import Target, {TargetData, certificateType} from "./components/Target"
import {PORTAL_PATH} from "../../../../../config/routes"

const {TabPane} = Tabs
const {Option} = Select

interface CoPaymentDetailData {
  applyToAllMedicalProvider: boolean
  applyToAllPlans: boolean
  applyToBlackList: boolean
  applyToVoluntaryTarget?: boolean
  applyTargetAgeRange?: boolean
  applyTargetCondition?: boolean
  medicalProviderIds: string[]
  planCoPaymentId: string
  target: string
  value: number
  ageRanges?: {from: number | undefined; to: number | undefined}[]
  blackLists?: certificateType[]
  planIds: string[]
  planInsuredBenefits?: BenefitData[]
}

interface PlanData {
  code?: string
  planId: string
  name?: string
  insurer?: string
  document?: string
  terms?: string
}

export interface BenefitData {
  code?: string
  insuredBenefitId?: string
  isDirectBilling?: boolean
  name?: string
  planInsuredBenefitId?: string
  type: string
  planName: string
  planId: string
}

const PolicyCoPaymentScreen = () => {
  const {policyId, coPaymentId} = useParams<{policyId: string; coPaymentId: string}>()
  const [loading, setLoading] = useState<boolean>(false)
  const [planListData, setPlanListData] = useState<PlanData[]>()
  const [isApplyAllPlans, setIsApplyAllPlans] = useState<boolean>(false)
  const [planIdList, setPlanIdList] = useState<string[]>()
  const [planBenefitListDefaultData, setPlanBenefitListDefaultData] = useState<BenefitData[]>()
  const [planBenefitListData, setPlanBenefitListData] = useState<BenefitData[]>()
  const [coPaymentData, setCoPaymentData] = useState<CoPaymentDetailData>()

  const [medicalProviderData, setMedicalProviderData] =
    useState<{applyToAllMedicalProvider: boolean; medicalProviderIds: string[]}>()
  const [benefitData, setBenefitData] = useState<BenefitData[]>()
  const [planInsuredBenefitIds, setPlanInsuredBenefitIds] = useState<string[]>()
  const [valueCoPayment, setValueCoPayment] = useState<number>()
  const [targetData, setTargetData] = useState<TargetData>({
    applyToVoluntaryTarget: false,
    applyTargetAgeRange: false,
    applyTargetCondition: false,
    target: "",
    ageRanges: [{from: undefined, to: undefined}],
    applyToBlackList: false,
    blackLists: [],
    insuredCertificateIds: [],
  })
  const history = useHistory()

  const getCoPaymentDetail = async () => {
    setLoading(true)
    const response = await CoPaymentServices.getDetailPolicyCoPayment(coPaymentId)
    if (response.isSuccess && response.data) {
      const defaultData = _.clone(response.data)
      setCoPaymentData(defaultData)
      setValueCoPayment(Math.floor(defaultData.value * 100))
      setPlanIdList(defaultData.planIds)
      setIsApplyAllPlans(defaultData.applyToAllPlans)
      setBenefitData(defaultData.planInsuredBenefits)
      setMedicalProviderData({
        applyToAllMedicalProvider: defaultData.applyToAllMedicalProvider,
        medicalProviderIds: defaultData.medicalProviderIds,
      })
      setPlanInsuredBenefitIds(defaultData.planInsuredBenefits.map((planBenefit) => planBenefit.planInsuredBenefitId))
      setTargetData({
        applyToVoluntaryTarget: defaultData.applyToVoluntaryTarget,
        applyTargetAgeRange: defaultData.applyTargetAgeRange,
        applyToBlackList: defaultData.applyToBlackList,
        applyTargetCondition: defaultData.applyTargetCondition,
        target: defaultData.target,
        blackLists: defaultData.blackLists,
        ageRanges: (defaultData.ageRanges.length === 0 && [{from: undefined, to: undefined}]) || defaultData.ageRanges,
        insuredCertificateIds: [],
      })
    }
    setLoading(false)
  }

  const getPlanListByPolicyId = async (id) => {
    const planList = await PlanServices.getPlans(id)
    if (planList.isSuccess && planList.data.collection) {
      setPlanListData(planList.data.collection)
    }
  }

  useMountEffect(() => {
    getPlanListByPolicyId(policyId).then()
  })

  useUpdateEffect(() => {
    if (coPaymentId) {
      getCoPaymentDetail().then()
    }
  }, [coPaymentId])

  useUpdateEffect(() => {
    getPlanListByPolicyId(policyId).then()
  }, [policyId, coPaymentId])

  const getPlanBenefits = async () => {
    let newPlanBenefits: BenefitData[] = []
    if (planListData) {
      for (const plan of planListData) {
        const response = await PlanServices.getPlanBenefit(plan.planId)
        if (response.isSuccess) {
          newPlanBenefits = newPlanBenefits.concat(response.data)
        }
      }
      setPlanBenefitListDefaultData(newPlanBenefits)
    }
  }

  useUpdateEffect(() => {
    if (coPaymentId) {
      getCoPaymentDetail().then()
    }
  }, [planBenefitListDefaultData])

  useUpdateEffect(() => {
    getPlanBenefits().then()
  }, [planListData])

  const updateBenefitListDataByPlanId = (planIds: string[] | undefined) => {
    if (planBenefitListDefaultData) {
      const newPlanBenefitListData: BenefitData[] = []
      planBenefitListDefaultData.forEach((benefit) => {
        if (planIds?.includes(benefit.planId)) {
          newPlanBenefitListData.push(benefit)
        }
      })
      setPlanBenefitListData(newPlanBenefitListData)
    }
  }

  useUpdateEffect(() => {
    updateBenefitListDataByPlanId(planIdList)
  }, [planIdList])

  const onApplyAllPlan = (e) => {
    if (e.target.checked) {
      setPlanBenefitListData(planBenefitListDefaultData)
    } else {
      if (planIdList) {
        updateBenefitListDataByPlanId(planIdList)
      } else {
        setPlanBenefitListData([])
      }
    }
    setIsApplyAllPlans(e.target.checked)
  }

  const handleValueCoPayment = (e) => {
    setValueCoPayment(parseInt(e.target.value) || undefined)
  }

  const handleSelectPlan = (value) => {
    setPlanIdList(value)
  }

  const handleChangeBenefitData = (ids: string[]) => {
    setPlanInsuredBenefitIds(ids)
  }

  const removeBenefit = async (id) => {
    if (coPaymentId && id) {
      const response = await CoPaymentServices.deletePolicyCoPaymentBenefit(coPaymentId, id)
      if (response.isSuccess) {
        message.success("Xóa quyền lợi bảo hiểm thành công")
        getCoPaymentDetail().then()
      } else {
        message.error(response.messages)
      }
      return response.isSuccess
    }
  }

  const handleMedicalProvider = (medicalProviderIds: string[], applyToAllMedicalProvider: boolean) => {
    const initialData = _.clone(coPaymentData?.medicalProviderIds)
    const newMedicalProviderIds: string[] = initialData || []
    medicalProviderIds.forEach((m) => {
      if (!newMedicalProviderIds.includes(m)) {
        newMedicalProviderIds.push(m)
      }
    })
    const newMedicalProviderData: {medicalProviderIds: string[]; applyToAllMedicalProvider: boolean} = {
      medicalProviderIds: newMedicalProviderIds,
      applyToAllMedicalProvider,
    }
    setMedicalProviderData(_.clone(newMedicalProviderData))
  }

  const removeMedicalProvider = async (id) => {
    if (coPaymentId && id) {
      const response = await CoPaymentServices.deletePolicyCoPaymentMedicalProvider(coPaymentId, [id])
      if (response.isSuccess) {
        message.success("Xóa cơ sở y tế thành công")
        getCoPaymentDetail().then()
      } else {
        message.error(response.messages)
      }
    }
  }

  const handleTargetData = (name, value) => {
    const newTargetData = _.clone(targetData)
    if (name) {
      newTargetData[name] = value
    }
    setTargetData(newTargetData)
  }

  const removeBlackList = async (id) => {
    if (coPaymentId && id) {
      const response = await CoPaymentServices.deletePolicyCoPaymentBlackLists(coPaymentId, [id])
      if (response.isSuccess) {
        message.success("Xóa đối tượng áp dụng thành công")
        getCoPaymentDetail().then()
      } else {
        message.error(response.messages)
      }
      return response.isSuccess
    }
  }

  const createTargetAndBlackList = async (coPaymentId, isUpdate?: boolean) => {
    if (targetData) {
      targetData.ageRanges =
        (targetData.ageRanges &&
          targetData.applyTargetAgeRange &&
          targetData.ageRanges[0].from &&
          targetData.ageRanges[0].to &&
          targetData.ageRanges) ||
        []

      const responseTarget = await CoPaymentServices.createPolicyCoPaymentTarget(coPaymentId, targetData)
      const responseBlackList = await CoPaymentServices.createPolicyCoPaymentBlackList(coPaymentId, {
        applyToBlackList: targetData.applyToBlackList,
        insuredCertificateIds: targetData.insuredCertificateIds,
      })
      if (responseTarget.isSuccess && responseBlackList.isSuccess) {
        message.success(`${(isUpdate && "Cập nhật") || "Tạo mới"} đối tượng bảo hiểm thành công`)
      } else {
        !responseTarget.isSuccess && message.error(responseTarget.messages)
        !responseBlackList.isSuccess && message.error(responseBlackList.messages)
      }
      return responseTarget && responseBlackList
    }
  }

  const handleUpdateCoPayment = async (updatedData) => {
    if (coPaymentData && updatedData && coPaymentId) {
      const response = await CoPaymentServices.updateDetailPolicyCoPayment(coPaymentId, updatedData)
      if (response.isSuccess) {
        message.success("Cập nhật đồng chi trả thành công")
      } else {
        message.error(response.messages)
      }
      return response.isSuccess
    }
  }

  const handleChangeCoPayment = async (e) => {
    e.preventDefault()
    const policyCoPaymentData = {
      value: valueCoPayment ? valueCoPayment / 100 : 0,
      applyToAllPlans: isApplyAllPlans,
      planIds: (isApplyAllPlans && []) || planIdList || [],
      planInsuredBenefitIds: planInsuredBenefitIds || [],
      applyToAllMedicalProvider: (medicalProviderData && medicalProviderData.applyToAllMedicalProvider) || false,
      medicalProviderIds:
        (medicalProviderData &&
          ((!medicalProviderData.applyToAllMedicalProvider && medicalProviderData.medicalProviderIds) || [])) ||
        [],
    }
    if (!coPaymentId) {
      const response = await CoPaymentServices.createPolicyCoPayment(policyId, policyCoPaymentData)
      if (response.isSuccess && response.data) {
        message.success("Tạo đồng chi trả HĐ thành công")
        await createTargetAndBlackList(response.data)
        history.push(
          PORTAL_PATH.POLICY_COPAYMENT_DETAIL.replace(":policyId", policyId).replace(":coPaymentId", response.data),
        )
      } else {
        message.error(response.messages)
      }
    } else {
      const responseCoPayment = await handleUpdateCoPayment(policyCoPaymentData).then()
      const responseTargetAnBlackList = await createTargetAndBlackList(coPaymentId, true)
      if (responseCoPayment || responseTargetAnBlackList) {
        getCoPaymentDetail().then()
      }
    }
  }

  return (
    <div className={styles.coPaymentContainer}>
      <Spin spinning={loading}>
        <div className={styles.contentCoPayment}>
          <div className={styles.percentCoPayment}>
            <div className={styles.percentLabel}>Giá trị đồng chi trả</div>
            <Input
              className={styles.percentInput}
              min={0}
              max={100}
              type="number"
              value={valueCoPayment}
              addonAfter="%"
              placeholder="Phần trăm chi trả"
              onChange={handleValueCoPayment}
            />
          </div>

          <div className={styles.selectPlan}>
            <div className={styles.selectPlanLabel}>Chọn gói bảo hiểm áp dụng</div>
            <div className={styles.selectPlanContent}>
              <Select
                disabled={isApplyAllPlans}
                placeholder="Chọn gói bảo hiểm"
                className={styles.selectPlanInput}
                onChange={handleSelectPlan}
                value={planIdList}
                mode="multiple"
              >
                {planListData &&
                  planListData.map((plan) => (
                    <Option key={plan.planId} value={plan.planId}>
                      {plan.name}
                    </Option>
                  ))}
              </Select>
              <div className={styles.checkApplyPlans}>
                <Checkbox checked={isApplyAllPlans} onChange={onApplyAllPlan}>
                  Áp dụng cho toàn bộ các sản phẩm bảo hiểm
                </Checkbox>
              </div>
            </div>
          </div>

          <div className={styles.tabCoPayment}>
            <Tabs className={styles.tabContainer} type="card">
              <TabPane tab="Quyền lợi" key="benefit">
                <Benefit
                  onChange={(benefitDataIds) => handleChangeBenefitData(benefitDataIds)}
                  benefitCoPaymentList={benefitData || []}
                  onDelete={(id) => removeBenefit(id)}
                  benefitList={planBenefitListData || []}
                />
              </TabPane>
              <TabPane tab="Cơ sở y tế" key="medicalProvider">
                <MedicalProvider
                  onChange={(medicalProviderDataIds, checkAll) =>
                    handleMedicalProvider(medicalProviderDataIds, checkAll)
                  }
                  data={medicalProviderData || {applyToAllMedicalProvider: false, medicalProviderIds: []}}
                  initialData={coPaymentData?.medicalProviderIds}
                  onDelete={(id) => removeMedicalProvider(id)}
                />
              </TabPane>
              <TabPane tab="Đối tượng" key="person">
                <Target
                  onChange={(name, value) => handleTargetData(name, value)}
                  data={targetData}
                  onDelete={(id) => removeBlackList(id)}
                />
              </TabPane>
            </Tabs>
          </div>

          <div className={styles.button}>
            {coPaymentId ? (
              <ButtonColor
                size={"middle"}
                icon={<CheckOutlined />}
                type={"warning"}
                href={""}
                onClick={(e) => handleChangeCoPayment(e)}
              >
                Cập nhật
              </ButtonColor>
            ) : (
              <ButtonColor
                size={"middle"}
                type={"primary"}
                icon={<PlusOutlined />}
                href={""}
                onClick={(e) => handleChangeCoPayment(e)}
              >
                Thêm mới
              </ButtonColor>
            )}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default PolicyCoPaymentScreen
