import React from "react"
import {TableColumnsType, Tag} from "antd"
import {PORTAL_PATH} from "app/portal/config/routes"
import {Link} from "react-router-dom"
import {utils} from "config/utils"
import {FORMATTER} from "config/constants"

const listSchema: TableColumnsType<IdentityUsers> = [
  {
    title: "Tên",
    dataIndex: "name",
    render: (name, record) => {
      return <Link to={`${PORTAL_PATH.ADMIN_USERS_LIST}/${record.user_id}`}>{name}</Link>
    },
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Số đt",
    dataIndex: "phone",
  },
  {
    title: "Ngày tạo",
    dataIndex: "created_at",
    render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT),
  },
  {
    title: "Ngày tạo",
    dataIndex: "created_at",
    render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT),
  },
  {
    title: "Role",
    render: (_, user) => user.roles.map(({role}) => role).join(", "),
  },
  {
    title: "Trạng Thái",
    dataIndex: "status",
    render: (status) => <Tag color={status === "Active" ? "green" : "yellow"}>{status}</Tag>,
  },
]

const userRolesSchema: TableColumnsType<IdentityUserRole> = [
  {
    title: "Role Name",
    dataIndex: "role",
  },
  {
    title: "Company",
    render: (_, role) => role.company_role?.company.name,
  },
  {
    title: "Company Role",
    render: (_, role) => role.company_role?.role,
  },
  {
    title: "Ngày tạo",
    dataIndex: "created_at",
    render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT),
  },
]

const logSchema: TableColumnsType<AuditLogsUserLoginLogs> = [
  {
    title: "Thời gian",
    dataIndex: "created_at",
    render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT),
  },
  {
    title: "message",
    dataIndex: "message",
    render: (text) => text,
  },
  {
    title: "device_info",
    dataIndex: "device_info",
    render: (text) => text,
  },
]

const createUserFormSchema = [
  {
    key: "name",
    label: "Name",
    placeholder: "Name",
    component: "input",
    type: "text",
    required: true,
  },
  {
    key: "email",
    label: "Email",
    placeholder: "Email",
    component: "input",
    type: "text",
    required: true,
  },
  {
    key: "phone",
    label: "Phone",
    placeholder: "Phone",
    component: "input",
    type: "text",
    required: true,
  },
  {
    key: "password",
    label: "Password",
    placeholder: "Password",
    component: "input",
    type: "password",
    required: true,
  },
]

const addRoleFormSchema = [
  {
    key: "role",
    label: "Role",
    placeholder: "Chọn role",
    component: "select",
    required: true,
  },
  {
    key: "companyRoleId",
    label: "Công ty",
    placeholder: "Chọn công ty",
    component: "select",
  },
]

export {createUserFormSchema, addRoleFormSchema, listSchema, userRolesSchema, logSchema}
