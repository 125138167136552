import styles from "./Recipient.module.css"
import {Input, Modal, Tag} from "antd"
import React, {useState} from "react"
import {EmailType, TemplateType} from "../../index"
import BaseForm from "app/common/components/Form"
import _ from "lodash"
import ReactHammer from "react-hammerjs"
import {DragOutlined, FullscreenOutlined} from "@ant-design/icons"

const {TextArea} = Input

const Recipient = (props: {
  templateType: TemplateType
  subject: string
  onChangeSubject: (value: string) => void
  onChangePhone: (value: string) => void
  onChangeTos: (tos: {name: string; email: string}[]) => void
  onChangeBccs: (bccs: {name: string; email: string}[]) => void
  onChangeCcs: (ccs: {name: string; email: string}[]) => void
  tos: {name: string; email: string}[]
  bccs: {name: string; email: string}[]
  ccs: {name: string; email: string}[]
  phone: string
}) => {
  const [isModal, showModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<EmailType>({
    email: "",
    name: "",
  })
  const [currentFieldEmail, setCurrentFieldEmail] = useState<string>()
  const [minimize, setMinimize] = useState<boolean>(false)
  const [position, setPosition] = useState<{x: number; y: number}>({
    x: 170,
    y: 20,
  })
  const [canDrag, setDrag] = useState<boolean>(false)

  const {
    templateType,
    subject,
    onChangeSubject,
    onChangePhone,
    onChangeTos,
    onChangeBccs,
    onChangeCcs,
    tos,
    bccs,
    ccs,
    phone,
  } = props
  const removeEmail = (fieldName: string, index: number) => {
    switch (fieldName) {
      case "tos": {
        const newTos = _.clone(tos)
        newTos.splice(index, 1)
        onChangeTos(newTos)
        break
      }
      case "ccs": {
        const newCcs = _.clone(ccs)
        newCcs.splice(index, 1)
        onChangeCcs(newCcs)
        break
      }
      case "bccs": {
        const newBccs = _.clone(bccs)
        newBccs.splice(index, 1)
        onChangeBccs(newBccs)
        break
      }
      default:
        break
    }
  }
  const emailForm = (name: string, listData: {email: string; name: string}[]): JSX.Element => (
    <div
      className={styles.listEmailContainer}
      onClick={() => {
        showModal(true)
        setCurrentFieldEmail(name)
      }}
    >
      {(listData &&
        listData.length &&
        listData.map((to, index) => (
          <Tag
            color={"geekblue"}
            className={styles.tagEmail}
            key={to.email}
            closable
            onClose={() => removeEmail(name, index)}
          >
            {`${to.name} `}( <b>{to.email}</b>)
          </Tag>
        ))) ||
        ""}
      <div className={styles.placeHolderAddNew}>Thêm mới Email</div>
    </div>
  )

  const saveEmail = () => {
    if (currentFieldEmail === "tos") {
      const newTos = _.clone(tos)
      const spreadEmail = newTos.map((t) => t.email)
      if (!spreadEmail.includes(modalData.email)) {
        newTos.push(modalData)
        onChangeTos(newTos)
      }
    }
    if (currentFieldEmail === "ccs") {
      const newCcs = _.clone(ccs)
      const spreadEmail = newCcs.map((t) => t.email)
      if (!spreadEmail.includes(modalData.email)) {
        newCcs.push(modalData)
        onChangeCcs(newCcs)
      }
    }
    if (currentFieldEmail === "bccs") {
      const newBccs = _.clone(bccs)
      const spreadEmail = newBccs.map((t) => t.email)
      if (!spreadEmail.includes(modalData.email)) {
        newBccs.push(modalData)
        onChangeBccs(newBccs)
      }
    }
    setModalData({
      email: "",
      name: "",
    })
    setCurrentFieldEmail("")
    showModal(false)
  }

  return (
    <>
      {templateType === TemplateType.EMAIL ? (
        <ReactHammer
          onPan={(event) => {
            setPosition({
              x: event.center.x - 100,
              y: event.center.y - 80,
            })
          }}
          options={{
            recognizers: {
              pan: {enable: canDrag},
              press: {enable: true},
            },
          }}
        >
          <div
            style={
              minimize
                ? {
                    left: position.x,
                    top: position.y,
                  }
                : {}
            }
            className={[styles.senderContainer, minimize ? styles.float : ""].join(" ")}
          >
            <Modal title="Thêm email" visible={isModal} onOk={() => {}} footer={null} onCancel={() => showModal(false)}>
              <BaseForm
                data={modalData}
                onChange={(key, value) => {
                  setModalData({
                    ...modalData,
                    [key]: value,
                  })
                }}
                loading={false}
                onSave={() => saveEmail()}
                onCancel={() => showModal(false)}
                schemas={[
                  {
                    key: "name",
                    label: "Tên",
                    placeholder: "Tên",
                    component: "input",
                    type: "text",
                    layout: 24,
                    required: true,
                  },
                  {
                    key: "email",
                    label: "Email",
                    placeholder: "Email",
                    component: "input",
                    type: "text",
                    layout: 24,
                    required: true,
                  },
                ]}
              />
            </Modal>
            {minimize ? (
              <div className={styles.headerFloat}>
                <div
                  onMouseDown={(e) => {
                    if (!canDrag) {
                      setDrag(true)
                    }
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onMouseUp={(e) => {
                    if (canDrag) {
                      setDrag(false)
                    }
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  className={[styles.headerBtn, styles.move].join(" ")}
                >
                  <DragOutlined /> Di chuyển
                </div>
                <div
                  className={styles.headerBtn}
                  onClick={() => {
                    setPosition({
                      x: 170,
                      y: 20,
                    })
                    setMinimize(false)
                  }}
                >
                  <FullscreenOutlined /> Phóng to
                </div>
              </div>
            ) : (
              ""
            )}
            <div>
              <label>Subject: </label>
              <TextArea
                size={"large"}
                value={subject}
                className={styles.subjectInput}
                onChange={(e) => onChangeSubject(e.target.value)}
              />
            </div>
            <div>
              <label>To: </label>
              <div className={styles.emailContainer}>{emailForm("tos", tos)}</div>
            </div>
            <div>
              <label>CC: </label>
              <div className={styles.emailContainer}>{emailForm("ccs", ccs)}</div>
            </div>
            <div>
              <label>BCC: </label>
              <div className={styles.emailContainer}>{emailForm("bccs", bccs)}</div>
            </div>
          </div>
        </ReactHammer>
      ) : (
        <div className={styles.senderContainer}>
          <div>
            <label>Phone: </label>
            <Input type={"number"} size={"middle"} value={phone} onChange={(e) => onChangePhone(e.target.value)} />
          </div>
        </div>
      )}
      {templateType === TemplateType.EMAIL && !minimize ? (
        <div className={styles.minimizeBtn} role={"button"} tabIndex={0} onClick={() => setMinimize(true)}>
          Thu nhỏ
        </div>
      ) : (
        ""
      )}
    </>
  )
}
export default Recipient
