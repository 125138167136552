import React, {Suspense} from "react"
import styles from "./Portal.module.css"
import {Redirect, Route} from "react-router-dom"
import NotFoundScreen from "app/common/screens/404NotFoundScreen"
import portalRoutes, {PORTAL_PATH} from "./config/routes"
import TopBar from "app/common/components/TopBar"
import SideBar from "app/common/components/SideBar"
import menus from "./config/sidebar"
import {AnimatedSwitch} from "react-router-transition"
import {useDispatch} from "react-redux"
import AuthServices from "services/AuthServices"
import {message, PageHeader} from "antd"
import {logout} from "store/commonActions/token"
import {ROUTES_PATH} from "app/routes"
import {useHistory} from "react-router"
import {useMountEffect} from "@react-hookz/web"
import OptionListServices from "services/OptionListServices"
import {TYPE} from "config/constants"
import setTypes from "store/commonActions/types"

const Portal = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const checkValid = async () => {
    const response = await AuthServices.me()
    if (response.isSuccess) {
      return true
    } else {
      if (response.messages) {
        message.error(response.messages)
      }
      return false
    }
  }

  const processValid = async () => {
    const response = await checkValid()
    if (!response) {
      dispatch(logout())
      history.push(ROUTES_PATH.LOGIN_SCREEN)
    }
  }

  const getTypesMetadata = async () => {
    const response = await OptionListServices.getTypes(TYPE.METADATA_TYPE.ALL)
    if (response.isSuccess) {
      dispatch(setTypes(response.data))
    }
  }

  const backgroundLoading = () => {
    getTypesMetadata().then()
  }

  const getFirstLoad = async () => {
    backgroundLoading()
    processValid().then()
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  return (
    <div className={styles.container}>
      <SideBar menus={menus} />
      <div className={styles.besideSideBar}>
        <TopBar />
        <AnimatedSwitch runOnMount={true} atEnter={{opacity: 0.5}} atLeave={{}} atActive={{opacity: 1}}>
          {(portalRoutes &&
            portalRoutes.length &&
            portalRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                render={() => (
                  <Suspense fallback={<div>Loading...</div>}>
                    <div className={styles.belowTopBar}>
                      {route.title ? (
                        <PageHeader
                          onBack={() => window.history.back()}
                          className={styles.pageHeader}
                          title={route.title}
                          subTitle={route.subTitle}
                        />
                      ) : (
                        ""
                      )}
                      <route.comp />
                    </div>
                  </Suspense>
                )}
              />
            ))) ||
            ""}
          {(window.location.pathname.split("/").length <= 2 ||
            !window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] ||
            window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] === "portal") && (
            <Redirect from={"/portal"} to={PORTAL_PATH.DASHBOARD} />
          )}
          <Route
            render={() => (
              <div className={styles.belowTopBar}>
                <NotFoundScreen />
              </div>
            )}
          />
        </AnimatedSwitch>
      </div>
    </div>
  )
}

export default Portal
