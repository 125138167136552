import React, {useState} from "react"
import styles from "./PlanBalanceScreen.module.css"
import {Button, Divider, message, Modal, Popconfirm, Select, Space, Spin, Tag} from "antd"
import TableList from "app/common/components/TableList"
import _ from "lodash"
import PlanServices from "services/PlanServices"
import {DeleteTwoTone, EditOutlined} from "@ant-design/icons"
import {PORTAL_PATH} from "app/portal/config/routes"
import BenefitTag from "app/common/components/BenefitTag"
import BaseForm from "app/common/components/Form"
import {balanceSchema} from "./schema"
import ButtonColor from "antd-button-color"
import {useHistory, useParams} from "react-router"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"
import {TYPE} from "config/constants"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"

interface IBenefitData {
  code?: string
  insuredBenefitId?: string
  isDirectBilling?: boolean
  name?: string
  planInsuredBenefitId: string
  type?: string
}

interface IStates {
  loading: boolean
  benefits: Array<IBenefitData> | []
  data?: IBalanceData | any
  option: {
    [key: string]: {name: string; value: string | boolean | number}[]
  }
}

export interface IBalanceData {
  name?: string
  value?: string
  type?: string
  description?: string
  maskedValue?: string
  planInsuredBenefits?: Array<IBenefitData>
}

const {Option} = Select

const PlanBalanceDetailScreen = () => {
  const planBalanceTypes = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.PLAN_BALANCE_TYPE],
  )
  const benefitTypeOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const [loading, setLoading] = useState(false)
  const [inputSearch, setInputSearch] = useState("")
  const [showModalPlanBalance, setShowModalPlanBalance] = useState(false)
  const [globalLoading, setGlobalLoading] = useState<boolean>(true)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [balanceData, setBalanceData] = useState<IStates["data"]>({})
  const [benefitList, setBenefitList] = useState<IStates["benefits"]>([])
  const [benefitData, setBenefitData] = useState<Array<string>>([])
  const {planId, planBalanceId} = useParams<{planId: string; planBalanceId: string}>()
  const history = useHistory()

  const getBalanceDetail = async () => {
    setGlobalLoading(true)
    const response = await PlanServices.getPlanBalanceDetail(planId, planBalanceId)
    if (response && response.data) {
      setBalanceData(response.data)
    }
    setGlobalLoading(false)
  }

  const deleteBenefit = async (id: string) => {
    setLoadingDelete(true)
    const response = await PlanServices.deleteBenefitOfBalance(planId, planBalanceId, id)
    if (response && response.isSuccess) {
      setLoadingDelete(false)
      message.success("Xóa benefit khỏi balance thành công")
      setLoadingDelete(false)
      getBalanceDetail().then()
    }
  }

  const benefitColumns = [
    {
      key: "code",
      dataIndex: "code",
      title: "Mã",
      width: 100,
      render: (text: string) => (
        <Tag className={"large-font-size"} color={"geekblue"}>
          {text}
        </Tag>
      ),
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Tên quyền lợi",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Loại quyền lợi",
      sorter: (a, b) => a.type.length - b.type.length,
      filters:
        benefitTypeOption && benefitTypeOption.length
          ? benefitTypeOption.map((b) => ({
              text: b.name,
              value: b.value,
            }))
          : [],
      onFilter: (value, record) => record.type === value,
      render: (text: string) => (
        <BenefitTag
          text={text}
          textReplace={
            (benefitTypeOption && _.find(benefitTypeOption, (b: {value: string}) => b.value === text)?.name) || ""
          }
        />
      ),
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Tác vụ",
      render: (value: boolean, row) =>
        value && (
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title="Bạn có chắc muốn xóa"
              onConfirm={() => deleteBenefit(row.planInsuredBenefitId)}
              okButtonProps={{
                loading: loadingDelete,
                danger: true,
              }}
              okText={"Xóa"}
            >
              <DeleteTwoTone
                twoToneColor="#EF5350"
                style={{
                  fontSize: 18,
                  textAlign: "center",
                }}
              />
            </Popconfirm>
          </div>
        ),
    },
  ]

  const getListBenefits = async () => {
    const benefits = await PlanServices.getPlanBenefit(planId)
    if (benefits && benefits.data) {
      setBenefitList(benefits.data)
    }
  }

  const mapBalanceBenefitToBenefitData = () => {
    if (balanceData.planInsuredBenefits) {
      setBenefitData(balanceData.planInsuredBenefits.map((benefit) => benefit.planInsuredBenefitId))
    }
  }

  useMountEffect(() => {
    getListBenefits().then()
    if (planBalanceId) {
      getBalanceDetail().then()
    }
  })

  useUpdateEffect(() => {
    mapBalanceBenefitToBenefitData()
  }, [balanceData])

  const mapListBenefit = () => {
    const newBenefits: Array<any> = []
    if (benefitList.length) {
      for (const i of benefitList) {
        newBenefits.push(
          <Option value={i.planInsuredBenefitId} key={i.name + (i.code || "") + (i.type || "")}>
            {i.type ? (
              <BenefitTag
                text={i.type}
                isSmall
                textReplace={benefitTypeOption && benefitTypeOption.find((b) => b.value === i.type)?.name}
              />
            ) : (
              ""
            )}
            <Tag color={"geekblue"}>{i.code}</Tag>
            {i.name}
          </Option>,
        )
      }
    }
    return newBenefits
  }

  const handleSelect = (value: Array<string>) => {
    const initialValue = balanceData.planInsuredBenefits.map((benefit) => benefit.planInsuredBenefitId) || []
    const allowUpdate = _.difference(initialValue, value).length === 0
    if (allowUpdate) {
      setBenefitData(value)
    }
  }

  const updateBalance = async () => {
    setLoading(true)
    if (balanceData) {
      balanceData.value = parseInt(balanceData.value)
      await PlanServices.updatePlanBalance(planId, planBalanceId, balanceData)
    }
    setLoading(false)
    setShowModalPlanBalance(false)
  }

  const filterByBenefitData = () => {
    let initialValue: any[] = []
    if (balanceData.planInsuredBenefits) {
      initialValue = balanceData.planInsuredBenefits.map((benefit) => benefit.planInsuredBenefitId)
    }
    const newDataTable = benefitData.map((benefitId) => ({
      ..._.find(benefitList, (benefit) => benefit.planInsuredBenefitId === benefitId),
    }))
    const finalData: any[] = []
    newDataTable.forEach((benefit) => {
      if (initialValue.indexOf(benefit.planInsuredBenefitId) >= 0) {
        benefit["action"] = true
        benefit["key"] = benefit.planInsuredBenefitId
      }
      finalData.unshift(benefit)
    })
    return finalData
  }

  const handleSearch = (value) => {
    setInputSearch(value)
  }

  const assignBenefitBalancePlan = async () => {
    const response = await PlanServices.assignBenefitBalancePlan(planId, planBalanceId, benefitData)
    if (response.isSuccess) {
      message.success("Gán benefit vào balance thành công")
      getBalanceDetail().then()
    }
  }

  const handleChange = (key: string, value: string) => {
    let newData = _.clone(balanceData)
    if (value) {
      if (!newData) {
        newData = {
          id: "",
          code: "",
          name: "",
        }
      }
      newData[key] = value
      setBalanceData(newData)
    } else {
      delete newData[key]
      setBalanceData(newData)
    }
  }

  return (
    <Space align={"start"} direction={"horizontal"} size={25} className={styles.balanceDetailContainer}>
      <Space direction={"vertical"} size={10} className={[styles.infoContainer, styles.shows].join(" ")}>
        <Spin spinning={globalLoading}>
          <div>
            <div className={styles.infoHeader}>
              <div>Thông tin quyền lợi</div>
              <ButtonColor
                icon={<EditOutlined />}
                size={"middle"}
                type={"lightdark"}
                className={styles.editButton}
                onClick={() => setShowModalPlanBalance(true)}
              >
                SỬA
              </ButtonColor>
            </div>
            <Divider className={styles.divider} />
          </div>
          <div>
            <div className={styles.title}>Tên :</div>
            <div>{balanceData.name}</div>
          </div>

          <div>
            <div className={styles.title}>Giá trị :</div>
            <div>{balanceData.maskedValue}</div>
          </div>

          <div>
            <div className={styles.title}>Loại :</div>
            <div>
              {(planBalanceTypes && planBalanceTypes.find((balance) => balance.value === balanceData.type)?.name) ||
                balanceData.type}
            </div>
          </div>
          <div>
            <div className={styles.title}>Description :</div>
            <div>{balanceData.description}</div>
          </div>
        </Spin>
      </Space>
      <div className={styles.balanceConfiguration}>
        <div className={styles.createBalance}>
          <p className={styles.title}>Add Benefits to Balance</p>
          <div>
            <Select
              className={styles.selectBalance}
              mode="multiple"
              size={"large"}
              maxTagCount={5}
              value={benefitData}
              style={{width: "100%"}}
              placeholder="Please select"
              onChange={handleSelect}
              dropdownStyle={{position: "fixed"}}
              onSearch={handleSearch}
              searchValue={inputSearch}
              filterOption={(input, option) =>
                (option && option.key && option.key.toString().toLowerCase().includes(input.toLowerCase())) || false
              }
            >
              {mapListBenefit()}
            </Select>
          </div>
        </div>

        <div className={styles.tablePreview}>
          <TableList
            title={"benefits"}
            search={["code", "name"]}
            onView={(record) => {
              let path = PORTAL_PATH.PLAN_BENEFIT_DETAIL
              path = path.replace(":planId", planId)
              path = path.replace(":planInsuredBenefitId", record.planInsuredBenefitId)
              history.push(path)
            }}
            schema={benefitColumns}
            data={filterByBenefitData()}
          />
          <Button type={"primary"} className={styles.button} onClick={() => assignBenefitBalancePlan()}>
            Lưu
          </Button>
          <Modal
            title=""
            centered
            visible={showModalPlanBalance}
            width={800}
            onCancel={() => setShowModalPlanBalance(false)}
            footer={[]}
          >
            <BaseForm
              title={"Thông tin chi tiết" || undefined}
              className={styles.containerForm}
              schemas={balanceSchema}
              option={{type: planBalanceTypes}}
              loading={loading}
              onChange={(key: string, value: string) => handleChange(key, value)}
              data={balanceData || {}}
              onSave={() => updateBalance()}
            />
          </Modal>
        </div>
      </div>
    </Space>
  )
}

export default PlanBalanceDetailScreen
