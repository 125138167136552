import PlanScreen from "../../../PlanScreen"
import PolicyScreen from "../../../PolicyScreen"

export const COMPANY_PATH = {
  COMPANY_PLAN: "/portal/companies/:companyId/plans",
  COMPANY_POLICY: "/portal/companies/:companyId/policies",
  COMPANY_DETAIL: "/portal/companies/:companyId",
}

const routes = [
  {
    key: "policy",
    path: COMPANY_PATH.COMPANY_POLICY,
    comp: PolicyScreen,
    title: "Policy",
  },
  {
    key: "plan",
    path: COMPANY_PATH.COMPANY_PLAN,
    comp: PlanScreen,
    title: "Plan",
  },
  {
    key: "company",
    path: COMPANY_PATH.COMPANY_DETAIL,
    comp: PolicyScreen,
    title: "Company",
  },
]

export default routes
