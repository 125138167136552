import React, {useCallback, useEffect, useState} from "react"
import styles from "./CompanyScreen.module.css"
import AdminServices from "services/AdminServices"
import {PORTAL_PATH} from "app/portal/config/routes"
import TableList from "app/common/components/TableList"
import {listSchema} from "./schema"
import {useHistory, useLocation, useParams} from "react-router"

type ItemType = {
  key?: string
  companyId: string
  name: string
  code: string
  types: string[]
  createdAt: string
  updatedAt: string
}

const CompanyScreen = () => {
  const [data, setData] = useState<ItemType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const params = useParams<{type: string}>()
  const {type} = params
  const location = useLocation()
  const history = useHistory()

  const getFirstLoad = useCallback(async () => {
    const response = await AdminServices.getCompanyList()

    if (response.isSuccess) {
      const newData = response.data.collection.map((item: ItemType) => {
        item.key = item.companyId
        return item
      })
      setData(newData)
    }
    setLoading(false)
  }, [])
  useEffect(() => {
    getFirstLoad().then()
  }, [getFirstLoad])

  useEffect(() => {
    getFirstLoad().then()
  }, [params, getFirstLoad])

  return (
    <div className={styles.container}>
      <TableList
        title={"công ty"}
        onCreate={() => history.push(`${PORTAL_PATH.COMPANY_LIST.replace(":type", type)}/create`)}
        onView={(record: ItemType) => history.push(`${location.pathname}/${record.companyId}`)}
        schema={listSchema}
        loading={loading}
        data={data}
      />
    </div>
  )
}

export default CompanyScreen
