import React from "react"
import {FORMATTER, TYPE} from "config/constants"
import {utils} from "config/utils"
import {Tag} from "antd"

const listSchema = [
  {
    title: "Mã người được BH",
    dataIndex: "insuredNumber",
    key: "insuredNumber",
  },
  {
    title: "Tên người được BH",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "CMT/CCCD",
    dataIndex: "paperId",
    key: "paperId",
  },
  {
    title: "Ngày sinh",
    dataIndex: "dob",
    key: "dob",
    render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_FORMAT),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Giới tính",
    key: "genderName",
    dataIndex: "genderName",
    render: (text) => (
      <Tag color={text === "Nam" ? "geekblue" : "pink"}>
        {text === "Nam" ? "♂" : "♀"} {text}
      </Tag>
    ),
  },
]

const listCertificateSchema = [
  {
    title: "Mã thẻ",
    dataIndex: "certificateCode",
    key: "name",
  },
  {
    title: "Chủ hợp đồng",
    dataIndex: "policyOwner",
    key: "policyOwner",
  },
  {
    title: "Phụ thuộc bởi",
    dataIndex: "parentInsuredPersonName",
    key: "parentInsuredPersonName",
  },
  {
    title: "Ngày hiệu lực",
    dataIndex: "issuedAt",
    key: "issuedAt",
    render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_FORMAT)
  },
  {
    title: "Ngày hết hiệu lực",
    dataIndex: "duedAt",
    key: "duedAt",
    render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_FORMAT)
  },
]

const formSchema = [
  {
    key: "name",
    label: "Name",
    placeholder: "Nhập tên",
    component: "input",
    type: "text",
    required: true,
  },
  {
    key: "paperId",
    label: "CMND/CCCD",
    placeholder: "Nhập National Id",
    component: "input",
    type: "text",
    required: true,
  },
  {
    key: "paperIssuedAt",
    label: "Ngày cấp CMND/CCCD",
    placeholder: "Nhập ngày cấp CMND/CCCD",
    component: "date",
  },
  {
    key: "paperIssuedPlace",
    label: "Nơi cấp CMND/CCCD",
    placeholder: "Nhập nơi cấp CMND/CCCD",
    component: "input",
    type: "text",
  },
  {
    key: "dob",
    label: "Ngày sinh",
    placeholder: "Nhập ngày sinh",
    component: "date",
    required: true,
  },
  {
    key: "gender",
    label: "Giới tính",
    placeholder: "Chọn giới tính",
    component: "select",
    required: true,
  },
  {
    key: "email",
    label: "Email",
    placeholder: "Nhập Email",
    component: "input",
    type: "text",
  },
  {
    key: "phone",
    label: "Phone",
    placeholder: "Nhập phone",
    component: "input",
    type: "text",
  },
]

const option = {
  gender: [
    {
      name: TYPE.GENDER_TYPE[0],
      value: 0,
    },
    {
      name: TYPE.GENDER_TYPE[1],
      value: 1,
    },
  ],
}

export {formSchema, listSchema, option, listCertificateSchema}
