import React, {useCallback, useState} from "react"
import styles from "./ClaimCaseCorrespondenceScreen.module.css"
import {Button, message, notification, Radio, Spin} from "antd"
import OptionListServices from "../../../../../../../../../services/OptionListServices"
import caolan from "async"
import {useParams} from "react-router-dom"
import ClaimServices from "../../../../../../../../../services/ClaimServices"
import {CloseOutlined, SendOutlined} from "@ant-design/icons"
import Recipient from "./components/Recipient"
import TemplateMessage from "./components/TemplateMessage"
import ListTemplate from "./components/ListTemplate"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"

export type EmailType = {name: string; email: string}
export type CorrespondentDataType = {
  content: string
  phone: string
  subject: string
  tos: {name: string; email: string}[]
}

export enum TemplateType {
  EMAIL = "emails",
  SMS = "sms",
}

const ClaimCaseCorrespondenceScreen = (props: {isPresentCase?: boolean}) => {
  const [templateList, setTemplateList] = useState<{
    emails: {name: string; value: string}[]
    sms: {name: string; value: string}[]
  }>({emails: [], sms: []})
  const [activeKey, setActiveKey] = useState<string>("")
  const [currentTemplate, setCurrentTemplate] = useState<TemplateType>(TemplateType.EMAIL)
  const [templateData, setTemplateData] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingTemplate, setLoadingTemplate] = useState<boolean>(true)

  const [subject, setSubject] = useState<string>("")
  const [tos, setTos] = useState<EmailType[]>([])
  const [ccs, setCcs] = useState<EmailType[]>([])
  const [bccs, setBccs] = useState<EmailType[]>([])
  const [phone, setPhone] = useState<string>("")

  const {claimCaseId} = useParams<{claimCaseId: string}>()

  const getTemplate = useCallback(async () => {
    caolan.auto(
      {
        getEmail: (callback) => {
          OptionListServices.getMetadata("email_template").then((res) => {
            if (res.isSuccess) {
              callback(null, res.data.collection)
            } else {
              callback(null, null)
            }
          })
        },
        getSms: (callback) => {
          OptionListServices.getMetadata("sms_template").then((res) => {
            if (res.isSuccess) {
              callback(null, res.data.collection)
            } else {
              callback(null, null)
            }
          })
        },
      },
      (err, result) => {
        if (result) {
          setActiveKey(result.getEmail[0].value)
          setTemplateList({
            sms: result.getSms,
            emails: result.getEmail,
          })
          setLoading(false)
        }
      },
    )
  }, [])

  const getPresentCaseTemplate = useCallback(async () => {
    const response = await OptionListServices.getMetadata("present_case_template")
    if (response.isSuccess) {
      setActiveKey(response.data.collection[0].value)
      setTemplateList({
        emails: response.data.collection,
        sms: [],
      })
      setLoading(false)
    }
  }, [])

  const renderTemplate = async (templateId: string, templateType?: string) => {
    setLoadingTemplate(true)
    const response = await ClaimServices.getTemplate(
      templateType || currentTemplate,
      templateId,
      claimCaseId,
      props.isPresentCase || false,
    )
    if (response.isSuccess) {
      if (response.data) {
        setTemplateData(response.data.content)
        setTos(response.data.tos)
        setSubject(response.data.subject)
        setPhone(response.data.phone)
      }
    } else {
      setTemplateData("")
      message.error("Template có lỗi")
    }

    setLoadingTemplate(false)
  }

  useUpdateEffect(() => {
    if (activeKey) {
      renderTemplate(activeKey).then(() => {})
    }
  }, [activeKey])

  useMountEffect(() => {
    if (!props.isPresentCase) {
      getTemplate().then(() => {})
    } else {
      getPresentCaseTemplate().then(() => {})
    }
  })

  const sendMailOrSms = async () => {
    setLoadingTemplate(true)
    const isEmail = currentTemplate === TemplateType.EMAIL
    const response = await ClaimServices.claimCaseSendCorrespondence(
      claimCaseId,
      isEmail,
      isEmail
        ? {
            subject: subject,
            tos: tos,
            ccs: ccs,
            bccs: bccs,
            content: templateData,
          }
        : {
            phone: phone,
            message: templateData,
          },
    )
    setLoadingTemplate(false)
    if (response.isSuccess) {
      message.success("Gửi correspondence thành công")
    } else {
      if (response.data && typeof response.data === "object") {
        for (let i = 0; i < Object.keys(response.data).length; i++) {
          setTimeout(() => {
            const title = <strong>Validate field {Object.keys(response.data)[i].split("$.")[1]}</strong>
            notification.error({
              message: title,
              description: response.data[Object.keys(response.data)[i]],
              icon: <CloseOutlined style={{color: "red"}} />,
            })
          }, 1200 * i)
        }
      } else {
        message.error(response.messages)
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <div className={styles.correspondenceContainer}>
        <div className={styles.leftContainer}>
          <Recipient
            templateType={currentTemplate}
            subject={subject}
            onChangeSubject={setSubject}
            onChangePhone={setPhone}
            onChangeTos={setTos}
            onChangeBccs={setBccs}
            onChangeCcs={setCcs}
            tos={tos}
            bccs={bccs}
            ccs={ccs}
            phone={phone}
          />
          <Spin spinning={loadingTemplate}>
            {templateData && activeKey ? (
              <TemplateMessage
                templateData={templateData}
                onChangeTemplateData={setTemplateData}
                templateType={currentTemplate}
              />
            ) : (
              ""
            )}
          </Spin>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.sendBtn}>
            <Button type={"primary"} size={"large"} icon={<SendOutlined />} onClick={() => sendMailOrSms()}>
              Gửi
            </Button>
          </div>
          {!props.isPresentCase ? (
            <Radio.Group
              size={"middle"}
              className={styles.radioGroup}
              options={[
                {label: "Email", value: "emails"},
                {label: "SMS", value: "sms"},
              ]}
              onChange={async (e) => {
                setCurrentTemplate(e.target.value)
                setTemplateData("")
                setActiveKey(templateList[e.target.value][0].value)
                await renderTemplate(templateList[e.target.value][0].value, e.target.value)
              }}
              value={currentTemplate}
              buttonStyle="solid"
              optionType="button"
            />
          ) : (
            ""
          )}
          <ListTemplate
            templateList={templateList}
            templateType={currentTemplate}
            activeKey={activeKey}
            onChangeActiveKey={setActiveKey}
          />
        </div>
      </div>
    </Spin>
  )
}

export default ClaimCaseCorrespondenceScreen
