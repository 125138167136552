import React, {useEffect, useState} from "react"
import styles from "./TopBar.module.css"
import {Link} from "react-router-dom"
import {DownOutlined, RightOutlined} from "@ant-design/icons"
import {Avatar, Dropdown, Menu, Popconfirm, Radio} from "antd"
import AuthServices from "services/AuthServices"
import {ROUTES_PATH} from "app/routes"
import {useTranslation} from "react-i18next"
import {THEMES} from "config/constants"
import {useSelector} from "react-redux"
import {useHistory, useLocation} from "react-router"
import {MeType} from "store/interface"

const TopBar = () => {
  const DontHideOnClick = ["1", "2"] // these menu wont hide when clicked (using key)
  const history = useHistory()
  const location = useLocation()
  const {t} = useTranslation("common")
  const me = useSelector((state: {me: MeType}) => state.me)
  const [theme, setTheme] = useState<string>("")
  const [profileDropdown, setProfileDropdown] = useState<boolean>(false)

  const changeTheme = (value: string | null) => {
    if (value) {
      if (value === THEMES.DARK_THEME) {
        document.documentElement.setAttribute("data-theme", THEMES.DARK_THEME)
      }
      if (value === THEMES.LIGHT_THEME) {
        document.documentElement.setAttribute("data-theme", THEMES.LIGHT_THEME)
      }
      if (value === THEMES.BLUE_THEME) {
        document.documentElement.setAttribute("data-theme", THEMES.BLUE_THEME)
      }
    } else {
      document.documentElement.setAttribute("data-theme", THEMES.LIGHT_THEME)
    }
    localStorage.setItem("theme", value || THEMES.LIGHT_THEME)
    setTheme(value || THEMES.LIGHT_THEME)
  }

  useEffect(() => {
    const theme = localStorage.getItem("theme")
    changeTheme(theme)
    setTheme(theme || "")
  }, [])

  const handleLogicProfile = (key: string | number) => {
    if (!DontHideOnClick.includes(key as string)) {
      setProfileDropdown(false)
    }
  }

  const handleVisibleChange = (flag: boolean) => {
    setProfileDropdown(flag)
  }

  const logout = () => {
    AuthServices.logout().then()
    history.push(ROUTES_PATH.LOGIN_SCREEN)
  }

  const tailPathname = location.pathname.split("/portal/")[1]
  //Component declare part
  const profileMenu = (
    <Menu className={styles.menuList} onClick={(e) => handleLogicProfile(e.key)}>
      <Menu.Item key="0">Edit profile</Menu.Item>
      <Menu.Item key="1">
        Switch theme <br />
        <Radio.Group defaultValue={theme} buttonStyle="solid" onChange={(e) => changeTheme(e.target.value)}>
          <Radio.Button value="light">Default</Radio.Button>
          <Radio.Button value="dark">Quiet Dark</Radio.Button>
          <Radio.Button value="blue">Blue Sea</Radio.Button>
        </Radio.Group>
      </Menu.Item>
      <Menu.Divider className={styles.menuDivider} />
      <Menu.Item key="2">
        <Popconfirm
          placement="bottom"
          title={t("TopBar.ConfirmLogoutTitle")}
          onConfirm={() => logout()}
          okText="Yes"
          okType="danger"
          cancelText="No"
        >
          <div className={styles.logout}>Logout</div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.breadcrumb}>
          Trang chủ
          {tailPathname &&
            tailPathname.split("/").map((tail, index) => (
              <span key={"tail" + tail}>
                {
                  //hiding id in breadcrumb
                }
                {(t(`Breadcrumb.${tail}`).includes("Breadcrumb") && " ") || (
                  <>
                    &nbsp;
                    <RightOutlined
                      style={{
                        fontSize: 10,
                        color: "var(--bread-crumb-color-active)",
                      }}
                    />
                    <Link
                      to={
                        "/portal/" +
                        tailPathname
                          .split("/")
                          .slice(0, index + 1)
                          .join("/")
                      }
                    >
                      &nbsp;{t(`Breadcrumb.${tail}`)}
                    </Link>
                  </>
                )}
              </span>
            ))}
        </div>
        <div className={styles.info}>
          <div className={[styles.button].join(" ")}>
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.2"
                d="M15.6636 12.4276C15.5945 12.3473 15.5267 12.2669 15.4601 12.1894C14.5442 11.1202 13.9901 10.475 13.9901 7.4483C13.9901 5.88134 13.6016 4.59562 12.836 3.63134C12.2715 2.91897 11.5084 2.37857 10.5026 1.9792C10.4896 1.97225 10.4781 1.96313 10.4684 1.95228C10.1067 0.78308 9.11666 0 8.0001 0C6.88354 0 5.89396 0.78308 5.53218 1.95107C5.52253 1.96153 5.51113 1.97036 5.49846 1.97719C3.15128 2.90973 2.01057 4.69888 2.01057 7.4471C2.01057 10.475 1.45729 11.1202 0.540562 12.1882C0.473952 12.2657 0.406092 12.3445 0.336984 12.4264C0.158468 12.6342 0.0453637 12.887 0.0110561 13.1549C-0.0232514 13.4227 0.0226739 13.6945 0.143397 13.9379C0.400264 14.4603 0.947719 14.7845 1.57261 14.7845H14.4322C15.0542 14.7845 15.5979 14.4607 15.8556 13.9408C15.9768 13.6972 16.0232 13.4253 15.9892 13.1571C15.9551 12.8889 15.8422 12.6357 15.6636 12.4276ZM8.0001 18C8.60169 17.9995 9.19194 17.8419 9.70823 17.5439C10.2245 17.2459 10.6476 16.8186 10.9326 16.3073C10.9461 16.2828 10.9527 16.2553 10.9519 16.2276C10.9511 16.1999 10.9429 16.1729 10.928 16.1491C10.9132 16.1254 10.8922 16.1058 10.8672 16.0922C10.8422 16.0785 10.814 16.0714 10.7853 16.0714H5.21579C5.18703 16.0713 5.15874 16.0784 5.13366 16.092C5.10859 16.1056 5.08759 16.1252 5.07271 16.149C5.05782 16.1727 5.04957 16.1998 5.04874 16.2275C5.04792 16.2553 5.05455 16.2827 5.06799 16.3073C5.35298 16.8185 5.776 17.2458 6.29221 17.5438C6.80843 17.8418 7.39858 17.9995 8.0001 18Z"
                fill="#171832"
              />
            </svg>
            <div className={styles.dotNew} />
          </div>
          <div className={[styles.button].join(" ")}>
            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.2"
                d="M20.125 0H2.875C2.11275 0.000812161 1.38196 0.302777 0.842969 0.839637C0.303978 1.3765 0.000815384 2.1044 0 2.86364V15.1364C0.000815384 15.8956 0.303978 16.6235 0.842969 17.1604C1.38196 17.6972 2.11275 17.9992 2.875 18H20.125C20.8872 17.9992 21.618 17.6972 22.157 17.1604C22.696 16.6235 22.9992 15.8956 23 15.1364V2.86364C22.9992 2.1044 22.696 1.3765 22.157 0.839637C21.618 0.302777 20.8872 0.000812161 20.125 0ZM19.397 4.73676L12.0042 10.464C11.86 10.5757 11.6826 10.6363 11.5 10.6363C11.3174 10.6363 11.14 10.5757 10.9958 10.464L3.60299 4.73676C3.51613 4.67143 3.44317 4.58955 3.38836 4.49589C3.33354 4.40223 3.29796 4.29865 3.28369 4.19117C3.26941 4.08369 3.27672 3.97446 3.30519 3.86982C3.33366 3.76518 3.38273 3.66722 3.44954 3.58163C3.51635 3.49604 3.59957 3.42454 3.69436 3.37126C3.78916 3.31799 3.89364 3.28401 4.00174 3.2713C4.10983 3.25859 4.21938 3.26741 4.32402 3.29724C4.42867 3.32707 4.52632 3.37731 4.61129 3.44506L11.5 8.78165L18.3887 3.44506C18.561 3.31549 18.7775 3.25866 18.9915 3.28686C19.2055 3.31507 19.3997 3.42603 19.5322 3.59577C19.6647 3.7655 19.7248 3.98036 19.6995 4.19388C19.6742 4.4074 19.5655 4.60242 19.397 4.73676Z"
                fill="#171832"
              />
            </svg>
          </div>
          <Dropdown
            overlay={profileMenu}
            trigger={["click"]}
            onVisibleChange={(flag) => handleVisibleChange(flag)}
            visible={profileDropdown}
          >
            <div className={styles.profile}>
              <Avatar
                size={40}
                style={{
                  color: "var(--pure-white)",
                  backgroundColor: "var(--background-avatar-color)",
                }}
              >
                {" "}
                {(me && me?.name?.charAt(0)) || "UserName".charAt(0)}
              </Avatar>
              <div className={styles.name}>{(me && me?.name) || "UserName"}</div>
              &nbsp;&nbsp;
              <DownOutlined
                style={{
                  fontSize: 10,
                  lineHeight: "45px",
                }}
              />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default TopBar
