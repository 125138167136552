import React from "react"
import styles from "./CertificateList.module.css"
import {certificateList} from "../../index"
import TableList from "app/common/components/TableList"
import {utils} from "../../../../../../../../../config/utils"
import {FORMATTER} from "../../../../../../../../../config/constants"

const CertificateList = (props: {data?: certificateList; loading: boolean}) => {
  const {data, loading} = props
  const schema = [
    {
      dataIndex: "certificateCode",
      key: "certificateCode",
      title: "Mã thẻ",
    },
    {
      dataIndex: "planName",
      key: "planName",
      title: "Tên gói",
    },
    {
      dataIndex: "parentInsuredPersonName",
      key: "parentInsuredPersonName",
      title: "Người phụ thuộc",
      render: (text) => text || "...",
    },
    {
      dataIndex: "policyOwner",
      key: "policyOwner",
      title: "Chủ hợp dồng",
    },
    {
      key: "issueDuedAt",
      title: "hiệu lực",
      render: (text, record) =>
        `${utils.parseToStringLocalDateTime(
          record.issuedAt,
          FORMATTER.DATE_FORMAT,
        )} - ${utils.parseToStringLocalDateTime(record.duedAt, FORMATTER.DATE_FORMAT)}`,
    },
  ]
  return (
    <div className={styles.container}>
      <TableList
        schema={schema}
        data={data || []}
        loading={loading}
        rowKey={"certificateCode"}
        onView={() => {}}
        title={"Danh sách thẻ"}
      />
    </div>
  )
}

export default CertificateList
