import React, {useCallback, useEffect, useState} from "react"
import styles from "./PolicyScreen.module.css"
import AdminServices from "services/AdminServices"
import {PORTAL_PATH} from "app/portal/config/routes"
import TableList from "app/common/components/TableList"
import {listSchema} from "./schema"
import {Modal, Radio, Space} from "antd"
import {TYPE} from "config/constants"
import {useHistory, useParams} from "react-router"

type itemType = {
  key?: string
  policyId: string
  name: string
  code: string
  types: number[]
  createdAt: string
  updatedAt: string
}

const PolicyScreen = () => {
  const [data, setData] = useState<itemType[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const {companyId} = useParams<{companyId: string}>()
  const history = useHistory()
  const companyType = new URL(window.location.href).searchParams.get("role") || ""

  const getFirstLoad = useCallback(async () => {
    setLoading(true)
    const response = await AdminServices.getPolicyList()
    if (response.isSuccess) {
      setData(
        response.data.collection.map((item: itemType) => {
          item.key = item.policyId
          return item
        }),
      )
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    getFirstLoad().then()
  }, [getFirstLoad])

  const mapPolicyDataByCompanyType = useCallback(
    (policyList, companyType) => {
      if (companyType) {
        let newPolicyData = []
        switch (companyType) {
          case TYPE.COMPANY_TYPE_GQL.TPA:
            newPolicyData = policyList.filter((policy) => policy.tpaId === companyId)
            break
          case TYPE.COMPANY_TYPE_GQL.CORPORATE:
            newPolicyData = policyList.filter((policy) => policy.policyOwnerId === companyId)
            break
          case TYPE.COMPANY_TYPE_GQL.INSURER:
            newPolicyData = policyList.filter((policy) => policy.insurerId === companyId)
            break
          case TYPE.COMPANY_TYPE_GQL.BROKER:
            newPolicyData = policyList.filter((policy) => policy.brokerId === companyId)
            break
        }
        return newPolicyData
      }
      return policyList
    },
    [companyId],
  )

  return (
    <div className={styles.container}>
      <TableList
        title={"hợp đồng BH"}
        onCreate={() => {
          setShowModal(true)
        }}
        onView={(record: itemType) => {
          let path = PORTAL_PATH.POLICY_INFO
          path = path.replace(":id", record.policyId)
          history.push(path)
        }}
        schema={listSchema}
        loading={loading}
        data={mapPolicyDataByCompanyType(data, companyType)}
      />

      <Modal
        className={styles.ownerTypeContainer}
        visible={showModal}
        footer={null}
        centered
        closable={false}
        onCancel={() => setShowModal(false)}
      >
        <Space direction={"vertical"} size={24}>
          <h3>Chọn loại</h3>

          <Radio.Group
            onChange={(e) => {
              history.push(`${PORTAL_PATH.POLICY_LIST}/create/${e.target.value}`)
            }}
          >
            <Space direction={"vertical"} size={16} align={"start"}>
              <Radio className={styles.radioStyle} value={TYPE.OWNER_TYPE.INDIVIDUAL}>
                Cá nhân
              </Radio>
              <Radio className={styles.radioStyle} value={TYPE.OWNER_TYPE.CORPORATE}>
                Doanh nghiệp
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
      </Modal>
    </div>
  )
}

export default PolicyScreen
