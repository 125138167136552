import {IResponseData} from "store/interface"
import {RESPONSE_CODE} from "config/constants"

export * from "./endpoints"
export * from "./services"
export * from "./AuthServices"

const axiosResponseToData = (response: any): IResponseData => {
  const data: IResponseData = {
    success: null,
    error: null,
  }
  //handling response axios here
  if (response.data) {
    data.success = {
      data: response.data.data.data,
      code: response.data.data.code,
      message: response.data.data.message,
    }
  }

  if (response.error) {
    data.error = {
      data: response.error.data.errors,
      code: response.error.data.status,
      message: response.error.data.title,
    }
  }
  return data
}

const defaultResponse = (axiosResponse: {success: any; error: any}) => {
  if (axiosResponse.success) {
    if (axiosResponse.success.code === RESPONSE_CODE.SUCCESS_200) {
      return {
        isSuccess: true,
        messages: axiosResponse.success.message,
        data: axiosResponse.success.data,
      }
    } else {
      return {
        isSuccess: false,
        messages: axiosResponse.success.message,
        code: axiosResponse.success.code,
        ...{data: axiosResponse.success.data || {}},
      }
    }
  } else {
    if (!axiosResponse.success && !axiosResponse.error) {
      return {
        isSuccess: false,
        messages: "Lỗi kết nối",
      }
    }
    if (axiosResponse.error?.code === RESPONSE_CODE.ERROR_VALIDATION) {
      return {
        isSuccess: false,
        messages: axiosResponse.error.message,
        data: axiosResponse.error.data,
        code: axiosResponse.error.data.code,
      }
    }
    return {isSuccess: false}
  }
}

export {axiosResponseToData, defaultResponse}
