import React, {useState} from "react"
import styles from "./PlanBenefitScreen.module.css"
import _ from "lodash"
import PlanService from "services/PlanServices"
import TransferComponent from "./components/TransferComponent"
import {Button, message, Spin, Tag} from "antd"
import {useParams} from "react-router"
import {TYPE} from "config/constants"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../../../../../store/interface"
import {useMountEffect} from "@react-hookz/web"

const PlanBenefitScreen = () => {
  const params = useParams<{planId: string}>()
  const benefitTypeOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const [loadingInfo, setLoadingInfo] = useState<boolean>(true)
  const [benefitList, setBenefitList] = useState([])
  const [filterType, setFilterType] = useState<string>("")
  const [planBenefit, setPlanBenefit] = useState<string[]>([])
  const [directBilling, setDirectBilling] = useState<string[]>([])
  const [applyToDependents, setApplyToDependents] = useState<string[]>([])
  const getBenefitData = async () => {
    const {planId} = params
    if (planId) {
      const response = await PlanService.getPlanBenefit(planId)
      if (response.isSuccess) {
        let planBenefitData = []
        let directBillingData = []
        let applyToDependentsData = []

        if (response.data && response.data.length) {
          const responseData = response.data.filter((r) => r !== null)
          planBenefitData = responseData.map((b) => b.insuredBenefitId)
          setPlanBenefit(planBenefitData)
          directBillingData = responseData.filter((b) => b.isDirectBilling).map((b) => b.insuredBenefitId)
          setDirectBilling(directBillingData)
          applyToDependentsData = responseData.filter((b) => b.applyToDependents).map((b) => b.insuredBenefitId)
          setApplyToDependents(applyToDependentsData)
        }
      }
    }
    setLoadingInfo(false)
  }

  const getBenefitList = async () => {
    const response = await PlanService.getInsuredBenefit()
    if (response.isSuccess) {
      let benefitData = []
      if (response.data && response.data.length) {
        benefitData = response.data.map((b) => ({
          key: b.insuredBenefitId,
          title: `${b.type}|D|${b.code}|D|${b.name}`,
        }))
        setBenefitList(benefitData)
      }
    }
  }

  const getFirstLoad = async () => {
    await getBenefitList()
    await getBenefitData()
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  const updateBenefitPlan = async () => {
    const {planId} = params
    if (planId) {
      const updateData: {insuredBenefitId: string; isDirectBilling: boolean}[] = planBenefit.map((pb) => ({
        insuredBenefitId: pb,
        isDirectBilling: directBilling.includes(pb),
        applyToDependents: applyToDependents.includes(pb),
      }))
      const response = await PlanService.updatePlanBenefit(planId, updateData)
      if (response.isSuccess) {
        getBenefitData().then()
        message.success("Cập nhật thành công")
      }
    }
  }

  const handleChange = (key: string | string[], isDirectBilling?: boolean): void => {
    if (isDirectBilling !== undefined) {
      const newDirectBilling = _.clone(directBilling)
      if (isDirectBilling) {
        if (!newDirectBilling.includes(key.toString())) {
          newDirectBilling.push(key.toString())
        }
      } else {
        newDirectBilling.splice(newDirectBilling.indexOf(key.toString()), 1)
      }
      setDirectBilling(newDirectBilling)
    } else {
      setPlanBenefit(key as string[])
    }
  }

  const onDirectBillingChange = (key: string, isDirectBilling: boolean, keys?: string[]) => {
    const newDirectBilling = _.clone(directBilling)
    if (isDirectBilling) {
      if (keys && keys.length) {
        for (const itemKey of keys) {
          if (!newDirectBilling.includes(itemKey)) {
            newDirectBilling.push(itemKey)
          }
        }
      } else if (!newDirectBilling.includes(key.toString())) {
        newDirectBilling.push(key.toString())
      }
    } else {
      if (keys && keys.length) {
        for (const itemKey of keys) {
          if (newDirectBilling.includes(itemKey)) {
            newDirectBilling.splice(newDirectBilling.indexOf(itemKey), 1)
          }
        }
      } else if (newDirectBilling.includes(key.toString())) {
        newDirectBilling.splice(newDirectBilling.indexOf(key.toString()), 1)
      }
    }
    setDirectBilling(newDirectBilling)
  }

  const onApplyToDepsChange = (key: string, isApplyToDependents: boolean, keys?: string[]) => {
    const newApplyToDependents = _.clone(applyToDependents)
    if (isApplyToDependents) {
      if (keys && keys.length) {
        for (const itemKey of keys) {
          if (!newApplyToDependents.includes(itemKey)) {
            newApplyToDependents.push(itemKey)
          }
        }
      } else if (!newApplyToDependents.includes(key.toString())) {
        newApplyToDependents.push(key.toString())
      }
    } else {
      if (keys && keys.length) {
        for (const itemKey of keys) {
          if (newApplyToDependents.includes(itemKey)) {
            newApplyToDependents.splice(newApplyToDependents.indexOf(itemKey), 1)
          }
        }
      } else if (newApplyToDependents.includes(key.toString())) {
        newApplyToDependents.splice(newApplyToDependents.indexOf(key.toString()), 1)
      }
    }
    setApplyToDependents(newApplyToDependents)
  }

  const countByType = () => {
    const filterByValueMapType = benefitList
      .filter((b: {key: string}) => planBenefit.includes(b.key))
      .map((data: {title: string}) => data.title.split("|D|")[0])
    return _.countBy(filterByValueMapType)
  }

  return (
    <div className={styles.container}>
      <div className={styles.statsTypeContainer}>
        {Object.keys(countByType()).map((key) => (
          <div className={styles.statsType} key={key}>
            <Tag
              onClick={() => setFilterType(key)}
              className={["large-font-size", filterType === key ? styles.filterActive : ""].join(" ")}
              color={
                TYPE.BENEFIT_TYPE_NAME[key] === 1
                  ? "volcano"
                  : TYPE.BENEFIT_TYPE_NAME[key] === 2
                  ? "cyan"
                  : TYPE.BENEFIT_TYPE_NAME[key] === 4
                  ? "pink"
                  : "gold"
              }
            >
              {benefitTypeOption.find((b) => b.value === key)?.name || key}
              <div className={styles.count}>{countByType()[key]}</div>
            </Tag>
          </div>
        ))}
      </div>

      <Spin spinning={loadingInfo}>
        <TransferComponent
          onChange={handleChange}
          onDirectBillingChange={onDirectBillingChange}
          onApplyToDepsChange={onApplyToDepsChange}
          value={planBenefit}
          searchRight={filterType}
          dataSource={benefitList}
          directBilling={directBilling}
          applyToDeps={applyToDependents}
        />
        <div className={styles.actionBar}>
          <Button className={styles.actionButton} size="large" type="primary" onClick={() => updateBenefitPlan()}>
            Lưu lại
          </Button>
        </div>
      </Spin>
    </div>
  )
}

export default PlanBenefitScreen
