import React, {useState} from "react"
import styles from "./BottomBar.module.css"
import {Button, Select, Space} from "antd"
import ClaimServices from "services/ClaimServices"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"
import {TYPE} from "config/constants"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"

const {Option} = Select

const BottomBar = (props: {
  claimCaseId: string
  value: string
  onUpdate: () => void
  disabledUpdate?: boolean
  handleChange: (value: string) => void
}) => {
  const {claimCaseId, value, onUpdate, disabledUpdate, handleChange} = props
  const statusTypeOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.CLAIM_CASE_STATUS],
  )
  const [statusList, setStatusList] = useState<string[]>([])
  const getStatusList = async () => {
    if (claimCaseId) {
      const response = await ClaimServices.getClaimCaseStatusList(claimCaseId)
      if (response.isSuccess) {
        setStatusList(response.data)
      }
    }
  }

  useMountEffect(() => {
    getStatusList().then()
  })

  useUpdateEffect(() => {
    getStatusList().then()
  }, [claimCaseId])

  return (
    <div className={[styles.bottomBar, "fixedLayout"].join(" ")}>
      <div className={styles.leftSide}>
        {claimCaseId ? (
          <Select
            showSearch
            style={{width: 250}}
            placeholder="Đổi trạng thái"
            optionFilterProp="children"
            size={"large"}
            onChange={(value) => handleChange(value)}
            value={value || ""}
          >
            {statusTypeOption && statusTypeOption.length && statusList && statusList.length
              ? statusTypeOption
                  .filter((sts) => statusList.includes(sts.value))
                  .map((status: {name: string; value: string}) => (
                    <Option value={status.value} key={status.value}>
                      {status.name}
                    </Option>
                  ))
              : ""}
          </Select>
        ) : (
          ""
        )}
      </div>
      <div className={styles.rightSide}>
        <Space align={"center"} size={16}>
          <Button disabled={disabledUpdate} type={"primary"} size={"large"} onClick={onUpdate}>
            {claimCaseId ? "Lưu lại" : "Khởi tạo"}
          </Button>
        </Space>
      </div>
    </div>
  )
}

export default BottomBar
