import Service from "../services"
import endPoints from "../endpoints"
import {axiosResponseToData, defaultResponse} from "../index"

interface TargetData {
  applyToVoluntaryTarget?: boolean
  applyTargetAgeRange?: boolean
  applyTargetCondition?: boolean
  applyToWillingTarget?: boolean
  applyToTargetAgeRange?: boolean
  applyToTarget?: boolean
  target?: string
  ageRanges?: {from: undefined | number; to: number | undefined}[]
}

interface BlackListData {
  applyToBlackList?: boolean
  insuredCertificateIds?: string[]
}

interface CoPaymentData {
  value: number
  applyToAllPlans: boolean
  planIds: string[]
  planInsuredBenefitIds: string[]
  applyToAllMedicalProvider: boolean
  medicalProviderIds: string[]
}

const CoPaymentServices = {
  //Policy CoPayment
  getPolicyCoPayment: async (policyId: string) => {
    const response = await Service(true).get(endPoints.POLICY_COPAYMENT(policyId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createPolicyCoPayment: async (policyId: string, coPaymentData: CoPaymentData) => {
    const response = await Service(true).post(endPoints.POLICY_COPAYMENT(policyId), coPaymentData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getDetailPolicyCoPayment: async (coPaymentId: string) => {
    const response = await Service(true).get(endPoints.POLICY_COPAYMENT_DETAIL(coPaymentId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateDetailPolicyCoPayment: async (coPaymentId: string, coPaymentData: CoPaymentData) => {
    const response = await Service(true).post(endPoints.POLICY_COPAYMENT_DETAIL(coPaymentId), coPaymentData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createPolicyCoPaymentTarget: async (coPaymentId: string, targetData: TargetData) => {
    const response = await Service(true).post(endPoints.POLICY_COPAYMENT_DETAIL_TARGETS(coPaymentId), targetData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createPolicyCoPaymentBlackList: async (coPaymentId: string, blackListData: BlackListData) => {
    const response = await Service(true).post(endPoints.POLICY_COPAYMENT_DETAIL_BLACKLISTS(coPaymentId), blackListData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  deletePolicyCoPaymentBlackLists: async (coPaymentId: string, blackListIds: string[]) => {
    const response = await Service(true).patch(endPoints.POLICY_COPAYMENT_DETAIL_BLACKLISTS(coPaymentId), blackListIds)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  deletePolicyCoPaymentBenefit: async (coPaymentId: string, benefitId: string) => {
    const response = await Service(true).patch(endPoints.POLICY_COPAYMENT_DETAIL_SINGLE_BENEFIT(coPaymentId, benefitId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  deletePolicyCoPaymentMedicalProvider: async (coPaymentId: string, medicalProviderIds: string[]) => {
    const response = await Service(true).patch(
      endPoints.POLICY_COPAYMENT_DETAIL_MEDICAL_PROVIDERS(coPaymentId),
      medicalProviderIds,
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
}

export default CoPaymentServices
