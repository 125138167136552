import React from "react"
import {Avatar, Col, Collapse, Empty, Row, Spin, Tooltip} from "antd"
import {RightSquareOutlined} from "@ant-design/icons"
import styles from "./SignOff.module.css"
import moment from "moment"
import ReactTimeago from "react-timeago"
import {FORMATTER} from "config/constants"
import {useSelector} from "react-redux"
import {createSelector} from "reselect"
import {MeType} from "store/interface"

const {Panel} = Collapse

const getNameInMeState = createSelector(
  (state: {me: MeType}) => state.me,
  (me) => (me ? me.name : ""),
)

interface IClaimNote {
  content: string
  createdAt: string
  createdBy: string
  type: string
}

const SignOff = (props: {data: IClaimNote[]; loading: boolean}) => {
  const userName = useSelector(getNameInMeState)
  const {data, loading} = props

  const sortByTime = (data: IClaimNote[]) =>
    data.sort(function compare(a, b) {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateA - dateB
    })

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
    >
      <Panel header={"Ghi chú"} key={"1"}>
        <Row className={styles.signOffContainer}>
          <Col span={24} className={styles.msgSignOffContainer}>
            <Spin spinning={loading}>
              {data && data.length ? (
                sortByTime(data).map((bubble) => (
                  <Row
                    key={bubble.createdAt}
                    className={[styles.bubbleMsg, userName === bubble.createdBy ? styles.myBubble : ""].join(" ")}
                  >
                    <div className={styles.avatarContainer}>
                      <Avatar size={30} className={styles.avatar}>
                        {bubble.createdBy.charAt(0)}
                      </Avatar>
                    </div>
                    <div className={styles.bubbleContent}>
                      <div>
                        <strong>{bubble.createdBy}</strong> ·{" "}
                        <Tooltip title={moment(bubble.createdAt).format(FORMATTER.DATE_TIME_FORMAT)}>
                          <ReactTimeago date={bubble.createdAt} live />
                        </Tooltip>
                      </div>
                      <div>
                        <div>{bubble.content}</div>
                      </div>
                    </div>
                  </Row>
                ))
              ) : (
                <Empty className={styles.empty} description={false} />
              )}
            </Spin>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default SignOff
