import React, {useState} from "react"
import {useSelector} from "react-redux"
import {useTranslation} from "react-i18next"
import styles from "./LoginScreen.module.css"
import {Button, Form, Input, message} from "antd"
import {LockOutlined, UserOutlined} from "@ant-design/icons"
import {PORTAL_PATH} from "app/portal/config/routes"
import AuthServices from "services/AuthServices"
import {useHistory} from "react-router"
import {useMountEffect} from "@react-hookz/web"

const LoginScreen = () => {
  const token = useSelector((state: {token: string}) => state.token)
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [generalError, setGeneralError] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const {t} = useTranslation("common")
  const history = useHistory()

  const checkValid = async () => {
    return token
    // return ClaimPortalService.me();
  }

  const verifyOldToken = async () => {
    const response = await checkValid()
    if (response) {
      history.push(PORTAL_PATH.DASHBOARD)
    }
  }

  const doLogin = async () => {
    setLoading(true)
    if (email && password) {
      const result = await AuthServices.login(email, password)
      setLoading(false)
      if (result.isSuccess) {
        message.success(result.messages)
        history.push(PORTAL_PATH.DASHBOARD)
      } else {
        message.error(result.messages)
        setGeneralError(true)
      }
    }
  }

  const enterToLogin = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      doLogin().then()
    }
  }

  useMountEffect(() => {
    window.addEventListener("keyup", enterToLogin)
    verifyOldToken().then()
    return window.removeEventListener("keyup", enterToLogin)
  })

  return (
    <div className={styles.container}>
      <div className={styles.loginPart}>
        <div className={styles.logo} />
        <div className={styles.loginForm}>
          <h1>{t("LoginScreen.LoginTitle")}</h1>
          <Form name="loginForm" onFinish={() => doLogin()}>
            <Form.Item
              name="Email"
              validateStatus={(generalError && "error") || undefined}
              rules={[
                {
                  required: true,
                  message: t("LoginScreen.RequireEmail"),
                },
                (email && {
                  type: "email",
                  message: t("LoginScreen.InvalidEmail"),
                }) ||
                  {},
              ]}
            >
              <Input
                size="large"
                value={email}
                prefix={<UserOutlined />}
                onChange={(e) => {
                  setEmail(e.target.value)
                  e.preventDefault()
                }}
                placeholder={t("LoginScreen.Email")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              validateStatus={(generalError && "error") || undefined}
              rules={[
                {
                  required: true,
                  message: t("LoginScreen.RequirePassword"),
                },
                {
                  pattern: new RegExp(/^\S+$/),
                  message: t("LoginScreen.NoneSpacePassword"),
                },
              ]}
            >
              <Input.Password
                size="large"
                value={password}
                prefix={<LockOutlined />}
                onChange={(e) => {
                  setPassword(e.target.value)
                  e.preventDefault()
                }}
                placeholder={t("LoginScreen.Password")}
              />
            </Form.Item>
            <Form.Item></Form.Item>
            <Form.Item>
              <Button size="large" shape="round" type="primary" loading={isLoading} htmlType="submit">
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.testimonial}>
        <div />
      </div>
    </div>
  )
}
export default LoginScreen
