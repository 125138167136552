import React, {useState} from "react"
import styles from "./ClaimCasePaymentScreen.module.css"
import BaseForm from "app/common/components/Form"
import {paymentSchema} from "./schema"
import _ from "lodash"
import {message} from "antd"
import OptionListServices from "services/OptionListServices"
import {METADATA} from "config/constants"
import ClaimServices from "services/ClaimServices"
import {useParams} from "react-router"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"

export type paymentDataType = {
  paymentAccountName?: string
  paymentAccountNumber?: string
  paymentBankBranch?: string
  paymentBankCity?: string
  paymentBankName?: string
}

const ClaimCasePaymentScreen = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<paymentDataType>({})
  const [bankList, setBankList] = useState<{name: string; value: string}[]>([])
  const [cityList, setCityList] = useState([])
  const {claimCaseId} = useParams<{claimCaseId: string}>()

  const getFirstLoad = async () => {
    const banks = await OptionListServices.getMetadata(METADATA.BANK)
    if (banks && banks.data && banks.data.collection) {
      setBankList(banks.data.collection)
    }
    const city = await OptionListServices.getMetadata(METADATA.CITY)
    if (city && city.data && city.data.collection) {
      setCityList(city.data.collection)
    }
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  const getClaimPayment = async () => {
    const data = await ClaimServices.getClaimCasePayment(claimCaseId)
    if (data && data.data) {
      if (data.data.paymentBankName) {
        const newBankName = bankList.filter((bank) => bank.value === data.data.paymentBankName)[0]
        if (newBankName) {
          data.data.paymentBankName = newBankName.name
        }
        setData(data.data)
      }
    }
    setLoading(false)
  }

  useUpdateEffect(() => {
    getClaimPayment().then()
  }, [bankList])

  const handleOptionMetadata = () => {
    const newBankList = bankList.map((bank: {name: string; value: string}) => {
      return {
        name: bank.name,
        value: bank.name,
      }
    })
    return {
      paymentBankName: newBankList,
      paymentBankCity: cityList,
    }
  }

  const handleChange = (key: string, value: string) => {
    let newData = _.clone(data)
    if (value) {
      if (!newData) {
        newData = {}
      }
      newData[key] = value
      setData(newData)
    } else {
      delete newData[key]
      setData(newData)
    }
  }

  const onSave = async () => {
    if (claimCaseId) {
      setLoading(true)
      if (data) {
        const response = await ClaimServices.updateClaimCasePayment(claimCaseId, data)
        if (response.isSuccess) {
          message.success("Cập nhật thông tin thanh toán thành công")
          await getClaimPayment()
        } else {
          message.error(response.messages)
        }
      }
      setLoading(false)
    }
  }

  return (
    <div>
      <BaseForm
        title={"Thông tin thanh toán" || undefined}
        className={styles.containerForm}
        schemas={paymentSchema}
        option={handleOptionMetadata()}
        loading={loading}
        onChange={(key: string, value: string) => handleChange(key, value)}
        data={data}
        onSave={() => onSave()}
      />
    </div>
  )
}

export default ClaimCasePaymentScreen
