import Service from "../services"
import endPoints from "../endpoints"
import {axiosResponseToData} from "../index"
import store from "store/store"
import {setMe, setToken} from "store/commonActions"
import {RESPONSE_CODE} from "config/constants"
import {IReturnResponse, MeType} from "store/interface"
import {logout} from "store/commonActions/token"
import {message} from "antd"

const AuthServices = {
  login: async (email: string, password: string): Promise<IReturnResponse> => {
    const response = await Service().post(endPoints.LOGIN, {
      email,
      password,
      isPortalApp: true,
    })
    const data = axiosResponseToData(response)
    if (data.success) {
      if (data.success.code === RESPONSE_CODE.SUCCESS_004) {
        store.dispatch(setToken(data.success.data.accessToken))
        return {
          isSuccess: true,
          messages: data.success.message,
        }
      } else {
        return {
          isSuccess: false,
          messages: data.success.message,
        }
      }
    } else {
      if (!data.success && !data.error) {
        return {
          isSuccess: false,
          messages: "Lỗi kết nối",
        }
      }
      if (data.error?.code === RESPONSE_CODE.ERROR_VALIDATION) {
        return {
          isSuccess: false,
          messages: data.error.message,
          data: data.error.data,
        }
      }
      return {isSuccess: false}
    }
  },
  logout: async () => {
    message.success("Đăng xuất thành công")
    store.dispatch(logout())
  },
  me: async () => {
    const response = await Service(true).get(endPoints.ME)
    const data = axiosResponseToData(response)
    if (data.success) {
      if (data.success.code === RESPONSE_CODE.SUCCESS_002) {
        store.dispatch(setMe(data.success.data as MeType))
        return {
          isSuccess: true,
          messages: data.success.message,
        }
      } else {
        return {
          isSuccess: false,
          messages: data.success.message,
        }
      }
    } else {
      if (!data.success && !data.error) {
        return {
          isSuccess: false,
          messages: "Lỗi kết nối",
        }
      }
      return {
        isSuccess: false,
        messages: "Phiên đăng nhập hết hạn",
      }
    }
  },
}

export default AuthServices
