import React, {useEffect, useState} from "react"
import styles from "../InsuredPersonScreen.module.css"
import BaseForm from "../../../../../common/components/Form"
import _ from "lodash"
import AdminServices from "../../../../../../services/AdminServices"
import {PORTAL_PATH} from "../../../../config/routes"
import {message, Modal, Spin} from "antd"
import Button from "antd-button-color"
import {EditOutlined} from "@ant-design/icons"
import TableList from "../../../../../common/components/TableList"
import {formSchema, listCertificateSchema, option} from "../schema"
import {FORMATTER} from "../../../../../../config/constants"
import {useHistory, useParams} from "react-router"
import {utils} from "../../../../../../config/utils"

export type InsuredPersonTypeUpdate = {
  id?: string
  name?: string
  insuredNumber?: string
  paperId?: string
  paperIssuedAt?: Date
  formatPaperIssuedAt?: string
  paperIssuedPlace?: string
  genderName?: string
  gender?: number
  dob?: Date
  formatDob?: string
  phone?: string
  email?: string
  createdAt?: string
  updatedAt?: string
}

interface CertificateList {
  certificateCode?: string
  duedAt?: string
  issuedAt?: string
  policyOwner?: string
  parent?: string
  policyId?: string
  insuredCertificateId?: string
}

const InsuredPersonDetailScreen = () => {
  const [loading, setLoading] = useState(false)
  const [showModalInsuredPerson, setShowModalInsuredPerson] = useState(false)
  const [data, setData] = useState<InsuredPersonTypeUpdate>({})
  const [certificateList, setCertificateList] = useState<CertificateList[]>([])
  const {id} = useParams<{id: string}>()
  const history = useHistory()

  const handleChange = (key: string, value: string) => {
    const newData = _.clone(data) || {}
    if (key === "gender" || value) {
      newData[key] = value
      setData(newData)
    } else {
      delete newData[key]
      setData(newData)
    }
  }

  const getFirstLoad = async () => {
    if (id) {
      const response = await AdminServices.getInsuredPersonById(id)
      if (response.isSuccess) {
        response.data.formatDob = response.data.dob
        response.data.formatPaperIssuedAt = response.data.paperIssuedAt
        setData(response.data)
      }
    }
    setLoading(false)
  }

  const updateInsuredPerson = async () => {
    setLoading(true)
    if (data) {
      const response = await AdminServices.updateInsuredPerson(id, data)
      if (response.isSuccess) {
        message.success("Update người được bảo hiểm thành công")
        await getFirstLoad()
      } else {
        message.error(response.messages)
      }
    }
    setLoading(false)
    setShowModalInsuredPerson(false)
    getFirstLoad().then()
  }

  const onSave = async () => {
    if (id) {
      await updateInsuredPerson()
    }
  }

  const getCertificateList = async () => {
    const certificateList = await AdminServices.getInsuredPersonCertificates(id)
    if (certificateList.isSuccess && certificateList.data.collection) {
      setCertificateList(certificateList.data.collection)
    }
  };

  useEffect(() => {
    getCertificateList().then()
    getFirstLoad().then()
  }, [])

  return (
    <div className={styles.container}>
      <Spin spinning={loading}>
        <div className={styles.insuredInformation}>
          <Button
            icon={<EditOutlined />}
            size={"middle"}
            type={"lightdark"}
            className={styles.editButton}
            onClick={() => setShowModalInsuredPerson(true)}
          >
            SỬA
          </Button>
          <div className={styles.insuredDetail}>
            <div className={styles.title}>Họ tên:</div>
            <div className={styles.content}>{data.name}</div>
          </div>

          <div className={styles.insuredDetail}>
            <div className={styles.title}>CMND/CCCD:</div>
            <div className={styles.content}>{data.paperId}</div>
          </div>

          <div className={styles.insuredDetail}>
            <div className={styles.title}>Giới tính:</div>
            <div className={styles.content}>{data.genderName}</div>
          </div>

          <div className={styles.insuredDetail}>
            <div className={styles.title}>Ngày sinh:</div>
            <div className={styles.content}>
              {utils.parseToStringLocalDateTime(data.dob?.toString(), FORMATTER.DATE_FORMAT)}
            </div>
          </div>

          <div className={styles.insuredDetail}>
            <div className={styles.title}>Email:</div>
            <div className={styles.content}>{data.email}</div>
          </div>

          <div className={styles.insuredDetail}>
            <div className={styles.title}>Số điện thoại:</div>
            <div className={styles.content}>{data.phone}</div>
          </div>

          <div className={styles.insuredDetail}>
            <div className={styles.title}>Nơi cấp CMND/CCCD:</div>
            <div className={styles.content}>{data.paperIssuedPlace}</div>
          </div>

          <div className={styles.insuredDetail}>
            <div className={styles.title}>Ngày cấp CMND/CCCD:</div>
            <div className={styles.content}>
              {utils.parseToStringLocalDateTime(data.formatPaperIssuedAt?.toString(), FORMATTER.DATE_FORMAT)}
            </div>
          </div>

          <div className={styles.insuredDetail}>
            <div className={styles.title}>Mã người được bảo hiểm:</div>
            <div className={styles.content}>{data.insuredNumber}</div>
          </div>
        </div>
      </Spin>

      <div className={styles.certificateContainer}>
        <TableList
          title={"chứng chỉ bảo hiểm"}
          onView={(record) => {
            let path = PORTAL_PATH.CERTIFICATE_DETAIL.replace(":policyId", record.policyId)
            path = path.replace(":certificateId", record.insuredCertificateId)
            history.push(path)
          }}
          schema={listCertificateSchema}
          data={certificateList || []}
        />
      </div>
      <Modal
        title=""
        centered
        visible={showModalInsuredPerson}
        width={900}
        onCancel={() => setShowModalInsuredPerson(false)}
        footer={[]}
      >
        <BaseForm
          title={""}
          className={styles.containerForm}
          schemas={formSchema}
          option={option}
          loading={loading}
          onChange={(key: string, value: string) => handleChange(key, value)}
          data={data || {}}
          onSave={() => onSave()}
          onCancel={() => setShowModalInsuredPerson(false)}
        />
      </Modal>
    </div>
  )
}

export default InsuredPersonDetailScreen
