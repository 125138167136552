import React, {useState} from "react"
import styles from "./HistoryClaimScreen.module.css"
import {Checkbox, message, Modal, notification, Select, Tag} from "antd"
import TableList from "app/common/components/TableList"
import ClaimServices from "services/ClaimServices"
import {FORMATTER, TYPE} from "config/constants"
import {useHistory, useParams} from "react-router"
import {utils} from "config/utils"
import {useMountEffect} from "@react-hookz/web"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../../../store/interface"
import InfoBlock, {schemaType} from "../../../../../../common/components/InfoBlock"
import ButtonColor from "antd-button-color"
import {IBenefit} from "../ClaimCaseScreen/screens/ClaimCaseInfoScreen"
import CertificateList from "./components/CertificateList"
import DetailClaimForm, {IClaimInfo} from "./components/DetailClaimForm"
import _ from "lodash"
import moment from "moment"
import {CloseOutlined} from "@ant-design/icons"
import {PORTAL_PATH} from "../../../../../config/routes"
import {CLAIM_CASE_PATH} from "../ClaimCaseScreen/routes"

type ClaimCaseType = {
  certificateCode: string
  code: string
  insurer: string
  updatedAt: string
  status: string
}

type InsuredPersonType = {
  age: number
  createdAt: string
  dob: string
  email: string
  gender: number
  genderName: string
  insuredNumber: string
  insuredPersonId: string
  name: string
  paperId: string
  paperIssuedAt: number
  paperIssuedPlace: string
  phone: string
  updatedAt: string
}

export type certificateType = {
  benefitTypes: {name: string; value: string}[]
  benefits: IBenefit[]
  effectiveDueDate: string
  insuredPersonName: string
  insuredPersonParentName: string
  insurer: string
  planId: string
  policyId: string
}

export type certificateList = {
  certificateCode: string
  duedAt: string
  insuredCertificateId: string
  issuedAt: string
  parentInsuredPersonName: string
  planName: string
  policyOwner: string
}[]

export type claimRequestType = {
  benefitType?: string
  isDirectBilling?: boolean
  insuredPersonId: string
  email?: string
  phone?: string
  diseases?: string
  diagnosis?: string
  eventDates?: string
  treatmentMethod?: string
  medicalProviderId?: string
  medicalProviderName?: string
  source: string
  requestAmount?: number
  status?: string
}

const IS_DIRECT_BILLING = "isDirectBilling"

const HistoryClaimScreen = () => {
  const history = useHistory()
  const {insuredPersonId} = useParams<{insuredPersonId: string}>()
  const [insuredPersonDetail, setInsuredPersonDetail] = useState<InsuredPersonType>()
  const [claimCases, setClaimCases] = useState<ClaimCaseType[]>()
  const [certificateList, setCertificateList] = useState<certificateList>([])
  const [benefitTypesList, setBenefitTypesList] = useState<{name: string; value: string}[]>([])
  const [claimData, setClaimData] = useState<IClaimInfo>({})
  const [loadingHistory, setLoadingHistory] = useState<boolean>(true)
  const [loadingInfo, setLoadingInfo] = useState<boolean>(true)
  const [loadingCertificateList, setLoadingCertificateList] = useState<boolean>(true)
  const [loadingCreateClaim, setLoadingCreateClaim] = useState<boolean>(false)
  const [isShowModal, showModal] = useState<boolean>(false)
  const statusTypeOption = useSelector((state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.CLAIM_STATUS])

  const getInsuredPersonDetail = async () => {
    const response = await ClaimServices.getInsuredPersonDetail(insuredPersonId)
    if (response && response.data) {
      setInsuredPersonDetail(response.data)
    }
    setLoadingInfo(false)
  }
  const getInsuredPersonCertificate = async () => {
    const response = await ClaimServices.getInsuredPersonCertificate(insuredPersonId)
    if (response && response.data && response.data.collection) {
      setCertificateList(response.data.collection)
    }
    setLoadingCertificateList(false)
  }
  const getInsuredPersonBenefitTypes = async () => {
    const response = await ClaimServices.getInsuredPersonBenefitTypes(insuredPersonId)
    if (response && response.data) {
      setBenefitTypesList(response.data)
    }
    setLoadingCertificateList(false)
  }
  const getClaimCase = async () => {
    const response = await ClaimServices.getInsuredPersonClaimCase(insuredPersonId)
    if (response && response.data && response.data.collection) {
      setClaimCases(response.data.collection)
    }
    setLoadingHistory(false)
  }
  const getFirstLoad = async () => {
    getInsuredPersonDetail().then()
    getClaimCase().then()
    getInsuredPersonCertificate().then()
    getInsuredPersonBenefitTypes().then()
  }
  useMountEffect(() => {
    getFirstLoad().then()
  })
  const onChangeClaimData = (value, name) => {
    let newClaimData: IClaimInfo = {}
    newClaimData = _.clone(claimData)
    if (name === IS_DIRECT_BILLING) {
      newClaimData.medicalProviderItem = undefined
      newClaimData.medicalProviderName = undefined
      newClaimData.medicalProviderId = undefined
    }
    if (newClaimData) {
      newClaimData[name] = value
    }
    setClaimData(newClaimData)
  }
  const createClaimRequest = async () => {
    setLoadingCreateClaim(true)
    const createClaimRequest: claimRequestType = {
      source: "",
      insuredPersonId: insuredPersonId,
    }
    if (claimData) {
      createClaimRequest.isDirectBilling = claimData.isDirectBilling
      createClaimRequest.benefitType = claimData?.benefitType
      if (claimData.eventDates || claimData.eventDateRanges?.length) {
        createClaimRequest.eventDates =
          claimData.eventDateRanges &&
          moment.isDate(new Date(claimData.eventDateRanges[0])) &&
          moment.isDate(new Date(claimData.eventDateRanges[1]))
            ? `${utils.parseLocalTime(claimData.eventDateRanges[0])}&${utils.parseLocalTime(
                claimData.eventDateRanges[1],
              )}`
            : ""
        if (claimData.eventDates === "Invalid date") claimData.eventDates = ""
      }

      createClaimRequest.medicalProviderId =
        (claimData.medicalProviderItem && claimData.medicalProviderItem.value) ||
        claimData.medicalProviderAssessmentId ||
        ""
      createClaimRequest.medicalProviderName =
        (claimData.medicalProviderItem && claimData.medicalProviderItem.label) ||
        claimData.medicalProviderAssessmentName ||
        ""
      createClaimRequest.diseases = claimData.diseases?.map((d) => d.value).join("|")
      createClaimRequest.email = claimData.email
      createClaimRequest.treatmentMethod = claimData.treatmentMethod
      createClaimRequest.diagnosis = claimData.diagnosis
      createClaimRequest.source = TYPE.SOURCE_TYPE.ASSESSOR
    }
    const response = await ClaimServices.createCertificateClaim(createClaimRequest)
    if (response.isSuccess) {
      message.success(`Tạo thành công`)
      history.push(PORTAL_PATH.CLAIM_REQUEST_DETAIL.replace(":claimId", response.data))
    } else {
      if (response.data && typeof response.data === "object") {
        for (let i = 0; i < Object.keys(response.data).length; i++) {
          setTimeout(() => {
            const title = <h3 style={{color: "red"}}>Validate field {Object.keys(response.data)[i].split("$.")[1]}</h3>
            notification.error({
              message: title,
              description: response.data[Object.keys(response.data)[i]],
              icon: <CloseOutlined style={{color: "red"}} />,
            })
          }, 200 * i)
        }
      } else {
        message.error(response.messages)
      }
    }
    setLoadingCreateClaim(false)
  }

  /**
   * @description: Schema for table list
   */
  const infoSchema: schemaType = [
    {
      key: "name",
      label: "Họ tên",
    },
    {
      key: "paperId",
      label: "CMND/CCCD",
    },
    {
      key: "dob",
      label: "Ngày sinh",
      render: (text, record) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_FORMAT) + ` (${record.age} tuổi)`,
    },
    {
      key: "genderName",
      label: "Giới tính",
      render: (text) => (
        <Tag color={text === "Nam" ? "geekblue" : "pink"}>
          {text === "Nam" ? "♂" : "♀"} {text}
        </Tag>
      ),
    },
    {
      key: "insuredNumber",
      label: "Mã người được bảo hiểm",
    },
    {
      key: "phone",
      label: "Số điện thoại",
    },
    {
      key: "email",
      label: "Email",
    },
  ]
  const historySchema = [
    {
      title: "Mã thẻ",
      dataIndex: "certificateCode",
      key: "certificateCode",
    },
    {
      title: "Mã claim case",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Công ty bảo hiểm",
      dataIndex: "insurer",
      key: "insurer",
    },
    {
      title: "Cập nhật lúc",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 260,
      render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      width: 200,
      render: (text: string, record: {status: string}) => (
        <Tag className={"large-font-size"} color={TYPE.STATUS_COLOR[TYPE.STATUS_CLAIM[text]]}>
          {(statusTypeOption && statusTypeOption.find((sts) => sts.value === record.status)?.name) || text}
        </Tag>
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.actionBar}>
        <ButtonColor className={styles.addButton} type="success" onClick={() => showModal(true)}>
          Tạo YCBT
        </ButtonColor>
      </div>
      <InfoBlock data={insuredPersonDetail} schema={infoSchema} loading={loadingInfo} />
      <CertificateList data={certificateList} loading={loadingCertificateList} />
      <div className={styles.historyContainer}>
        <TableList
          loading={loadingHistory}
          scroll={{x: 800}}
          className={styles.tableHistory}
          title={"lịch sử bồi thường"}
          schema={historySchema}
          rowKey={"claimCaseId"}
          data={claimCases || []}
          onView={(record) => {
            history.push(CLAIM_CASE_PATH.CLAIM_CASE_INFO.replace(":claimCaseId", record.claimCaseId))
          }}
        />
      </div>
      <Modal
        className={styles.modalContainer}
        title={
          <div>
            <span>Tạo yêu cầu bồi thường</span>{" "}
            <Checkbox
              className={styles.checkbox}
              checked={claimData.isDirectBilling}
              onChange={(e) => {
                onChangeClaimData(e.target.checked, IS_DIRECT_BILLING)
              }}
            >
              Bảo lãnh viện phí
            </Checkbox>
          </div>
        }
        visible={isShowModal}
        okText={"Tạo claim"}
        onOk={createClaimRequest}
        afterClose={() => setClaimData({})}
        confirmLoading={loadingCreateClaim}
        onCancel={() => showModal(false)}
      >
        <div className={styles.benefitSelect}>
          <label>Quyền lợi</label>
          <Select
            size={"large"}
            placeholder="Chọn quyền lợi"
            optionFilterProp="children"
            value={claimData?.benefitType}
            onChange={(value) => {
              setClaimData({...claimData, benefitType: value as string})
            }}
          >
            {benefitTypesList && benefitTypesList.length
              ? benefitTypesList.map((benefitType: {name: string; value: string}) => (
                  <Select.Option key={benefitType.value} value={benefitType.value}>
                    {benefitType.name}
                  </Select.Option>
                ))
              : ""}
          </Select>
        </div>
        <DetailClaimForm data={claimData} loading={false} onChange={(value, name) => onChangeClaimData(value, name)} />
      </Modal>
    </div>
  )
}

export default HistoryClaimScreen
