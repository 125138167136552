const detailSchema = [
  {
    key: "name",
    label: "Tên bệnh viện",
    placeholder: "Nhập tên bệnh viện",
    component: "input",
    type: "text",
    required: true,
  },
  {
    key: "address",
    label: "Địa chỉ",
    placeholder: "Nhập địa chỉ",
    component: "input",
    type: "text",
  },
  {
    key: "phone",
    label: "Số điện thoại",
    placeholder: "Nhập số điện thoại",
    component: "input",
    type: "text",
  },
  {
    key: "email",
    label: "Email",
    placeholder: "Nhập email",
    component: "input",
    type: "text",
  },
  {
    key: "province",
    label: "Tỉnh",
    placeholder: "Nhập tỉnh",
    component: "select",
    type: "text",
    required: true,
  },
  {
    key: "isDirectBilling",
    label: "Bảo lãnh viện phí",
    placeholder: "Chọn bảo lãnh viện phí",
    component: "select",
    type: "text",
    required: true,
  },
  {
    key: "type",
    label: "Loại bệnh viện",
    placeholder: "Chọn loại bệnh viện",
    component: "select",
    type: "text",
    required: true,
  },
  {
    key: "description",
    label: "Miêu tả",
    placeholder: "Nhập miêu tả",
    component: "textArea",
    type: "text",
    layout: "24",
  },
]

export { detailSchema }
