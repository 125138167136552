const balanceSchema = [
  {
    key: "name",
    label: "Tên",
    placeholder: "Input name",
    component: "input",
    type: "text",
    required: true,
    layout: "24",
  },
  {
    key: "value",
    label: "Value",
    placeholder: "Input value",
    component: "inputNumber",
    type: "currency",
    required: true,
    layout: "24",
  },
  {
    key: "type",
    label: "Type",
    placeholder: "Input type",
    component: "select",
    type: "text",
    required: true,
    layout: "24",
  },
  {
    key: "description",
    label: "Description",
    placeholder: "Input description",
    component: "input",
    type: "text",
    layout: "24",
  },
]

export { balanceSchema }
