import React, {useState} from "react"
import styles from "../../../../CompanyScreen.module.css"
import BaseForm from "app/common/components/Form"
import _ from "lodash"
import AdminServices from "services/AdminServices"
import {formSchema} from "../../../../schema"
import {message} from "antd"
import {useHistory} from "react-router"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"
import {TYPE} from "config/constants"

export type companyType = {
  key?: string
  companyId?: string
  name: string
  code: string
  companyCode: string
  phone?: string
  taxCode?: string
  logo?: string
  description?: string
  status?: string
  statusName?: string
  types: string[]
}

const CompanyCreateScreen = (props: {isReturn: () => void; type?: string}) => {
  const companyRoleTypes = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.COMPANY_ROLE_TYPE],
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [companyData, setCompanyData] = useState<companyType>()
  const history = useHistory()

  const handleChange = (key: string, value: string) => {
    const newData = _.clone(companyData) || {
      types: [],
      code: "",
      name: "",
      companyCode: "",
    }
    if (value) {
      newData[key] = value
      setCompanyData(newData)
    } else {
      delete newData[key]
      setCompanyData(newData)
    }
  }
  const createCompany = async () => {
    setLoading(true)
    if (companyData) {
      const response = await AdminServices.createCompany(companyData)
      if (response.isSuccess) {
        message.success("Tạo công ty thành công")
      } else {
        message.error(response.messages)
      }
    }
    setLoading(false)
  }
  const onSave = async () => {
    await createCompany()
  }

  const goToList = () => {
    const {isReturn} = props
    if (!isReturn) {
      history.goBack()
    } else {
      isReturn()
    }
  }

  return (
    <div className={styles.container}>
      <BaseForm
        title={""}
        className={styles.containerForm}
        schemas={formSchema}
        option={{types: companyRoleTypes}}
        loading={loading}
        disabled={(props.type !== undefined && {types: true}) || undefined}
        onChange={(key: string, value: string) => handleChange(key, value)}
        data={companyData || {}}
        onSave={() => onSave()}
        onCancel={() => goToList()}
      />
    </div>
  )
}

export default CompanyCreateScreen
