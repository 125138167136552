import React from "react"
import styles from "./DocumentData.module.css"
import {PlusOutlined, RightSquareOutlined} from "@ant-design/icons"
import {Collapse, Empty, Image, Spin, Tabs} from "antd"
import {TYPE} from "../../../../../../../../../config/constants"
import _ from "lodash"
import {IDocumentData} from "../../index"
import Button from "antd-button-color"
const {Panel} = Collapse
const {TabPane} = Tabs

const DocumentData = (props: {
  claimId: string
  data?: IDocumentData[]
  loading: boolean
  showButtonUpload: boolean
  onOpenModal: (isOpen) => void
}) => {
  const {data, loading, showButtonUpload, onOpenModal} = props

  const getListPaper = (type) => {
    return _.filter(data, ["type", type])
  }

  const DocumentReader = (props: {dataList: IDocumentData[]}) => {
    const mapPaperType = (type: string) => {
      switch (type) {
        case TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.DISCHARGE_PAPER
        case TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.INVOICE_PAPER
        case TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.PRESCRIPTION_PAPER
        case TYPE.CLAIM_FILE_TYPE.OTHER_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.OTHER_PAPER
        default:
          break
      }
    }
    const {dataList} = props
    return (
      <Image.PreviewGroup>
        {(dataList &&
          dataList.map((paper) => {
            if (paper.isImage) {
              return (
                <div key={paper.id} className={styles.imageContainer}>
                  <Image width={148} height={108} src={paper.url} />
                  <div className={styles.paperLabel}>{mapPaperType(paper.type)}</div>
                </div>
              )
            } else {
              return (
                <div key={paper.id} className={styles.documentContainer}>
                  <div className={styles.pdfContainer}>
                    <div className={styles.pdfCover} onClick={() => window.open(paper.url)} />
                  </div>
                  <div className={styles.paperLabel}>{mapPaperType(paper.type)}</div>
                </div>
              )
            }
          })) ||
          ""}
      </Image.PreviewGroup>
    )
  }

  const openModal = (e) => {
    e.preventDefault()
    onOpenModal(true)
  }

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse"
    >
      <Panel header="Thông tin giấy tờ" key="1">
        <Spin spinning={loading}>
          <div className={styles.claimDocumentContainer}>
            {(showButtonUpload && (
              <div className={styles.buttonUpdate}>
                <Button icon={<PlusOutlined />} type={"primary"} onClick={(e) => openModal(e)} href={""}>
                  Upload
                </Button>
              </div>
            )) ||
              ""}
            <Tabs tabPosition={"left"} className={styles.tabBar}>
              <TabPane tab={`Tất cả (${data?.length})`} key="all">
                <div className={styles.contentPaper}>
                  {(data && data.length === 0 && <Empty style={{width: "100%"}} />) || (
                    <DocumentReader dataList={data || []} />
                  )}
                </div>
              </TabPane>
              <TabPane
                tab={`Giấy ra viện (${getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER).length})`}
                key="dischargePaper"
              >
                <div className={styles.contentPaper}>
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER).length === 0 && (
                    <Empty style={{width: "100%"}} />
                  )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER)} />}
                </div>
              </TabPane>
              <TabPane tab={`Hóa đơn (${getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER).length})`} key="invoicePaper">
                <div className={styles.contentPaper}>
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER).length === 0 && (
                    <Empty style={{width: "100%"}} />
                  )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER)} />}
                </div>
              </TabPane>
              <TabPane
                tab={`Toa thuốc (${getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER).length})`}
                key="prescriptionPaper"
              >
                <div className={styles.contentPaper}>
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER).length === 0 && (
                    <Empty style={{width: "100%"}} />
                  )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER)} />}
                </div>
              </TabPane>
              <TabPane tab={`Giấy tờ khác (${getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length})`} key="otherPaper">
                <div className={styles.contentPaper}>
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length === 0 && (
                    <Empty style={{width: "100%"}} />
                  )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER)} />}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Spin>
      </Panel>
    </Collapse>
  )
}

export default DocumentData
