import React, {useState} from "react"
import styles from "./PlanDetailScreen.module.css"
import {message, Modal, Popconfirm, Tabs, Tag} from "antd"
import PlanServices from "services/PlanServices"
import PlanTableList from "./components"
import Button from "antd-button-color"
import {PORTAL_PATH} from "app/portal/config/routes"
import {CheckCircleTwoTone, CloseCircleTwoTone, DeleteTwoTone, EditOutlined} from "@ant-design/icons"
import BaseForm from "app/common/components/Form"
import {planDetailSchema, planUpdateSchema} from "../../schema"
import caolan from "async"
import _ from "lodash"
import BenefitTag from "app/common/components/BenefitTag"
import TableList from "app/common/components/TableList"
import FileService from "services/FileServices"
import {TYPE} from "config/constants"
import {useHistory, useLocation, useParams} from "react-router"
import {utils} from "config/utils"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"
import AdminServices from "services/AdminServices"

const {TabPane} = Tabs

interface IPlanData {
  code?: string
  name?: string
  insurerId?: string
  insurer?: string
  id?: string
  document?: string
  terms?: string
  termsDoc?: Array<any>
  remarkCoPays?: remarkCopayType[]
}

export type remarkCopayType = {
  id?: string
  name: string
  value: string
  description: string
}

export interface BenefitData {
  code?: string
  insuredBenefitId?: string
  isDirectBilling?: boolean
  name?: string
  planBenefitId?: string
  type?: string
}

export interface IBalanceData {
  description: string
  maskedValue: string
  name: string
  planBalanceId: string
  planInsuredBenefits: string
  type: string
  value: number
}

export interface IPlanDetailCollection {
  id: string
  name: string
  description: string
  coverageDescription: string
}

export interface IPlanDetailData {
  code: string
  name: string
  description?: string
  coverageDescription?: string
  territorialLimit?: string
  fee?: string
}

const PlanDetailScreen = () => {
  const [loading, setLoading] = useState(false)
  const [showModalRemark, setShowModalRemark] = useState<boolean>(false)
  const [loadingRemark, setLoadingRemark] = useState(false)
  const [remarkCopayData, setRemarkCopayData] = useState<remarkCopayType>()
  const [showModalPlan, setShowModalPlan] = useState<boolean>(false)
  const [showModalFile, setShowModalFile] = useState<boolean>(false)
  const [showModalPlanDetail, setModalPlanDetail] = useState<boolean>(false)
  const [fileCreateData, setFileCreateData] = useState<any>({})
  const [termFileList, setTermFileList] = useState([])
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [planData, setPlanData] = useState<IPlanData>({})
  const [benefits, setBenefits] = useState<BenefitData[]>([])
  const [balances, setBalances] = useState<IBalanceData[]>([])
  const [planDetailData, setPlanDetailData] = useState<IPlanDetailData>()
  const [planDetails, setPlanDetails] = useState<IPlanDetailCollection[]>([])
  const [optionCardList, setOptionCardList] = useState({})
  const [optionParentPlanDetail, setOptionParentPlanDetail] = useState<[]>()
  const balanceOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.PLAN_BALANCE_TYPE],
  )
  const benefitOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const history = useHistory()
  const location = useLocation()
  const {planId} = useParams<{planId: string}>()

  const getCardList = async (insurerId: string) => {
    const response = await AdminServices.getCardsByInsurerId(insurerId)
    if (response.isSuccess && response.data) {
      const options = response.data.map((card) => ({
        name: card.name,
        value: card.cardId,
      }))
      setOptionCardList({cardId: options})
    }
  }

  const getTermsFile = async () => {
    const response = await FileService.getFileList(TYPE.FILE_TYPE.PLAN_DOCUMENT, "", "", planId)
    if (response.isSuccess) {
      setTermFileList(response.data.collection)
    }
  }

  const getPlanDetails = async () => {
    const planDetails = await PlanServices.getPlanDetailsByPlanIdAsync(planId)
    if (planDetails && planDetails.data) {
      setPlanDetails(planDetails.data)
      const options = planDetails.data.map((planDetail) => ({
        name: planDetail.name,
        value: planDetail.id,
      }))
      setOptionParentPlanDetail(options)
    }
  }

  const getPlanIdDetails = async () => {
    const response = await PlanServices.getPlanDetail(planId)
    if (response && response.data) {
      setPlanData(response.data)
      getCardList(response.data.insurerId).then()
    }
  }

  const getPlanBenefitDetail = async () => {
    const benefits = await PlanServices.getPlanBenefit(planId)
    if (benefits && benefits.data) {
      setBenefits(benefits.data)
    }
  }

  const getFirstLoad = async () => {
    setShowModalPlan(false)
    setLoading(true)
    if (planId) {
      await getPlanIdDetails()
      await getPlanBenefitDetail()
      getPlanDetails().then()
      getTermsFile().then()
    }
    setLoading(false)
  }

  const getTypeName = (type: string) => {
    return balanceOption.find((b) => b.value === type)?.name
  }

  const getBalanceData = async () => {
    const balances = await PlanServices.getPlanBalances(planId)
    if (balances && balances.data && balanceOption.length) {
      const newBalanceData = _.clone(balances.data)
      newBalanceData.forEach((balance) => {
        balance.type = getTypeName(balance.type)
      })
      setBalances(newBalanceData)
    }
  }

  useMountEffect(() => {
    getFirstLoad().then()
    getBalanceData().then()
    getTermsFile().then()
  })

  useUpdateEffect(() => {
    getBalanceData().then()
  }, [balanceOption])

  const handleChange = (key: string, value: string) => {
    let newData = _.clone(planData)
    if (value) {
      if (!newData) {
        newData = {
          id: "",
          code: "",
          name: "",
        }
      }
      newData[key] = value
      setPlanData(newData)
    } else {
      delete newData[key]
      setPlanData(newData)
    }
  }

  const uploadDocument = async (data, id: string) => {
    for (const paperData of data) {
      if (paperData) {
        const file = await utils.base64ToFile(paperData.originFileObj, paperData.name)
        try {
          // eslint-disable-next-line no-await-in-loop
          await FileService.uploadFilePlan(id, file)
        } catch (e) {
          return e
        }
      }
    }
  }

  const onSave = async () => {
    if (planData && planId) {
      const response = await PlanServices.updatePlanDetail(planId, planData)
      if (response.isSuccess) {
        if (planData.termsDoc) {
          uploadDocument(planData.termsDoc, response.data).then()
        }
        message.success("Cập nhật thông tin sản phẩm bảo hiểm thành công")
        getFirstLoad().then()
      }
    }
  }

  const onCreatePlanDetail = async () => {
    if (planDetailData && planId) {
      const response = await PlanServices.createPlanDetail(planId, planDetailData)
      if (response.isSuccess) {
        message.success("Tạo quyền lợi chi tiết bảo hiểm thành công")
        getPlanDetails().then()
        setModalPlanDetail(false)
      }
    }
  }

  const handleChangeFileData = (key: string, value: string) => {
    let newData = _.clone(fileCreateData)
    if (value || value === "") {
      if (!newData) {
        newData = {}
      }
      newData[key] = value
      setFileCreateData(newData)
    } else {
      setFileCreateData(newData)
    }
  }

  const handleChangePlanDetailData = (key: string, value: string) => {
    let newData = _.clone(planDetailData)
    if (value || value === "") {
      if (!newData) {
        newData = {
          code: "",
          name: "",
        }
      }
      newData[key] = value
      setPlanDetailData(newData)
    } else {
      setPlanDetailData(newData)
    }
  }

  const uploadFile = async (data: {originFileObj: File; name: string}) => {
    if (data.originFileObj.size / (1024 * 1024) <= 10) {
      const file = await utils.base64ToFile(data.originFileObj, data.name)
      return FileService.uploadFilePlan(planId, file)
    } else {
      message.warn("Kích thước tập tin vượt quá 10MB")
    }
  }

  const createFile = async () => {
    if (fileCreateData && fileCreateData.document) {
      caolan.each(
        fileCreateData.document,
        (paperData: {originFileObj: File; name: string}, callback) => {
          uploadFile(paperData).then(() => callback())
        },
        (err) => {
          if (err) {
            message.error("Thêm mới thất bại")
          } else {
            setShowModalFile(false)
            setFileCreateData({document: []})
            getTermsFile().then()
          }
        },
      )
    }
  }

  const deleteFile = async (id) => {
    setLoadingDelete(true)
    const response = await FileService.updateFilePlan(planId, id)
    if (response.isSuccess) {
      message.success("Xóa file thành công")
      getTermsFile().then()
    } else {
      message.error(response.messages)
    }
    setLoadingDelete(false)
  }

  const deletePlanDetail = async (planDetailId) => {
    setLoadingDelete(true)
    const response = await PlanServices.deletePlanDetail(planId, planDetailId)
    if (response.isSuccess) {
      message.success("Xóa quyền lợi chi tiết thành công")
      getPlanDetails().then()
    } else {
      message.error(response.messages)
    }
    setLoadingDelete(false)
  }

  const saveRemarkCopay = async () => {
    setLoadingRemark(true)
    if (remarkCopayData) {
      const response = await PlanServices.saveRemarkCopay(planId, [remarkCopayData])
      if (response.isSuccess) {
        message.success("Cập nhật remark Co-pay thành công")
        getPlanIdDetails().then()
      } else {
        message.error(response.messages)
      }
    }

    setShowModalRemark(false)
    setLoadingRemark(false)
  }

  const handleRemarkCopayChange = (key: string, value: string) => {
    let newData: any = _.clone(remarkCopayData)
    newData = {...newData, [key]: value}
    setRemarkCopayData(newData)
  }

  const benefitColumns = [
    {
      key: "code",
      dataIndex: "code",
      title: "Mã",
      width: 100,
      render: (text: string) => (
        <Tag className={"large-font-size"} color={"geekblue"}>
          {text}
        </Tag>
      ),
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Tên quyền lợi",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Loại quyền lợi",
      width: 300,
      sorter: (a, b) => a.type.length - b.type.length,
      filters:
        benefitOption && benefitOption.length
          ? benefitOption.map((b) => ({
              text: b.name,
              value: b.value,
            }))
          : [],
      onFilter: (value, record) => record.type === value,
      render: (text: string) => (
        <BenefitTag
          text={text}
          textReplace={(benefitOption && _.find(benefitOption, (b: {value: string}) => b.value === text)?.name) || ""}
        />
      ),
    },
    {
      key: "isDirectBilling",
      dataIndex: "isDirectBilling",
      title: "Bảo lãnh viện phí",
      width: 200,
      filters: [
        {
          text: "Có",
          value: true,
        },
        {
          text: "Không",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isDirectBilling === value,
      render: (text: boolean) => (
        <span>
          {(text && (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
              <span
                style={{
                  color: "#52c41a",
                  fontWeight: "bold",
                }}
              >
                Có
              </span>
            </>
          )) || (
            <>
              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
              <span
                style={{
                  color: "var(--red-alert)",
                  fontWeight: "bold",
                }}
              >
                Không
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      key: "applyToDependents",
      dataIndex: "applyToDependents",
      title: "Áp dụng cho người thân",
      width: 200,
      filters: [
        {
          text: "Có",
          value: true,
        },
        {
          text: "Không",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isDirectBilling === value,
      render: (text: boolean) => (
        <span>
          {(text && (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
              <span
                style={{
                  color: "#52c41a",
                  fontWeight: "bold",
                }}
              >
                Có
              </span>
            </>
          )) || (
            <>
              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
              <span
                style={{
                  color: "var(--red-alert)",
                  fontWeight: "bold",
                }}
              >
                Không
              </span>
            </>
          )}
        </span>
      ),
    },
  ]
  const balanceColumns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Tên hạn mức",
    },
    {
      key: "value",
      dataIndex: "maskedValue",
      title: "Giá trị hạn mức",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Loại hạn mức",
    },
    {
      key: "description",
      dataIndex: "description",
      title: "Mô tả hạn mức",
    },
  ]

  const planDetailColumns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Tên quyền lợi",
    },
    {
      key: "coverageDescription",
      dataIndex: "coverageDescription",
      title: "Mô tả hạn mức",
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Tác vụ",
      render: (value, row) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            title="Bạn có chắc muốn xóa"
            onConfirm={() => deletePlanDetail(row.id)}
            okButtonProps={{
              loading: loadingDelete,
              danger: true,
            }}
            okText={"Xóa"}
          >
            <DeleteTwoTone
              twoToneColor="#EF5350"
              style={{
                fontSize: 18,
                textAlign: "center",
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ]

  const FileListSchema = [
    {
      title: "Tên file",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Tác vụ",
      render: (value, row) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            title="Bạn có chắc muốn xóa"
            onConfirm={() => deleteFile(row.id)}
            okButtonProps={{
              loading: loadingDelete,
              danger: true,
            }}
            okText={"Xóa"}
          >
            <DeleteTwoTone
              twoToneColor="#EF5350"
              style={{
                fontSize: 18,
                textAlign: "center",
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.planInformation}>
        <Button
          icon={<EditOutlined />}
          size={"middle"}
          type={"lightdark"}
          className={styles.editButton}
          onClick={() => setShowModalPlan(true)}
        >
          SỬA
        </Button>
        <div className={styles.planDetail}>
          <div className={styles.title}>Tên sản phẩm:</div>
          <div className={styles.content}>{planData.name}</div>
        </div>

        <div className={styles.planDetail}>
          <div className={styles.title}>Mã sản phẩm:</div>
          <div className={styles.content}>{planData.code}</div>
        </div>
        <div className={styles.planDetail}>
          <div className={styles.title}>Công ty bảo hiểm:</div>
          <div className={styles.content}>{planData.insurer}</div>
        </div>
        <div className={styles.planDetail}>
          <div className={styles.title}>Tài liệu:</div>
          <div className={styles.content}>{planData.document || "Không có"}</div>
        </div>
      </div>

      {planData.remarkCoPays ? (
        <TableList
          className={styles.planRemarkContainer}
          onCreate={() => {
            setRemarkCopayData(undefined)
            setShowModalRemark(true)
          }}
          loading={loadingRemark}
          onCreateTitle="Thêm mới"
          title={"Danh sách remark copay"}
          schema={[
            {
              dataIndex: "name",
              key: "name",
              title: "Tên",
            },
            {
              dataIndex: "value",
              key: "value",
              title: "Giá trị",
            },
            {
              dataIndex: "description",
              key: "description",
              title: "Mô tả",
            },
          ]}
          data={planData.remarkCoPays}
          onView={(record) => {
            setRemarkCopayData(record)
            setShowModalRemark(true)
          }}
        />
      ) : (
        ""
      )}

      <Tabs className={styles.tabContainer} type="card">
        <TabPane tab="Quyền lợi" key="benefit">
          <PlanTableList
            loading={loading}
            data={benefits.map((b) => ({
              ...b,
              key: b.planBenefitId,
            }))}
            title="quyền lợi"
            createTitle="Cập nhật"
            search={["code", "name"]}
            onView={(record) => {
              let path = PORTAL_PATH.PLAN_BENEFIT_DETAIL
              path = path.replace(":planId", planId)
              path = path.replace(":planInsuredBenefitId", record.planInsuredBenefitId)
              history.push(path)
            }}
            onCreate={() => history.push(PORTAL_PATH.PLAN_BENEFIT.replace(":planId", planId))}
            listSchema={benefitColumns}
          />
        </TabPane>
        <TabPane tab="Hạn mức" key="balance">
          <PlanTableList
            loading={loading}
            data={balances.map((b) => ({
              ...b,
              key: b.planBalanceId,
            }))}
            title="hạn mức"
            onView={(record) => {
              let path = PORTAL_PATH.PLAN_BALANCES_DETAIL
              path = path.replace(":planId", planId)
              path = path.replace(":planBalanceId", record.planBalanceId)
              history.push(path)
            }}
            createTitle="Tạo mới"
            onCreate={() => history.push(location.pathname + "/balances")}
            listSchema={balanceColumns}
          />
        </TabPane>
        <TabPane tab="Quy tắc bảo hiểm" key="terms">
          <TableList
            title={"Quy tắc bảo hiểm"}
            data={termFileList}
            onCreate={() => setShowModalFile(true)}
            onCreateTitle={"Tải lên file quy tắc bảo hiểm"}
            onView={(record) => {
              window.open(record.url)
            }}
            schema={FileListSchema}
          />
        </TabPane>
        <TabPane tab="Quyền lợi chi tiết" key="details">
          <PlanTableList
            loading={loading}
            data={planDetails.map((b) => ({
              ...b,
              key: b.id,
            }))}
            title="Quyền lợi chi tiết"
            createTitle="Cập nhật"
            search={["name"]}
            onView={(record) => {
              let path = PORTAL_PATH.PLAN_DETAIL_INFO
              path = path.replace(":planId", planId)
              path = path.replace(":planDetailId", record.id)
              history.push(path)
            }}
            onCreate={() => setModalPlanDetail(true)}
            listSchema={planDetailColumns}
          />
        </TabPane>
      </Tabs>
      <Modal
        title=""
        onCancel={() => {
          setShowModalRemark(false)
          setRemarkCopayData(undefined)
        }}
        centered
        visible={showModalRemark}
        width={800}
        footer={[]}
      >
        <BaseForm
          title={"Remark Copay"}
          className={styles.containerForm}
          schemas={[
            {
              key: "name",
              label: "Tên",
              placeholder: "Nhập tên copay",
              component: "input",
              layout: "24",
            },
            {
              key: "value",
              label: "Giá trị",
              placeholder: "Nhập giá trị copay",
              component: "input",
              layout: "24",
            },
            {
              key: "description",
              label: "Mô tả",
              placeholder: "Nhập mô tả",
              component: "input",
              layout: "24",
            },
          ]}
          loading={false}
          onChange={(key: string, value: string) => handleRemarkCopayChange(key, value)}
          data={remarkCopayData || {}}
          onSave={() => saveRemarkCopay()}
          onCancel={() => setShowModalRemark(false)}
        />
      </Modal>
      <Modal title="" centered visible={showModalPlan} width={800} onCancel={() => setShowModalPlan(false)} footer={[]}>
        <BaseForm
          title={"Cập nhật sản phẩm bảo hiểm"}
          className={styles.containerForm}
          schemas={planUpdateSchema}
          option={optionCardList || {}}
          loading={false}
          disabled={{code: true}}
          onChange={(key: string, value: string) => handleChange(key, value)}
          data={planData || {}}
          onSave={() => onSave()}
          onCancel={() => setShowModalPlan(false)}
        />
      </Modal>
      <Modal title="" onCancel={() => setShowModalFile(false)} centered visible={showModalFile} width={800} footer={[]}>
        <BaseForm
          title={"Thêm file quy tắc bảo hiểm"}
          className={styles.containerForm}
          schemas={[
            {
              key: "document",
              label: "File quy tắc bảo hiểm",
              placeholder: "Chọn file quy tắc bảo hiểm",
              component: "upload",
              layout: "24",
            },
          ]}
          option={{}}
          loading={false}
          onChange={(key: string, value: string) => handleChangeFileData(key, value)}
          data={fileCreateData || {}}
          onSave={() => createFile()}
          onCancel={() => setShowModalFile(false)}
        />
      </Modal>
      <Modal
        title=""
        centered
        visible={showModalPlanDetail}
        width={800}
        onCancel={() => setModalPlanDetail(false)}
        footer={[]}
      >
        <BaseForm
          title={"Thêm quyền lợi chi tiết"}
          className={styles.containerForm}
          schemas={planDetailSchema}
          loading={false}
          onChange={(key: string, value: string) => handleChangePlanDetailData(key, value)}
          data={planDetailData || {}}
          onSave={() => onCreatePlanDetail()}
          option={{
            parentPlanDetailId: optionParentPlanDetail || [],
            territorialLimit: [
              {name: "Việt Nam", value: "Việt Nam"},
              {name: "Toàn cầu", value: "Toàn cầu"},
            ],
          }}
          onCancel={() => setModalPlanDetail(false)}
        />
      </Modal>
    </div>
  )
}

export default PlanDetailScreen
