import Service from "../services"
import endPoints from "../endpoints"
import {IClaimCaseUpdate} from "app/portal/screens/ClaimPortal"
import {axiosResponseToData, defaultResponse} from "../index"

const HospitalServices = {
  createHospitalClaim: async (createClaimRequest: {
    benefitType: string
    isDirectBilling: boolean
    certificateId: string
    email?: string
    phone?: string
    diseases?: string
    diagnosis?: string
    eventDates?: string
    treatmentMethod?: string
    medicalProviderId?: string
    medicalProviderName?: string
    source?: string
    requestAmount: number
  }) => {
    const response = await Service(true).post(endPoints.CLAIM(), createClaimRequest)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimDocument: async (claimId: string) => {
    const response = await Service(true).get(endPoints.DOCUMENT_CLAIM(claimId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createHospitalClaimInfo: async (claimId: string, claimInfoData: {name?: string; value?: number | undefined}[]) => {
    const response = await Service(true).post(endPoints.CLAIM_INFO(claimId), claimInfoData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateHospitalClaimInfo: async (claimId: string, claimInfoData: {name?: string; value?: number | undefined}[]) => {
    const response = await Service(true).put(endPoints.CLAIM_INFO(claimId), claimInfoData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimDetail: async (claimId: string, source?: string) => {
    const response = await Service(true).get(endPoints.CLAIM(claimId, "", source))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateClaimDetail: async (claimId: string, claimData: IClaimCaseUpdate) => {
    const response = await Service(true).put(endPoints.CLAIM(claimId), claimData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getCertificateBenefit: async (certificateId: string) => {
    const response = await Service(true).get(endPoints.CERTIFICATE_BENEFIT(certificateId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
}

export default HospitalServices
