import React, {useCallback, useState} from "react"
import styles from "./CertificateDetailScreen.module.css"
import {useHistory, useParams} from "react-router"
import TableList from "app/common/components/TableList"
import {LAListSchema, PolicyOwnerSchema, SalaryFormSchema} from "./schema"
import {message, Modal, Popconfirm, Tabs} from "antd"
import {PORTAL_PATH} from "app/portal/config/routes"
import AdminServices from "services/AdminServices"
import BaseForm from "app/common/components/Form"
import _ from "lodash"
import {utils} from "config/utils"
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons"
import {FORMATTER} from "config/constants"
import {useMountEffect} from "@react-hookz/web";

const {TabPane} = Tabs

interface CertificateData {
  insuredCertificateId?: string
  certificateCode?: string
  issuedAt?: string
  duedAt?: string
  email?: string
  insuredPersonName?: string
  insuredPersonPaperId?: string
  policyNumber?: string
  planName?: string
  childrenCertificates?: Array<CertificateData>
  parentCertificate?: CertificateData
}

const CertificateDetailScreen = () => {
  const [certificateDetailData, setCertificateDetailData] = useState<CertificateData>({})
  const [salaryList, setSalaryList] = useState([])
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [showSalaryModal, setShowSalaryModal] = useState(false)
  const [salaryData, setSalaryData] = useState<{
    insuredCertificateSalaryId?: string
    value?: number
    effectiveAt?: string
  }>({})
  const {policyId, certificateId} = useParams<{policyId: string; certificateId: string}>()
  const history = useHistory()

  const getCertificateDetail = async (certificateId: string) => {
    const response = await AdminServices.getCertificateDetail(certificateId)
    if (response.isSuccess && response.data) {
      setCertificateDetailData(response.data)
    }
  }

  const getListSalary = useCallback(async () => {
    const response = await AdminServices.getListCertificateSalary(certificateId)
    if (response.isSuccess) {
      setSalaryList(response.data.collection)
    }
  }, [certificateId])

  useMountEffect(() => {
    getCertificateDetail(certificateId).then()
    getListSalary().then()
  })

  const handleChangeSalary = (key: string, value: string) => {
    let newData = _.clone(salaryData)
    if (value || value === "") {
      if (!newData) {
        newData = {}
      }
      newData[key] = value
      setSalaryData(newData)
    } else {
      setSalaryData(newData)
    }
  }

  const handleSalary = async () => {
    if (salaryData.insuredCertificateSalaryId && salaryData.effectiveAt) {
      const requestData = _.clone(salaryData)
      requestData.effectiveAt = utils.parseLocalTime(salaryData.effectiveAt)
      const response = await AdminServices.updateCertificateSalaryDetail(
        certificateId,
        salaryData.insuredCertificateSalaryId,
        requestData,
      )
      if (response.isSuccess) {
        message.success("Cập nhật thành công")
        setShowSalaryModal(false)
        getListSalary().then()
      }
    } else {
      if (salaryData && salaryData.effectiveAt) {
        const requestData = _.clone(salaryData)
        requestData.effectiveAt = utils.parseLocalTime(salaryData.effectiveAt)
        const response = await AdminServices.createCertificateSalary(certificateId, requestData)
        if (response.isSuccess) {
          message.success("Tạo lương thành công")
          setShowSalaryModal(false)
          getListSalary().then()
        } else {
          message.error(response.messages)
        }
      }
    }
  }

  const updateSalary = (data = {}) => {
    setSalaryData(data)
    setShowSalaryModal(true)
  }

  const deleteSalary = async (id: string) => {
    setLoadingDelete(true)
    const response = await AdminServices.deleteCertificateSalaryDetail(certificateId, id)
    if (response.isSuccess) {
      message.success("Xóa thành công")
      getListSalary().then()
    }
    setLoadingDelete(false)
  }

  const SalaryListSchema = [
    {
      title: "Giá trị",
      dataIndex: "maskedValue",
      key: "maskedValue",
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "effectiveAt",
      key: "effectiveAt",
      render: (value) => (
        <span>{utils.parseToStringLocalDateTime(value.toString(), FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}</span>
      ),
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value) => (
        <span>
          {(value && utils.parseToStringLocalDateTime(value.toString(), FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)) || ""}
        </span>
      ),
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Tác vụ",
      render: (value, row) => (
        <div className={styles.actionTable}>
          <div onClick={() => updateSalary(row)}>
            <EditTwoTone
              twoToneColor="#faad14"
              style={{
                fontSize: 18,
                textAlign: "center",
              }}
            />
          </div>
          <div style={{marginLeft: 20}} onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title="Bạn có chắc muốn xóa"
              onConfirm={() => deleteSalary(row.insuredCertificateSalaryId)}
              okButtonProps={{
                loading: loadingDelete,
                danger: true,
              }}
              okText={"Xóa"}
            >
              <DeleteTwoTone
                twoToneColor="#EF5350"
                style={{
                  fontSize: 18,
                  textAlign: "center",
                }}
              />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.certificateInformation}>
        <div className={styles.certificateDetail}>
          <div className={styles.title}>Mã thẻ:</div>
          <div className={styles.content}>{certificateDetailData.certificateCode}</div>
        </div>

        <div className={styles.certificateDetail}>
          <div className={styles.title}>Mã hợp đồng:</div>
          <div className={styles.content}>{certificateDetailData.policyNumber}</div>
        </div>

        <div className={styles.certificateDetail}>
          <div className={styles.title}>Ngày hiệu lực:</div>
          <div className={styles.content}>
            {utils.parseToStringLocalDateTime(certificateDetailData.issuedAt, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}
          </div>
        </div>

        <div className={styles.certificateDetail}>
          <div className={styles.title}>Gói sản phẩm:</div>
          <div className={styles.content}>{certificateDetailData.planName}</div>
        </div>

        <div className={styles.certificateDetail}>
          <div className={styles.title}>Ngày hết hiệu lực:</div>
          <div className={styles.content}>
            {utils.parseToStringLocalDateTime(certificateDetailData.duedAt, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}
          </div>
        </div>

        <div className={styles.certificateDetail}>
          <div className={styles.title}>Người được bảo hiểm:</div>
          <div className={styles.content}>{certificateDetailData.insuredPersonName}</div>
        </div>

        <div className={styles.certificateDetail}>
          <div className={styles.title}>CMND/CCCD người được bảo hiểm:</div>
          <div className={styles.content}>{certificateDetailData.insuredPersonPaperId}</div>
        </div>

        <div className={styles.certificateDetail}>
          <div className={styles.title}>Email:</div>
          <div className={styles.content}>{certificateDetailData.email}</div>
        </div>
      </div>

      <div className={styles.certificateContainer}>
        <Tabs className={styles.tabContainer} type="card">
          <TabPane tab="Danh sách người thân" key="children">
            <TableList
              className={styles.insuredContainer}
              title={"Thông tin"}
              onView={(record) => {
                let path = PORTAL_PATH.CERTIFICATE_DETAIL.replace(":policyId", policyId)
                path = path.replace(":certificateId", record.insuredCertificateId)
                history.push(path)
              }}
              scroll={{x: 800}}
              schema={LAListSchema}
              search={["insuredPersonName", "certificateCode"]}
              data={
                (certificateDetailData.childrenCertificates &&
                  certificateDetailData.childrenCertificates.map((c) => ({
                    ...c,
                    key: c.certificateCode,
                  }))) ||
                []
              }
            />
          </TabPane>
          <TabPane tab="Danh sách nhân viên" key="parent">
            <TableList
              className={styles.insuredContainer}
              title={"Thông tin"}
              onView={(record) => {
                let path = PORTAL_PATH.CERTIFICATE_DETAIL.replace(":policyId", policyId)
                path = path.replace(":certificateId", record.insuredCertificateId)
                history.push(path)
              }}
              scroll={{x: 800}}
              schema={PolicyOwnerSchema}
              search={["insuredPersonName", "certificateCode"]}
              rowKey={"insuredCertificateId"}
              data={certificateDetailData.parentCertificate ? [certificateDetailData.parentCertificate] : []}
            />
          </TabPane>
          <TabPane tab="Quản lý lương" key="salary">
            <TableList
              className={styles.insuredContainer}
              title={"Thông tin"}
              onView={() => {}}
              onCreate={() => {
                setSalaryData({value: 0})
                setShowSalaryModal(true)
              }}
              schema={SalaryListSchema}
              data={salaryList || []}
            />
          </TabPane>
        </Tabs>
        <Modal title="" centered closable={false} visible={showSalaryModal} width={800} footer={[]}>
          <BaseForm
            title={"Tạo thời hạn lương"}
            className={styles.containerForm}
            schemas={SalaryFormSchema}
            option={{}}
            loading={false}
            onChange={(key: string, value: string) => handleChangeSalary(key, value)}
            data={salaryData || {}}
            onSave={() => handleSalary()}
            onCancel={() => setShowSalaryModal(false)}
          />
        </Modal>
      </div>
    </div>
  )
}

export default CertificateDetailScreen
