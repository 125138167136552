import styles from "./ListTemplate.module.css"
import {Menu, Tooltip} from "antd"
import React from "react"
import {TemplateType} from "../.."

const ListTemplate = (props: {
  activeKey: string
  onChangeActiveKey: (value: string) => void
  templateList: {[key: string]: {name: string; value: string}[]}
  templateType: TemplateType
}) => {
  const {onChangeActiveKey, activeKey, templateList, templateType} = props
  return (
    <Menu
      mode="vertical"
      onSelect={(value) => {
        onChangeActiveKey(value.key as string)
      }}
      activeKey={activeKey}
      className={styles.menuBar}
    >
      {templateList[templateType] && templateList[templateType].length
        ? templateList[templateType].map((template) => (
            <Menu.Item key={template.value}>
              <Tooltip title={template.name} placement={"topLeft"} mouseEnterDelay={0.5}>
                {template.name}
              </Tooltip>
            </Menu.Item>
          ))
        : ""}
    </Menu>
  )
}

export default ListTemplate
