import React from "react"
import styles from "./MetaData.module.css"
import {RightSquareOutlined} from "@ant-design/icons"
import {Col, Collapse, InputNumber, Row, Spin} from "antd"
import {MetaDataClaimInfoInterface} from "../../index"

const {Panel} = Collapse

const MetaDataClaimInfo = (props: {
  loading: boolean
  data?: MetaDataClaimInfoInterface[]
  onChange: (data) => void
}) => {
  const {loading, data, onChange} = props

  const handleChange = (name, value, id) => {
    const newObject: {name?: string; value?: number; claimInformationId?: string} = {}
    newObject.claimInformationId = id
    newObject.name = name
    newObject.value = value
    onChange(newObject)
  }

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse"
    >
      <Panel header="Chi phí điều trị" key="1">
        <Spin spinning={loading}>
          <Row className={styles.infoInputContainer}>
            {(data?.length &&
              data.map((item) => (
                <Col key={item.name} span={8}>
                  <label>{item.name}</label>
                  <InputNumber
                    className={styles.inputNumber}
                    onChange={(value) => handleChange(item.name, value, item.claimInformationId)}
                    size={"large"}
                    min={0}
                    value={item.value && (item.value < 0 ? item.defaultValue : item.value)}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    placeholder={`Nhập ${item.name}`}
                  />
                </Col>
              ))) ||
              ""}
          </Row>
        </Spin>
      </Panel>
    </Collapse>
  )
}

export default MetaDataClaimInfo
