import React, {useEffect, useState} from "react"
import styles from "./TableList.module.css"
import {Button, Input, message, Space, Table, Tooltip} from "antd"
import ButtonColor from "antd-button-color"
import {CheckOutlined, PlusOutlined, SearchOutlined, UploadOutlined} from "@ant-design/icons"
import {TABLE_CONFIG} from "config/constants"
import {calculateColumnsWidth} from "./utils"
import {TableRowSelection} from "antd/lib/table/interface"
import {utils} from "config/utils"

interface ITableListProps {
  className?: string
  title: string
  rowSelection?: TableRowSelection<any>
  onCreate?: () => void
  onAction?: () => void
  onActionTitle?: string
  onCreateTitle?: string
  onView: (record) => void
  onImport?: (e) => void
  percent?: number
  loading?: boolean
  search?: string[]
  schema: any[]
  data?: any[]
  scroll?: any
  hidePaginate?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowKey?: any
}

const TableList = (props: ITableListProps) => {
  let progress
  let searchInput
  let tempPercent = 0
  const {
    className,
    title,
    rowSelection,
    onCreate,
    onAction,
    onActionTitle,
    onCreateTitle,
    onView,
    onImport,
    loading,
    search,
    schema,
    data,
    scroll,
    percent,
    hidePaginate,
    rowKey,
  } = props
  const [uploadDone, setUploadDone] = useState<boolean>(false)
  const [progressPercent, setPercent] = useState<number>(percent || 0)
  const [, setSearchText] = useState<string>("")
  const [, setSearchedColumn] = useState<string>("")

  useEffect(() => {
    setPercent(percent || 0)
    tempPercent = percent || 0
  }, [percent])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText("")
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={`Tìm kiếm ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Tìm kiếm
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
  })

  const handleUpload = (e) => {
    if (onImport) {
      onImport(e)
    }
    message
      .loading({
        content: "importing...",
        key: "upload",
      })
      .then()
    tempPercent = percent || progressPercent
    progress = setInterval(async () => {
      if (tempPercent >= 100) {
        message.destroy("upload")
        e.target.value = ""
        setUploadDone(true)
        await utils.sleep(2000)
        setPercent(0)
        await utils.sleep(600)
        setUploadDone(false)
        clearInterval(progress)
      } else {
        tempPercent += 20
        setPercent(tempPercent)
      }
    }, 200)
  }

  const dataTable = scroll ? calculateColumnsWidth(schema, data, scroll.x) : undefined

  return (
    <div className={[styles.tableListContainer, className].join(" ")}>
      <div
        className={[(onImport && styles.progress) || "", (uploadDone && styles.done) || ""].join(" ")}
        style={{width: (progressPercent < 100 ? progressPercent : 99.2) + "%"}}
      />
      <Table
        className={styles.container}
        rowKey={rowKey}
        scroll={dataTable ? {x: dataTable.tableWidth} : undefined}
        title={() =>
          onCreate || onAction || onImport || title ? (
            <div className={styles.titleHeader}>
              <Space direction={"horizontal"} size={12} align="start" wrap={true} className={styles.actionBar}>
                <span>{title}</span>
                <Space direction="horizontal" size={6}>
                  {(onAction && (
                    <ButtonColor
                      icon={<CheckOutlined />}
                      size={"middle"}
                      type={"warning"}
                      className={styles.createButton}
                      onClick={() => onAction()}
                    >
                      {onActionTitle || ""}
                    </ButtonColor>
                  )) ||
                    ""}
                  {(onImport && (
                    <Tooltip title={`Import ${title}`}>
                      <Button
                        disabled={progressPercent !== 0}
                        icon={<UploadOutlined />}
                        size={"middle"}
                        type={"primary"}
                        onClick={() => {
                          document.getElementById(`import_${title}`)?.click()
                        }}
                        className={styles.createButton}
                        href={"#"}
                      >
                        Import
                      </Button>
                      <input
                        id={`import_${title}`}
                        accept={".csv"}
                        type="file"
                        onChange={(e) => {
                          handleUpload(e)
                        }}
                        hidden
                      />
                    </Tooltip>
                  )) ||
                    ""}
                  {(onCreate && (
                    <Tooltip title={`Thêm mới ${title}`}>
                      <ButtonColor
                        icon={<PlusOutlined />}
                        size={"middle"}
                        type={"success"}
                        className={styles.createButton}
                        onClick={() => onCreate()}
                      >
                        {onCreateTitle || ""}
                      </ButtonColor>
                    </Tooltip>
                  )) ||
                    ""}
                </Space>
              </Space>
            </div>
          ) : (
            ""
          )
        }
        columns={
          search && search.length
            ? schema.map((s) => ({
                ...s,
                ...(s.dataIndex ? (search.includes(s.dataIndex) ? getColumnSearchProps(s.dataIndex) : {}) : {}),
              }))
            : schema
        }
        dataSource={data || []}
        loading={loading}
        onRow={(record) => ({
          onClick: () => onView(record),
        })}
        pagination={hidePaginate ? false : TABLE_CONFIG.PAGINATION}
        rowSelection={rowSelection}
      />
    </div>
  )
}

export default TableList
