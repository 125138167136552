import React from "react"
import {TYPE} from "config/constants"
import {utils} from "config/utils"
import {Tag} from "antd"

const BenefitTag = (props: {
  text: string
  textReplace?: string | JSX.Element
  isSmall?: boolean
  className?: string
  onClick?: () => void
  style?: any
}) => {
  const {text, textReplace, isSmall, style, className, onClick} = props
  return (
    <Tag
      style={style}
      className={[(!isSmall && "large-font-size") || "", className || ""].join(" ")}
      onClick={onClick}
      color={
        TYPE.BENEFIT_TYPE_NAME[text] === 1
          ? "volcano"
          : TYPE.BENEFIT_TYPE_NAME[text] === 2
          ? "cyan"
          : TYPE.BENEFIT_TYPE_NAME[text] === 5
          ? "default"
          : TYPE.BENEFIT_TYPE_NAME[text] === 6
          ? "pink"
          : TYPE.BENEFIT_TYPE_NAME[text] === 7
          ? "purple"
          : TYPE.BENEFIT_TYPE_NAME[text] === 4
          ? "red"
          : "gold"
      }
    >
      {textReplace ? textReplace : text ? utils.pascalToSentence(text) : ""}
    </Tag>
  )
}

export default BenefitTag
