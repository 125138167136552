import {Checkbox, Input, Switch, Table, Tag, Tooltip, Transfer} from "antd"
import React, {useEffect, useState} from "react"
import styles from "./TransferComponent.module.css"
import BenefitTag from "app/common/components/BenefitTag"
import difference from "lodash/difference"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../../../../../../../store/interface"
import {TYPE} from "../../../../../../../../../../../config/constants"
import {useUpdateEffect} from "@react-hookz/web"

const {Search} = Input

type dataType = {key: string; title: string}[]

const TransferComponent = (props: {
  dataSource: dataType
  value?: string[]
  directBilling?: string[]
  searchRight?: string
  applyToDeps?: string[]
  onChange: (key: string | string[]) => void
  onDirectBillingChange: (key: string, isDirectBilling: boolean, keys?: string[]) => void
  onApplyToDepsChange: (key: string, applyToDependents: boolean, keys?: string[]) => void
}) => {
  const benefitTypeOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const {
    dataSource: initDataSource,
    directBilling: initDirectBilling,
    applyToDeps: initApplyToDeps,
    value: initValue,
    onChange: propOnChange,
    onDirectBillingChange: propOnDirectBillingChange,
    onApplyToDepsChange: propOnApplyDepsChange,
  } = props
  const [dataSource, setDataSource] = useState(initDataSource || [])
  const [directBilling, setDirectBilling] = useState(initDirectBilling || [])
  const [applyToDeps, setApplyToDeps] = useState(initApplyToDeps || [])
  const [targetKeys, setTargetKeys] = useState(initValue || [])

  useUpdateEffect(() => {
    setDirectBilling(initDirectBilling || [])
    setApplyToDeps(initApplyToDeps || [])
    setDataSource(initDataSource)
    setTargetKeys(initValue || [])
  }, [initValue, initDirectBilling, initDataSource, initApplyToDeps])

  const onDataChange = (keys) => {
    setTargetKeys(keys)
    propOnChange(keys)
  }

  const onDirectBillingChange = (key: string, isDirectBilling: boolean, keys?: string[]) => {
    propOnDirectBillingChange(key, isDirectBilling || false, keys)
  }

  const onApplyToDepsChange = (key: string, applyToDependents: boolean, keys?: string[]) => {
    propOnApplyDepsChange(key, applyToDependents || false, keys)
  }

  const CustomTransfer = ({
    dataSource,
    targetKeys,
    directBillings,
    applyToDeps,
    onDirectBillingChange,
    onApplyToDeps,
    ...restProps
  }) => {
    const [searchLeft, setSearchLeft] = useState<string>("")
    const [searchRight, setSearchRight] = useState<string>("")
    const [selectAllDeps, setSelectAllDeps] = useState<boolean>(false)
    const [selectAllDirect, setSelectAllDirect] = useState<boolean>(false)

    const transferDataSource: any[] = []

    function flatten(list = []) {
      list.forEach((item: any) => {
        item.isDirectBilling = directBillings.includes(item.key)
        item.applyToDependents = applyToDeps.includes(item.key)
        transferDataSource.push(item)
        flatten(item.children)
      })
    }

    useEffect(() => {
      setSearchRight(restProps.searchRight)
    }, [restProps.searchRight])

    flatten(dataSource)
    const isChecked = (selectedKeys: string, eventKey: string) => selectedKeys.indexOf(eventKey) !== -1

    return (
      <Transfer
        {...restProps}
        targetKeys={targetKeys}
        titles={["Tất cả quyền lợi", "Quyền lợi đã được thêm"]}
        dataSource={transferDataSource}
        operations={["Thêm", "Xóa"]}
        className={styles.transferContainer}
        render={(item: any) => (
          <div className={styles.rowTransfer}>
            <div>{item.title}</div>
            <div>
              <span>
                <strong>Is Direct Billing </strong>
              </span>
              <Switch
                size={"small"}
                checked={item.isDirectBilling}
                onClick={(value, e) => {
                  e.stopPropagation()
                  restProps.onChange(item.key, value || false)
                }}
              />
            </div>
          </div>
        )}
        showSelectAll={false}
      >
        {({direction, onItemSelect, onItemSelectAll, selectedKeys}) => {
          const columns = [
            {
              title: "Mã",
              dataIndex: "code",
              key: "code",
              width: 100,
              render: (text, record) => (
                <Tag
                  style={
                    direction === "left"
                      ? // eslint-disable-next-line react/prop-types
                        targetKeys.includes(record.key)
                        ? {
                            opacity: 0.5,
                            cursor: "not-allowed",
                          }
                        : {opacity: 1}
                      : {}
                  }
                  color={"geekblue"}
                >
                  {text}
                </Tag>
              ),
            },
            {
              title: "Loại",
              dataIndex: "type",
              key: "type",
              width: 100,
              render: (text, record) => (
                <BenefitTag
                  style={
                    direction === "left"
                      ? targetKeys.includes(record.key)
                        ? {
                            opacity: 0.5,
                            cursor: "not-allowed",
                          }
                        : {opacity: 1}
                      : {}
                  }
                  text={text}
                  textReplace={benefitTypeOption && benefitTypeOption.find((b) => b.value === text)?.name}
                  isSmall
                />
              ),
            },
            {
              title: "Tên quyền lợi",
              dataIndex: "name",
              key: "name",
              width: direction === "left" ? 400 : 300,
              ellipsis: true,
              render: (text, record) => (
                <Tooltip placement={"top"} color={"geekblue"} title={text} mouseEnterDelay={0.5}>
                  <span
                    style={
                      direction === "left"
                        ? // eslint-disable-next-line react/prop-types
                          targetKeys.includes(record.key)
                          ? {
                              opacity: 0.5,
                              cursor: "not-allowed",
                            }
                          : {opacity: 1}
                        : {}
                    }
                  >
                    {text}
                  </span>
                </Tooltip>
              ),
            },
            direction === "right"
              ? {
                  title: "Apply to dependents",
                  dataIndex: "applyToDeps",
                  key: "applyToDeps",
                  width: 100,
                  render: (text, record) => (
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      onChange={(e) => {
                        onApplyToDeps(record.key, e.target.checked || false)
                      }}
                      checked={record.applyToDeps}
                    />
                  ),
                }
              : {},
            direction === "right"
              ? {
                  title: "Is direct billing",
                  dataIndex: "directBilling",
                  key: "directBilling",
                  width: 100,
                  render: (text, record) => (
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      onChange={(e) => {
                        onDirectBillingChange(record.key, e.target.checked || false)
                      }}
                      checked={record.isDirectBilling}
                    />
                  ),
                }
              : {},
          ]

          const rowSelection = {
            getCheckboxProps: (item) => {
              return {
                // eslint-disable-next-line react/prop-types
                disabled: direction === "left" ? targetKeys.includes(item.key) : false,
              }
            },
            onSelectAll(selected, selectedRows) {
              const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({key}) => key)
              const diffKeys = selected
                ? difference(treeSelectedKeys, selectedKeys)
                : difference(selectedKeys, treeSelectedKeys)
              onItemSelectAll(diffKeys, selected)
            },
            onSelect({key}, selected) {
              onItemSelect(key, selected)
            },
            selectedRowKeys: selectedKeys,
          }

          if (direction === "left") {
            const checkedKeys: any = [...selectedKeys, ...targetKeys]
            return (
              <>
                <div className={styles.searchBar}>
                  <Search
                    style={{marginBottom: 8}}
                    placeholder="Search"
                    onChange={(e) => setSearchLeft(e.target.value)}
                  />
                </div>
                <div className={[styles.listContainer, styles.left].join(" ")}>
                  <Table
                    size="small"
                    dataSource={dataSource
                      .filter((dataItem) => dataItem.title.toLowerCase().includes(searchLeft.toLowerCase()))
                      .map((item) => ({
                        key: item.key,
                        code: item.title.split("|D|")[1],
                        type: item.title.split("|D|")[0],
                        name: item.title.split("|D|")[2],
                      }))}
                    columns={columns}
                    rowSelection={rowSelection}
                    onRow={({key}) => ({
                      onClick: () => {
                        onItemSelect(key as string, !isChecked(checkedKeys, key as string))
                      },
                    })}
                  />
                </div>
              </>
            )
          }
          if (direction === "right") {
            const checkedKeys: any = [...selectedKeys, ...targetKeys]
            return (
              <div className={styles.transferContainer}>
                <div className={styles.searchBar}>
                  <Search
                    style={{marginBottom: 8}}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchRight(e.target.value)
                      setSelectAllDirect(false)
                      setSelectAllDeps(false)
                    }}
                    value={searchRight}
                  />
                </div>
                <div className={styles.actionBar}>
                  <Checkbox
                    className={styles.actionBarCheckbox}
                    checked={selectAllDeps}
                    onChange={(e) => {
                      setSelectAllDeps(e.target.checked)
                      const selectedItems = dataSource
                        .filter(
                          (dataItem) =>
                            // eslint-disable-next-line react/prop-types
                            targetKeys.includes(dataItem.key) &&
                            dataItem.title.toLowerCase().includes(searchRight.toLowerCase()),
                        )
                        .map((item) => item.key)
                      onApplyToDeps(null, e.target.checked, selectedItems)
                    }}
                  >
                    Select all apply to deps
                  </Checkbox>
                  <Checkbox
                    className={styles.actionBarCheckbox}
                    checked={selectAllDirect}
                    onChange={(e) => {
                      setSelectAllDirect(e.target.checked)
                      const selectedItems = dataSource
                        .filter(
                          (dataItem) =>
                            // eslint-disable-next-line react/prop-types
                            targetKeys.includes(dataItem.key) &&
                            dataItem.title.toLowerCase().includes(searchRight.toLowerCase()),
                        )
                        .map((item) => item.key)
                      onDirectBillingChange(null, e.target.checked, selectedItems)
                    }}
                  >
                    Select all direct billing
                  </Checkbox>
                </div>
                <div className={styles.listContainer}>
                  <Table
                    size="small"
                    dataSource={dataSource
                      .filter(
                        (dataItem) =>
                          // eslint-disable-next-line react/prop-types
                          targetKeys.includes(dataItem.key) &&
                          dataItem.title.toLowerCase().includes(searchRight.toLowerCase()),
                      )
                      .map((item) => ({
                        key: item.key,
                        code: item.title.split("|D|")[1],
                        type: item.title.split("|D|")[0],
                        name: item.title.split("|D|")[2],
                        applyToDeps: item.applyToDependents,
                        isDirectBilling: item.isDirectBilling,
                      }))}
                    columns={columns}
                    rowSelection={rowSelection}
                    onRow={({key}) => ({
                      onClick: () => {
                        onItemSelect(
                          key as string,
                          selectedKeys.includes(key)
                            ? !isChecked(checkedKeys, key as string)
                            : isChecked(checkedKeys, key as string),
                        )
                      },
                    })}
                  />
                </div>
              </div>
            )
          }
        }}
      </Transfer>
    )
  }

  return (
    <CustomTransfer
      dataSource={dataSource}
      targetKeys={targetKeys}
      directBillings={directBilling}
      applyToDeps={applyToDeps}
      onChange={onDataChange}
      searchRight={props.searchRight}
      onDirectBillingChange={onDirectBillingChange}
      onApplyToDeps={onApplyToDepsChange}
    />
  )
}

export default TransferComponent
