import Service from "../services"
import endPoints from "../endpoints"
import {axiosResponseToData, defaultResponse} from "../index"
import {claimRequestType, IBenefitUpdate, IClaimCaseInfo, IClaimCaseUpdate} from "app/portal/screens/ClaimPortal"
import {paymentDataType} from "app/portal/screens/ClaimPortal/CreateClaimRequestScreen/screens/ClaimCaseScreen/screens/ClaimCasePaymentScreen"
import _ from "lodash"
import {EmailType} from "app/portal/screens/ClaimPortal/CreateClaimRequestScreen/screens/ClaimCaseScreen/screens/ClaimCaseCorrespondenceScreen"

interface IClaimUpdate {
  source: string
  email?: string
  diseases?: string
  eventDates?: string
  treatmentMethod?: string
  medicalProviderId?: string
  medicalProviderName?: string
  diagnosis?: string
  status?: string
}

const ClaimServices = {
  getAllClaim: async (
    search?: string,
    filter?: {
      from?: string
      to?: string
      status?: string
      benefitType?: string
    },
  ) => {
    const response = await Service(true).get(endPoints.CLAIM(undefined, search, "", filter))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getCertificateList: async (search?: string) => {
    const response = await Service(true).get(endPoints.CERTIFICATE_LIST(search))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimBenefit: async (claimId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_BENEFIT(claimId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getCertificateDetail: async (id: string) => {
    const response = await Service(true).get(endPoints.CERTIFICATE_DETAIL_BY_ID(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimDetail: async (claimId: string) => {
    const response = await Service(true).get(endPoints.CLAIM(claimId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateClaimDetail: async (claimId: string, claimData: IClaimUpdate) => {
    const response = await Service(true).put(endPoints.CLAIM(claimId), claimData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createCertificateClaim: async (createClaimRequest: claimRequestType) => {
    const response = await Service(true).post(endPoints.CLAIM(), createClaimRequest)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getInsuredPersonDetail: async (insuredPersonId: string) => {
    const response = await Service(true).get(endPoints.INSURED_PERSON_DETAIL(insuredPersonId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getInsuredPersonClaimCase: async (insuredPersonId: string) => {
    const response = await Service(true).get(endPoints.INSURED_PERSON_CLAIM_CASES(insuredPersonId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getInsuredPersonCertificate: async (insuredPersonId: string) => {
    const response = await Service(true).get(endPoints.INSURED_PERSON_CERTIFICATE(insuredPersonId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getInsuredPersonBenefitTypes: async (insuredPersonId: string) => {
    const response = await Service(true).get(endPoints.INSURED_PERSON_BENEFIT_TYPES(insuredPersonId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCasePayment: async (claimId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_CASE_PAYMENT_CLAIM(claimId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateClaimCasePayment: async (claimId: string, paymentData: paymentDataType) => {
    const response = await Service(true).post(endPoints.CLAIM_CASE_PAYMENT_CLAIM(claimId), {
      ...paymentData,
    })
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateClaimCaseStatus: async (claimCaseId: string, status: string) => {
    const response = await Service(true).post(endPoints.CLAIM_CASE_STATUS(claimCaseId), {status})
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getTemplate: async (template: string, type: string, claimCaseId: string, isPresentCase?: boolean) => {
    const response = await Service(true).get(endPoints.CLAIM_CASE_TEMPLATE(template, type, claimCaseId, isPresentCase))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  claimCaseSendCorrespondence: async (
    claimCaseId: string,
    isEmail: boolean,
    sendData:
      | {
          subject: string
          tos?: EmailType[]
          ccs?: EmailType[]
          bccs?: EmailType[]
          content: string
        }
      | {
          phone: string
          message: string
        },
  ) => {
    const response = await Service(true).post(endPoints.CLAIM_CASE_SEND_CORESPONDENT(claimCaseId, isEmail), sendData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimDocument: async (claimId: string) => {
    const response = await Service(true).get(endPoints.DOCUMENT_CLAIM(claimId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getDocumentDetail: async (claimId: string, documentId: string) => {
    const response = await Service(true).get(endPoints.DOCUMENT_DETAIL(claimId, documentId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCaseList: async (claimId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_CASE_LIST(claimId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCertificate: async (claimId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_CERTIFICATE_LIST(claimId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createClaimCase: async (claimId: string, insuredCertificateIds: string[]) => {
    const response = await Service(true).post(endPoints.CLAIM_CASE_LIST(claimId), {insuredCertificateIds})
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCaseBenefit: async (claimCaseId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_CASE_BENEFITS(claimCaseId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCaseDetail: async (claimCaseId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_CASE(claimCaseId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateClaimCaseDetail: async (claimCaseId: string, claimCaseData: IClaimCaseUpdate) => {
    const response = await Service(true).put(endPoints.CLAIM_CASE(claimCaseId), claimCaseData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  sendClaimCaseSignOffMsg: async (claimCaseId: string, signOffNote: {type: string; content: string}) => {
    const response = await Service(true).post(endPoints.CLAIM_CASE_SIGN_OFF(claimCaseId), signOffNote)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCaseBenefitBalance: async (claimCaseId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_CASE_BALANCE_BENEFIT(claimCaseId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCaseStatusList: async (claimCaseId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_CASE_STATUS(claimCaseId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCaseClaimRequest: async (claimCaseId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_CASE_CLAIM_REQUEST(claimCaseId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  checkIncludeTimeBenefitBalance: async (claimCaseId: string, benefitId: string) => {
    const response = await Service(true).get(endPoints.CLAIM_BENEFIT_IGNORE_TIME(claimCaseId, benefitId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createClaimBenefitBalance: async (claimCaseId: string, planBenefitId: string, benefitBalanceData: IBenefitUpdate) => {
    const benefitBalanceRequest = _.omit(benefitBalanceData, "id")
    const response = await Service(true).post(endPoints.CLAIM_CASE_BALANCE_BENEFIT(claimCaseId), {
      ...benefitBalanceRequest,
      planBenefitId,
    })
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateClaimBenefitBalance: async (claimId: string, planBenefitId: string, benefitBalanceData: IBenefitUpdate) => {
    const benefitBalanceRequest = _.omit(benefitBalanceData, "id")
    const response = await Service(true).put(
      endPoints.UPDATE_CLAIM_BALANCE_BENEFIT(claimId, benefitBalanceRequest?.claimDetailId || ""),
      {
        ...benefitBalanceRequest,
        planBenefitId,
      },
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateClaimCasePending: async (claimCaseId: string, pendingData: IClaimCaseInfo["pendings"], isUpdate?: boolean) => {
    const response = isUpdate
      ? await Service(true).put(endPoints.CLAIM_CASE_PENDING(claimCaseId), pendingData)
      : await Service(true).post(endPoints.CLAIM_CASE_PENDING(claimCaseId), pendingData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
}

export default ClaimServices
