import config from "./configuration"
import token from "./token"
import me from "./me"
import types from "./types"

const reducers = {
  config,
  token,
  me,
  types,
}

export default reducers
