import React, {useState} from "react"
import InfoBlock, {schemaType} from "app/common/components/InfoBlock"
import ClaimServices from "services/ClaimServices"
import {useMountEffect} from "@react-hookz/web"
import {utils} from "config/utils"
import {FORMATTER, KEYS, STATE_STATUS, TYPE} from "config/constants"
import {useHistory, useParams} from "react-router-dom"
import DetailClaimForm from "../../components/DetailClaimForm"
import _ from "lodash"
import moment from "moment"
import {
  Button,
  Col,
  Empty,
  Image,
  message,
  Modal,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd"
import {
  BookOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CloseOutlined,
  ContainerOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import {claimRequestType} from "../../index"
import styles from "./ClaimRequestDetailScreen.module.css"
import ButtonColor from "antd-button-color"
import BottomBar from "./components/BottomBar"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"
import {CLAIM_CASE_PATH} from "../../../ClaimCaseScreen/routes"
import FileService from "../../../../../../../../../services/FileServices"
import BenefitTag from "../../../../../../../../common/components/BenefitTag"
import caolan from "async"
import UploadComponent from "../../../ClaimCaseScreen/screens/ClaimCaseDocumentScreen/component/UploadComponent"

const {TabPane} = Tabs
const {Paragraph} = Typography
const {Option} = Select

export type claimInfoType =
  | {
      amount: number
      benefitType: string
      claimNumber: string
      diagnosis: string
      diseases: {name: string; value: string}[]
      email: string
      eventDates: string
      eventDateRanges?: string[]
      medicalProviderItem?: {
        key: string
        value: string
        label: string
      }
      medicalProviderAssessmentId?: string
      medicalProviderAssessmentName?: string
      treatmentMethod?: string
      insuredPersonDob: string
      insuredPersonEmail: string
      insuredPersonId: string
      insuredPersonName: string
      insuredPersonPaperId: string
      insuredPersonPhone: string
      isDirectBilling: boolean
      medicalProviderId: string
      medicalProviderName: string
      notes: string
      pendings: string
      phone: string
      requestAmount: number
      status: string
    }
  | undefined

export type certificateType = {
  claimCaseCode: string
  claimCaseId: string
  duedAt: string
  insuredCertificateCode: string
  insuredCertificateId: string
  hasBenefitType: boolean
  hasBenefitTypeDirectBilling: boolean
  isValid: boolean
  insurer: string
  issuedAt: string
  hasClaimCase: boolean
}

export type claimCaseType = {
  certificateCode: string
  claimCaseId: string
  code: string
  createdAt: string
  insuredPersonName: string
  insurer: string
  status: string
  updatedAt: string
}

interface IDocumentData {
  id: string
  url: string
  type: string
  source: string
  displayName: string
  fileName: string
  isImage: boolean
  isTrash: boolean
}

const DocumentReader = (props: {dataList: IDocumentData[]}) => {
  const mapPaperType = (type: string) => {
    switch (type) {
      case TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER:
        return TYPE.CLAIM_FILE_TYPE_VALUE.DISCHARGE_PAPER
      case TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER:
        return TYPE.CLAIM_FILE_TYPE_VALUE.INVOICE_PAPER
      case TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER:
        return TYPE.CLAIM_FILE_TYPE_VALUE.PRESCRIPTION_PAPER
      case TYPE.CLAIM_FILE_TYPE.OTHER_PAPER:
        return TYPE.CLAIM_FILE_TYPE_VALUE.OTHER_PAPER
      default:
        break
    }
  }
  const {dataList} = props
  return (
    <Image.PreviewGroup>
      {(dataList &&
        dataList.map((paper) => {
          if (paper.isImage) {
            return (
              <div key={paper.id} className={styles.imageContainer}>
                <Image width={148} height={108} src={paper.url} />
                <div className={styles.paperLabel}>{mapPaperType(paper.type)}</div>
              </div>
            )
          } else {
            return (
              <div key={paper.id} className={styles.documentContainer}>
                <div className={styles.pdfContainer}>
                  <div className={styles.pdfCover} onClick={() => window.open(paper.url)} />
                </div>
                <div className={styles.paperLabel}>{mapPaperType(paper.type)}</div>
              </div>
            )
          }
        })) ||
        ""}
    </Image.PreviewGroup>
  )
}

const ClaimRequestDetailScreen = () => {
  const [claimInfo, setClaimInfo] = useState<claimInfoType>()
  const [claimCases, setClaimCases] = useState<claimCaseType[]>([])
  const [certificates, setCertificates] = useState<certificateType[]>()
  const [activeCertificate, setActiveCertificate] = useState<string[]>([])
  const [loadingInfo, setLoadingInfo] = useState<boolean>(true)
  const [loadingDocs, setLoadingDocs] = useState<boolean>(false)
  const [showCreate, setShowCreate] = useState(false)
  const [fileType, setFileType] = useState("")
  const [statusClaimCase, setStatusClaimCase] = useState<STATE_STATUS>(STATE_STATUS.INITIAL)
  const [statusCertificate, setStatusCertificate] = useState<STATE_STATUS>(STATE_STATUS.INITIAL)
  const [statusDocuments, setStatusDocuments] = useState<STATE_STATUS>(STATE_STATUS.INITIAL)
  const [fileList, setFileList] = useState<IDocumentData[]>([])
  const benefitTypesList = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )

  const statusTypeOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.CLAIM_CASE_STATUS],
  )
  const history = useHistory()

  const {claimId} = useParams<{claimId: string}>()

  const getCertificates = async () => {
    setStatusCertificate(STATE_STATUS.LOADING)
    const response = await ClaimServices.getClaimCertificate(claimId)
    if (response && response.data) {
      setCertificates(response.data)
      if (response.data.length) {
        setStatusCertificate(STATE_STATUS.DONE)
      } else {
        setStatusCertificate(STATE_STATUS.EMPTY)
      }
    }
  }

  const getClaimDetail = async () => {
    const response = await ClaimServices.getClaimDetail(claimId)
    if (response && response.data) {
      const newClaimInfo: claimInfoType = response.data
      if (newClaimInfo) {
        newClaimInfo.diseases = newClaimInfo?.diseases.map((d) => ({
          key: d.value,
          label: d.name,
          value: d.value,
          name: d.name,
        }))
        newClaimInfo.eventDateRanges = [
          utils.parseLocalTime(newClaimInfo.eventDates.split("&")[0]),
          utils.parseLocalTime(newClaimInfo.eventDates.split("&")[1]),
        ]
        newClaimInfo.medicalProviderItem = {
          key: newClaimInfo.medicalProviderId,
          label: newClaimInfo.medicalProviderName,
          value: newClaimInfo.medicalProviderId,
        }
      }
      setClaimInfo(newClaimInfo)
    }
    setLoadingInfo(false)
  }

  const getClaimCase = async () => {
    setStatusClaimCase(STATE_STATUS.LOADING)
    const response = await ClaimServices.getClaimCaseList(claimId)
    if (response && response.data) {
      setClaimCases(response.data)
      if (response.data.length) {
        setStatusClaimCase(STATE_STATUS.DONE)
      } else {
        setStatusClaimCase(STATE_STATUS.EMPTY)
      }
    }
  }

  const getClaimRequestDocuments = async () => {
    setStatusDocuments(STATE_STATUS.LOADING)
    const response = await FileService.getFileList(TYPE.FILE_TYPE.CLAIM_DOCUMENT, claimId, "", "", true)
    if (response && response.data) {
      setFileList(response.data.collection)
      if (response.data.collection.length) {
        setStatusDocuments(STATE_STATUS.DONE)
      } else {
        setStatusDocuments(STATE_STATUS.EMPTY)
      }
    }
  }

  const createClaimCase = async () => {
    setStatusClaimCase(STATE_STATUS.LOADING)
    const response = await ClaimServices.createClaimCase(claimId, activeCertificate)
    if (response.isSuccess) {
      getClaimCase().then()
      getCertificates().then()
      message.success("Tạo Claim Case thành công")
    } else {
      message.error("Tạo Claim Case không thành công")
    }
    setStatusClaimCase(STATE_STATUS.DONE)
  }

  const getFirstLoad = async () => {
    getClaimDetail().then()
    getClaimCase().then()
    getCertificates().then()
    getClaimRequestDocuments().then()
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  const onChangeClaimInfo = (value, name) => {
    // eslint-disable-next-line
    let newclaimInfo = _.clone(claimInfo)
    if (newclaimInfo) {
      newclaimInfo[name] = value
    }
    setClaimInfo(newclaimInfo)
  }

  const updateClaimData = async () => {
    setLoadingInfo(true)
    const updateClaimRequest: claimRequestType = {
      source: "",
      insuredPersonId: claimInfo?.insuredPersonId || "",
    }
    if (claimInfo) {
      updateClaimRequest.benefitType = claimInfo?.benefitType

      if (claimInfo.eventDates || claimInfo.eventDateRanges?.length) {
        updateClaimRequest.eventDates =
          claimInfo.eventDateRanges &&
          moment.isDate(new Date(claimInfo.eventDateRanges[0])) &&
          moment.isDate(new Date(claimInfo.eventDateRanges[1]))
            ? `${utils.parseLocalTime(claimInfo.eventDateRanges[0])}&${utils.parseLocalTime(
                claimInfo.eventDateRanges[1],
              )}`
            : ""
        if (claimInfo.eventDates === "Invalid date") claimInfo.eventDates = ""
      }

      updateClaimRequest.medicalProviderId =
        (claimInfo.medicalProviderItem && claimInfo.medicalProviderItem.value) ||
        claimInfo.medicalProviderAssessmentId ||
        ""
      updateClaimRequest.medicalProviderName =
        (claimInfo.medicalProviderItem && claimInfo.medicalProviderItem.label) ||
        claimInfo.medicalProviderAssessmentName ||
        ""
      updateClaimRequest.diseases = claimInfo.diseases?.map((d) => d.value).join("|")
      updateClaimRequest.email = claimInfo.email
      updateClaimRequest.treatmentMethod = claimInfo.treatmentMethod
      updateClaimRequest.diagnosis = claimInfo.diagnosis
      updateClaimRequest.source = TYPE.SOURCE_TYPE.ASSESSOR
      updateClaimRequest.status = claimInfo.status;
    }
    const response = await ClaimServices.updateClaimDetail(claimId, updateClaimRequest)
    if (response.isSuccess) {
      message.success(`Cập nhật thành công`)
    } else {
      if (response.data && typeof response.data === "object") {
        for (let i = 0; i < Object.keys(response.data).length; i++) {
          setTimeout(() => {
            const title = <h3 style={{color: "red"}}>Validate field {Object.keys(response.data)[i].split("$.")[1]}</h3>
            notification.error({
              message: title,
              description: response.data[Object.keys(response.data)[i]],
              icon: <CloseOutlined style={{color: "red"}} />,
            })
          }, 200 * i)
        }
      } else {
        message.error(response.messages)
      }
    }
    setLoadingInfo(false)
  }

  const onActiveCertificate = (certificateId: string) => {
    // eslint-disable-next-line
    let newActiveCertificate = _.clone(activeCertificate)
    if (newActiveCertificate.includes(certificateId)) {
      newActiveCertificate.splice(
        activeCertificate.findIndex((value) => value === certificateId),
        1,
      )
    } else {
      newActiveCertificate.push(certificateId)
    }
    setActiveCertificate(newActiveCertificate)
  }

  const getListPaper = (type) => {
    return _.filter(fileList, ["type", type])
  }

  const selectFileType = (value) => {
    setFileType(value)
  }

  const checkAllDocumentHasType = (dataList) => {
    let result = true
    dataList.forEach((data, index) => {
      if (data.type === "") {
        result = false
        setLoadingDocs(false)
        message.warn(`Vui lòng chọn loại giấy tờ cho giấy tờ thứ ${index + 1}`)
        return result
      }
    })
    return result
  }

  const uploadFile = async (data: {file?: File; type?: string}) => {
    if (data.file && data.type) {
      const file = await utils.base64ToFile(data.file)
      file.append(KEYS.DOC_TYPE, data.type)
      file.append(KEYS.DOC_SOURCE, KEYS.DOC_SOURCE_NAME.ASSESSMENTS)
      await FileService.uploadFileClaim(claimId, file)
    }
  }

  const uploadDocument = async () => {
    setLoadingDocs(true)
    if (fileList && checkAllDocumentHasType(fileList)) {
      caolan.eachOf(
        fileList,
        (paperData: {file?: File; type?: string}, key, callback) => {
          if (paperData.type) {
            uploadFile(paperData).then(() => callback())
          }
        },
        (err) => {
          if (err) {
            message.error("Thêm mới thất bại")
            setLoadingDocs(false)
          } else {
            setLoadingDocs(false)
            message.success("Tải lên hình ảnh thành công")
            setShowCreate(false)
            getFirstLoad().then()
          }
        },
      )
    }
  }

  const handleFileData = (fileList) => {
    setFileList(fileList)
    setFileType("")
  }

  const infoSchema: schemaType = [
    {
      key: "insuredPersonName",
      label: "Họ tên",
    },
    {
      key: "insuredPersonPaperId",
      label: "CMND/CCCD",
    },
    {
      key: "insuredPersonDob",
      label: "Ngày sinh",
      render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_FORMAT),
    },

    {
      key: "insuredPersonPhone",
      label: "Số điện thoại",
    },
    {
      key: "insuredPersonEmail",
      label: "Email",
    },
    {
      key: "benefitType",
      label: "Quyền lợi",
      render: (text) => (
        <BenefitTag
          text={text}
          textReplace={
            benefitTypesList ? benefitTypesList.find((benefitType) => benefitType.value === text)?.name || text : ""
          }
        />
      ),
    },
  ]

  return (
    <div className={styles.container}>
      {claimInfo ? (
        <div className={styles.claimNo}>
          <Paragraph copyable={{tooltips: false}}>{claimInfo.claimNumber}</Paragraph>
        </div>
      ) : (
        ""
      )}
      <Row gutter={[15, 10]}>
        <Col span={16}>
          <InfoBlock
            data={
              claimInfo
                ? {
                    insuredPersonName: claimInfo.insuredPersonName,
                    insuredPersonPaperId: claimInfo.insuredPersonPaperId,
                    insuredPersonDob: claimInfo.insuredPersonDob,
                    insuredPersonPhone: claimInfo.insuredPersonPhone,
                    insuredPersonEmail: claimInfo.insuredPersonEmail,
                    benefitType: claimInfo.benefitType,
                  }
                : {}
            }
            schema={infoSchema}
            loading={loadingInfo}
          />
          <div className={[styles.detailFormContainer, styles.blockContainer].join(" ")}>
            <h3>
              Thông tin YCBT
              {claimInfo?.isDirectBilling ? (
                <>
                  {" - "}
                  <span className={"successLabel"} color={"green"}>
                    <CheckCircleOutlined /> Bảo lãnh viện phí
                  </span>{" "}
                </>
              ) : (
                ""
              )}
            </h3>
            <DetailClaimForm
              data={claimInfo}
              loading={loadingInfo}
              onChange={(value, name) => onChangeClaimInfo(value, name)}
            />
          </div>
          <div className={[styles.blockContainer, styles.claimDocumentContainer].join(" ")}>
            <h3>Thông tin giấy tờ</h3>
            <div className={styles.actionBar}>
              <ButtonColor
                icon={<PlusOutlined />}
                size={"middle"}
                type={"success"}
                className={styles.buttonCreate}
                onClick={() => setShowCreate(true)}
              >
                Tải lên hình ảnh mới
              </ButtonColor>
            </div>
            <Spin spinning={statusDocuments === STATE_STATUS.LOADING}>
              <Tabs tabPosition={"left"} className={styles.tabBar}>
                <TabPane tab={`Tất cả (${fileList?.length})`} key="all">
                  <div className={styles.contentPaper}>
                    {(fileList && fileList.length === 0 && <Empty style={{width: "100%"}} />) || (
                      <DocumentReader dataList={fileList || []} />
                    )}
                  </div>
                </TabPane>
                <TabPane
                  tab={`Giấy ra viện (${getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER).length})`}
                  key="dischargePaper"
                >
                  <div className={styles.contentPaper}>
                    {(getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER).length === 0 && (
                      <Empty style={{width: "100%"}} />
                    )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER)} />}
                  </div>
                </TabPane>
                <TabPane
                  tab={`Hóa đơn (${getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER).length})`}
                  key="invoicePaper"
                >
                  <div className={styles.contentPaper}>
                    {(getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER).length === 0 && (
                      <Empty style={{width: "100%"}} />
                    )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER)} />}
                  </div>
                </TabPane>
                <TabPane
                  tab={`Toa thuốc (${getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER).length})`}
                  key="prescriptionPaper"
                >
                  <div className={styles.contentPaper}>
                    {(getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER).length === 0 && (
                      <Empty style={{width: "100%"}} />
                    )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER)} />}
                  </div>
                </TabPane>
                <TabPane
                  tab={`Giấy tờ khác (${getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length})`}
                  key="otherPaper"
                >
                  <div className={styles.contentPaper}>
                    {(getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length === 0 && (
                      <Empty style={{width: "100%"}} />
                    )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER)} />}
                  </div>
                </TabPane>
              </Tabs>
            </Spin>
          </div>
        </Col>
        <Col span={8} className={styles.sideContainer}>
          {statusCertificate !== STATE_STATUS.EMPTY &&
          certificates &&
          claimCases &&
          certificates.length !== claimCases.length ? (
            <Space direction={"vertical"} className={styles.spaceContainer}>
              <div className={styles.headerSide}>
                <h2>
                  <BookOutlined /> Thẻ bảo hiểm
                </h2>
                <ButtonColor
                  disabled={!activeCertificate.length}
                  loading={statusCertificate === STATE_STATUS.LOADING}
                  type={"success"}
                  onClick={createClaimCase}
                >
                  Tạo Claim Case
                </ButtonColor>
              </div>
              <div className={styles.claimCaseListContainer}>
                {certificates.map((certificate) =>
                  !certificate.hasClaimCase ? (
                    <Row
                      gutter={[0, 10]}
                      align={"middle"}
                      key={certificate.insuredCertificateId}
                      className={[
                        styles.blockContainer,
                        styles.certificateItem,
                        activeCertificate.includes(certificate.insuredCertificateId) ? styles.active : "",
                      ].join(" ")}
                      onClick={() => onActiveCertificate(certificate.insuredCertificateId)}
                    >
                      <Col span={24}>
                        <label>Mã thẻ bảo hiểm</label>
                        <h4>{certificate.insuredCertificateCode} </h4>
                      </Col>
                      <Col span={24}>
                        <label>Công ty bảo hiểm</label>
                        <div>{certificate.insurer}</div>
                      </Col>
                      <Col span={12}>
                        <label>Có quyền lợi phù hợp</label>
                        <div>
                          {certificate.hasBenefitType ? (
                            <>
                              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                              <span
                                style={{
                                  color: "#52c41a",
                                  fontWeight: "bold",
                                }}
                              >
                                Có
                              </span>
                            </>
                          ) : (
                            <>
                              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
                              <span
                                style={{
                                  color: "var(--red-alert)",
                                  fontWeight: "bold",
                                }}
                              >
                                Không
                              </span>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <label>Có bảo lãnh viện phí</label>
                        <div>
                          {certificate.hasBenefitTypeDirectBilling ? (
                            <>
                              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                              <span
                                style={{
                                  color: "#52c41a",
                                  fontWeight: "bold",
                                }}
                              >
                                Có
                              </span>
                            </>
                          ) : (
                            <>
                              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
                              <span
                                style={{
                                  color: "var(--red-alert)",
                                  fontWeight: "bold",
                                }}
                              >
                                Không
                              </span>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <label>Ngày hiệu lực</label>
                        <div>
                          {utils.parseToStringLocalDateTime(
                            certificate.issuedAt,
                            FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC,
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <label>Ngày hết hiệu lực</label>
                        <div>
                          {utils.parseToStringLocalDateTime(certificate.duedAt, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  ),
                )}
              </div>
            </Space>
          ) : statusCertificate === STATE_STATUS.LOADING ? (
            <Space direction={"vertical"} className={styles.spaceContainer}>
              <div className={styles.headerSide}>
                <h2>
                  <BookOutlined /> Thẻ bảo hiểm
                </h2>
              </div>
              <Row
                gutter={[0, 10]}
                align={"middle"}
                className={[styles.blockContainer, styles.certificateItem].join(" ")}
              >
                <Col span={24}>
                  <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 300}} />
                </Col>
                <Col span={12}>
                  <label>Công ty bảo hiểm</label>
                  <div>
                    <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 250}} />
                  </div>
                </Col>
                <Col span={12}>
                  <label>Ngày hiệu lực</label>
                  <div>
                    <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 150}} />
                  </div>
                </Col>
                <Col span={12}>
                  <label>Ngày hết hiệu lực</label>
                  <div>
                    <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 150}} />
                  </div>
                </Col>
              </Row>
            </Space>
          ) : (
            ""
          )}
          {statusClaimCase === STATE_STATUS.DONE ? (
            <Space direction={"vertical"} className={styles.spaceContainer}>
              <div className={styles.headerSide}>
                <h2>
                  <ContainerOutlined /> Hồ sơ bồi thường
                </h2>
              </div>
              <div className={styles.claimCaseListContainer}>
                {claimCases.map((claimCase) => (
                  <Col
                    key={claimCase.claimCaseId}
                    className={[styles.blockContainer, styles.claimCaseItem].join(" ")}
                    onClick={() =>
                      history.push(CLAIM_CASE_PATH.CLAIM_CASE_INFO.replace(":claimCaseId", claimCase.claimCaseId))
                    }
                  >
                    <Row gutter={[16, 10]} align={"middle"}>
                      <Col span={24}>
                        <label>Mã hồ sơ bồi thường</label>
                        <h4>{claimCase.code}</h4>
                      </Col>
                      <Col span={24}>
                        <label>Mã thẻ bảo hiểm</label>
                        <div>{claimCase.certificateCode}</div>
                      </Col>
                      <Col span={12}>
                        <label>Công ty bảo hiểm</label>
                        <div>{claimCase.insurer}</div>
                      </Col>
                      <Col span={12}>
                        <label>Tạo lúc</label>
                        <div>
                          {utils.parseToStringLocalDateTime(
                            claimCase.createdAt,
                            FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC,
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <label>Cập nhật lúc</label>
                        <div>
                          {utils.parseToStringLocalDateTime(
                            claimCase.updatedAt,
                            FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC,
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <label>Trạng thái</label>
                        <div>
                          {statusTypeOption &&
                            statusTypeOption.find((status) => status.value === claimCase.status)?.name}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </div>
            </Space>
          ) : statusClaimCase === STATE_STATUS.LOADING ? (
            <Space direction={"vertical"} className={styles.spaceContainer}>
              <div className={styles.headerSide}>
                <h2>
                  <ContainerOutlined /> Hồ sơ bồi thường
                </h2>
              </div>
              <Col className={[styles.blockContainer, styles.claimCaseItem].join(" ")}>
                <Row gutter={[16, 10]} align={"middle"}>
                  <Col span={24}>
                    <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 300}} />
                  </Col>
                  <Col span={12}>
                    <label>Người được bảo hiểm</label>
                    <div>
                      <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 250}} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <label>Công ty bảo hiểm</label>
                    <div>
                      <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 150}} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <label>Tạo lúc</label>
                    <div>
                      <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 150}} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <label>Cập nhật lúc</label>
                    <div>
                      <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 300}} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <label>Trạng thái</label>
                    <div>
                      <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 150}} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Space>
          ) : (
            ""
          )}
        </Col>
      </Row>
      {(showCreate && (
        <Modal
          title=""
          centered
          visible={showCreate}
          width={750}
          onCancel={() => {
            setShowCreate(false)
            setFileType("")
          }}
          footer={[
            <div key="0">
              <Select
                style={{
                  width: 120,
                  marginRight: 20,
                }}
                placeholder="Chọn loại"
                onChange={selectFileType}
                value={fileType || undefined}
              >
                <Option value={TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER}>
                  {TYPE.CLAIM_FILE_TYPE_VALUE.DISCHARGE_PAPER}
                </Option>
                <Option value={TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER}>{TYPE.CLAIM_FILE_TYPE_VALUE.INVOICE_PAPER}</Option>
                <Option value={TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER}>
                  {TYPE.CLAIM_FILE_TYPE_VALUE.PRESCRIPTION_PAPER}
                </Option>
                <Option value={TYPE.CLAIM_FILE_TYPE.OTHER_PAPER}>{TYPE.CLAIM_FILE_TYPE_VALUE.OTHER_PAPER}</Option>
              </Select>
              <Button key="submit" type="primary" loading={loadingDocs} onClick={() => uploadDocument()}>
                Submit
              </Button>
            </div>,
          ]}
        >
          <Spin spinning={loadingDocs}>
            <div className={styles.contentModal}>
              <UploadComponent onChange={handleFileData} type={fileType} />
            </div>
          </Spin>
        </Modal>
      )) ||
        ""}
      <BottomBar
        claimId={claimId}
        value={claimInfo?.status || ""}
        onUpdate={() => updateClaimData()}
        handleChange={value => setClaimInfo(prev => ({...prev, status: value } as claimInfoType))}
      />
    </div>
  )
}

export default ClaimRequestDetailScreen
