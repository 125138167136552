import React, {useState} from "react"
import styles from "../MedicalProviderScreen.module.css"
import _ from "lodash"
import {message} from "antd"
import BaseForm from "app/common/components/Form"
import {detailSchema} from "../schema"
import {useHistory, useParams} from "react-router"
import {PORTAL_PATH} from "app/portal/config/routes"
import { useSelector } from "react-redux"
import { TypesType } from "store/interface"
import {METADATA, TYPE} from "config/constants"
import OptionListServices from "services/OptionListServices"
import AdminServices from "services/AdminServices";
import {useMountEffect} from "@react-hookz/web";

export type MedicalProviderUpdateType = {
  id?: string
  name: string
  address?: string
  phone?: string
  email?: string
  province?: string
  type: string
  isDirectBilling: boolean
  description?: string
}

const MedicalProviderDetailScreen = () => {
  const [loading, setLoading] = useState(true)
  const medicalProviderType = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.MEDICAL_PROVIDER_TYPE],
  )
  const [provinceList, setProvinceList] = useState([])
  const [medicalProviderData, setMedicalProviderData] = useState<MedicalProviderUpdateType>({
    name: "",
    type: "",
    isDirectBilling: false
  })
  const {id} = useParams<{id: string}>()
  const history = useHistory()
  const getFirstLoad = async () => {
    const province = await OptionListServices.getMetadata(METADATA.CITY)
    if (province && province.data && province.data.collection) {
      setProvinceList(province.data.collection)
    }

    if(id) {
      const medicalProviderDetail = await AdminServices.getMedicalProviderDetail(id)
      if (medicalProviderDetail.isSuccess && medicalProviderDetail.data) {
        setMedicalProviderData(medicalProviderDetail.data)
      }
    }
    setLoading(false)
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  const handleChange = (key: string, value: string) => {
    let newData = _.clone(medicalProviderData)
    if (value) {
      if (!newData) {
        newData = {
          name: "",
          type: "",
          isDirectBilling: false
        }
      }
      newData[key] = value
      setMedicalProviderData(newData)
    } else {
      delete newData[key]
      setMedicalProviderData(newData)
    }
  }

  const onSave = async () => {
    setLoading(true)
    if (medicalProviderData) {
      const response = await AdminServices.updateMedicalProvider(id, medicalProviderData)
      if (response.isSuccess) {
        message.success("Cập nhật thông tin cơ sở y tế thành công")
        await getFirstLoad()
      } else {
        message.error(response.messages)
      }
    }
    setLoading(false)
    getFirstLoad().then()
  }

  return (
    <div>
      <BaseForm
        title={"Thông tin chi tiết" || undefined}
        className={styles.containerForm}
        schemas={detailSchema}
        option={{type: medicalProviderType || [], province: provinceList || [], isDirectBilling: [{name: "Có", value: true}, {name: "Không", value: false}]}}
        loading={loading}
        onChange={(key: string, value: string) => handleChange(key, value)}
        data={medicalProviderData || {}}
        onSave={() => onSave()}
        onCancel={() => history.push(PORTAL_PATH.MEDICAL_PROVIDER)}
      />
    </div>
  )
}

export default MedicalProviderDetailScreen
