import React from "react"
import styles from "./DashboardScreen.module.css"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"

const DashboardScreen = () => {
  const types = useSelector((state: {types: TypesType}) => state.types)
  return (
    <div className={styles.container}>
      Hi, This is Dashboard page. It mean you have passed the <strong>login</strong> phase !
      <img src={"https://i.gifer.com/embedded/download/78nS.gif"} alt={"funny papaa"} />
    </div>
  )
}
export default DashboardScreen
