import React, {useState} from "react"
import TableList from "app/common/components/TableList"
import {PORTAL_PATH} from "app/portal/config/routes"
import {useHistory, useLocation} from "react-router"
import {useMountEffect} from "@react-hookz/web"
import TemplateCardServices from "../../../../../services/TemplateCardServices"

export interface ICardData {
  cardId: string
  name: string
  content: string
  companyId: string
  companyCode: string
  companyName: string
}

const listSchema = [
  {
    title: "Tên thẻ",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Công ty",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Mã công ty",
    dataIndex: "companyCode",
    key: "companyCode",
  },
]

const DynamicCardScreen = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ICardData[]>([])
  const history = useHistory()
  const location = useLocation()

  const getCardList = async () => {
    setLoading(true)
    const response = await TemplateCardServices.getList()
    if (response.isSuccess) {
      setData(response.data)
    }
    setLoading(false)
  }

  useMountEffect(() => {
    getCardList().then()
  })

  return (
    <TableList
      title={"mẫu thẻ"}
      onCreateTitle={"Thêm mới thẻ bảo hiểm"}
      onCreate={() => {
        history.push(PORTAL_PATH.DYNAMIC_CARD_CREATE)
      }}
      onView={(record: ICardData) => history.push(`${location.pathname}/${record.cardId}`)}
      schema={listSchema}
      loading={loading}
      data={data.map((d) => ({
        ...d,
        key: d.cardId,
      }))}
    />
  )
}

export default DynamicCardScreen
