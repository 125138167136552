import React, {useState} from "react"
import BaseForm from "app/common/components/Form"
import styles from "../../PlanScreen/screens/PlanDetailScreen/screens/PlanBalanceScreen/PlanBalanceScreen.module.css"
import _ from "lodash"
import {detailSchema} from "../schema"
import {message} from "antd"
import {PORTAL_PATH} from "app/portal/config/routes"
import {useHistory} from "react-router"
import { useSelector } from "react-redux"
import { TypesType } from "store/interface"
import { TYPE, METADATA } from "config/constants"
import OptionListServices from "services/OptionListServices"
import AdminServices from "services/AdminServices";
import {useMountEffect} from "@react-hookz/web";

export type MedicalProviderCreateType = {
  name: string
  address?: string
  phone?: string
  email?: string
  province?: string
  type: string
  isDirectBilling: boolean
  description?: string
}

const MedicalProviderCreateScreen = () => {
  const medicalProviderType = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.MEDICAL_PROVIDER_TYPE],
  )
  const [provinceList, setProvinceList] = useState([])
  const [medicalProviderData, setMedicalProviderData] = useState<MedicalProviderCreateType>({
    name: "",
    type: "",
    isDirectBilling: false
  })
  const history = useHistory()

  const handleChange = (key: string, value: string) => {
    let newData = _.clone(medicalProviderData)
    if (value) {
      if (!newData) {
        newData = {
          name: "",
          type: "",
          isDirectBilling: false
        }
      }
      newData[key] = value
      setMedicalProviderData(newData)
    } else {
      delete newData[key]
      setMedicalProviderData(newData)
    }
  }

  const getFirstLoad = async () => {
    const city = await OptionListServices.getMetadata(METADATA.CITY)
    if (city && city.data && city.data.collection) {
      setProvinceList(city.data.collection)
    }
  }

  const onSave = async () => {
    if (medicalProviderData) {
      const response = await AdminServices.createMedicalProvider(medicalProviderData)
      if (response.isSuccess) {
        message.success("Thêm cơ sở y tế thành công")
        history.push(PORTAL_PATH.MEDICAL_PROVIDER_DETAIL.replace(":id", response.data))
      } else {
        message.error(response.messages)
      }
    }
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  return (
    <div>
      <BaseForm
        title={"Thông tin chi tiết" || undefined}
        className={styles.containerForm}
        schemas={detailSchema}
        option={{type: medicalProviderType || [], province: provinceList || [], isDirectBilling: [{name: "Có", value: true}, {name: "Không", value: false}]}}
        loading={false}
        onChange={(key: string, value: string) => handleChange(key, value)}
        data={medicalProviderData || {}}
        onSave={() => onSave()}
      />
    </div>
  )
}

export default MedicalProviderCreateScreen
