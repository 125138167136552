import {RANDOM_WAITING_TEXT, VALIDATE} from "./constants"
import moment from "moment"
import momentTz from "moment-timezone"

export const utils = {
  checkFileType: (base64: string) => {
    const type = base64.match(/[^:]\w+\/[\w-+\d.]+(?=[,])/)
    return !!(type && type[0] === "application/pdf")
  },
  validate: (type: string, text: string) => {
    switch (type) {
      case VALIDATE.EMAIL: {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(text)
      }
      case VALIDATE.PHONE: {
        return /^[0-9]*$/gm.test(text)
      }
      case VALIDATE.NONE_SPACE: {
        return /[^\s\\]/.test(text)
      }
      case VALIDATE.NONE_SPECIAL_CHAR: {
        // underscore exception, because \w support _
        if (text && text.length && text.includes("_")) return false
        return /^[\w]+$/.test(text)
      }
      default:
        break
    }
    return false
  },
  pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    const copy = {} as Pick<T, K>
    keys.forEach((key) => (copy[key] = obj[key]))
    return copy
  },
  toBase64: (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    }),
  base64ToFile: async (file: File, fileName?: string): Promise<FormData> => {
    const formFile = new FormData()
    const base64 = (await utils.toBase64(file)) as string
    const block = base64.split(";")
    const contentType = block[0].split(":")[1] // In this case "image/gif"
    const realData = block[1].split(",")[1] // In this case "R0lGODlhPQBEAPeoAJosM...."
    const blob = utils.b64toBlob(realData, contentType)
    formFile.append("file", blob, fileName || file.name)
    return formFile
  },
  b64toBlob: (b64Data: string, contentType: string, sliceSize?: number) => {
    const newContentType = contentType || ""
    const newSliceSize = sliceSize || 512
    const byteCharacters = window.atob(b64Data)
    const byteArrays: Uint8Array[] = []
    for (let offset = 0; offset < byteCharacters.length; offset += newSliceSize) {
      const slice = byteCharacters.slice(offset, offset + newSliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, {type: newContentType})
  },
  pascalToSentence: (text: string) => {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([a-z])/g, " $1$2")
      .replace("_", "")
      .replace(/ +/g, " ")
      .trim()
  },
  parseLocalTime: (date: string | Date | number | moment.Moment) =>
    moment.tz(date, momentTz.tz.guess()).format().toString(),
  parseToStringLocalDateTime: (date?: string, format?: string) => {
    if (!date) return ""
    else {
      if (date.includes("&")) {
        const from = utils.parseLocalTime(date.split("&")[0])
        const to = utils.parseLocalTime(date.split("&")[1])
        return `${moment(from).format(format)} - ${moment(to).format(format)}`
      } else {
        return moment(utils.parseLocalTime(date)).format(format)
      }
    }
  },
  keysToCamel: (o) => {
    const toCamel = (s) => {
      return s.replace(/([-_][a-z])/gi, ($1) => {
        s
        return $1.toUpperCase().replace("-", "").replace("_", "")
      })
    }
    const isArray = (a) => {
      return Array.isArray(a)
    }
    const isObject = (o) => {
      return o === Object(o) && !isArray(o) && typeof o !== "function"
    }
    if (isObject(o)) {
      const n = {}

      Object.keys(o).forEach((k) => {
        n[toCamel(k)] = utils.keysToCamel(o[k])
      })

      return n
    } else if (isArray(o)) {
      return o.map((i) => {
        return utils.keysToCamel(i)
      })
    }
    return o
  },
  sleep: (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  },
  getRandomText: () => {
    const length = Object.keys(RANDOM_WAITING_TEXT).length
    return RANDOM_WAITING_TEXT[Math.floor(Math.random() * length)]
  },
}
