import {CLAIM_CASE_PATH} from "./routes"

const topbar = [
  {
    key: "claim",
    title: "Thẩm định",
    path: CLAIM_CASE_PATH.CLAIM_CASE_INFO,
    subPath: "/info",
    isLock: false,
  },
  {
    key: "correspondence",
    title: "Correspondences",
    path: CLAIM_CASE_PATH.CORRESPONDENCE_CLAIM,
    subPath: "/correspondence",
    isLock: false,
  },
  {
    key: "checkCase",
    title: "Trình case",
    path: CLAIM_CASE_PATH.CHECK_CASE,
    subPath: "/presentCase",
    isLock: false,
  },
  {
    key: "payment",
    title: "Thanh toán",
    path: CLAIM_CASE_PATH.PAYMENT_CLAIM,
    subPath: "/payment",
    isLock: false,
  },
  {
    key: "history",
    title: "Lịch sử hoạt động",
    path: CLAIM_CASE_PATH.HISTORY_ACTIVITY,
    subPath: "/historyActivity",
    isLock: true,
  },
  {
    key: "gallery",
    title: "Hình ảnh",
    path: CLAIM_CASE_PATH.GALLERY,
    subPath: "/gallery",
    isLock: false,
  },
  {
    key: "original",
    title: "Claim gốc",
    path: CLAIM_CASE_PATH.ORIGINAL,
    subPath: "/original",
    isLock: false,
  },
]

export default topbar
