import React, {useState} from "react"
import {useParams} from "react-router"
import {gql, useMutation, useQuery} from "@apollo/client"
import {message, Modal} from "antd"

import styles from "../UserScreen.module.css"
import TableList from "app/common/components/TableList"
import BaseForm from "app/common/components/Form"
import {addRoleFormSchema, logSchema, userRolesSchema} from "app/portal/screens/AdminPortal/UserScreen/schema"
import {FORMATTER} from "config/constants"
import {utils} from "config/utils"

const USER_QUERY = gql`
  query getUserByPK($userId: uuid!) {
    user: identity_users_by_pk(user_id: $userId) {
      user_id
      name
      email
      phone
      created_at
      updated_at
      last_login_at
      status
      source
      roles(where: {deleted_at: {_is_null: true}}) {
        user_role_id
        role
        deleted_at
        company_role {
          company {
            name
          }
          role
        }
        created_at
      }
    }
  }
`

const USER_LOGIN_AND_CHANGE_PASS_LOG_QUERY = gql`
  query loginLogs($userId: uuid!) {
    loginLogs: audit_logs_user_login_logs(where: {user_id: {_eq: $userId}}, limit: 100, order_by: {created_at: desc}) {
      user_login_log_id
      user_id
      created_at
      message
      device_info
    }
    changePasswordLogs: audit_logs_user_password_changes(
      where: {user_id: {_eq: $userId}}
      limit: 100
      order_by: {created_at: desc}
    ) {
      user_password_change_id
      user_id
      created_at
      device_info
    }
  }
`

const COMPANIES_AND_USER_ROLES_QUERY = gql`
  query companyRolesAndUserRoles {
    identity_roles {
      value
      comment
    }
    company_roles {
      company_role_id
      company_id
      role
      company {
        name
      }
    }
  }
`

const ADD_NEW_ROLE_QUERY = gql`
  mutation addRoleToUser($userRole: identity_user_role_insert_input!) {
    insert_identity_user_role_one(object: $userRole) {
      user_role_id
    }
  }
`

const REMOVE_ROLE_QUERY = gql`
  mutation deleteRole($userRoleId: uuid!) {
    update_identity_user_role_by_pk(pk_columns: {user_role_id: $userRoleId}, _set: {deleted_at: "now()"}) {
      user_role_id
    }
  }
`

const EditUserScreen = () => {
  const {userId} = useParams<{userId: string}>()
  const [showEditRoleModal, setShowEditRoleModal] = useState<boolean>(false)
  const [data, setData] = useState<{role?: string; companyRoleId?: string}>({})

  const {
    data: dataUsers,
    loading,
    refetch,
  } = useQuery<{user: IdentityUsers}>(USER_QUERY, {
    context: {
      headers: {
        "x-hasura-role": "admin",
      },
    },
    variables: {userId},
    nextFetchPolicy: "cache-and-network",
  })
  const {data: dataLoginLogs} = useQuery<{
    loginLogs: AuditLogsUserLoginLogs[]
    changePasswordLogs: AuditLogsUserPasswordChanges[]
  }>(USER_LOGIN_AND_CHANGE_PASS_LOG_QUERY, {
    context: {
      headers: {
        "x-hasura-role": "admin",
      },
    },
    variables: {userId},
  })
  const {data: dataCompaniesUserRoles} = useQuery<{identity_roles: IdentityRoles[]; company_roles: CompanyRoles[]}>(
    COMPANIES_AND_USER_ROLES_QUERY,
    {
      context: {
        headers: {
          "x-hasura-role": "admin",
        },
      },
      variables: {userId},
    },
  )
  const [addUserRole] = useMutation<IdentityUserRole>(ADD_NEW_ROLE_QUERY, {
    context: {
      headers: {
        "x-hasura-role": "admin",
      },
    },
    onCompleted: () => {
      message.success("Thêm role thành công")
      refetch()
    },
    onError: () => message.error("Thêm role thất bại"),
  })

  const [removeRole] = useMutation<IdentityUserRole>(REMOVE_ROLE_QUERY, {
    context: {
      headers: {
        "x-hasura-role": "admin",
      },
    },
    onCompleted: () => {
      message.success("Xóa role thành công")
      refetch()
    },
    onError: () => message.error("Xóa role thất bại"),
  })

  if (!dataUsers?.user || !dataCompaniesUserRoles) return null

  const {user_id, name, email, phone, roles, created_at, last_login_at} = dataUsers?.user

  const handleChange = (key: string, value: string) => {
    setData((prevData) => {
      return {...prevData, [key]: value}
    })
  }

  const onSave = () => {
    addUserRole({variables: {userRole: {user_id: user_id, role: data.role, company_role_id: data.companyRoleId}}})
  }

  const onRemoveRole = (user_role_id) => {
    removeRole({variables: {userRoleId: user_role_id}})
  }

  return (
    <div className={styles.container}>
      <div className={styles.userInformation}>
        <div className={styles.userDetail}>
          <div className={styles.title}>Họ tên:</div>
          <div className={styles.content}>{name}</div>
        </div>
        <div className={styles.userDetail}>
          <div className={styles.title}>Email:</div>
          <div className={styles.content}>{email}</div>
        </div>
        <div className={styles.userDetail}>
          <div className={styles.title}>Số đt:</div>
          <div className={styles.content}>{phone}</div>
        </div>
        <div className={styles.userDetail}>
          <div className={styles.title}>Ngày tạo:</div>
          <div className={styles.content}>
            {utils.parseToStringLocalDateTime(created_at, FORMATTER.DATE_TIME_FORMAT)}
          </div>
        </div>
        <div className={styles.userDetail}>
          <div className={styles.title}>Lần đăng nhập cuối cùng:</div>
          <div className={styles.content}>
            {utils.parseToStringLocalDateTime(last_login_at, FORMATTER.DATE_TIME_FORMAT)}
          </div>
        </div>
      </div>
      <div className={styles.userRoles}>
        <TableList
          onCreate={() => setShowEditRoleModal(true)}
          onView={() => null}
          title={"Roles"}
          schema={[
            ...userRolesSchema,
            {
              title: "Remove Role",
              key: "x",
              render: (_, record) => <a onClick={() => onRemoveRole(record.user_role_id)}>Remove</a>,
            },
          ]}
          loading={loading}
          data={roles}
          rowKey={(record: IdentityUserRole) => record.user_role_id}
        />
      </div>
      <div className={styles.loginLogs}>
        <TableList
          onView={() => null}
          title={"Change Password logs"}
          schema={logSchema}
          loading={loading}
          data={dataLoginLogs?.changePasswordLogs}
          rowKey={(record: AuditLogsUserPasswordChanges) => record.user_password_change_id}
        />
      </div>
      <div className={styles.loginLogs}>
        <TableList
          onView={() => null}
          title={"Login logs"}
          schema={logSchema}
          loading={loading}
          data={dataLoginLogs?.loginLogs}
          rowKey={(record: AuditLogsUserLoginLogs) => record.user_login_log_id}
        />
      </div>
      <Modal
        title=""
        centered
        visible={showEditRoleModal}
        width={900}
        onCancel={() => setShowEditRoleModal(false)}
        footer={[]}
      >
        <BaseForm
          title={""}
          className={styles.containerForm}
          schemas={addRoleFormSchema}
          option={{
            role: dataCompaniesUserRoles?.identity_roles.map(({value, comment}) => ({
              name: `${value} (${comment})`,
              value,
            })),
            companyRoleId: dataCompaniesUserRoles?.company_roles.map(({company_role_id, role, company}) => ({
              name: `${company.name} - ${role}`,
              value: company_role_id,
            })),
          }}
          loading={loading}
          onChange={(key: string, value: string) => handleChange(key, value)}
          data={data || {}}
          onSave={() => onSave()}
          onCancel={() => setShowEditRoleModal(false)}
        />
      </Modal>
    </div>
  )
}

export default EditUserScreen
