import React, {useState} from "react"
import styles from "./Target.module.css"
import {Checkbox, InputNumber, Modal, Popconfirm, Select, Spin} from "antd"
import _ from "lodash"
import {DeleteTwoTone} from "@ant-design/icons"
import TableList from "app/common/components/TableList"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"
import {FIELDS, FORMATTER, TYPE} from "config/constants"
import AdminServices from "services/AdminServices"
import {utils} from "config/utils"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../../../../../store/interface"

const {Option} = Select

export interface TargetData {
  applyToVoluntaryTarget?: boolean
  applyTargetAgeRange?: boolean
  applyTargetCondition?: boolean
  target?: string
  ageRanges?: {from: number | undefined; to: number | undefined}[]
  applyToBlackList?: boolean
  blackLists?: certificateType[]
  insuredCertificateIds?: string[]
}

export type certificateType = {
  key?: string
  salary: 0
  email: string
  isActive: boolean
  insuredPersonName: string
  certificateCode: string
  userInfoNationalId: string
  userInfoParentNationalId: string
  insuredCertificateId: string
}

const Target = (props: {onChange: (name, value) => void; data: TargetData; onDelete: (data) => void}) => {
  const {onChange, onDelete, data} = props
  const {policyId, coPaymentId} = useParams<{policyId: string; coPaymentId: string}>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [rowSelectionKeys, setRowSelectionKeys] = useState<string[]>()
  const [rowSelectionKeysDefault, setRowSelectionKeysDefault] = useState<string[]>()
  const [certificateList, setCertificateList] = useState<certificateType[]>()
  const [blackListData, setBlackListData] = useState<certificateType[]>()
  const coPaymentTargetType = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.POLICY_CO_PAYMENT_TARGET],
  )

  const getCertificateList = async () => {
    if (policyId) {
      const response = await AdminServices.getLAByPolicyId(policyId)
      if (response.isSuccess) {
        const newData = response.data.collection.map((item: certificateType) => {
          item.key = item.insuredCertificateId
          return item
        })
        setCertificateList(newData)
      }
    }
  }

  const getInitialData = () => {
    const newBlackListData = _.clone(data.blackLists) || []
    let newRowSelectionKeys: string[] = []
    const newRowSelectionKeysDefault: string[] = []
    if (data.blackLists) {
      data.blackLists.forEach((data) => {
        newRowSelectionKeysDefault.push(data.insuredCertificateId)
      })
    }
    newRowSelectionKeys = _.clone(newRowSelectionKeysDefault)
    if (data.insuredCertificateIds && certificateList) {
      certificateList.forEach((insured) => {
        if (
          data.insuredCertificateIds?.includes(insured.insuredCertificateId) &&
          _.filter(newBlackListData, ["insuredCertificateId", insured.insuredCertificateId]).length === 0
        ) {
          newBlackListData.push(insured)
          newRowSelectionKeys.push(insured.insuredCertificateId)
        }
      })
    }
    setRowSelectionKeysDefault(newRowSelectionKeysDefault)
    setRowSelectionKeys(newRowSelectionKeys)
    setBlackListData(newBlackListData)
  }

  useMountEffect(() => {
    setLoading(true)
    getCertificateList().then()
    getInitialData()
    setLoading(false)
  })

  useUpdateEffect(() => {
    getInitialData()
  }, [data])

  const rowSelectionUpdate = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setRowSelectionKeys(selectedRowKeys.toString().split(","))
      onChange(FIELDS.INSURED_PERSON_ID_LIST, selectedRowKeys.toString().split(","))
    },
    selectedRowKeys: rowSelectionKeys,
  }

  const mapCoPaymentTarget = () => {
    const newCoPaymentTarget: JSX.Element[] = []
    if (coPaymentTargetType) {
      for (const i of coPaymentTargetType) {
        newCoPaymentTarget.push(
          <Option key={i.value} value={i.value} label={i.name}>
            {i.name}
          </Option>,
        )
      }
    }
    return newCoPaymentTarget
  }

  const selectTarget = (value) => {
    onChange(FIELDS.TARGET, value)
  }

  const onApplyAgeRange = (e) => {
    onChange(FIELDS.IS_AGE_RANGE_TARGET, e.target.checked)
  }

  const onApplyTargetCondition = (e) => {
    onChange(FIELDS.IS_TARGET, e.target.checked)
  }

  const onApplyBlackListCondition = (e) => {
    onChange(FIELDS.IS_BLACKLIST, e.target.checked)
  }

  const onApplyIsWillingness = (e) => {
    onChange(FIELDS.IS_WILLING_TARGET, e.target.checked)
  }

  const handleChangeAge = (value, index, type) => {
    const newRangeAge = _.clone(data.ageRanges) || [{from: undefined, to: undefined}]
    if (data.ageRanges) {
      const object = _.clone(data.ageRanges[index])
      object[type] = value
      newRangeAge.splice(index, 1, object)
      onChange(FIELDS.AGE_RANGES, newRangeAge)
    }
  }

  const handleAddRangeAge = () => {
    const newRangeAge = _.clone(data.ageRanges) || []
    if (data.ageRanges) {
      newRangeAge.push({from: undefined, to: undefined})
      onChange(FIELDS.AGE_RANGES, newRangeAge)
    }
  }

  const removeRangeAge = (index) => {
    const newRangeAge = _.clone(data.ageRanges) || []
    newRangeAge.splice(index, 1)
    onChange(FIELDS.AGE_RANGES, newRangeAge)
  }

  const listSchema = [
    {
      title: "Chủ thẻ",
      dataIndex: "insuredPersonName",
      key: "insuredPersonName",
      width: 150,
    },
    {
      title: "Mã thẻ",
      dataIndex: "certificateCode",
      key: "certificateCode",
      width: 180,
    },
    {
      title: "Tên gói",
      dataIndex: "planName",
      key: "planName",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
    },
    {
      title: "Hiệu lực",
      dataIndex: "issuedAt",
      key: "issuedAt",
      width: 150,
      render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
    },
    {
      title: "Hết hiệu lực",
      dataIndex: "duedAt",
      key: "duedAt",
      width: 150,
      render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Tác vụ",
      render: (value, row) =>
        (rowSelectionKeysDefault?.includes(row.insuredCertificateId) && (
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title="Bạn có chắc muốn xóa"
              onConfirm={() => onDelete(row.insuredCertificateId)}
              okButtonProps={{
                danger: true,
              }}
              okText={"Xóa"}
            >
              <DeleteTwoTone
                twoToneColor="#EF5350"
                style={{
                  fontSize: 18,
                  textAlign: "center",
                }}
              />
            </Popconfirm>
          </div>
        )) ||
        "",
    },
  ]

  return (
    <div className={styles.personContainer}>
      <Spin spinning={loading}>
        <div className={styles.category}>
          <div className={styles.toggleApply}>
            <Checkbox checked={data.applyTargetCondition} onChange={onApplyTargetCondition}>
              Áp dụng điều kiện này
            </Checkbox>
          </div>
          <div className={styles.content}>
            {(!data.applyTargetCondition && <div className={styles.overlay} />) || ""}
            <div className={styles.contentContainer}>
              <div className={styles.label}>Chọn đối tượng áp dụng:</div>
              <Select placeholder="Select a target" value={data.target} style={{width: 248}} onChange={selectTarget}>
                {mapCoPaymentTarget()}
              </Select>
              <div className={styles.checkBox}>
                <Checkbox checked={data.applyToVoluntaryTarget} onChange={onApplyIsWillingness}>
                  Áp dụng cho tự nguyện
                </Checkbox>
              </div>
              <div className={styles.checkBox}>
                <Checkbox checked={data.applyTargetAgeRange} onChange={onApplyAgeRange}>
                  Độ tuổi
                </Checkbox>
              </div>
              <div className={styles.rangeAge}>
                {(!data.applyTargetAgeRange && <div className={styles.overlay} />) || ""}
                {data.ageRanges &&
                  data.ageRanges.map((age, index) => (
                    <div key={index} className={styles.single}>
                      <p>Từ</p>
                      <InputNumber
                        className={styles.inputAge}
                        name="from"
                        value={age.from}
                        onChange={(e) => handleChangeAge(e, index, "from")}
                      />
                      <p>Đến</p>
                      <InputNumber
                        className={styles.inputAge}
                        name="to"
                        value={age.to}
                        onChange={(e) => handleChangeAge(e, index, "to")}
                      />
                      {
                        //TODO: Handle select and remove multiple age ranges
                      }
                      {/*<DeleteTwoTone*/}
                      {/*  twoToneColor="#EF5350"*/}
                      {/*  style={{*/}
                      {/*    fontSize: 18,*/}
                      {/*    lineHeight: "32px",*/}
                      {/*    textAlign: "center",*/}
                      {/*    cursor: "pointer",*/}
                      {/*  }}*/}
                      {/*  onClick={() => removeRangeAge(index)}*/}
                      {/*/>*/}
                    </div>
                  ))}
                {
                  //TODO: Handle select and remove multiple age ranges
                }
                {/*<div className={styles.addField} onClick={handleAddRangeAge}>*/}
                {/*  <PlusCircleTwoTone twoToneColor="#87d068" />*/}
                {/*  <span className={styles.addFieldLabel}>Thêm điều kiện</span>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.blackList}>
          <div className={styles.toggleApply}>
            <Checkbox checked={data.applyToBlackList} onChange={onApplyBlackListCondition}>
              Áp dụng điều kiện này
            </Checkbox>
          </div>
          <div className={styles.blackListTable}>
            {(!data.applyToBlackList && <div className={styles.overlay} />) || ""}
            <TableList
              title={"Danh sách blacklist"}
              onView={() => {}}
              onCreate={() => setShowModal(true)}
              onCreateTitle={"Cập nhật"}
              schema={listSchema}
              data={blackListData}
            />
          </div>
          <Modal
            title="Chọn đối tượng áp dụng"
            centered
            visible={showModal}
            width={1200}
            onCancel={() => setShowModal(false)}
            onOk={() => setShowModal(false)}
          >
            <div className={styles.modalActivateContent}>
              <TableList
                className={styles.tableList}
                rowSelection={rowSelectionUpdate}
                title={"Đối tượng áp dụng"}
                onView={() => {}}
                schema={_.filter(listSchema, (schema) => {
                  return schema.key != "action"
                })}
                search={["name"]}
                loading={loading}
                data={
                  certificateList &&
                  certificateList.map((certificate) => ({
                    ...certificate,
                    key: certificate.insuredCertificateId,
                  }))
                }
              />
            </div>
          </Modal>
        </div>
      </Spin>
    </div>
  )
}

export default Target
