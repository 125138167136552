import React, {useCallback, useState} from "react"
import styles from "./PlanBenefitScreen.module.css"
import {Card, Col, Divider, Empty, Row, Space, Spin, Statistic, Tooltip} from "antd"
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons"
import PlanServices from "services/PlanServices"
import {useHistory, useParams} from "react-router"
import _ from "lodash"
import TableList from "app/common/components/TableList"
import {PORTAL_PATH} from "app/portal/config/routes"
import BenefitTag from "app/common/components/BenefitTag"
import {TYPE} from "config/constants"
import {useMountEffect} from "@react-hookz/web"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"

type BalanceType = {name: string; value: string}
const PlanBenefitDetailScreen: React.FC = () => {
  const balanceOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.PLAN_BALANCE_TYPE],
  )
  const benefitTypeOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const params = useParams<{planId: string; planInsuredBenefitId: string}>()
  const [data, setData] = useState<{
    name: string
    code: string
    type: string
    isDirectBilling: boolean
    applyToDependents: boolean
    planBalances: {
      planBalanceId: string
      name: string
      maskedValue: string
      type: string
    }[]
    planCoPayments?: {
      medicalProviderName: string
      planCoPaymentId: string
      value: number
    }[]
  }>({
    name: "",
    code: "",
    type: "",
    isDirectBilling: false,
    applyToDependents: false,
    planBalances: [],
  })
  const history = useHistory()

  const [loadingInfo, setLoadingInfo] = useState<boolean>(true)

  const getPlanBenefitData = useCallback(async () => {
    const {planId, planInsuredBenefitId} = params
    if (planId && planInsuredBenefitId) {
      const response = await PlanServices.getPlanBenefitDetail(planId, planInsuredBenefitId)
      if (response.isSuccess) {
        setData(response.data)
      }
    }
    setLoadingInfo(false)
  }, [params])

  const getFirstLoad = () => {
    getPlanBenefitData().then()
  }

  useMountEffect(() => {
    getFirstLoad()
  })

  const listSchema = [
    {
      title: "Bệnh viện",
      dataIndex: "medicalProviderName",
      key: "medicalProviderName",
    },
    {
      title: "Copay",
      dataIndex: "value",
      key: "value",
      render: (text: string) => <span>{Math.round(parseFloat(text) * 100)}%</span>,
    },
  ]

  return (
    <>
      <Space align={"start"} direction={"horizontal"} size={25} className={styles.planBenefitDetailContainer}>
        <Space direction={"vertical"} size={10} className={[styles.infoContainer, styles.shows].join(" ")}>
          <Spin spinning={loadingInfo}>
            <div>
              <div className={styles.infoHeader}>
                <div>Thông tin quyền lợi</div>
              </div>
              <Divider className={styles.divider} />
            </div>
            <>
              <div>
                <div className={styles.title}>Tên :</div>
                <div>{data.name}</div>
              </div>
              <div>
                <div className={styles.title}>Mã:</div>
                <div>{data.code}</div>
              </div>
              <div>
                <div className={styles.title}>Loại :</div>
                <div>
                  <BenefitTag
                    text={data.type}
                    textReplace={benefitTypeOption && benefitTypeOption.find((b) => b.value === data.type)?.name}
                  />
                </div>
              </div>
              <div>
                <div className={styles.title}>Bảo lãnh viện phí:</div>
                <div>
                  {data.isDirectBilling ? (
                    <>
                      <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                      <span
                        style={{
                          color: "#52c41a",
                          fontWeight: "bold",
                        }}
                      >
                        Có
                      </span>
                    </>
                  ) : (
                    <>
                      <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
                      <span
                        style={{
                          color: "var(--red-alert)",
                          fontWeight: "bold",
                        }}
                      >
                        Không
                      </span>
                    </>
                  )}
                </div>
                <div>
                  <div className={styles.title}>Áp dụng cho người thân:</div>
                  <div>
                    {data.applyToDependents ? (
                      <>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                        <span
                          style={{
                            color: "#52c41a",
                            fontWeight: "bold",
                          }}
                        >
                          Có
                        </span>
                      </>
                    ) : (
                      <>
                        <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
                        <span
                          style={{
                            color: "var(--red-alert)",
                            fontWeight: "bold",
                          }}
                        >
                          Không
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          </Spin>
        </Space>
        <div>
          <Spin spinning={loadingInfo}>
            <div className={styles.balanceContainer}>
              <Row gutter={16}>
                {(data.planBalances &&
                  data.planBalances.length &&
                  data.planBalances.map((planBalance) => (
                    <Col xxl={{span: 6}} xl={{span: 8}} lg={{span: 12}} md={{span: 24}} key={planBalance.planBalanceId}>
                      <Card
                        className={styles.balanceCard}
                        onClick={() => {
                          let path = PORTAL_PATH.PLAN_BALANCES_DETAIL
                          path = path.replace(":planId", params.planId)
                          path = path.replace(":planBalanceId", planBalance.planBalanceId)
                          history.push(path)
                        }}
                      >
                        <Statistic
                          title={
                            <div className={styles.statsTitle}>
                              <Tooltip title={planBalance.name}>
                                <h2>{planBalance.name}</h2>
                              </Tooltip>
                              <h4>{_.find(balanceOption, (b: BalanceType) => b.value === planBalance.type)?.name}</h4>
                            </div>
                          }
                          value={planBalance.maskedValue}
                        />
                      </Card>
                    </Col>
                  ))) || (
                  <div className={styles.emptyContainer}>
                    <Empty
                      image="https://i.ibb.co/3YYSWcS/notFound.png"
                      imageStyle={{
                        height: 60,
                      }}
                      description={<h3>Quyền lợi này chưa có Balance nào 🤨</h3>}
                    />
                  </div>
                )}
              </Row>
            </div>
          </Spin>
          <Spin spinning={true}>
            <div className={styles.coPayContainer}>
              <div>
                <h2>Co-Pay</h2>
              </div>
              <div>
                <TableList
                  schema={listSchema}
                  onView={() => {}}
                  rowKey={"planCoPaymentId"}
                  data={data.planCoPayments}
                  title={""}
                />
              </div>
            </div>
          </Spin>
        </div>
      </Space>
    </>
  )
}

export default PlanBenefitDetailScreen
