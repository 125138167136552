import React, {useCallback, useEffect, useState} from "react"
import styles from "./DocumentLink.module.css"
import {Button, Popover, Tooltip} from "antd"
import FileService from "services/FileServices"
import {TYPE} from "config/constants"
import {FolderOpenOutlined} from "@ant-design/icons"

type DocType = {
  displayName: string
  fileName: string
  id: string
  isTrash: boolean
  source: string
  type: string
  url: string
}

const DocumentLink = (props: {planId: string; policyId: string}) => {
  const {planId, policyId} = props
  const [planDocs, setPlanDocs] = useState<DocType[]>([])
  const [policyDocs, setPolicyDocs] = useState<DocType[]>([])

  const getPolicyDocs = useCallback(async () => {
    const response = await FileService.getFileList(TYPE.FILE_TYPE.POLICY_DOCUMENT, "", policyId, "")
    if (response.isSuccess) {
      setPolicyDocs(response.data.collection)
    }
  }, [policyId])

  const getPlanDocs = useCallback(async () => {
    const response = await FileService.getFileList(TYPE.FILE_TYPE.PLAN_DOCUMENT, "", "", planId)
    if (response.isSuccess) {
      setPlanDocs(response.data.collection)
    }
  }, [planId])

  useEffect(() => {
    getPolicyDocs().then()
    getPlanDocs().then()
  }, [getPolicyDocs, getPlanDocs])
  return (
    <>
      <div className={styles.documentLinkContainer}>
        <Popover
          trigger="click"
          content={
            <div className={styles.fileListContainer}>
              {planDocs && planDocs.length
                ? planDocs.map((planDoc) => (
                    <Tooltip key={planDoc.id} title={planDoc.fileName}>
                      <div onClick={() => window.open(planDoc.url)} className={styles.fileItem}>
                        {planDoc.fileName}
                      </div>
                    </Tooltip>
                  ))
                : ""}
            </div>
          }
        >
          <Button icon={<FolderOpenOutlined />}>Tài liệu bảo hiểm</Button>
        </Popover>
      </div>
      <div className={styles.documentLinkContainer}>
        <Popover
          trigger="click"
          content={
            <div className={styles.fileListContainer}>
              {policyDocs && policyDocs.length
                ? policyDocs.map((policyDoc) => (
                    <Tooltip key={policyDoc.id} title={policyDoc.fileName}>
                      <div onClick={() => window.open(policyDoc.url)} className={styles.fileItem}>
                        {policyDoc.fileName}
                      </div>
                    </Tooltip>
                  ))
                : ""}
            </div>
          }
        >
          <Button icon={<FolderOpenOutlined />}>Tài liệu hợp đồng</Button>
        </Popover>
      </div>
    </>
  )
}

export default DocumentLink
