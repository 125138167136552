import i18next from 'i18next';
import commonVi from './vi/common.json'
import commonEn from './en/common.json'

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'vi-VN',                              // language to use
  resources: {
    'en-US': {
      common: commonEn,               // 'common' is our custom namespace
    },
    'vi-VN': {
      common: commonVi,
    },
  },
}).then();

export default i18next