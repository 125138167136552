import React, {useState} from "react"
import styles from "./CreateClaimRequestScreen.module.css"
import {Button, Input, Space} from "antd"
import {PORTAL_PATH} from "app/portal/config/routes"
import {SETTINGS} from "config/constants"
import {useHistory, useLocation} from "react-router"
import useQueryParams from "app/common/hooks/useQueryParam"
import {useDebouncedEffect, useFirstMountState} from "@react-hookz/web"
import AdminServices from "services/AdminServices"
import TableList from "app/common/components/TableList"
import listInsuredSchema from "./schema"

type DataType = {
  certificateNumber: string
  createdDate: string
  dob: string
  dueDate: string
  employeeName: string
  insuredCertificateId: string
  insurer: string
  issueDate: string
  lifeAssureName: string
  paperId: string
  paperIssuedAt: string
  planName: string
  policyOwner: string
}[]

const CreateClaimRequestScreen = () => {
  const [data, setData] = useState<DataType>([])
  const [loading, setLoading] = useState<boolean>(false)
  const queryParams = useQueryParams()
  const [search, setSearch] = useState<string>(queryParams.get("search") || "")
  const history = useHistory()
  const location = useLocation()
  const firstMount = useFirstMountState()

  const getCertificates = async () => {
    setLoading(true)
    if (search) {
      const response = await AdminServices.getInsuredPersonList(search)
      if (response.isSuccess) {
        setData(response.data?.collection)
        setLoading(false)
      }
    }
  }

  useDebouncedEffect(
    () => {
      if (search) {
        getCertificates().then(() => {
          history.replace(`${location.pathname}?search=${search}`)
        })
      }
    },
    [search],
    SETTINGS.SEARCH_DEBOUNCE_TIME,
  )

  const actionTable = (id: string) => {
    let path = PORTAL_PATH.HISTORY_CLAIM
    path = path.replace(":insuredPersonId", id)
    history.push(path)
  }

  return (
    <div className={styles.container}>
      <div className={styles.typeHeader}>
        <p>Tìm kiếm người được bảo hiểm</p>
      </div>
      <div className={styles.searchArea}>
        <Space size={2} direction="vertical">
          <label>Số CMND / CCCD / Mã nhân viên</label>
          <Space size={16} direction="horizontal">
            <Input
              className={styles.searchInput}
              size={"large"}
              placeholder={"Điền tại đây"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button type="primary" size={"large"} onClick={() => getCertificates()}>
              Tìm kiếm
            </Button>
          </Space>
        </Space>
      </div>

      {(data && (
        <div className={styles.certificatesContainer}>
          <div className={styles.certificateList}>
            {search && !firstMount ? (
              <TableList
                title={"Danh sách người được bảo hiểm"}
                data={data}
                rowKey={"insuredPersonId"}
                schema={listInsuredSchema}
                loading={loading}
                hidePaginate
                onView={(record) => actionTable(record.insuredPersonId)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )) ||
        ""}
    </div>
  )
}

export default CreateClaimRequestScreen
