import {utils} from "config/utils"
import {FORMATTER} from "config/constants"
import {Tag} from "antd"
import React from "react"

const listInsuredSchema = [
  {
    title: "Mã người được BH",
    dataIndex: "insuredNumber",
    key: "insuredNumber",
  },
  {
    title: "Tên người được BH",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "CMT/CCCD",
    dataIndex: "paperId",
    key: "paperId",
  },
  {
    title: "Ngày sinh",
    dataIndex: "dob",
    key: "dob",
    render: (text) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_FORMAT),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Giới tính",
    key: "genderName",
    dataIndex: "genderName",
    render: (text) => (
      <Tag color={text === "Nam" ? "geekblue" : "pink"}>
        {text === "Nam" ? "♂" : "♀"} {text}
      </Tag>
    ),
  },
]

export default listInsuredSchema
