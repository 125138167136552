import React, {useState} from "react"
import styles from "./ClaimCaseDocumentScreen.module.css"
import caolan from "async"
import {Button, Empty, Image, message, Modal, Select, Spin, Tabs} from "antd"
import ButtonColor from "antd-button-color"
import {KEYS, TYPE} from "config/constants"
import _ from "lodash"
import {CheckCircleTwoTone, CloseCircleTwoTone, CloseOutlined, PlusOutlined} from "@ant-design/icons"
import {utils} from "config/utils"
import FileService from "services/FileServices"
import UploadComponent from "./component/UploadComponent"
import {useParams} from "react-router"
import {useMountEffect} from "@react-hookz/web"

const {Option} = Select
const {TabPane} = Tabs

export interface IDocumentData {
  id: string
  url: string
  type: string
  source: string
  displayName: string
  fileName: string
  isImage: boolean
  isTrash: boolean
}

interface IStates {
  data?: IDocumentData[] | any
  loading: boolean
}

const ClaimCaseDocumentScreen = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IStates["data"]>([])
  const [labelSource, setLabelSource] = useState(TYPE.SOURCE_TYPE.CLIENT)
  const [showCreate, setShowCreate] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [selectedPaper, setSelectedPaper] = useState({})
  const [fileType, setFileType] = useState("")
  const [fileList, setFileList] = useState<{file?: File; type?: string}[]>([])
  const {claimCaseId} = useParams<{claimCaseId: string}>()

  const getFirstLoad = async () => {
    setLoading(true)
    const response = await FileService.getClaimCaseFileList(TYPE.FILE_TYPE.CLAIM_DOCUMENT, claimCaseId, "", "", true)
    if (response.data && response.isSuccess) {
      setData(response.data.collection)
      setFileType("")
      setFileList([])
    }
    setLoading(false)
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  const getListPaper = (type) => {
    const fileByType = _.filter(data, ["type", type])
    return _.filter(fileByType, {
      source: labelSource,
      isTrash: false,
    })
  }

  const filterListPaperBySource = (source, isTrash) => {
    return _.filter(data, {
      source: source,
      isTrash: isTrash,
    })
  }

  const selectFileType = (value) => {
    setFileType(value)
  }

  const getSelectedPaperAndShowModal = (paper) => {
    setSelectedPaper(paper)
    setShowModalConfirm(true)
  }

  const checkAllDocumentHasType = (dataList) => {
    let result = true
    dataList.forEach((data, index) => {
      if (data.type === "") {
        result = false
        setLoading(false)
        message.warn(`Vui lòng chọn loại giấy tờ cho giấy tờ thứ ${index + 1}`)
        return result
      }
    })
    return result
  }

  const uploadFile = async (data: {file?: File; type?: string}) => {
    if (data.file && data.type) {
      const file = await utils.base64ToFile(data.file)
      file.append(KEYS.DOC_TYPE, data.type)
      file.append(KEYS.DOC_SOURCE, KEYS.DOC_SOURCE_NAME.ASSESSMENTS)
      await FileService.uploadFileClaimCase(claimCaseId, file)
    }
  }

  const uploadDocument = async () => {
    setLoading(true)
    if (fileList && checkAllDocumentHasType(fileList)) {
      caolan.eachOf(
        fileList,
        (paperData: {file?: File; type?: string}, key, callback) => {
          if (paperData.type) {
            uploadFile(paperData).then(() => callback())
          }
        },
        (err) => {
          if (err) {
            message.error("Thêm mới thất bại")
            setLoading(false)
          } else {
            setLoading(false)
            message.success("Tải lên hình ảnh thành công")
            setShowCreate(false)
            getFirstLoad().then()
          }
        },
      )
    }
  }

  const handleFileData = (fileList) => {
    setFileList(fileList)
    setFileType("")
  }

  const actionDocument = async (paperData) => {
    setLoading(true)
    if (paperData) {
      const response = await FileService.updateFileClaimCase(claimCaseId, paperData.id)
      if (response) {
        if (showDelete) {
          message.success("Xóa giấy tờ thành công")
          setShowDelete(false)
        } else {
          message.success("Khôi phục giấy tờ thành công")
        }
        setShowModalConfirm(false)
        setLoading(false)
        getFirstLoad().then()
      }
    }
    setLoading(false)
  }

  const DocumentReader = (props: {
    dataList: IDocumentData[]
    showDelete: boolean
    onClick: (paper) => void
    isTrash: boolean
  }) => {
    const {dataList} = props
    const handleOnClick = (paper) => {
      if (props.onClick) {
        props.onClick(paper)
      }
    }

    const mapPaperType = (type: string) => {
      switch (type) {
        case TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.DISCHARGE_PAPER
        case TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.INVOICE_PAPER
        case TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.PRESCRIPTION_PAPER
        case TYPE.CLAIM_FILE_TYPE.OTHER_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.OTHER_PAPER
        default:
          break
      }
    }

    return (
      <Image.PreviewGroup>
        {(dataList &&
          dataList.map((paper) => {
            if (paper.isImage) {
              return (
                <div key={paper.id} className={styles.imageContainer}>
                  {(props.isTrash && <div className={styles.overlay} />) || ""}
                  {(props.isTrash && (
                    <div className={styles.iconDelete} onClick={() => handleOnClick(paper)}>
                      <CheckCircleTwoTone style={{fontSize: 16}} />
                    </div>
                  )) ||
                    (props.showDelete && (
                      <div className={styles.iconDelete} onClick={() => handleOnClick(paper)}>
                        <CloseCircleTwoTone style={{fontSize: 16}} />
                      </div>
                    ))}
                  <Image width={148} height={108} src={paper.url} />
                  <div className={styles.paperLabel}>{mapPaperType(paper.type)}</div>
                </div>
              )
            } else {
              return (
                <div key={paper.id} className={styles.documentContainer}>
                  <div className={styles.pdfContainer}>
                    {(props.isTrash && <div className={styles.overlay} />) || ""}
                    {(props.isTrash && (
                      <div className={styles.iconDelete} onClick={() => handleOnClick(paper)}>
                        <CheckCircleTwoTone style={{fontSize: 16}} />
                      </div>
                    )) ||
                      (props.showDelete && (
                        <div className={styles.iconDelete} onClick={() => handleOnClick(paper)}>
                          <CloseCircleTwoTone style={{fontSize: 16}} />
                        </div>
                      ))}
                    <div className={styles.pdfCover} onClick={() => window.open(paper.url)} />
                  </div>
                  <div className={styles.paperLabel}>{mapPaperType(paper.type)}</div>
                </div>
              )
            }
          })) ||
          ""}
      </Image.PreviewGroup>
    )
  }

  return (
    <>
      <div>
        <div className={styles.sourcePaper}>
          <Button
            className={styles.buttonSourceLabel}
            type={(labelSource === TYPE.SOURCE_TYPE.CLIENT && "primary") || "default"}
            onClick={() => setLabelSource(TYPE.SOURCE_TYPE.CLIENT)}
          >
            Khách hàng ({filterListPaperBySource(TYPE.SOURCE_TYPE.CLIENT, false).length})
          </Button>
          <Button
            className={styles.buttonSourceLabel}
            onClick={() => setLabelSource(TYPE.SOURCE_TYPE.ASSESSOR)}
            type={(labelSource === TYPE.SOURCE_TYPE.ASSESSOR && "primary") || "default"}
          >
            Thẩm định viên ({filterListPaperBySource(TYPE.SOURCE_TYPE.ASSESSOR, false).length})
          </Button>
          <Button
            className={styles.buttonSourceLabel}
            onClick={() => setLabelSource(TYPE.SOURCE_TYPE.MEDICAL_PROVIDERS)}
            type={(labelSource === TYPE.SOURCE_TYPE.MEDICAL_PROVIDERS && "primary") || "default"}
          >
            Cơ sơ y tế ({filterListPaperBySource(TYPE.SOURCE_TYPE.MEDICAL_PROVIDERS, false).length})
          </Button>
          <ButtonColor
            icon={<CloseOutlined />}
            size={"middle"}
            type={"danger"}
            className={styles.buttonDelete}
            onClick={() => setShowDelete(!showDelete)}
          >
            Xóa hình ảnh
          </ButtonColor>
          <ButtonColor
            icon={<PlusOutlined />}
            size={"middle"}
            type={"success"}
            className={styles.buttonCreate}
            onClick={() => setShowCreate(true)}
          >
            Tải lên hình ảnh mới
          </ButtonColor>
        </div>
        <div className={styles.containerPaper}>
          <Spin spinning={loading}>
            <Tabs tabPosition={"left"} className={styles.tabBar}>
              <TabPane tab={`Tất cả (${filterListPaperBySource(labelSource, false).length})`} key="all">
                <div className={styles.contentPaper}>
                  <DocumentReader
                    dataList={filterListPaperBySource(labelSource, false)}
                    showDelete={showDelete}
                    onClick={(paper) => getSelectedPaperAndShowModal(paper)}
                    isTrash={false}
                  />
                  {(filterListPaperBySource(labelSource, false).length === 0 && <Empty style={{width: "100%"}} />) ||
                    ""}
                </div>
              </TabPane>
              <TabPane
                tab={`Giấy ra viện (${getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER).length})`}
                key="dischargePaper"
              >
                <div className={styles.contentPaper}>
                  <DocumentReader
                    dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER)}
                    showDelete={showDelete}
                    onClick={(paper) => getSelectedPaperAndShowModal(paper)}
                    isTrash={false}
                  />
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER).length === 0 && (
                    <Empty style={{width: "100%"}} />
                  )) ||
                    ""}
                </div>
              </TabPane>
              <TabPane tab={`Hóa đơn (${getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER).length})`} key="invoicePaper">
                <div className={styles.contentPaper}>
                  <DocumentReader
                    dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER)}
                    showDelete={showDelete}
                    onClick={(paper) => getSelectedPaperAndShowModal(paper)}
                    isTrash={false}
                  />
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER).length === 0 && (
                    <Empty style={{width: "100%"}} />
                  )) ||
                    ""}
                </div>
              </TabPane>
              <TabPane
                tab={`Toa thuốc (${getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER).length})`}
                key="prescriptionPaper"
              >
                <div className={styles.contentPaper}>
                  <DocumentReader
                    dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER)}
                    showDelete={showDelete}
                    onClick={(paper) => getSelectedPaperAndShowModal(paper)}
                    isTrash={false}
                  />
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER).length === 0 && (
                    <Empty style={{width: "100%"}} />
                  )) ||
                    ""}
                </div>
              </TabPane>
              <TabPane tab={`Giấy tờ khác (${getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length})`} key="otherPaper">
                <div className={styles.contentPaper}>
                  <DocumentReader
                    dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER)}
                    showDelete={showDelete}
                    onClick={(paper) => getSelectedPaperAndShowModal(paper)}
                    isTrash={false}
                  />
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length === 0 && <Empty style={{width: "100%"}} />) ||
                    ""}
                </div>
              </TabPane>
              <TabPane tab={`Đã xóa (${filterListPaperBySource(labelSource, true).length})`} key="trash">
                <div className={styles.contentPaper}>
                  <DocumentReader
                    dataList={filterListPaperBySource(labelSource, true)}
                    showDelete={showDelete}
                    onClick={(paper) => getSelectedPaperAndShowModal(paper)}
                    isTrash={true}
                  />
                  {(getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length === 0 && <Empty style={{width: "100%"}} />) ||
                    ""}
                </div>
              </TabPane>
            </Tabs>
          </Spin>
        </div>
        {(showCreate && (
          <Modal
            title=""
            centered
            visible={showCreate}
            width={750}
            onCancel={() => {
              setShowCreate(false)
              setFileType("")
            }}
            footer={[
              <div key="0">
                <Select
                  style={{
                    width: 120,
                    marginRight: 20,
                  }}
                  placeholder="Chọn loại"
                  onChange={selectFileType}
                  value={fileType || undefined}
                >
                  <Option value={TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER}>
                    {TYPE.CLAIM_FILE_TYPE_VALUE.DISCHARGE_PAPER}
                  </Option>
                  <Option value={TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER}>{TYPE.CLAIM_FILE_TYPE_VALUE.INVOICE_PAPER}</Option>
                  <Option value={TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER}>
                    {TYPE.CLAIM_FILE_TYPE_VALUE.PRESCRIPTION_PAPER}
                  </Option>
                  <Option value={TYPE.CLAIM_FILE_TYPE.OTHER_PAPER}>{TYPE.CLAIM_FILE_TYPE_VALUE.OTHER_PAPER}</Option>
                </Select>
                <Button key="submit" type="primary" loading={loading} onClick={() => uploadDocument()}>
                  Submit
                </Button>
              </div>,
            ]}
          >
            <Spin spinning={loading}>
              <div className={styles.contentModal}>
                <UploadComponent onChange={handleFileData} type={fileType} />
              </div>
            </Spin>
          </Modal>
        )) ||
          ""}
        {(setShowModalConfirm && (
          <Modal
            title=""
            centered
            visible={showModalConfirm}
            width={350}
            onCancel={() => {
              setShowModalConfirm(false)
            }}
            footer={[
              <Button key="submit" type="primary" loading={loading} onClick={() => actionDocument(selectedPaper)}>
                Confirm
              </Button>,
            ]}
          >
            {(showDelete && "Bạn có xác nhận xóa giấy tờ này.") || "Bạn có xác nhận khôi phục lại giấy tờ này."}
          </Modal>
        )) ||
          ""}
      </div>
    </>
  )
}

export default ClaimCaseDocumentScreen
