import React, {useState} from "react"
import styles from "./PolicyDetailScreen.module.css"
import BaseForm from "app/common/components/Form"
import _ from "lodash"
import AdminServices from "services/AdminServices"
import {PORTAL_PATH} from "app/portal/config/routes"
import {formCreateSchema, formUpdateSchema} from "../../schema"
import {Button, message, Modal} from "antd"
import {companyType} from "../../../CompanyScreen/screens/CompanyInfo"
import {TYPE} from "config/constants"
import {utils} from "config/utils"
import moment from "moment"
import FileService from "services/FileServices"
import {useHistory, useParams} from "react-router"
import {useMountEffect} from "@react-hookz/web"

export type policyType = {
  key?: string
  policyNumber: string
  effectiveDates?: string
  corporate: string
  corporateId?: string
  brokerId?: string
  insurerId: string
  tpaId: string
  individualOwnerId?: string
  insurer: string
  isConfirm?: boolean
  remarkCopay?: string
  document?: Array<any>
}

const PolicyDetailScreen = (props: {callFromInfo: (isReload?: boolean) => void}) => {
  const params = useParams<{id: string; type: string}>()
  const history = useHistory()
  const {id, type} = params
  const [loading, setLoading] = useState<boolean>(true)
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [confirmChange, setConfirmChange] = useState<boolean>(false)
  const [data, setData] = useState<policyType | any>()
  const [option, setOption] = useState<{[key: string]: {name: string; value: string}[]}>()
  //TODO: must create an interface for schema using in table list and form list
  const [createSchema, setCreateSchema] = useState<any>(formCreateSchema)
  const [updateSchema] = useState<any>(formUpdateSchema)

  const overrideCreateForm = () => {
    // Why: Eslint cannot define splice is also a reassigned callback function
    // eslint-disable-next-line
    let newCreateSchema: any = _.clone(formCreateSchema)
    if (newCreateSchema) {
      if (type) {
        if (type !== TYPE.OWNER_TYPE.INDIVIDUAL) {
          newCreateSchema.splice(
            newCreateSchema.indexOf(newCreateSchema.find((s: any) => s.key === "individualOwner")),
            1,
          )
        }

        if (type !== TYPE.OWNER_TYPE.CORPORATE) {
          newCreateSchema.splice(
            newCreateSchema.findIndex((s: any) => s.key === "corporateId"),
            1,
          )
        }
        setCreateSchema(newCreateSchema)
      } else {
        newCreateSchema.splice(
          newCreateSchema.findIndex((s: any) => s.key === "tpaId"),
          1,
        )
        newCreateSchema.splice(
          newCreateSchema.findIndex((s: any) => s.key === "individualOwner"),
          1,
        )
        newCreateSchema.splice(
          newCreateSchema.findIndex((s: any) => s.key === "corporateId"),
          1,
        )
      }
    }
  }

  const getInsuredPerson = async () => {
    const response = await AdminServices.getInsuredPersonList()
    if (response.isSuccess) {
      return response.data.collection.map((ip) => ({
        name: ip.name,
        value: ip.insuredPersonId,
      }))
    } else {
      return []
    }
  }

  const getCompanyList = async () => {
    const response = await AdminServices.getCompanyList()
    let individualOwnerId = []
    if (type === TYPE.OWNER_TYPE.INDIVIDUAL) {
      individualOwnerId = await getInsuredPerson()
    }
    if (response.isSuccess) {
      setOption({
        insurerId: response.data.collection
          .filter((company: companyType) => company.types.includes("Insurer"))
          .map((insurer: companyType) => ({
            name: insurer.name,
            value: insurer.companyId,
          })),
        corporateId: response.data.collection
          .filter((company: companyType) => company.types.includes("Corporate"))
          .map((corporate: companyType) => ({
            name: corporate.name,
            value: corporate.companyId,
          })),
        brokerId: response.data.collection
          .filter((company: companyType) => company.types.includes("Broker"))
          .map((broker: companyType) => ({
            name: broker.name,
            value: broker.companyId,
          })),
        tpaId: response.data.collection
          .filter((company: companyType) => company.types.includes("TPA"))
          .map((tpa: companyType) => ({
            name: tpa.name,
            value: tpa.companyId,
          })),
        individualOwnerId,
      })
    } else {
      return {
        error: response.messages,
        data: null,
      }
    }
  }

  const getFirstLoad = async () => {
    if (id && id !== "create") {
      const response = await AdminServices.getPolicyById(id)
      if (response.isSuccess) {
        const newData = response.data
        newData.effectiveDates = [
          moment(utils.parseLocalTime(newData.effectiveDates.split("&")[0])),
          moment(utils.parseLocalTime(newData.effectiveDates.split("&")[1])),
        ]
        setData(response.data)
      }
    }
    setLoading(false)
  }

  useMountEffect(() => {
    getFirstLoad().then()
    overrideCreateForm()
    getCompanyList().then()
  })

  const handleChange = (key: string, value: string) => {
    let newData = _.clone(data)
    if (value || value === "") {
      if (!newData) {
        newData = {}
      }
      newData[key] = value
      setData(newData)
    } else {
      setData(newData)
    }
  }

  const uploadDocument = async (data, id: string) => {
    for (const paperData of data) {
      if (paperData) {
        const file = await utils.base64ToFile(paperData.originFileObj, paperData.name)
        try {
          // eslint-disable-next-line no-await-in-loop
          await FileService.uploadFilePolicy(id, file)
        } catch (e) {
          return e
        }
      }
    }
  }

  const goToDetail = (id: string) => {
    const path = PORTAL_PATH.POLICY_INFO.replace(":id", id)
    history.push(path)
  }

  const goToList = () => {
    history.push(PORTAL_PATH.POLICY_LIST)
  }

  const createPolicy = async () => {
    setLoading(true)
    if (data) {
      const requestData = _.clone(data)
      requestData.effectiveDates = `${utils.parseLocalTime(data.effectiveDates[0])}&${utils.parseLocalTime(
        data.effectiveDates[1],
      )}`
      const response = await AdminServices.createPolicy(requestData)
      if (response.isSuccess) {
        if (data.document) {
          uploadDocument(data.document, response.data).then()
        }
        message.success("Tạo policy thành công")
        goToDetail(response.data)
      } else {
        message.error(response.messages)
      }
    }
    setLoading(false)
  }

  const updatePolicy = async () => {
    setLoading(true)
    if (data) {
      const requestData = _.clone(data)
      requestData.effectiveDates = `${utils.parseLocalTime(data.effectiveDates[0])}&${utils.parseLocalTime(
        data.effectiveDates[1],
      )}`
      if (confirmChange) {
        requestData.isConfirm = true
      }
      const response = await AdminServices.updatePolicy(id, requestData)
      if (response.isSuccess) {
        const {callFromInfo} = props
        message.success("Cập nhật policy thành công")
        await getFirstLoad()
        if (callFromInfo) {
          callFromInfo(true)
        }
      } else {
        //@ts-ignore
        if (response.code === "Error_019") {
          setShowModalConfirm(true)
          setErrorMessage(response.messages)
        }
      }
    }
    setLoading(false)
  }

  const onSave = async () => {
    if (id) {
      await updatePolicy()
    } else {
      await createPolicy()
    }
  }

  const {callFromInfo} = props
  return (
    <div className={styles.container}>
      <div>
        <BaseForm
          className={styles.createFormContainer}
          title={(!callFromInfo && "Chi tiết hợp đồng BH") || undefined}
          schemas={(!callFromInfo && createSchema) || updateSchema}
          loading={loading}
          onChange={(key: string, value: string) => handleChange(key, value)}
          data={data || {}}
          option={option}
          onSave={() => onSave()}
          onCancel={() => {
            if (callFromInfo) {
              callFromInfo()
            } else {
              goToList()
            }
          }}
        />
      </div>
      <Modal
        title="Cập nhật thông tin hợp đồng"
        centered
        visible={showModalConfirm}
        width={500}
        onCancel={() => setShowModalConfirm(false)}
        footer={[
          <Button key="back" onClick={() => setShowModalConfirm(false)}>
            Hủy bỏ
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              setConfirmChange(true)
              onSave().then()
            }}
          >
            Xác nhận
          </Button>,
        ]}
      >
        <p className={styles.errorConfirmMessage}>{errorMessage}</p>
      </Modal>
    </div>
  )
}

export default PolicyDetailScreen
