import React from "react";
import moment from "moment";

const listSchema = [
  {
    key: "code",
    dataIndex: "code",
    title: "Mã sản phẩm",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Tên sản phẩm",
  },
  {
    key: "insurer",
    dataIndex: "insurer",
    title: "Công ty bảo hiểm",
  },
  {
    key: "createdAt",
    title: "Ngày khởi tạo",
    render: (text: string, record: any) => (
      <div>
        {moment(record.createdAt).format('DD/MM/YYYY')}
      </div>
    )
  }
];

const planSchema = [
  {
    key: 'name',
    label: 'Tên sản phẩm',
    placeholder: 'Nhập tên sản phẩm',
    component: 'input',
    type: 'text',
    required: true
  },
  {
    key: 'code',
    label: 'Mã sản phẩm',
    placeholder: 'Nhập mã sản phẩm',
    component: 'input',
    type: 'text',
    required: true
  },
  {
    key: 'document',
    label: 'Tài liệu',
    placeholder: 'Nhập đường dẫn tới tệp tài liệu',
    component: 'input',
    type: 'text'
  },
  {
    key: 'cardId',
    label: 'Mẫu thẻ',
    placeholder: 'Chọn mẫu thẻ',
    component: 'select',
    required: false,
  },
  {
    key: "termsDoc",
    label: "Term and condition",
    placeholder: "Chọn tệp quy tắc bảo hiểm",
    component: "upload",
    layout: "24"
  },
];

const planUpdateSchema = [
  {
    key: 'name',
    label: 'Tên sản phẩm',
    placeholder: 'Nhập tên sản phẩm',
    component: 'input',
    type: 'text',
    required: true
  },
  {
    key: 'code',
    label: 'Mã sản phẩm',
    placeholder: 'Nhập mã sản phẩm',
    component: 'input',
    type: 'text',
    required: true
  },
  {
    key: 'document',
    label: 'Tài liệu',
    placeholder: 'Nhập đường dẫn tới tệp tài liệu',
    component: 'input',
    type: 'text'
  },
  {
    key: 'cardId',
    label: 'Mẫu thẻ',
    placeholder: 'Chọn mẫu thẻ',
    component: 'select',
    required: false,
  }
];

const planDetailSchema = [
  {
    key: 'name',
    label: 'Tên quyền lợi chi tiết',
    placeholder: 'Nhập tên quyền lợi chi tiết',
    component: 'input',
    type: 'text',
    required: true
  },
  {
    key: 'code',
    label: 'Mã quyền lợi chi tiết',
    placeholder: 'Nhập mã quyền lợi chi tiết',
    component: 'input',
    type: 'text',
    required: true
  },
  {
    key: 'description',
    label: 'Mô tả',
    placeholder: 'Nhập mô tả',
    component: "textArea",
    layout: "24",
    required: false
  },
  {
    key: 'coverageDescription',
    label: 'Mô tả hạn mức',
    placeholder: 'Nhập mô tả hạn mức',
    component: "textArea",
    layout: "24",
    required: false
  },
  {
    key: 'territorialLimit',
    label: 'Giới hạn lãnh thổ',
    placeholder: 'Nhập giới hạn lãnh thổ',
    component: 'select',
    required: false
  },
  {
    key: 'fee',
    label: 'Phí bảo hiểm',
    placeholder: 'Nhập phí bảo hiểm',
    component: 'input',
    type: 'text',
    required: false
  },
  {
    key: 'parentPlanDetailId',
    label: 'Quyền lợi chính',
    placeholder: 'Chọn quyền lợi chính',
    component: 'select',
    required: false,
  }
];

export {planSchema, listSchema, planUpdateSchema, planDetailSchema};