import styles from "./TemplateMessage.module.css"
import ContentEditable from "react-contenteditable"
import React from "react"
import { TemplateType } from "../../index"
import { Input } from "antd"

const { TextArea } = Input

const TemplateMessage = (props: {
  templateType: TemplateType
  templateData: string
  onChangeTemplateData: (value: string) => void
}) => {
  const { templateType, templateData, onChangeTemplateData } = props
  return templateType === TemplateType.EMAIL ? (
    <div className={styles.mailContainer}>
      <ContentEditable
        tagName={"article"}
        html={templateData || ""}
        onChange={(e) => onChangeTemplateData(e.target.value)} // handle innerHTML change
      />
    </div>
  ) : (
    <div className={styles.smsContainer}>
      <div>
        <TextArea
          rows={templateData ? templateData.length / 74 + 1 : 3 || 0}
          value={templateData || ""}
          onChange={(e) => onChangeTemplateData(e.target.value)} // handle innerHTML change
        />
      </div>
    </div>
  )
}

export default TemplateMessage
