import React from "react"
import styles from "./BottomBar.module.css"
import {Button, Select, Space} from "antd"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"
import {TYPE} from "config/constants"

const {Option} = Select

const BottomBar = (props: {
  claimId: string
  value: string
  onUpdate: () => void
  disabledUpdate?: boolean
  handleChange: (value: string) => void
}) => {
  const {claimId, value, onUpdate, disabledUpdate, handleChange} = props
  const statusList = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.CLAIM_REQUEST_STATUS],
  )

  return (
    <div className={[styles.bottomBar, "fixedLayout"].join(" ")}>
      <div className={styles.leftSide}>
        {claimId ? (
          <Select
            showSearch
            style={{width: 250}}
            placeholder="Đổi trạng thái"
            optionFilterProp="children"
            size={"large"}
            onChange={(value) => handleChange(value)}
            value={value || ""}
          >
            {statusList && statusList.length && statusList && statusList.length
              ? statusList.map((status: {name: string; value: string}) => (
                  <Option value={status.value} key={status.value}>
                    {status.name}
                  </Option>
                ))
              : ""}
          </Select>
        ) : (
          ""
        )}
      </div>
      <div className={styles.rightSide}>
        <Space align={"center"} size={16}>
          <Button disabled={disabledUpdate} type={"primary"} size={"large"} onClick={onUpdate}>
            {claimId ? "Lưu lại" : "Khởi tạo"}
          </Button>
        </Space>
      </div>
    </div>
  )
}

export default BottomBar
