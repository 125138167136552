import React from "react"
import ReactDOM from "react-dom"
import { I18nextProvider } from "react-i18next"
import reportWebVitals from "../src/reportWebVitals"
import { Provider } from "react-redux"
import i18next from "./translation"
import store from "./store/store"
import App from "./app"
import { BrowserRouter as Router } from "react-router-dom"
import { ApolloProvider, InMemoryCache } from "@apollo/client"
import createApolloClient from "./services/appoloClientServices"
const cache = new InMemoryCache()
const client = createApolloClient(
  store.getState().token || localStorage.getItem("token") || "",
  cache,
)

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <Router>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root"),
)

export default ReactDOM
reportWebVitals()
