import React, {useState} from "react"
import styles from "./PolicyInfoScreen.module.css"
import caolan from "async"
import AdminServices from "services/AdminServices"
import {Drawer, message, Modal, notification, Popconfirm, Spin, Tabs} from "antd"
import TableList from "app/common/components/TableList"
import {certificateSchema, formWaitingSchema, LAListSchema, planListSchema} from "../../schema"
import Button from "antd-button-color"
import {DeleteTwoTone, EditOutlined, WarningOutlined} from "@ant-design/icons"
import PolicyDetailScreen from "../PolicyDetailScreen"
import {FORMATTER, TYPE} from "config/constants"
import {utils} from "config/utils"
import BaseForm from "app/common/components/Form"
import {planSchema} from "../../../PlanScreen/schema"
import _ from "lodash"
import PlanServices from "services/PlanServices"
import {PORTAL_PATH} from "app/portal/config/routes"
import {useHistory, useParams} from "react-router"
import BenefitTag from "app/common/components/BenefitTag"
import FileService from "services/FileServices"
import {useMountEffect} from "@react-hookz/web"
import CoPaymentServices from "services/CoPaymentServices"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"

const {TabPane} = Tabs

export type policyType = {
  broker: string
  brokerId: string
  corporate: string
  corporateId: string
  createdAt: string
  effectiveDates: string
  individualOwner: string
  individualOwnerId: string
  insurer: string
  insurerId: string
  policyId: string
  policyNumber: string
  type: string
  typeName: string
  updatedAt: string
  waitingTimes: []
  remarkWaitingTimes: remarkWaitingTimesType[]
}

export type remarkWaitingTimesType = {
  id?: string
  name: string
  value: string
  description: string
}

export type WaitingTimeType = {
  policyWaitingTimeId?: string
  description?: string
  value?: string | number
  insuredBenefitTypes?: string[]
}

export type laType = {
  salary: 0
  email: string
  isActive: boolean
  insuredPersonName: string
  certificateCode: string
  userInfoNationalId: string
  userInfoParentNationalId: string
  insuredCertificateId: string
}

interface IPlanData {
  code?: string
  cardId?: string
  createdAt?: string
  id?: string
  name?: string
  insurer?: string
  document?: string
  terms?: string
  termsDoc?: Array<any>
  updatedAt?: string
}

interface CoPaymentData {
  applyToAllMedicalProvider: boolean
  applyToBlackList: boolean
  applyToTarget: boolean
  applyToTargetAgeRange: boolean
  applyToWillingTarget: boolean
  policyCoPaymentId: string
  target: null
  totalInsuredBenefits: number
  totalMedicalProvider: number
  totalPlans: number
  totalTargetInBlackList: number
  value: number
  plans: string[]
}

type PolicyFileType = {
  id: string
  fileName: string
  displayName: string
  url: string
  type: string
  isImage: boolean
  source: string
  isTrash: boolean
}

const PolicyInfoScreen = () => {
  const insuredBenefitTypes = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [showModalPlan, setShowModalPlan] = useState<boolean>(false)
  const [showModalFile, setShowModalFile] = useState<boolean>(false)
  const [fileCreateData, setFileCreateData] = useState<any>({})
  const [waitingTimeModal, showWaitingTimeModal] = useState<boolean>(false)
  const [percent, setPercent] = useState(0)
  const [planCreateData, setPlanCreateData] = useState<IPlanData>({})
  const [currentWaitingTimeData, setCurrentWaitingTimeData] = useState<WaitingTimeType>({
    description: undefined,
    insuredBenefitTypes: [],
    value: undefined,
  })
  const [updateModeWaitingTime, setUpdateModeWaitingTime] = useState<boolean>()
  const [planListData, setPlanListData] = useState<IPlanData[]>([])
  const [policyCoPaymentData, setPolicyCoPaymentData] = useState<CoPaymentData[]>([])
  const [optionCardList, setOptionCardList] = useState({})
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [policy, setPolicy] = useState<policyType | any>()
  const [showConfirmActivate, setShowConfirmActivate] = useState(false)
  const [la, setLa] = useState<laType[] | any[]>()
  const [loading, setLoading] = useState<boolean>()
  const [policyFileList, setPolicyFileList] = useState<PolicyFileType[]>([])
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [showModalRemark, setShowModalRemark] = useState<boolean>(false)
  const [loadingRemark, setLoadingRemark] = useState(false)
  const [remarkWaitingTimesData, setRemarkWaitingTimesData] = useState<remarkWaitingTimesType>()

  const history = useHistory()
  const {id} = useParams<{id: string}>()

  const getCardList = async (insurerId: string) => {
    const response = await AdminServices.getCardsByInsurerId(insurerId)
    if (response.isSuccess && response.data) {
      const options = response.data.map((card) => ({
        name: card.name,
        value: card.cardId,
      }))
      setOptionCardList({cardId: options})
    }
  }

  const getPolicyInfo = async () => {
    setLoading(true)
    if (id && id !== "create") {
      const response = await AdminServices.getPolicyById(id)
      if (response.isSuccess) {
        setPolicy(response.data)
        if (response.data.insurerId) {
          getCardList(response.data.insurerId)
        }
      }
    }
    setLoading(false)
  }

  const getLAList = async () => {
    if (id && id !== "create") {
      const response = await AdminServices.getLAByPolicyId(id)
      if (response.isSuccess) {
        setLa(response.data.collection)
      }
    }
  }

  const getPlanList = async () => {
    const planList = await PlanServices.getPlans(id)
    if (planList.isSuccess && planList.data.collection) {
      setPlanListData(planList.data.collection)
    }
  }

  const getPolicyFile = async () => {
    const response = await FileService.getFileList(TYPE.FILE_TYPE.POLICY_DOCUMENT, "", id, "")
    if (response.isSuccess) {
      setPolicyFileList(response.data.collection)
    }
  }

  const getPolicyCoPayment = async () => {
    const response = await CoPaymentServices.getPolicyCoPayment(id)
    if (response.isSuccess) {
      setPolicyCoPaymentData(response.data.collection)
    }
  }

  const getFirstLoad = async () => {
    getPolicyInfo().then()
    getLAList().then()
    getPlanList().then()
    getPolicyFile().then()
    getPolicyCoPayment().then()
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  const handleChangePlanData = (key: string, value: string) => {
    const newData = _.clone(planCreateData)
    if (value) {
      newData[key] = value
      setPlanCreateData(newData)
    } else {
      delete newData[key]
      setPlanCreateData(newData)
    }
  }

  const handleWaitingTime = (key: string, value: string) => {
    let newData = _.clone(currentWaitingTimeData)
    if (value) {
      if (!newData) {
        newData = {
          insuredBenefitTypes: [],
          value: undefined,
          description: undefined,
        }
      }
      newData[key] = value
      setCurrentWaitingTimeData(newData)
    }
  }

  const importLAByCSV = async (e) => {
    const file: File = e.target.files[0]
    const uploadFile = await utils.base64ToFile(file, file.name)
    const response = await AdminServices.importData(uploadFile, TYPE.IMPORT_TYPE.LA, (percent: number) => {
      setPercent(percent)
    })
    if (response.isSuccess) {
      getLAList().then()
      for (let i = 0; i < response.data.length; i++) {
        setTimeout(() => {
          notification.warning({
            message: "Import info",
            description: response.data[i],
            icon: <WarningOutlined style={{color: "orange"}} />,
          })
        }, 1200 * i)
      }
    } else {
      setPercent(100)
      message.error(response.messages)
    }
  }

  const uploadDocument = async (data, id: string) => {
    for (const paperData of data) {
      if (paperData) {
        const file = await utils.base64ToFile(paperData.originFileObj, paperData.name)
        try {
          // eslint-disable-next-line no-await-in-loop
          await FileService.uploadFilePlan(id, file)
        } catch (e) {
          return e
        }
      }
    }
  }

  const createPlan = async () => {
    if (planCreateData && id) {
      const response = await PlanServices.createPlanByPolicy(id, planCreateData)
      if (response.isSuccess && response.data) {
        if (planCreateData.termsDoc) {
          uploadDocument(planCreateData.termsDoc, response.data).then()
        }
        setShowModalPlan(false)
        message.success("Tạo sản phẩm bảo hiểm thành công")
        let path = PORTAL_PATH.PLAN_DETAIL
        path = path.replace(":planId", response.data)
        history.push(path)
      }
    }
  }

  const createUpdateWaitingTime = async () => {
    setLoading(true)
    if (
      currentWaitingTimeData &&
      currentWaitingTimeData.insuredBenefitTypes &&
      currentWaitingTimeData.value &&
      currentWaitingTimeData.description
    ) {
      let response
      if (updateModeWaitingTime && currentWaitingTimeData.policyWaitingTimeId) {
        response = await PlanServices.updateWaitingTime(policy.policyId, currentWaitingTimeData.policyWaitingTimeId, {
          ..._.omit(currentWaitingTimeData, "policyWaitingTimeId"),
          value: parseInt(currentWaitingTimeData.value as string),
        })
        setUpdateModeWaitingTime(false)
      } else {
        response = await PlanServices.createWaitingTime(policy.policyId, {
          ...currentWaitingTimeData,
          value: parseInt(currentWaitingTimeData.value as string),
        })
      }

      if (response.isSuccess) {
        message.success("Tạo thời gian chờ thành công")
        setCurrentWaitingTimeData({
          description: undefined,
          value: undefined,
          insuredBenefitTypes: [],
        })
        getPolicyInfo().then()
      }
    }
    showWaitingTimeModal(false)
    setLoading(false)
  }

  const deleteWaitingTime = async (policyWaitingTimeId: string) => {
    if (policyWaitingTimeId) {
      const response = await PlanServices.deleteWaitingTime(policy.policyId, policyWaitingTimeId)
      if (response.isSuccess) {
        message.success("Xoá waiting time thành công")
        getPolicyInfo().then()
      } else {
        message.error("Xoá waiting time không thành công")
      }
    }
  }

  const activateCertificate = async () => {
    const data = {
      selectedIds: selectedKeys,
    }
    if (selectedKeys.length) {
      const response = await AdminServices.activateCertificate(id, data)
      if (response.isSuccess) {
        message.success("Kích hoạt thẻ thành công")
        setShowConfirmActivate(false)
        getLAList().then()
      }
    } else {
      message.warn("Không có thẻ nào được chọn")
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeys(selectedRowKeys.toString().split(","))
    },
    selectedRowKeys: selectedKeys,
  }

  const getSelectedCertificate = () => {
    const listSelected: laType[] | any[] = []
    if (selectedKeys && la) {
      la.forEach((la) => {
        if (selectedKeys.includes(la.insuredCertificateId)) {
          listSelected.push(la)
        }
      })
    }
    return listSelected
  }

  const handleChangeFileData = (key: string, value: string) => {
    let newData = _.clone(fileCreateData)
    if (value || value === "") {
      if (!newData) {
        newData = {}
      }
      newData[key] = value
      setFileCreateData(newData)
    } else {
      setFileCreateData(newData)
    }
  }

  const uploadFile = async (data: {originFileObj: File; name: string}) => {
    if (data.originFileObj.size / (1024 * 1024) <= 10) {
      const file = await utils.base64ToFile(data.originFileObj, data.name)
      await FileService.uploadFilePolicy(id, file)
    } else {
      message.warn("Kích thước tập tin vượt quá 10MB")
    }
  }

  const createFile = () => {
    if (fileCreateData && fileCreateData.document) {
      caolan.each(
        fileCreateData.document,
        (paperData: {originFileObj: File; name: string}, callback) => {
          uploadFile(paperData).then(() => callback())
        },
        (err) => {
          if (err) {
            message.error("Thêm mới thất bại")
          } else {
            setShowModalFile(false)
            setFileCreateData({document: []})
            getPolicyFile().then()
          }
        },
      )
    }
  }

  const deleteFile = async (docId) => {
    setLoadingDelete(true)
    const response = await FileService.updateFilePolicy(id, docId)
    if (response.isSuccess) {
      message.success("Xóa file thành công")
      getPolicyFile().then()
    } else {
      message.error(response.messages)
    }
    setLoadingDelete(false)
  }

  const saveRemarkWaitingTimes = async () => {
    setLoadingRemark(true)
    if (remarkWaitingTimesData) {
      const response = await AdminServices.saveRemarkWaitingTimes(id, [remarkWaitingTimesData])
      if (response.isSuccess) {
        message.success("Cập nhật remark thời gian chờ thành công")
        getPolicyInfo().then()
      } else {
        message.error(response.messages)
      }
    }

    setShowModalRemark(false)
    setLoadingRemark(false)
  }

  const handleRemarkWaitingTimesChange = (key: string, value: string) => {
    let newData: any = _.clone(remarkWaitingTimesData)
    newData = {...newData, [key]: value}
    setRemarkWaitingTimesData(newData)
  }

  const FileListSchema = [
    {
      title: "Tên file",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Tác vụ",
      render: (value, row) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            title="Bạn có chắc muốn xóa"
            onConfirm={() => deleteFile(row.id)}
            okButtonProps={{
              loading: loadingDelete,
              danger: true,
            }}
            okText={"Xóa"}
          >
            <DeleteTwoTone
              twoToneColor="#EF5350"
              style={{
                fontSize: 18,
                textAlign: "center",
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ]

  return (
    <>
      {(policy && (
        <div className={styles.policyInformation}>
          <Button
            icon={<EditOutlined />}
            size={"middle"}
            type={"lightdark"}
            className={styles.editButton}
            onClick={() => setShowDrawer(true)}
          >
            SỬA
          </Button>
          <div className={styles.policyDetail}>
            <div className={styles.title}>Số hợp đồng:</div>
            <div className={styles.content}>{policy.policyNumber}</div>
          </div>

          <div className={styles.policyDetail}>
            <div className={styles.title}>Công ty bảo hiểm:</div>
            <div className={styles.content}>{policy.insurer}</div>
          </div>

          {policy.brokerId ? (
            <div className={styles.policyDetail}>
              <div className={styles.title}>Công ty môi giới:</div>
              <div className={styles.content}>{policy.broker}</div>
            </div>
          ) : (
            ""
          )}

          {policy.corporate ? (
            <div className={styles.policyDetail}>
              <div className={styles.title}>Công ty khách hàng:</div>
              <div className={styles.content}>{policy.corporate}</div>
            </div>
          ) : (
            ""
          )}

          {policy.individualOwner ? (
            <div className={styles.policyDetail}>
              <div className={styles.title}>Khách hàng cá nhân:</div>
              <div className={styles.content}>{policy.individualOwner}</div>
            </div>
          ) : (
            ""
          )}

          <div className={styles.policyDetail}>
            <div className={styles.title}>Ngày hiệu lực:</div>
            <div className={styles.content}>
              {utils.parseToStringLocalDateTime(policy.effectiveDates, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}
            </div>
          </div>

          <div className={styles.policyDetail}>
            <div className={styles.title}>Tạo từ:</div>
            <div className={styles.content}>
              {utils.parseToStringLocalDateTime(policy.createdAt, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}
            </div>
          </div>
        </div>
      )) ||
        ""}
      {policy && policy.remarkCopay ? (
        <div className={styles.remarkCopayContainer}>
          <label>Đồng chi trả</label>
          <div>{policy.remarkCopay}</div>
        </div>
      ) : (
        ""
      )}

      {policy && policy.remarkWaitingTimes ? (
        <TableList
          className={styles.remarkWaitingTimesContainer}
          onCreate={() => {
            setRemarkWaitingTimesData(undefined)
            setShowModalRemark(true)
          }}
          loading={loadingRemark}
          onCreateTitle="Thêm mới"
          title={"Danh sách remark waiting time"}
          schema={[
            {
              dataIndex: "name",
              key: "name",
              title: "Tên",
            },
            {
              dataIndex: "value",
              key: "value",
              title: "Giá trị",
            },
            {
              dataIndex: "description",
              key: "description",
              title: "Mô tả",
            },
          ]}
          data={policy.remarkWaitingTimes}
          onView={(record) => {
            setRemarkWaitingTimesData(record)
            setShowModalRemark(true)
          }}
        />
      ) : (
        ""
      )}
      <Modal
        title=""
        onCancel={() => {
          setShowModalRemark(false)
          setRemarkWaitingTimesData(undefined)
        }}
        centered
        visible={showModalRemark}
        width={800}
        footer={[]}
      >
        <BaseForm
          title={"Remark Waiting Time"}
          className={styles.containerForm}
          schemas={[
            {
              key: "name",
              label: "Tên",
              placeholder: "Nhập tên copay",
              component: "input",
              layout: "24",
            },
            {
              key: "value",
              label: "Giá trị",
              placeholder: "Nhập giá trị copay",
              component: "input",
              layout: "24",
            },
            {
              key: "description",
              label: "Mô tả",
              placeholder: "Nhập mô tả",
              component: "input",
              layout: "24",
            },
          ]}
          loading={false}
          onChange={(key: string, value: string) => handleRemarkWaitingTimesChange(key, value)}
          data={remarkWaitingTimesData || {}}
          onSave={() => saveRemarkWaitingTimes()}
          onCancel={() => setShowModalRemark(false)}
        />
      </Modal>

      <Spin spinning={loading}>
        <Tabs className={styles.tabContainer} type="card">
          <TabPane tab="Thẻ bảo hiểm" key="certificates">
            <TableList
              rowSelection={rowSelection}
              className={styles.insuredContainer}
              title={"thẻ bảo hiểm"}
              onView={(record) => {
                let path = PORTAL_PATH.CERTIFICATE_DETAIL.replace(":policyId", id)
                path = path.replace(":certificateId", record.insuredCertificateId)
                history.push(path)
              }}
              onImport={(e) => {
                importLAByCSV(e)
              }}
              onAction={() => setShowConfirmActivate(true)}
              onActionTitle={"Kích hoạt thẻ"}
              scroll={{x: 800}}
              schema={LAListSchema}
              search={["insuredPersonName", "certificateCode"]}
              percent={percent}
              data={
                (la &&
                  la.map((item) => ({
                    ...item,
                    key: item.insuredCertificateId,
                  }))) ||
                []
              }
            />
          </TabPane>
          <TabPane tab="Sản phẩm bảo hiểm" key="plans">
            <TableList
              title={"sản phẩm bảo hiểm"}
              onView={(record) => {
                let path = PORTAL_PATH.PLAN_DETAIL
                path = path.replace(":planId", record.planId)
                history.push(path)
              }}
              onCreateTitle={"Tạo plan"}
              onCreate={() => {
                setShowModalPlan(true)
              }}
              schema={planListSchema}
              percent={percent}
              rowKey={"code"}
              data={planListData}
            />
          </TabPane>
          <TabPane tab="Thời gian chờ" key="waitingTime">
            <TableList
              title={"Thời gian chờ"}
              onView={(record) => {
                setCurrentWaitingTimeData(record)
                setUpdateModeWaitingTime(true)
                showWaitingTimeModal(true)
              }}
              onCreateTitle={"Tạo thời gian chờ"}
              onCreate={() => {
                showWaitingTimeModal(true)
              }}
              schema={[
                {
                  key: "insuredBenefitTypes",
                  dataIndex: "insuredBenefitTypes",
                  title: "Quyền lợi",
                  render: (text) =>
                    text.map((t) => (
                      <BenefitTag
                        key={t + Math.random()}
                        text={t}
                        textReplace={insuredBenefitTypes && insuredBenefitTypes?.find((b) => b.value === t)?.name}
                      />
                    )),
                },
                {
                  key: "value",
                  dataIndex: "value",
                  title: "Thời gian",
                  render: (text, record) => `${text} ngày - ${record.description}`,
                },
                {
                  title: "Xóa",
                  render: (text, record: WaitingTimeType) => (
                    <div onClick={(e) => e.stopPropagation()}>
                      <Popconfirm
                        title="Bạn có chắc muốn xóa"
                        onConfirm={() => deleteWaitingTime(record.policyWaitingTimeId || "")}
                        okButtonProps={{
                          loading: loading,
                          danger: true,
                        }}
                        okText={"Xóa"}
                      >
                        <DeleteTwoTone
                          twoToneColor="#EF5350"
                          style={{
                            fontSize: 18,
                            textAlign: "center",
                          }}
                        />
                      </Popconfirm>
                    </div>
                  ),
                },
              ]}
              data={
                policy
                  ? policy.waitingTimes.map((p) => ({
                      ...p,
                      key: p.policyWaitingTimeId,
                    }))
                  : []
              }
            />
          </TabPane>
          <TabPane tab="Đồng chi trả" key="policyCoPayment">
            <TableList
              title="Đồng chi trả"
              onView={(record) => {
                let path = PORTAL_PATH.POLICY_COPAYMENT_DETAIL
                path = path.replace(":policyId", id)
                path = path.replace(":coPaymentId", record.policyCoPaymentId)
                history.push(path)
              }}
              onCreate={() => history.push(PORTAL_PATH.POLICY_COPAYMENT_CREATE.replace(":policyId", id))}
              onCreateTitle={"Tạo mới"}
              schema={[
                {
                  title: "Giá trị",
                  dataIndex: "value",
                  key: "value",
                  render: (record) => <span>{Math.floor(record * 100)}%</span>,
                },
                {
                  title: "Tổng sản phẩm áp dụng",
                  dataIndex: "plans",
                  key: "plans",
                  render: (record) => <span>{record.join(", ")}</span>,
                },
                {
                  title: "Tổng danh sách bệnh viện",
                  dataIndex: "totalMedicalProvider",
                  key: "totalMedicalProvider",
                },
                {
                  title: "Tổng đối tượng áp dụng",
                  dataIndex: "totalTargetInBlackList",
                  key: "totalTargetInBlackList",
                },
              ]}
              data={
                (policyCoPaymentData &&
                  policyCoPaymentData.map((copayItem) => ({...copayItem, key: copayItem.policyCoPaymentId}))) ||
                []
              }
            />
          </TabPane>
          <TabPane tab="Tập tin hợp đồng" key="policyFile">
            <TableList
              title={"Tập tin hợp đồng"}
              rowKey={"id"}
              data={policyFileList}
              onView={(record) => {
                window.open(record.url)
              }}
              onCreate={() => setShowModalFile(true)}
              onCreateTitle={"Tải lên tập tin hợp đồng"}
              schema={FileListSchema}
            />
          </TabPane>
        </Tabs>
      </Spin>
      <Drawer
        title={<h3 className={styles.titleDrawer}>Sửa policy</h3>}
        width={804}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        bodyStyle={{
          paddingBottom: 80,
          backgroundColor: "var(--topbar-background-color)",
        }}
      >
        {(showDrawer && (
          <PolicyDetailScreen
            callFromInfo={(isReload) => {
              setShowDrawer(false)
              if (isReload) {
                getFirstLoad().then()
              }
            }}
          />
        )) ||
          ""}
      </Drawer>
      <Modal
        title=""
        centered
        onCancel={() => {
          setCurrentWaitingTimeData({
            description: undefined,
            value: undefined,
            insuredBenefitTypes: [],
          })
          setUpdateModeWaitingTime(false)
          showWaitingTimeModal(false)
        }}
        visible={waitingTimeModal}
        width={800}
        footer={[]}
      >
        <BaseForm
          title={"Thời gian chờ"}
          className={styles.containerForm}
          schemas={formWaitingSchema}
          option={{insuredBenefitTypes}}
          loading={false}
          onChange={(key: string, value: string) => handleWaitingTime(key, value)}
          data={currentWaitingTimeData}
          onSave={() => createUpdateWaitingTime()}
          onCancel={() => {
            setCurrentWaitingTimeData({
              description: undefined,
              value: undefined,
              insuredBenefitTypes: [],
            })
            setUpdateModeWaitingTime(false)
            showWaitingTimeModal(false)
          }}
        />
      </Modal>
      <Modal title="" centered visible={showModalPlan} width={800} footer={[]}>
        <BaseForm
          title={"Tạo sản phẩm bảo hiểm"}
          className={styles.containerForm}
          schemas={planSchema}
          option={optionCardList || {}}
          loading={false}
          onChange={(key: string, value: string) => handleChangePlanData(key, value)}
          data={planCreateData || {}}
          onSave={() => createPlan()}
          onCancel={() => setShowModalPlan(false)}
        />
      </Modal>
      <Modal
        title="Kích hoạt thẻ"
        centered
        visible={showConfirmActivate}
        width={1200}
        onCancel={() => setShowConfirmActivate(false)}
        onOk={() => activateCertificate()}
      >
        <div className={styles.modalActivateContent}>
          <TableList
            title={"Danh sách thẻ được chọn"}
            onView={() => {}}
            schema={certificateSchema}
            data={getSelectedCertificate() || []}
          />
        </div>
      </Modal>
      <Modal title="" onCancel={() => setShowModalFile(false)} centered visible={showModalFile} width={800} footer={[]}>
        <BaseForm
          title={"Thêm tập tin hợp đồng"}
          className={styles.containerForm}
          schemas={[
            {
              key: "document",
              label: "Tập tin hợp đồng",
              placeholder: "Chọn tập tin hợp đồng",
              component: "upload",
              layout: "24",
            },
          ]}
          option={{}}
          loading={false}
          onChange={(key: string, value: string) => handleChangeFileData(key, value)}
          data={fileCreateData || {}}
          onSave={() => createFile()}
          onCancel={() => setShowModalFile(false)}
        />
      </Modal>
    </>
  )
}

export default PolicyInfoScreen
