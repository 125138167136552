import {LIMIT_MEDICAL_PROVIDER_LIST} from "../config/constants"

const endPoints = {
  LOGIN: "/auth",
  ME: "/auth",
  //ADMIN
  FILE: (type: string, claimRequestId?: string, policyId?: string, planId?: string, isWithTrashed?: boolean) =>
    `/Files?type=${type}${(claimRequestId && `&claimRequestId=` + claimRequestId) || ""}${
      (policyId && `&policyId=` + policyId) || ""
    }${(planId && `&planId=` + planId) || ""}${(isWithTrashed && `&isWithTrashed=` + isWithTrashed) || ""}`,
  INSURED_BENEFIT: "/InsuredBenefits",

  TYPES: (type?: number) => `/types?type=${type}`,

  COMPANY_LIST_TYPE: (type: string) => `/Companies?Type=${type}`,
  COMPANY_LIST: (type?: string) => `/Companies${(type && `?type=` + type) || ""}`,
  COMPANY_DETAIL: (id: string) => `/Companies/${id}`,
  COMPANY_CARD_LIST: (insurerId: string) => `/Companies/${insurerId}/cards`,

  CARD_LIST: "/cards",
  CARD_DETAIL: (id: string) => `/cards/${id}`,
  CARD_BACK: (id: string) => `/cards/${id}/back`,

  POLICY_LIST: "/Policies",
  POLICY_DETAIL: (id: string) => `/Policies/${id}`,
  POLICY_LA: (id: string) => `/Policies/${id}/insuredCertificates`,
  POLICY_DOCUMENT: (id: string) => `/Policies/${id}/uploads`,
  POLICY_DOCUMENT_DETAIL: (policyId: string, policyDocumentId: string) =>
    `/policies/${policyId}/documents/${policyDocumentId}`,

  POLICY_COPAYMENT: (policyId: string) => `/policies/${policyId}/policyCoPayments`,
  POLICY_COPAYMENT_DETAIL: (coPaymentId: string) => `/policyCoPayments/${coPaymentId}`,
  POLICY_COPAYMENT_DETAIL_TARGETS: (coPaymentId: string) => `/policyCoPayments/${coPaymentId}/targets`,
  POLICY_COPAYMENT_DETAIL_MEDICAL_PROVIDERS: (coPaymentId: string) =>
    `/policyCoPayments/${coPaymentId}/medicalProviders`,
  POLICY_COPAYMENT_DETAIL_BLACKLISTS: (coPaymentId: string) => `/policyCoPayments/${coPaymentId}/blackLists`,
  POLICY_COPAYMENT_DETAIL_SINGLE_BENEFIT: (coPaymentId: string, planInsuredBenefitId: string) =>
    `/policyCoPayments/${coPaymentId}/planInsuredBenefits/${planInsuredBenefitId}`,
  POLICY_REMARK_WAITING_TIME: (id: string) => `/policies/${id}/remarkWaitingTimes`,

  PLAN_DETAIL: (id: string) => `/plans/${id}`,
  PLAN_DOCUMENT: (id: string) => `/plans/${id}/uploads`,
  PLAN_DOCUMENT_DETAIL: (planId: string, planDocumentId: string) => `/plans/${planId}/documents/${planDocumentId}`,
  PLAN_BALANCE_LIST: (id: string) => `/plans/${id}/planBalances`,
  PLAN_BALANCE_DETAIL: (planId: string, planBalanceId: string) => `/plans/${planId}/planBalances/${planBalanceId}`,
  ASSIGN_BENEFIT_BALANCE_PLAN: (planId: string, planBalanceId: string) =>
    `/plans/${planId}/planBalances/${planBalanceId}/balanceBenefits`,
  PLAN_LIST: (policyId?: string) => `/plans${(policyId && `?policyId=` + policyId) || ""}`,
  PLAN_DETAIL_LIST: (id: string) => `/plans/${id}/details`,
  PLAN_DETAIL_INFO: (id: string, planDetailId: string) => `/plans/${id}/details/${planDetailId}`,
  CREATE_PLAN_BY_POLICY: (policyId: string) => `/policies/${policyId}/plans`,
  POLICY_WAITING_TIME: (policyId: string, policyWaitingTimeId?: string) =>
    `/policies/${policyId}/waitingTimes${policyWaitingTimeId ? `/${policyWaitingTimeId}` : ""}`,
  PLAN_BENEFIT: (planId: string) => `/Plans/${planId}/planBenefits`,
  PLAN_BENEFIT_DETAIL: (planId: string, planBenefitId: string) => `/Plans/${planId}/planBenefits/${planBenefitId}`,
  PLAN_REMARK_COPAY: (id: string) => `/plans/${id}/remarkCoPays`,

  INSURED_PERSON_LIST: (search?: string) => `/insuredPersons${search ? `?search=${search}` : ""}`,
  INSURED_PERSON_DETAIL: (id: string) => `/insuredPersons/${id}`,
  INSURED_PERSON_CLAIM_CASES: (insuredPersonId: string) => `/insuredPersons/${insuredPersonId}/claimCases`,
  INSURED_PERSON_CERTIFICATE: (insuredPersonId: string) => `/insuredPersons/${insuredPersonId}/certificates`,
  INSURED_PERSON_BENEFIT_TYPES: (insuredPersonId: string) => `/insuredPersons/${insuredPersonId}/benefitTypes`,

  CERTIFICATE_LIST: (search?: string) => `/certificates${(search && `?search=` + search) || ""}`,
  CERTIFICATE_DETAIL_BY_ID: (id: string) => `/certificates/${id}`,
  CERTIFICATE_DETAILS_BY_ID: (id: string) => `/certificates/${id}/details`,

  ACTIVATE_CERTIFICATE: (policyId: string) => `/policies/${policyId}/insuredCertificates/activate`,
  CERTIFICATE_BENEFIT: (certificateId: string) => `/certificates/${certificateId}/benefits`,
  CERTIFICATE_SALARY: (certificateId: string) => `/certificates/${certificateId}/salaries`,
  CERTIFICATE_SALARY_DETAIL: (certificateId: string, certificateSalaryHistoryId: string) =>
    `/certificates/${certificateId}/salaries/${certificateSalaryHistoryId}`,
  CLAIM: (
    claimId?: string,
    search?: string,
    source?: string,
    filter?: {
      from?: string
      to?: string
      certificateId?: string
      status?: string
      benefitType?: string
      insuredPersonId?: string
      insurerId?: string
    },
  ) =>
    `/claims${claimId ? `/${claimId}` : ""}${search ? `?search=${search}` : ""}${source ? `?source=${source}` : ""}${
      filter
        ? Object.keys(filter)
            .map(
              (key, index) =>
                (search && `&${key}=${filter[key]}`) ||
                (index === 0 && `?${key}=${filter[key]}`) ||
                `&${key}=${filter[key]}`,
            )
            .join("")
        : ""
    }`,
  CLAIM_BENEFIT: (claimId: string) => `/claims/${claimId}/benefits`,

  CLAIM_SIGN_OFF: (claimId: string) => `/claims/${claimId}/signOff`,

  CLAIM_BENEFIT_IGNORE_TIME: (claimCaseId: string, benefitId: string) =>
    `/claimCases/${claimCaseId}/benefits/${benefitId}`,

  CLAIM_CASE_BALANCE_BENEFIT: (claimCaseId: string) => `/claimCases/${claimCaseId}/details`,

  CLAIM_STATUS: (claimId: string) => `/claims/${claimId}/statuses`,

  UPDATE_CLAIM_BALANCE_BENEFIT: (claimId: string, claimDetailId: string) =>
    `/claims/${claimId}/details/${claimDetailId}`,

  CLAIM_PENDING: (claimId: string) => `/claims/${claimId}/pendings`,

  CLAIM_INFO: (claimId: string) => `/claims/${claimId}/infos`,
  CLAIM_CASE_LIST: (claimId: string) => `/claims/${claimId}/claimCases`,
  CLAIM_CERTIFICATE_LIST: (claimId: string) => `/claims/${claimId}/certificates`,

  CLAIM_CASE: (claimCaseId: string) => `/claimCases/${claimCaseId}`,
  CLAIM_CASE_BENEFITS: (claimCaseId: string) => `/claimCases/${claimCaseId}/benefits`,
  CLAIM_CASE_SIGN_OFF: (claimCaseId: string) => `/claimCases/${claimCaseId}/notes`,
  CLAIM_CASE_PENDING: (claimCaseId: string) => `/claimCases/${claimCaseId}/pendings`,
  CLAIM_CASE_STATUS: (claimCaseId: string) => `/claimCases/${claimCaseId}/statuses`,
  CLAIM_CASE_SEND_CORESPONDENT: (claimCaseId: string, isEmail = false) =>
    `/claimCases/${claimCaseId}/${isEmail ? "emails" : "sms"}`,
  CLAIM_CASE_TEMPLATE: (template: string, type: string, claimId: string, isPresentCase?: boolean) =>
    `/templates/${template}?type=${type}&claimCaseId=${claimId}${isPresentCase ? "&isPresentCase=true" : ""}`,
  CLAIM_CASE_PAYMENT_CLAIM: (claimId: string) => `/claimCases/${claimId}/payments`,
  CLAIM_CASE_FILE: (type: string, claimCaseId?: string, policyId?: string, planId?: string, isWithTrashed?: boolean) =>
    `/Files?type=${type}${(claimCaseId && `&claimCaseId=` + claimCaseId) || ""}${
      (policyId && `&policyId=` + policyId) || ""
    }${(planId && `&planId=` + planId) || ""}${(isWithTrashed && `&isWithTrashed=` + isWithTrashed) || ""}`,
  CLAIM_CASE_DOCUMENT_DETAIL: (claimCaseId: string, documentId: string) =>
    `/claimCases/${claimCaseId}/documents/${documentId}`,
  CLAIM_CASE_CLAIM_REQUEST: (claimCaseId: string) => `/claimCases/${claimCaseId}/claimRequest`,
  DOCUMENT_CLAIM_CASE: (claimCaseId: string) => `/claimCases/${claimCaseId}/documents`,
  MEDICAL_PROVIDER_DETAIL: (id: string) => `/medicalProviders/${id}`,

  //Options
  MEDICAL_PROVIDER: (search: string, limit?: number) =>
    `/MedicalProviders?search=${search}&limit=${LIMIT_MEDICAL_PROVIDER_LIST}`,
  METADATA: (type: string, search: string, limit?: number) =>
    `/Metadata?Type=${type}&Search=${search}${limit ? `&limit=${limit}` : ""}`,
  IMPORT: "/imports",
  PAYMENT_CLAIM: (claimId: string) => `/claims/${claimId}/payment`,
  DOCUMENT_CLAIM: (claimId: string) => `/claims/${claimId}/documents`,
  DOCUMENT_DETAIL: (claimId: string, documentId: string) => `/claims/${claimId}/documents/${documentId}`,
  TEMPLATE: (template: string, type: string, claimId: string, isPresentCase?: boolean) =>
    `/templates/${template}?type=${type}&claimRequestId=${claimId}${isPresentCase ? "&isPresentCase=true" : ""}`,
  SEND_CORESPONDENT: (claimId: string, isEmail = false) => `/claims/${claimId}/${isEmail ? "emails" : "sms"}`,
}

export default endPoints
