import React, {useCallback, useState} from "react"
import {gql, useMutation, useQuery} from "@apollo/client"
import {message, Modal} from "antd"
import _ from "lodash"

import {listSchema, createUserFormSchema} from "./schema"
import styles from "./UserScreen.module.css"
import TableList from "app/common/components/TableList"
import BaseForm from "app/common/components/Form"

const USERS_QUERY = gql`
  query IdentityUsers {
    identity_users(order_by: {created_at: desc}) {
      user_id
      name
      email
      phone
      created_at
      updated_at
      last_login_at
      status
      source
      roles(where: {deleted_at: {_is_null: true}}) {
        role
        company_role {
          company {
            name
          }
          role
        }
      }
    }
  }
`

const UPDATE_USER_QUERY = gql`
  mutation updateUser($userId: uuid!, $object: identity_users_set_input!) {
    update_identity_users_by_pk(pk_columns: {user_id: $userId}, _set: $object) {
      user_id
      name
    }
  }
`

const CREATE_USER_QUERY = gql`
  mutation register(
    $email: String
    $phone: String
    $name: String!
    $password: String
    $roles: [String]
    $source: String
  ) {
    response: register(email: $email, phone: $phone, name: $name, password: $password, roles: $roles, source: $source) {
      success
      token
      message
      user_id
    }
  }
`

const UserScreen = () => {
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false)
  const [user, setUser] = useState({})

  const {data, loading, refetch} = useQuery<{identity_users: IdentityUsers[]}>(USERS_QUERY, {
    context: {
      headers: {
        "x-hasura-role": "admin",
      },
    },
    nextFetchPolicy: "cache-and-network",
  })
  const [updateUser] = useMutation<{update_identity_users_by_pk: IdentityUsers}>(UPDATE_USER_QUERY, {
    context: {
      headers: {
        "x-hasura-role": "admin",
      },
    },
    onCompleted: ({update_identity_users_by_pk: {name}}) => {
      message.success(`Cập nhật ${name} thành công`)
      refetch()
    },
  })
  const [createUser] = useMutation<{response: {success: boolean; message: string}}>(CREATE_USER_QUERY, {
    context: {
      headers: {
        "x-hasura-role": "admin",
      },
    },
    onCompleted: (data) => {
      if (data.response.success) {
        message.success(`Tạo tài khoản thành công`)
        refetch()
      } else {
        message.error(`Tạo tài khoản không thành công. Lỗi: ${data.response.message}`)
      }
    },
    onError: (error) => message.error(`Tạo tài khoản không thành công. Lỗi: ${error}`),
  })

  const handleChange = (key: string, value: string) => {
    setUser((prevState) => ({...prevState, [key]: value}))
  }

  const onSave = useCallback(() => {
    createUser({variables: {...user, source: "phone"}})
  }, [user, createUser])

  return (
    <div>
      <TableList
        title={"Danh sách người dùng Papaya"}
        onView={() => {}}
        schema={[
          ...listSchema,
          {
            title: "Action",
            render: (_, record: IdentityUsers) => {
              if (record.status === "Active") {
                return (
                  <a onClick={() => updateUser({variables: {userId: record.user_id, object: {status: "Disabled"}}})}>
                    Disable
                  </a>
                )
              } else {
                return (
                  <a onClick={() => updateUser({variables: {userId: record.user_id, object: {status: "Active"}}})}>
                    Activate
                  </a>
                )
              }
            },
          },
        ]}
        loading={loading}
        data={data?.identity_users}
        rowKey={(record: IdentityUsers) => record.user_id}
      />
      <Modal
        title=""
        centered
        visible={showCreateUserModal}
        width={900}
        onCancel={() => setShowCreateUserModal(false)}
        footer={[]}
      >
        <BaseForm
          title={""}
          className={styles.containerForm}
          schemas={createUserFormSchema}
          loading={loading}
          onChange={(key: string, value: string) => handleChange(key, value)}
          data={data || {}}
          onSave={() => onSave()}
          onCancel={() => setShowCreateUserModal(false)}
        />
      </Modal>
    </div>
  )
}

export default UserScreen
