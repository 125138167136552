import React, {useState} from "react"
import styles from "./SideBar.module.css"
import {Link} from "react-router-dom"
import {IMenu} from "app/portal/config/sidebar"
import {useLocation} from "react-router"

interface IProps {
  menus: IMenu[]
}

const SideBar = (props: IProps) => {
  const {menus} = props
  const [toggle, setToggle] = useState<boolean>(false)
  const location = useLocation()

  const toggleSidebar = () => {
    setToggle(!toggle)
  }

  return (
    <div className={[styles.container, (toggle && styles.toggle + " hideSidebar") || ""].join(" ")}>
      <div className={styles.toggleIcon} onClick={() => toggleSidebar()}>
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 1.40161L7 1.40161" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M17 6.20288L1 6.20288" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M17 11.0042L11 11.0042"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={styles.logo}>Papaya</div>
      <div className={styles.belowLogoContainer}>
        <div className={styles.menuContainer}>
          {menus &&
            menus.length &&
            menus.map((menu) => (
              <div key={menu.key}>
                {(menu && menu.isHeader && (
                  <div key={menu.key} className={styles.menuHeader}>
                    {menu.title}
                  </div>
                )) || (
                  <div
                    className={[
                      styles.menuItem,
                      (location.pathname.includes(menu.path) && styles.active) || "",
                      (menu.icon && styles[menu.icon]) || "",
                    ].join(" ")}
                  >
                    <Link to={menu.path} key={menu.key}>
                      {menu.title}
                    </Link>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className={styles.copyright}>
          © {new Date().getFullYear()} <strong>Papaya Insurtech</strong>
        </div>
      </div>
    </div>
  )
}

export default SideBar
