import styles from "./WaitingTime.module.css"
import { Col, Row, Space } from "antd"
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"
import React from "react"

const WaitingTime = (props: {
  waitingType?: {
    description: string
    isInWaitingTime: boolean
    value: number
  }[]
}) => {
  const { waitingType } = props
  return waitingType && waitingType.length ? (
    <Space className={styles.waitingTime} direction={"vertical"} size={15}>
      <h3>Thời gian chờ</h3>
      <div className={styles.waitingTimeContainer}>
        {(waitingType.length
          ? waitingType.map((waitingTime) => (
              <Space
                key={waitingTime.description}
                direction={"vertical"}
                className={styles.waitingTimeItem}
              >
                <Row>
                  <Col span={24} className={styles.description}>
                    {waitingTime.description}
                  </Col>
                </Row>

                <Row className={styles.contentContainer}>
                  <Col span={24}>
                    <label>Thời gian: </label>
                  </Col>
                  <span>{waitingTime.value} ngày</span>
                </Row>
                <Row className={styles.contentContainer}>
                  <Col span={24}>
                    <label>Trong thời gian chờ :</label>
                  </Col>
                  <Col span={24}>
                    {(waitingTime.isInWaitingTime && (
                      <>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                        <span style={{ color: "#52c41a", fontWeight: "bold" }}>
                          Có
                        </span>
                      </>
                    )) || (
                      <>
                        <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
                        <span
                          style={{
                            color: "var(--red-alert)",
                            fontWeight: "bold",
                          }}
                        >
                          Không
                        </span>
                      </>
                    )}
                  </Col>
                </Row>
              </Space>
            ))
          : "") || ""}
      </div>
    </Space>
  ) : (
    <div />
  )
}

export default WaitingTime
