import React, {useEffect, useRef} from "react"
import styles from "./DragAndDrop.module.css"

interface IProps {
  handleDrop: (any) => void
}

const DragAndDrop = (props: IProps) => {
  const dropRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const inputRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
    }
  }

  useEffect(() => {
    const div = dropRef.current
    if (div) {
      div.addEventListener("dragenter", handleDragIn)
      div.addEventListener("dragleave", handleDragOut)
      div.addEventListener("dragover", handleDrag)
      div.addEventListener("drop", handleDrop)
    }
    return function cleanup() {
      const div = dropRef.current
      if (div) {
        div.removeEventListener("dragenter", handleDragIn)
        div.removeEventListener("dragleave", handleDragOut)
        div.removeEventListener("dragover", handleDrag)
        div.removeEventListener("drop", handleDrop)
      }
    }
  })

  const openUpload = () => {
    inputRef.current?.click()
  }
  const handleChangeInput = (e) => {
    if (e.target.files) {
      props.handleDrop(e.target.files)
    }
  }
  return (
    <div className={styles.dropZone} ref={dropRef} onClick={() => openUpload()}>
      <div className={styles.dropZoneLabel}>
        <div className={styles.image} />
        <div className={styles.label}>
          Thả hình ảnh muốn tải lên vào đây, hoặc <span>duyệt file trên máy tính</span>
        </div>
        <div className={styles.subLabel}>Hỗ trợ: JPG, PNG, PDF</div>
      </div>
      <input
        ref={inputRef}
        name="file"
        type="file"
        id="imgUpload"
        style={{display: "none"}}
        multiple
        accept="image/*,application/pdf"
        onChange={handleChangeInput}
      />
    </div>
  )
}
export default DragAndDrop
