import styles from "./RemarkCopay.module.css"
import { Col, Row } from "antd"
import React from "react"

const RemarkCopay = (props: { remarkCopay?: string }) => {
  const { remarkCopay } = props
  return remarkCopay ? (
    <div className={styles.remarkCopay}>
      <h3>Đồng chi trả</h3>
      <Row className={styles.remarkCopayItem}>
        <Col span={24}>{remarkCopay}</Col>
      </Row>
    </div>
  ) : (
    <div />
  )
}

export default RemarkCopay
