import React, {useState} from "react"
import styles from "./MedicalProviderScreen.module.css"
import TableList from "app/common/components/TableList"
import {PORTAL_PATH} from "app/portal/config/routes"
import {Input, message, notification} from "antd"
import {CheckCircleTwoTone, CloseCircleTwoTone, WarningOutlined} from "@ant-design/icons"
import OptionListServices from "services/OptionListServices"
import {METADATA, QUERY_LIMIT, SETTINGS, TYPE} from "config/constants"
import _ from "lodash"
import {useHistory} from "react-router-dom"
import {useLocation} from "react-router"
import AdminServices from "services/AdminServices";
import {useDebouncedEffect, useMountEffect} from "@react-hookz/web"
import useQueryParams from "app/common/hooks/useQueryParam";
import {useSelector} from "react-redux";
import {TypesType} from "store/interface";
import {utils} from "../../../../../config/utils";

interface IMedicalProviderListData {
  medicalProviderId?: string
  name?: string
  address?: string
  phone?: string
  email?: string
  province?: string
  description?: string
  type?: string
  isDirectBilling?: boolean
}

const MedicalProviderScreen = () => {
  const [loading, setLoading] = useState(true)
  const [medicalProviderListData, setMedicalProviderListData] = useState<IMedicalProviderListData[]>([])
  const queryParams = useQueryParams()
  const [percent, setPercent] = useState<number>(0)
  const [search, setSearch] = useState<string>(queryParams.get("search") || "")
  const [cityList, setCityList] = useState([])
  const medicalProviderType = useSelector(
      (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.MEDICAL_PROVIDER_TYPE],
  )
  const history = useHistory()
  const location = useLocation()

  const getMedicalProviderList = async (search: string) => {
    const medicalProviderList = await AdminServices.getMedicalProviders(search, QUERY_LIMIT)
    if (medicalProviderList.isSuccess && medicalProviderList.data.collection) {
      setMedicalProviderListData(medicalProviderList.data.collection)
      setLoading(false)
    }
  };
  
  const getFirstLoad = async () => {
    const city = await OptionListServices.getMetadata(METADATA.CITY)
    if (city && city.data && city.data.collection) {
      setCityList(city.data.collection)
    }
  }

  useMountEffect(() => {
    getMedicalProviderList("").then()
    getFirstLoad().then()
  })

  const importMedicalProviderByCSV = async (e) => {
    const file: File = e.target.files[0]
    const uploadFile = await utils.base64ToFile(file, file.name)
    const response = await AdminServices.importData(
      uploadFile,
      TYPE.IMPORT_TYPE.MedicalProvider,
      (percent: number) => {
        setPercent(percent)
        if (percent === 100) {
          setPercent(0)
        }
      },
    )
    if (response.isSuccess) {
      await getMedicalProviderList("")
      for (let i = 0; i < response.data.length; i++) {
        setTimeout(() => {
          notification.warning({
            message: "Import info",
            description: response.data[i],
            icon: <WarningOutlined style={{color: "orange"}} />,
          })
        }, 1200 * i)
      }
    } else {
      setPercent(100)
      message.error(response.messages)
    }
  }

  useDebouncedEffect(
      () => {
        if (search) {
          getMedicalProviderList(search).then(() => {
            history.replace(`${location.pathname}?search=${search}`)
          })
        }
        else{
          getMedicalProviderList("").then(() => {
            history.replace(`${location.pathname}`)
          })
        }
      },
      [search],
      SETTINGS.SEARCH_DEBOUNCE_TIME,
  )
  
  const listSchema = [
    {
      title: "Tên bệnh viện",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      render: (text: boolean) => text || "---"
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      render: (text: boolean) => text || "---"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: boolean) => text || "---"
    },
    {
      title: "Tỉnh thành",
      dataIndex: "province",
      key: "province",
      filters:
        cityList && cityList.length
          ? cityList.map((b: {name: string; value: string}) => ({
              text: b.name,
              value: b.value,
            }))
          : [],
      onFilter: (value, record) => record.province === value,
      render: (text: string) => <span>{_.find(cityList, (b: {value: string}) => b.value === text)?.value || "---"}</span>,
    },
    {
      title: "Loại",
      dataIndex: "type",
      key: "type",
      filters: medicalProviderType.map(medicalProvider => ({
        text: medicalProvider.name,
        value: medicalProvider.value
      })) || [],
      onFilter: (value, record) => record.type === value,
      render: (text: string) => (
        <span>{_.find(medicalProviderType, (b: {value: string}) => b.value === text)?.name || ""}</span>
      ),
    },
    {
      title: "Bảo lãnh viện phí",
      dataIndex: "isDirectBilling",
      key: "isDirectBilling",
      width: 200,
      filters: [
        {
          text: "Có",
          value: true,
        },
        {
          text: "Không",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isDirectBilling === value,
      render: (text: boolean) => (
        <span>
          {(text && (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
              <span
                style={{
                  color: "#52c41a",
                  fontWeight: "bold",
                }}
              >
                Có
              </span>
            </>
          )) || (
            <>
              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
              <span
                style={{
                  color: "var(--red-alert)",
                  fontWeight: "bold",
                }}
              >
                Không
              </span>
            </>
          )}
        </span>
      ),
    },
  ]

  return (
    <div>
      <Input.Search
        className={styles.search}
        size={"large"}
        placeholder={"Tìm kiếm bệnh viện"}
        onChange={(e) => setSearch(e.target.value)}
      />
      <TableList
        title={"bệnh viện"}
        onCreateTitle={"Tạo mới"}
        onCreate={() => {
          history.push(PORTAL_PATH.MEDICAL_PROVIDER_CREATE)
        }}
        onView={(record: IMedicalProviderListData) => history.push(`${location.pathname}/${record.medicalProviderId}`)}
        onImport={(e) => importMedicalProviderByCSV(e)}
        schema={listSchema}
        loading={loading}
        percent={percent}
        data={
          medicalProviderListData.map((med) => ({
            ...med,
            key: med.medicalProviderId,
          }))
        }
      />
    </div>
  )
}

export default MedicalProviderScreen
