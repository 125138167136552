import {LOGOUT, SET_TOKEN} from '../types'
import {SetTokenAction} from '../interface'

export default function setToken(token: string): SetTokenAction {
  return {
    type: SET_TOKEN,
    token: token
  };
}


export function logout() {
  return {
    type: LOGOUT
  };
}