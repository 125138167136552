import _ from "lodash"
import {SET_TYPES} from "store/types"
import {SetTypesAction, TypesType} from "../interface"

const initialType = (): TypesType => {
  return JSON.parse(JSON.stringify(localStorage.getItem("types")))
}

export default function types(state = initialType() || {}, action: SetTypesAction) {
  let newState = _.clone(state)
  if (action.type === SET_TYPES) {
    newState = action.types
  }
  return newState
}
