import {QuestionCircleTwoTone, RightSquareOutlined, ToolTwoTone} from "@ant-design/icons"
import {Col, Collapse, DatePicker, Input, InputNumber, Popover, Row, Select, Spin, Tag, Tooltip} from "antd"
import styles from "./DetailForm.module.css"
import React, {useCallback, useEffect, useState} from "react"
import {FORMATTER, KEYS} from "config/constants"
import moment from "moment"
import ListOptionsServices from "services/OptionListServices"
import TableList from "app/common/components/TableList"
import {ClaimInfo} from "../../index"
import _ from "lodash"

const {Panel} = Collapse
const {Option} = Select
const {RangePicker} = DatePicker

const DetailForm = (props: {
  loading: boolean
  data?: ClaimInfo
  onChange: (key: string, value: any, name?: string) => void
}) => {
  const {loading, onChange, data} = props
  const [loadingICDCode, setLoadingICDCode] = useState<boolean>(false)
  const [diseasesOptions, setDiseasesOptions] = useState<{name: string; value: string}[]>([])
  const [dateRangeMode, setDateRangeMode] = useState<boolean>(false)

  useEffect(() => {
    if (data?.eventDateRanges) {
      setDateRangeMode(true)
    }
  }, [data?.eventDateRanges])

  // GET METADATA
  const getDisease = useCallback(async (search = "") => {
    setLoadingICDCode(true)
    const response = await ListOptionsServices.getDisease(search)
    if (response.isSuccess) {
      setDiseasesOptions(response.data.collection)
      setLoadingICDCode(false)
    }
  }, [])
  const searchSearchDisease = _.debounce(getDisease, 600)
  useEffect(() => {
    getDisease().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const tagRender = (props: any) => {
    // eslint-disable-next-line react/prop-types
    const {label, onClose} = props
    return (
      <Tag color={"blue"} closable={true} onClose={onClose} className={styles.tag}>
        {label}
      </Tag>
    )
  }

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
    >
      <Panel header="Thông tin điều trị" key="1">
        <Spin spinning={loading}>
          <Row className={styles.infoInputContainer}>
            <Col span={8}>
              <label
                style={{cursor: "pointer"}}
                onClick={() => {
                  setDateRangeMode(!dateRangeMode)
                }}
              >
                Ngày xảy ra sự kiện bảo hiểm <ToolTwoTone style={{fontSize: "18px"}} />
              </label>
              {
                <RangePicker
                  showTime
                  format={FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC}
                  size={"large"}
                  className={styles.datePicker}
                  placeholder={["Nhập khoảng ngày", "Nhập khoảng ngày"]}
                  onChange={(value) => {
                    onChange(
                      KEYS.CREATE_CLAIM.CLAIM_INFO,
                      [moment(value && value[0]), moment(value && value[1])],
                      KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.EVENT_DATE_RANGE,
                    )
                  }}
                  value={
                    (data?.eventDateRanges &&
                      moment(data?.eventDateRanges[0], FORMATTER.API_DATE_FORMAT).isValid() &&
                      moment(data?.eventDateRanges[1], FORMATTER.API_DATE_FORMAT).isValid() && [
                        moment(data?.eventDateRanges[0], FORMATTER.API_DATE_FORMAT),
                        moment(data?.eventDateRanges[1], FORMATTER.API_DATE_FORMAT),
                      ]) ||
                    undefined
                  }
                />
              }
            </Col>
            <Col span={8}>
              <label>Phương thức điều trị</label>
              <Input
                onChange={(e) =>
                  onChange(
                    KEYS.CREATE_CLAIM.CLAIM_INFO,
                    e.target.value,
                    KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.TREATMENT_METHOD,
                  )
                }
                value={data?.treatmentMethod}
                size={"large"}
                placeholder="Nhập phương thức điều trị"
              />
            </Col>
            <Col span={8}>
              <Popover
                placement="leftTop"
                trigger="click"
                content={
                  <div className={styles.popOverContainer}>
                    <TableList
                      title={"Mã ICD đã chọn"}
                      onView={() => {}}
                      data={data?.diseases}
                      search={["value", "label"]}
                      schema={[
                        {
                          title: "Mã",
                          dataIndex: "value",
                          key: "code",
                          render: (text: string) => <Tag color={"geekblue"}>{text}</Tag>,
                        },
                        {
                          title: "Tên",
                          dataIndex: "label",
                          key: "name",
                        },
                      ]}
                    />
                  </div>
                }
              >
                <Tooltip mouseEnterDelay={0.5} mouseLeaveDelay={0.5} placement={"topLeft"} title={"Xem danh sách chọn"}>
                  <label style={{cursor: "pointer"}}>
                    ICD Code <QuestionCircleTwoTone style={{fontSize: "18px"}} twoToneColor="#eb2f96" />
                  </label>
                </Tooltip>
              </Popover>

              <Select
                className={styles.autoComplete}
                mode="multiple"
                autoClearSearchValue={true}
                size={"large"}
                maxTagCount="responsive"
                labelInValue
                filterOption={false}
                placeholder="Nhập ICD code"
                notFoundContent={loadingICDCode ? <Spin size="small" /> : null}
                tagRender={(props) => tagRender(props)}
                onChange={(value) =>
                  onChange(KEYS.CREATE_CLAIM.CLAIM_INFO, value, KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.DISEASES)
                }
                value={data?.diseases || undefined}
                onSearch={(search: string) => searchSearchDisease(search)}
              >
                {diseasesOptions.length &&
                  diseasesOptions.map((disease: {name: string; value: string}) => (
                    <Option key={disease.value} value={disease.value}>
                      {`${disease.value} - ${disease.name}`}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={8}>
              <label>Chẩn đoán bệnh</label>
              <Input
                onChange={(e) =>
                  onChange(KEYS.CREATE_CLAIM.CLAIM_INFO, e.target.value, KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.DIAGNOSIS)
                }
                size={"large"}
                value={data?.diagnosis}
                placeholder="Nhập chẩn đoán bệnh"
              />
            </Col>
            <Col span={8}>
              <label>Số tiền yêu cầu</label>
              <InputNumber
                onChange={(value) =>
                  onChange(KEYS.CREATE_CLAIM.CLAIM_INFO, value, KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.REQUEST_AMOUNT)
                }
                className={styles.inputNumber}
                size={"large"}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                value={(data && data.requestAmount) || undefined}
                placeholder="Nhập số tiền yêu cầu"
              />
            </Col>
          </Row>
        </Spin>
      </Panel>
    </Collapse>
  )
}

export default DetailForm
