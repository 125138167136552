import React, {useState} from "react"
import {Button, Col, Input, message, Row, Select, Tag} from "antd"
import {FORMATTER} from "config/constants"
import {utils} from "config/utils"
import {gql, useLazyQuery} from "@apollo/client"
import styles from "./HospitalCertificatesScreen.module.css"
import {useParams} from "react-router-dom"
import {useHistory} from "react-router"
import TableList from "../../../../common/components/TableList"
import {PORTAL_PATH} from "../../../config/routes"
import {useEventListener} from "@react-hookz/web"

const {Option} = Select

enum FilterType {
  PolicyNumberAndInsuredPersonName = "policy_number_and_insured_person_name",
  InsuredPersonPaperIdAndInsuredCertificateCode = "insured_person_paper_id_and_insured_certificate_code",
  InsuredPersonPaperIdAndInsuredPersonPhone = "insured_person_paper_id_and_insured_person_phone",
}

const GET_CERTIFICATES = gql`
  query (
    $searchBy: search_by_enum!
    $insurerId: String
    $policyNumber: String
    $insuredPersonName: String
    $insuredPersonPaperId: String
    $insuredCertificateCode: String
    $insuredPersonPhone: String
  ) {
    search_certificates(
      search_by: $searchBy
      insurer_id: $insurerId
      policy_number: $policyNumber
      insured_person_name: $insuredPersonName
      insured_person_paper_id: $insuredPersonPaperId
      insured_certificate_code: $insuredCertificateCode
      insured_person_phone: $insuredPersonPhone
    ) {
      success
      message
      insured_certificates {
        certificate_code
        created_at
        deleted_at
        dued_at
        email
        insured_certificate_id
        insured_person_id
        is_voluntary
        issued_at
        parent_insured_certificate_id
        plan_id
        policy_id
        updated_at
        policy {
          broker_id
          corporate_id
          created_at
          deleted_at
          dued_at
          insured_person_id
          insurer_id
          issued_at
          policy_id
          policy_number
          remark_copay
          tpa_id
          types
          updated_at
        }
        insured_person {
          created_at
          deleted_at
          dob
          email
          gender
          insured_number
          insured_person_id
          name
          notes
          paper_expired_at
          paper_id
          paper_issued_at
          paper_issued_place
          phone
          updated_at
          user_id
        }
      }
    }
  }
`

const listSchema = [
  {
    title: "Tên NĐBH",
    dataIndex: "name",
    key: "name",
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: "Hiệu lực",
    dataIndex: "issuedDued",
    key: "issuedDued",
    render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_FORMAT),
  },
  {
    title: "Mã thẻ",
    dataIndex: "certificateCode",
    key: "certificateCode",
  },
  {
    title: "Số hợp đồng",
    dataIndex: "policyNumber",
    key: "policyNumber",
  },
]

interface IData {
  name: string
  issuedDued: string
  certificateId: string
  certificateCode: string
  policyNumber: string
  types: string
  key: string
}

const HospitalCertificatesScreen = () => {
  const [filterType, setFilterType] = useState<string>(FilterType.InsuredPersonPaperIdAndInsuredCertificateCode)
  const [policyNumber, setPolicyNumber] = useState<string>("")
  const [personName, setPersonName] = useState<string>("")
  const [paperId, setPaperId] = useState<string>("")
  const [certificateCode, setCertificateCode] = useState<string>("")
  const [personPhone, setPersonPhone] = useState<string>("")
  const [data, setData] = useState<IData[]>([])

  const history = useHistory()
  const {insurerId} = useParams<{insurerId: string}>()

  const [getCertificateList, {loading}] = useLazyQuery(GET_CERTIFICATES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (dataSearchResult) => {
      let newData = []
      if (
        dataSearchResult &&
        dataSearchResult.search_certificates &&
        dataSearchResult.search_certificates.insured_certificates
      ) {
        if (dataSearchResult.search_certificates.insured_certificates.length) {
          newData = dataSearchResult.search_certificates.insured_certificates.map((item) => ({
            name: item.insured_person.name,
            issuedDued: item.policy.issued_at + "&" + item.policy.dued_at,
            certificateCode: item.certificate_code,
            certificateId: item.insured_certificate_id,
            policyNumber: item.policy.policy_number,
            types: item.policy.types,
            key: item.insured_certificate_id,
          }))
        } else {
          message.warn("Không tìm thấy kết quả, hãy thử lại").then()
        }
        setData(newData)
      }
    },
  })

  const onSearch = () => {
    getCertificateList({
      variables: {
        searchBy: filterType,
        insurerId: insurerId,
        policyNumber: policyNumber.trim(),
        insuredPersonPaperId: paperId.trim(),
        insuredPersonPhone: personPhone.trim(),
        insuredCertificateCode: certificateCode.trim(),
      },
    })
  }

  const isAvailableForSearch = () => {
    switch (filterType) {
      case FilterType.InsuredPersonPaperIdAndInsuredPersonPhone: {
        return !!(paperId && personPhone)
      }
      case FilterType.InsuredPersonPaperIdAndInsuredCertificateCode: {
        return !!(paperId && certificateCode)
      }
      case FilterType.PolicyNumberAndInsuredPersonName: {
        return !!(policyNumber && personName)
      }
      default:
        return false
    }
  }

  useEventListener(
    window,
    "keypress",
    (e) => {
      if (e.key === "Enter" && isAvailableForSearch()) {
        onSearch()
      }
    },
    {passive: true},
  )

  const resetFields = () => {
    setPolicyNumber("")
    setCertificateCode("")
    setPaperId("")
    setPersonName("")
    setPersonPhone("")
    setData([])
  }

  return (
    <div className={styles.container}>
      <h2>Vui lòng nhập thông tin khách hàng</h2>
      <label>Loại thông tin</label>
      <Select
        className={styles.selectType}
        size={"large"}
        value={filterType}
        placeholder="Chọn loại thông tin muốn tìm kiếm"
        onChange={(value) => setFilterType(value)}
      >
        <Option value={FilterType.PolicyNumberAndInsuredPersonName}>Số HĐ + Tên NĐBH</Option>
        <Option value={FilterType.InsuredPersonPaperIdAndInsuredCertificateCode}>CMND/CCCD/HC/GKS + Số thẻ</Option>
        <Option value={FilterType.InsuredPersonPaperIdAndInsuredPersonPhone}>CMND/CCCD/HC/GKS + Số điện thoại</Option>
      </Select>
      {filterType ? (
        <Row className={styles.row} gutter={10}>
          <Col span={6}>
            <label>{filterType === FilterType.PolicyNumberAndInsuredPersonName ? "Số HĐ" : "CMND/CCCD/HC/GKS"}</label>
            <Input
              size="large"
              type="text"
              value={filterType === FilterType.PolicyNumberAndInsuredPersonName ? policyNumber : paperId}
              placeholder="Điền tại đây"
              onChange={(e) => {
                filterType === FilterType.PolicyNumberAndInsuredPersonName
                  ? setPolicyNumber(e.target.value)
                  : setPaperId(e.target.value)
              }}
            />
          </Col>
          <Col span={6}>
            <label>
              {filterType === FilterType.PolicyNumberAndInsuredPersonName
                ? "Tên NĐBH"
                : filterType === FilterType.InsuredPersonPaperIdAndInsuredPersonPhone
                ? "Số điện thoại"
                : filterType === FilterType.InsuredPersonPaperIdAndInsuredCertificateCode
                ? "Số thẻ"
                : ""}
            </label>
            <Input
              size="large"
              type={filterType === FilterType.InsuredPersonPaperIdAndInsuredPersonPhone ? "number" : "text"}
              value={
                filterType === FilterType.PolicyNumberAndInsuredPersonName
                  ? personName
                  : filterType === FilterType.InsuredPersonPaperIdAndInsuredPersonPhone
                  ? personPhone
                  : certificateCode
              }
              placeholder="Điền tại đây"
              onChange={(e) => {
                filterType === FilterType.PolicyNumberAndInsuredPersonName
                  ? setPersonName(e.target.value)
                  : filterType === FilterType.InsuredPersonPaperIdAndInsuredPersonPhone
                  ? setPersonPhone(e.target.value)
                  : setCertificateCode(e.target.value)
              }}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Button
        disabled={!isAvailableForSearch()}
        type="primary"
        size="large"
        className={styles.searchBtn}
        onClick={() => onSearch()}
      >
        Tìm kiếm
      </Button>
      <Button type="default" size="large" className={styles.searchBtn} onClick={() => resetFields()}>
        Reset
      </Button>

      <div className={styles.infoRow}>
        <h2>Thông tin khách hàng</h2>
      </div>
      {data && data.length ? (
        <>
          <div className={styles.stats}>
            <span>
              <b>{filterType === FilterType.PolicyNumberAndInsuredPersonName ? "Số HĐ" : "CMND/CCCD/HC/GKS"}</b>:{" "}
              {filterType === FilterType.PolicyNumberAndInsuredPersonName ? policyNumber : paperId}
            </span>
            <span>
              <b>
                {filterType === FilterType.PolicyNumberAndInsuredPersonName
                  ? "Tên NĐBH"
                  : filterType === FilterType.InsuredPersonPaperIdAndInsuredPersonPhone
                  ? "Số điện thoại"
                  : filterType === FilterType.InsuredPersonPaperIdAndInsuredCertificateCode
                  ? "Số thẻ"
                  : ""}
              </b>
              :{" "}
              {filterType === FilterType.PolicyNumberAndInsuredPersonName
                ? personName
                : filterType === FilterType.InsuredPersonPaperIdAndInsuredPersonPhone
                ? personPhone
                : certificateCode}
            </span>
          </div>
          <h4>Tìm thấy khách hàng có {data.length} thẻ bảo hiểm</h4>
        </>
      ) : (
        ""
      )}
      <TableList
        title={""}
        onView={(record) => {
          const path = PORTAL_PATH.HOSPITAL_CERTIFICATES_BENEFIT
          history.push(path.replace(":certificateId", record.certificateId))
        }}
        schema={listSchema}
        loading={loading}
        data={data}
        hidePaginate
      />
    </div>
  )
}

export default HospitalCertificatesScreen
