import Portal from "./portal"
import LoginScreen from "./common/screens/LoginScreen"
import NotFoundScreen from "./common/screens/404NotFoundScreen"

export const ROUTES_PATH = {
  LOGIN_SCREEN: "/login",
  PORTAL: "/portal",
  NOTFOUND: "/404",
}

export interface IRoute {
  key: string
  path: string
  comp: (T) => JSX.Element | JSX.Element
  exact?: boolean
  title: string
}

const routes: IRoute[] = [
  {
    key: "portal",
    path: ROUTES_PATH.PORTAL,
    comp: Portal,
    title: "Dashboard Screen",
  },
  {
    key: "login",
    path: ROUTES_PATH.LOGIN_SCREEN,
    comp: LoginScreen,
    exact: true,
    title: "Login Screen",
  },
  {
    key: "404",
    path: ROUTES_PATH.NOTFOUND,
    comp: NotFoundScreen,
    title: "404 Not Found",
  },
]

export default routes
