import React, {useCallback, useEffect, useState} from "react"
import styles from "./ClaimScreen.module.css"
import {useParams} from "react-router-dom"
import InsuredInfo from "./components/InsuredInfo"
import ClaimServices from "../../../../../../../services/ClaimServices"
import DetailForm from "./components/DetailForm"
import {Button, message, Modal, Select, Space} from "antd"
import HospitalServices from "../../../../../../../services/HospitalServices"
import {KEYS, TYPE} from "../../../../../../../config/constants"
import _ from "lodash"
import {gql, useQuery} from "@apollo/client"
import MetaDataClaimInfo from "./components/MetaDataClaimInfo"
import moment from "moment"
import {utils} from "../../../../../../../config/utils"
import {PORTAL_PATH} from "../../../../../config/routes"
import {useHistory} from "react-router"
import ButtonColor from "antd-button-color"
import {CheckOutlined, EditTwoTone, PlusOutlined} from "@ant-design/icons"
import DetailFormReadOnly from "./components/DetailFormReadOnly"
import MetaDataClaimInfoReadOnly from "./components/MetaDataClaimInfoReadOnly"
import DocumentData from "./components/DocumentData"
import SignOff from "./components/SignOff"
import UploadComponent from "../../../../../../common/components/UploadComponent/UploadComponent"
import FileService from "../../../../../../../services/FileServices"
import caolan from "async"

const {Option} = Select

const GET_METADATA_CLAIM_INFO = gql`
  query {
    metadata(where: {type: {_eq: "claim_info"}}) {
      title
      value
    }
  }
`

export interface MetaDataClaimInfoInterface {
  name?: string
  value?: number | undefined
  defaultValue?: number
  isShow?: boolean
  claimInformationId?: string
}

export interface IDocumentData {
  id: string
  url: string
  type: string
  source: string
  displayName: string
  fileName: string
  isImage: boolean
  isTrash: boolean
}

export interface InsuredInfoData {
  insurer: string
  insuredPersonId: string
  insuredPersonName: string
  insuredPersonParentName: string
  effectiveDueDate: string
  // benefitType: string,
  // certIssuedAt: string,
  // certDuedAt: string
}

export interface ClaimInfo {
  benefitType: string
  claimNumber: string
  certificateId: string
  diagnosis: string
  diseases:
    | {
        name?: string
        key: string
        value: string
        label: string
      }[]
    | {key: string; value: string; label: string}[]
  eventDateRanges: string[]
  eventDates?: string
  treatmentMethod: string
  dueDate: string
  effectiveDate: string
  isDirectBilling: boolean
  requestAmount: number
  source: string
}

interface IClaimNote {
  content: string
  createdAt: string
  createdBy: string
  type: string
}

const ClaimScreen = () => {
  const [loadingCertificate, setLoadingCertificate] = useState<boolean>(false)
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false)
  const [fileList, setFileList] = useState<IDocumentData[]>()
  const [fileType, setFileType] = useState<string>("")
  const [showModalUpload, setShowModalUpload] = useState<boolean>(false)
  const [checkEdit, setCheckEdit] = useState<boolean>(false)
  const [loadingClaim, setLoadingClaim] = useState<boolean>(false)
  const [insuredInfoData, setInsuredInfoData] = useState<InsuredInfoData>()
  const [detailFormData, setDetailFormData] = useState<ClaimInfo>()
  const [documentData, setDocumentData] = useState<IDocumentData[]>()
  const [metaDataClaimInfoData, setMetaDataClaimInfoData] = useState<MetaDataClaimInfoInterface[]>([])
  const [claimNotes, setClaimNotes] = useState<IClaimNote[]>([])
  const [claimCaseId, setClaimCaseId] = useState("")
  const [loadingClaimNotes, setLoadingClaimNotes] = useState(false)
  const {certificateId, benefitType, claimId} =
    useParams<{certificateId: string; benefitType: string; claimId?: string}>()
  const history = useHistory()

  const {data: metaDataClaimInfo} = useQuery(GET_METADATA_CLAIM_INFO)

  const getInsuredInfoData = useCallback(async () => {
    setLoadingCertificate(true)
    if (certificateId) {
      const response = await ClaimServices.getCertificateDetail(certificateId)
      if (response.isSuccess) {
        setInsuredInfoData(response.data)
      }
    }
    setLoadingCertificate(false)
  }, [certificateId])

  const getClaimCasesList = useCallback(async () => {
    setLoadingClaimNotes(true)

    if (claimId) {
      const claimCasesRes = await ClaimServices.getClaimCaseList(claimId)
      const claimCaseId = claimCasesRes.data?.[0]?.claimCaseId

      if (claimCasesRes.isSuccess) {
        setClaimCaseId(claimCaseId)
      }
    }
  }, [claimId])

  const getClaimCaseDetail = useCallback(async () => {
    if (claimCaseId) {
      setLoadingClaimNotes(true)
      const claimCaseDetailRes = await ClaimServices.getClaimCaseDetail(claimCaseId)

      if (claimCaseDetailRes.isSuccess) {
        setClaimNotes(claimCaseDetailRes.data?.notes || [])
      }
      setLoadingClaimNotes(false)
    }
  }, [claimCaseId])

  const getDetailFormData = useCallback(async () => {
    setLoadingClaim(true)
    if (claimId) {
      const response = await HospitalServices.getClaimDetail(claimId, TYPE.SOURCE_TYPE.MEDICAL_PROVIDERS)
      if (response.isSuccess) {
        const newClaimInfo: ClaimInfo = response.data
        if (newClaimInfo && newClaimInfo.diseases) {
          newClaimInfo.diseases = newClaimInfo?.diseases.map((d) => ({
            key: d.value,
            label: d.name,
            value: d.value,
            name: d.name,
          }))
        }
        if (newClaimInfo.eventDates && newClaimInfo.eventDates.includes("&")) {
          newClaimInfo.eventDateRanges = [
            utils.parseLocalTime(newClaimInfo.eventDates.split("&")[0]),
            utils.parseLocalTime(newClaimInfo.eventDates.split("&")[1]),
          ]
        }
        setCheckEdit(false)
        setDetailFormData(newClaimInfo)
        setMetaDataClaimInfoData(response.data.claimInfos)
      }
    }
    setLoadingClaim(false)
  }, [claimId])

  const getMetaDataClaimInfo = useCallback(() => {
    if (metaDataClaimInfo && metaDataClaimInfo.metadata && benefitType) {
      const filterByBenefitType = metaDataClaimInfo.metadata.filter((item) => item.title === benefitType)
      const newDataForm: MetaDataClaimInfoInterface[] = []
      if (filterByBenefitType) {
        filterByBenefitType.forEach((item) => {
          const newObject: MetaDataClaimInfoInterface = {isShow: true}
          newObject.name = item.value
          newObject.value = undefined
          newDataForm.push(newObject)
        })
        setMetaDataClaimInfoData(newDataForm)
      }
    }
  }, [metaDataClaimInfo, benefitType])

  useEffect(() => {
    getMetaDataClaimInfo()
  }, [getMetaDataClaimInfo])

  useEffect(() => {
    getClaimCasesList()
  }, [getClaimCasesList])

  useEffect(() => {
    getClaimCaseDetail()
  }, [getClaimCaseDetail])

  const filterListPaperBySource = (fileList: IDocumentData[]) => {
    // return _.filter(fileList, {source: TYPE.SOURCE_TYPE.MEDICAL_PROVIDERS})
    return fileList
  }

  const getFileList = useCallback(async () => {
    setLoadingDocument(true)
    const response = await FileService.getFileList(TYPE.FILE_TYPE.CLAIM_DOCUMENT, claimId, "", "", true)
    if (response.data && response.isSuccess) {
      const newDataList = filterListPaperBySource(response.data.collection)
      setDocumentData(newDataList)
    }
    setLoadingDocument(false)
  }, [claimId])

  useEffect(() => {
    getInsuredInfoData().then()
    if (claimId) {
      getDetailFormData().then()
      getFileList().then()
    }
  }, [claimId, getInsuredInfoData, getDetailFormData, getFileList])

  const handleDetailFormData = async (key: string, value: [] | string | number, name?: string) => {
    let newDetailFormData = _.clone(detailFormData)
    if (!newDetailFormData) {
      newDetailFormData = {
        benefitType: benefitType,
        certificateId: certificateId,
        diagnosis: "",
        diseases: [],
        treatmentMethod: "",
        requestAmount: 0,
        eventDateRanges: [],
        isDirectBilling: true,
        source: TYPE.SOURCE_TYPE.MEDICAL_PROVIDERS,
        claimNumber: "",
        dueDate: "",
        effectiveDate: "",
      }
    }
    if (name) {
      newDetailFormData[name] = value
      setDetailFormData(newDetailFormData)
    }
  }

  const handleMetaDataClaimInfo = (data) => {
    const newData: MetaDataClaimInfoInterface[] = _.clone(metaDataClaimInfoData)
    if (newData && metaDataClaimInfoData) {
      const index = _.findIndex(newData, (item) => item.name === data.name)
      newData.splice(index, 1, data)
      setMetaDataClaimInfoData(newData)
    }
  }

  const handleSubmit = async (e) => {
    setLoadingClaim(true)
    e.preventDefault()
    if (detailFormData) {
      const claimRequestData: {
        certificateId: string
        benefitType: string
        isDirectBilling: boolean
        source: string
        eventDates: string
        diseases: string
        treatmentMethod: string
        requestAmount: number
        diagnosis: string
        insuredPersonId: string
      } = {
        certificateId: detailFormData.certificateId,
        benefitType: detailFormData.benefitType,
        isDirectBilling: true,
        source: TYPE.SOURCE_TYPE.MEDICAL_PROVIDERS,
        diseases: detailFormData.diseases.map((d) => d.value).join("|"),
        eventDates:
          moment.isDate(new Date(detailFormData.eventDateRanges[0])) &&
          moment.isDate(new Date(detailFormData.eventDateRanges[1]))
            ? `${utils.parseLocalTime(detailFormData.eventDateRanges[0])}&${utils.parseLocalTime(
                detailFormData.eventDateRanges[1],
              )}`
            : "",
        diagnosis: detailFormData.diagnosis,
        requestAmount: detailFormData.requestAmount,
        treatmentMethod: detailFormData.treatmentMethod,
        insuredPersonId: insuredInfoData?.insuredPersonId || "",
      }
      if (metaDataClaimInfoData) {
        const newMetaDataClaimInfoData: MetaDataClaimInfoInterface[] = []
        metaDataClaimInfoData.forEach((item) => {
          delete item.defaultValue
          delete item.isShow
          newMetaDataClaimInfoData.push(item)
        })
        if (checkEdit && claimId) {
          const responseUpdateClaim = await HospitalServices.updateClaimDetail(claimId, claimRequestData)
          if (responseUpdateClaim.isSuccess && responseUpdateClaim.data) {
            const responseUpdateClaimInfo = await HospitalServices.updateHospitalClaimInfo(
              claimId,
              newMetaDataClaimInfoData,
            )
            if (responseUpdateClaimInfo.isSuccess && responseUpdateClaimInfo.data) {
              setCheckEdit(false)
              message.success("Cập nhật yêu cầu bảo hiểm thành công")
              getDetailFormData().then()
            }
          }
        } else {
          const response = await HospitalServices.createHospitalClaim(claimRequestData)
          if (response.isSuccess && response.data) {
            const responseClaimInfo = await HospitalServices.createHospitalClaimInfo(
              response.data,
              newMetaDataClaimInfoData,
            )
            if (responseClaimInfo.isSuccess && responseClaimInfo.data) {
              message.success("Khởi tạo yêu cầu bảo hiểm thành công")
              let path = PORTAL_PATH.HOSPITAL_CLAIM_DETAIL
              path = path.replace(":certificateId", certificateId)
              path = path.replace(":benefitType", benefitType)
              path = path.replace(":claimId", response.data)
              history.push(path)
            }
          }
        }
      }
    }
    setLoadingClaim(false)
  }

  const toggleEdit = (e) => {
    e.preventDefault()
    setCheckEdit(!checkEdit)
  }

  const selectFileType = (value) => {
    setFileType(value)
  }

  const handleFileData = (fileList) => {
    setFileList(fileList)
    setFileType("")
  }

  const uploadFile = async (data: {file?: File; type?: string}) => {
    if (data.file && data.type) {
      const file = await utils.base64ToFile(data.file)
      file.append(KEYS.DOC_TYPE, data.type)
      file.append(KEYS.DOC_SOURCE, TYPE.SOURCE_TYPE.MEDICAL_PROVIDERS)
      if (claimId) {
        return await FileService.uploadFileClaim(claimId, file)
      }
    }
  }

  const checkAllDocumentHasType = (dataList) => {
    let result = true
    dataList.forEach((data, index) => {
      if (data.type === "") {
        result = false
        message.warn(`Vui lòng chọn loại giấy tờ cho giấy tờ thứ ${index + 1}`)
        return result
      }
    })
    return result
  }

  const uploadDocument = async () => {
    if (fileList && checkAllDocumentHasType(fileList)) {
      caolan.eachOf(
        fileList,
        (paperData: {file?: File; type?: string}, key, callback) => {
          if (paperData.type) {
            uploadFile(paperData).then((res) => callback(res as any))
          }
        },
        (res) => {
          if ((res as any).code == "Invalid_Operation") {
            message.error("Không thể thêm mới hình ảnh khi YCBT không phải đang ở trạng thái Đang khởi tạo")
          } else if (!(res as any).isSuccess) {
            message.error("Thêm mới thất bại")
          } else {
            message.success("Tải lên hình ảnh thành công")
            setShowModalUpload(false)
            getFileList().then()
          }
        },
      )
    }
  }

  return (
    <div className={styles.claimContainer}>
      <div className={styles.collapseContainer}>
        {(claimId && (
          <div className={styles.checkEditButton}>
            <ButtonColor
              icon={<EditTwoTone twoToneColor={"#96631a"} />}
              type="warning"
              onClick={(e) => toggleEdit(e)}
              href=""
            >
              Chỉnh sửa
            </ButtonColor>
          </div>
        )) ||
          ""}
        <Space direction={"vertical"} size={18} className={styles.spaceContainer}>
          <InsuredInfo loading={loadingCertificate} data={insuredInfoData} benefitType={benefitType} />
          {(claimId &&
            ((checkEdit && (
              <>
                <DetailForm loading={loadingClaim} onChange={handleDetailFormData} data={detailFormData} />
                <MetaDataClaimInfo
                  loading={loadingClaim}
                  data={metaDataClaimInfoData}
                  onChange={handleMetaDataClaimInfo}
                />
              </>
            )) || (
              <>
                <DetailFormReadOnly data={detailFormData} loading={loadingClaim} />
                <MetaDataClaimInfoReadOnly data={metaDataClaimInfoData} loading={loadingClaim} />
              </>
            ))) || (
            <>
              <DetailForm loading={loadingClaim} onChange={handleDetailFormData} data={detailFormData} />
              <MetaDataClaimInfo
                loading={loadingClaim}
                data={metaDataClaimInfoData}
                onChange={handleMetaDataClaimInfo}
              />
            </>
          )}
          {(claimId && (
            <DocumentData
              onOpenModal={(isOpen) => setShowModalUpload(isOpen)}
              showButtonUpload={true}
              claimId={claimId}
              loading={loadingDocument}
              data={documentData}
            />
          )) ||
            ""}

          <SignOff
            loading={loadingClaimNotes}
            data={claimNotes.filter((note) => note.type === TYPE.NOTE_TYPE.SIGN_OFF)}
          />

          {(claimId &&
            ((checkEdit && (
              <div className={styles.button}>
                <ButtonColor icon={<CheckOutlined />} type={"success"} onClick={(e) => handleSubmit(e)} href={""}>
                  Cập nhật
                </ButtonColor>
              </div>
            )) || <div />)) || (
            <div className={styles.button}>
              <ButtonColor icon={<PlusOutlined />} type="primary" onClick={(e) => handleSubmit(e)} href={""}>
                Khởi tạo
              </ButtonColor>
            </div>
          )}
        </Space>
      </div>
      <Modal
        title=""
        centered
        visible={showModalUpload}
        width={750}
        onCancel={() => {
          setShowModalUpload(false)
          setFileType("")
        }}
        footer={[
          <div key="0">
            <Select
              style={{
                width: 120,
                marginRight: 20,
              }}
              placeholder="Chọn loại"
              onChange={selectFileType}
              value={fileType || undefined}
            >
              <Option value={TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER}>{TYPE.CLAIM_FILE_TYPE_VALUE.DISCHARGE_PAPER}</Option>
              <Option value={TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER}>{TYPE.CLAIM_FILE_TYPE_VALUE.INVOICE_PAPER}</Option>
              <Option value={TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER}>
                {TYPE.CLAIM_FILE_TYPE_VALUE.PRESCRIPTION_PAPER}
              </Option>
              <Option value={TYPE.CLAIM_FILE_TYPE.OTHER_PAPER}>{TYPE.CLAIM_FILE_TYPE_VALUE.OTHER_PAPER}</Option>
            </Select>
            <Button key="submit" type="primary" onClick={() => uploadDocument()}>
              Submit
            </Button>
          </div>,
        ]}
      >
        <div className={styles.contentModal}>
          <UploadComponent onChange={handleFileData} type={fileType} />
        </div>
      </Modal>
    </div>
  )
}

export default ClaimScreen
