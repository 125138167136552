import {PORTAL_PATH} from "./routes"

export interface IMenu {
  key: string
  title: string
  icon: string
  path: string
  roles?: string[]
  isHeader?: boolean
}

const menus: IMenu[] = [
  {
    key: "dash",
    title: "Dashboard",
    icon: "dashBoardIcon",
    path: PORTAL_PATH.DASHBOARD,
  },

  // Header
  {
    key: "adminHeader",
    title: "Admin Group",
    icon: "",
    path: "",
    isHeader: true,
  },
  //

  {
    key: "UserScreen",
    title: "Người dùng Portal",
    icon: "userIcon",
    path: PORTAL_PATH.ADMIN_USERS_LIST,
  },
  {
    key: "userInfos",
    title: "Người được bảo hiểm",
    icon: "userIcon",
    path: PORTAL_PATH.INSURED_PERSON_LIST,
  },
  {
    key: "company",
    title: "Công ty",
    icon: "companyIcon",
    path: PORTAL_PATH.COMPANY_LIST,
  },
  {
    key: "policy",
    title: "Hợp đồng",
    icon: "policyIcon",
    path: PORTAL_PATH.POLICY_LIST,
  },
  {
    key: "plan",
    title: "Sản phẩm BH",
    icon: "planIcon",
    path: PORTAL_PATH.PLAN_LIST,
  },
  {
    key: "card",
    title: "Mẫu thẻ",
    icon: "cardIcon",
    path: PORTAL_PATH.DYNAMIC_CARD_LIST,
  },
  {
    key: "medicalProvider",
    title: "Cơ sở y tế",
    icon: "medicalProviderIcon",
    path: PORTAL_PATH.MEDICAL_PROVIDER,
  },

  // Claim
  {
    key: "claimHeader",
    title: "Claim Group",
    icon: "",
    path: "",
    isHeader: true,
  },
  //

  {
    key: "createClaimRequestByInsuredPerson",
    title: "Tạo YCBT",
    icon: "directBillingIcon",
    path: PORTAL_PATH.CREATE_CLAIM_REQUEST,
  },

  {
    key: "claimList",
    title: "Claims",
    icon: "claimListIcon",
    path: PORTAL_PATH.CLAIM_LIST,
  },

  // Hospital Group
  {
    key: "hospitalHeader",
    title: "Hospital Group",
    icon: "",
    path: "",
    isHeader: true,
  },
  {
    key: "insurer",
    title: "Công ty bảo hiểm",
    icon: "companyIcon",
    path: PORTAL_PATH.HOSPITAL_INSURER_SELECTION,
  },
  {
    key: "hospitalClaimList",
    title: "Yêu cầu đang chờ kết quả",
    icon: "claimListIcon",
    path: PORTAL_PATH.HOSPITAL_CLAIM,
  },
]

export default menus
