import React from "react"
import tableStyles from "app/common/components/TableList/TableList.module.css"

enum STATE_STATUS {
  INITIAL = "initial",
  LOADING = "loading",
  ERROR = "error",
  DONE = "done",
  EMPTY = "empty",
}

const SETTINGS = {
  SEARCH_DEBOUNCE_TIME: 600,
}

const QUERY_LIMIT = 10000

const THEMES = {
  DARK_THEME: "dark",
  LIGHT_THEME: "light",
  BLUE_THEME: "blue",
}

const FIELDS = {
  IS_WILLING_TARGET: "applyToVoluntaryTarget",
  IS_AGE_RANGE_TARGET: "applyTargetAgeRange",
  IS_TARGET: "applyTargetCondition",
  IS_BLACKLIST: "applyToBlackList",
  TARGET: "target",
  AGE_RANGES: "ageRanges",
  INSURED_PERSON_ID_LIST: "insuredCertificateIds",
}

const KEYS = {
  FILE: "file",
  DOC_TYPE: "type",
  DOC_SOURCE: "source",
  DOC_SOURCE_NAME: {
    ASSESSMENTS: "2",
  },
  CREATE_CLAIM: {
    BENEFITS: "benefits",
    CLAIM_INFO: "claimInfo",
    CLAIM_INFO_TYPE: {
      MEDICAL_PROVIDER_ITEM: "medicalProviderItem",
      EMAIL: "email",
      ICD_CODE: "icdCode",
      EVENT_DATE: "eventDates",
      EVENT_DATE_RANGE: "eventDateRanges",
      TREATMENT_METHOD: "treatmentMethod",
      DISEASES: "diseases",
      DIAGNOSIS: "diagnosis",
      REQUEST_AMOUNT: "requestAmount",
      STATUS: "status",
    },
  },
}

const METADATA = {
  DISEASES: "diseases",
  BANK: "bank_list",
  CITY: "city",
}

const TYPE = {
  METADATA_TYPE: {
    ALL: 0,
    CLAIM_DOCUMENT_TYPE: 1,
    CLAIM_NOTE_TYPE: 2,
    CLAIM_PENDING_CODE_STATUS: 3,
    CLAIM_SOURCE: 4,
    CLAIM_STATUS: 5,
    COMPANY_ROLE_TYPE: 6,
    INSURED_BENEFIT_TYPE: 7,
    INSURED_RELATIONSHIP_TYPE: 8,
    PLAN_BALANCE_TYPE: 9,
    POLICY_CO_PAYMENT_TARGET: 10,
    POLICY_TYPE: 11,
    MEDICAL_PROVIDER_TYPE: 12,
    MEDICAL_PROVIDER_STATUS: 13,
  },
  METADATA_TYPE_NAME: {
    CLAIM_DOCUMENT_TYPE: "ClaimDocumentType",
    CLAIM_NOTE_TYPE: "ClaimNoteType",
    CLAIM_PENDING_CODE_STATUS: "ClaimPendingCodeStatus",
    CLAIM_SOURCE: "ClaimSource",
    CLAIM_STATUS: "ClaimRequestStatus",
    CLAIM_CASE_STATUS: "ClaimCaseStatus",
    CLAIM_REQUEST_STATUS: "ClaimRequestStatus",
    COMPANY_ROLE_TYPE: "CompanyRoleType",
    INSURED_BENEFIT_TYPE: "InsuredBenefitType",
    INSURED_RELATIONSHIP_TYPE: "InsuredRelationshipType",
    PLAN_BALANCE_TYPE: "PlanBalanceTypes",
    POLICY_CO_PAYMENT_TARGET: "PolicyCoPaymentTarget",
    POLICY_TYPE: "PolicyType",
    MEDICAL_PROVIDER_TYPE: "MedicalProviderType",
    MEDICAL_PROVIDER_STATUS: "MedicalProviderStatus",
  },
  SOURCE_TYPE: {
    CLIENT: "InsuredPersons",
    ASSESSOR: "Assessments",
    MEDICAL_PROVIDERS: "MedicalProviders",
  },
  FILE_TYPE: {
    CLAIM_DOCUMENT: "ClaimDocument",
    PLAN_DOCUMENT: "PlanDocument",
    POLICY_DOCUMENT: "PolicyDocument",
  },
  CLAIM_FILE_TYPE: {
    DISCHARGE_PAPER: "DischargePaper",
    INVOICE_PAPER: "InvoicePaper",
    PRESCRIPTION_PAPER: "PrescriptionPaper",
    OTHER_PAPER: "OtherPaper",
  },
  CLAIM_FILE_TYPE_VALUE: {
    DISCHARGE_PAPER: "Giấy ra viện",
    INVOICE_PAPER: "Hóa đơn",
    PRESCRIPTION_PAPER: "Toa thuốc",
    OTHER_PAPER: "Giấy tờ khác",
  },
  CLAIM_TYPE_NAME: {
    REIMBURSEMENT: "reimbursement",
    DIRECT_BILLING: "directBilling",
  },
  BENEFIT_TYPE: {
    0: "Life",
    1: "Inpatient",
    2: "Outpatient",
    3: "Allowance",
  },
  BENEFIT_TYPE_NAME: {
    Life: 0,
    InPatient: 1,
    InPatient_Maternity: 1,
    OutPatient: 2,
    OutPatient_Dental: 2,
    Allowance: 3,
    Accident: 4,
    Others: 5,
    Maternity: 6,
    Dental: 7,
  },
  CLAIM_STATUS_NAME: {
    Initialize: "",
    Received: "",
    Created: "Khởi tạo thành công",
    EstimateGuarantee: "Chấp nhận bảo lãnh",
    InProgress: "Đang thẩm định",
  },
  COMPANY_TYPE: {
    1: "Insurer",
    0: "Corporate",
    2: "Broker",
  },
  COMPANY_TYPE_NAME: {
    Insurer: 1,
    Corporate: 0,
    Broker: 2,
  },
  COMPANY_TYPE_GQL: {
    TPA: "TPA",
    CORPORATE: "Corporate",
    INSURER: "Insurer",
    BROKER: "Broker",
  },
  GENDER_TYPE: {
    1: "Nữ",
    0: "Nam",
  },
  GENDER_TYPE_NAME: {
    Female: 1,
    Male: 0,
  },
  OWNER_TYPE: {
    INDIVIDUAL: "individual",
    CORPORATE: "corporate",
  },
  IMPORT_TYPE: {
    LA: "1",
    InsuredPerson: "0",
    MedicalProvider: "2",
  },
  STATUS_CLAIM: {
    Initialize: 0,
    Received: 3,
    Created: 2,
    Estimated: 4,
    InProgress: 4,
    Pending: 4,
    Updated: 3,
    Waiting: 4,
    ApprovedWaitingDoc: 8,
    Approved: 8,
    Declined: 9,
    Cancelled: 10,
    Suspension: 9,
    Paid: 8,
  },
  STATUS_COLOR: {
    0: "yellow",
    7: "yellow",
    3: "blue",
    2: "default",
    4: "orange",
    8: "green",
    9: "red",
    10: "black",
  },
  POPUP: {
    EDIT: 1,
    CREATE: 0,
  },
  PENDING_STATUS: {
    OutstandingRequirement: "O",
    RequirementReceived: "R",
    RequirementWaived: "W",
    Suspended: "S",
  },
  PENDING_STATUS_NAME: {
    O: "OutstandingRequirement",
    R: "RequirementReceived",
    W: "RequirementWaived",
    S: "Suspended",
  },
  VN_PENDING_STATUS_NAME: {
    O: "Đang bổ sung",
    R: "Đã nộp",
    W: "Miễn trừ",
    S: "Hết hạn",
  },
  NOTE_TYPE: {
    SIGN_OFF: "SignOff",
    REMARK: "Remark",
  },
}

const VALIDATE = {
  NONE_SPACE: "none-space",
  EMAIL: "email",
  PASSWORD: "password",
  PHONE: "phone",
  NONE_SPECIAL_CHAR: "none-special",
}

const RESPONSE_CODE = {
  ERROR_VALIDATION: 400,
  SUCCESS_200: "Success_200",
  SUCCESS_004: "Success_004", //auth token success
  SUCCESS_002: "Success_002",
  ERROR_004: "Error_004", //wrong credential,
  ERROR_018: "Error_018",
  ERROR_022: "Error_022", //Wrong paid day over limit
  ERROR_023: "Error_023", //Wrong paid unit over limit
  ERROR_024: "Error_024", //Wrong paid total over limit
}

const FORMATTER = {
  DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm:ss",
  DATE_FORMAT: "DD/MM/YYYY",
  DATE_TIME_FORMAT_WITHOUT_SEC: "DD/MM/YYYY HH:mm",
  API_DATE_FORMAT: "YYYY-MM-DD HH:mm",
}

const TABLE_CONFIG = {
  PAGINATION: {
    className: tableStyles.paginateContainer,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "50", "100"],
    showTotal: (total: number, row: number[]) => (
      <div>
        Total: {row[1]}/{total}
      </div>
    ),
  },
}

const LIMIT_MEDICAL_PROVIDER_LIST = 2000

const RANDOM_WAITING_TEXT = {
  0: "Có liền",
  1: "Chờ em xí",
  2: "Đến Ngay nè",
  3: "Chút nữa thôi",
  4: "Loading",
  5: "Sắp có rồi",
  6: "...",
  7: "Chờ 1 tí",
  8: "Nhanh lắm",
  9: "Đang chạy",
}

export {
  KEYS,
  FIELDS,
  METADATA,
  VALIDATE,
  RESPONSE_CODE,
  THEMES,
  FORMATTER,
  TYPE,
  TABLE_CONFIG,
  SETTINGS,
  RANDOM_WAITING_TEXT,
  STATE_STATUS,
  QUERY_LIMIT,
  LIMIT_MEDICAL_PROVIDER_LIST,
}
