import {
  LockOutlined,
  MinusOutlined,
  PlusOutlined,
  RightSquareOutlined,
  SaveFilled,
  UnlockOutlined,
} from "@ant-design/icons"
import styles from "./ClaimBenefit.module.css"
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  PageHeader,
  Row,
  Select,
  Space,
  Tag,
} from "antd"
import ButtonColor from "antd-button-color"
import _ from "lodash"
import NumberFormat from "react-number-format"
import React, {useState} from "react"
import {IBenefit, IBenefitUpdate} from "../../index"
import ClaimServices from "services/ClaimServices"
import {RefSelectProps} from "antd/lib/select"
import {RESPONSE_CODE} from "config/constants"
import BenefitTag from "app/common/components/BenefitTag"
import {useMountEffect} from "@react-hookz/web"

const {Panel} = Collapse
const {Option} = Select
const {TextArea} = Input

const ClaimBenefit = (props: {
  claimCaseId: string
  benefitTypeId: string
  loading: boolean
  benefits?: IBenefit[]
  onUpdate?: () => void
  totalBenefit?: {
    coPayAmount: number
    paidAmount: number
    requestAmount: number
  }
}) => {
  const {claimCaseId, loading, onUpdate, totalBenefit} = props

  const benefitSelection = React.createRef<RefSelectProps>()
  const [currentBenefit, setCurrentBenefit] = useState<string>("")
  const [benefitInfo, setBenefitInfo] = useState<IBenefitUpdate[]>([])
  const [allowToDeleteId, setAllowToDeleteId] = useState<string>("")
  const [allowAddBenefit, setAllowAddBenefit] = useState<boolean>(true)
  const [claimBenefits, setBenefits] = useState<IBenefit[]>(props.benefits || [])
  const [error, setError] = useState<{
    isError: true
    benefitId: string
    balanceId: string
  }>()
  const getPlanBenefitInfo = async () => {
    if (claimCaseId) {
      const response = await ClaimServices.getClaimCaseBenefitBalance(claimCaseId)
      if (response.isSuccess) {
        setBenefitInfo(response.data)
      }
    }
  }

  const getBenefits = async () => {
    if (claimCaseId && !claimBenefits.length) {
      const response = await ClaimServices.getClaimCaseBenefit(claimCaseId)
      if (response.isSuccess) {
        setBenefits(response.data)
      }
    }
  }

  useMountEffect(() => {
    getPlanBenefitInfo().then()
    getBenefits().then()
  })

  const unlockFocusAndLockAll = (benefitId: string) => {
    setCurrentBenefit(benefitId)
  }

  const handleBenefitBalanceChange = (benefitId: string, name: string, value: string | number) => {
    setError(undefined)
    const benefitData = _.clone(benefitInfo)
    if (benefitData) {
      const index = _.findIndex(benefitData, (b: IBenefitUpdate) => b.planBenefitId === benefitId)
      benefitData[index][name] = value
      setBenefitInfo(benefitData)
    }
  }

  const handleChange = async (value) => {
    let currentBenefit = ""
    let allowAddBenefit = false
    if (claimCaseId) {
      if (!benefitInfo?.length) {
        await ClaimServices.checkIncludeTimeBenefitBalance(claimCaseId, value[0]).then((response) => {
          const newBenefitInfo: IBenefitUpdate[] | undefined = [
            {
              planBenefitId: value[0],
              ...((response.isSuccess && {
                balanceDetails: response.data.balanceDetails,
                displayAmount: response.data.displayAmount,
                displayUnit: response.data.displayUnit,
                unitName: response.data.unitName,
              }) ||
                {}),
            },
          ]
          currentBenefit = value[0]
          setBenefitInfo(newBenefitInfo)
          setAllowToDeleteId(value[0])
          setCurrentBenefit(currentBenefit)
        })
      } else {
        let newBenefitInfo = _.clone(benefitInfo)
        const listAddId = _.difference(
          value,
          newBenefitInfo.map((b) => b.planBenefitId),
        )

        if (listAddId && listAddId.length) {
          setAllowToDeleteId(listAddId[0])
          ClaimServices.checkIncludeTimeBenefitBalance(claimCaseId, listAddId[0]).then((response) => {
            const benefitItem: IBenefitUpdate = {planBenefitId: listAddId[0]}
            currentBenefit = listAddId[0]
            if (response.isSuccess) {
              benefitItem.balanceDetails = response.data.balanceDetails
              benefitItem.displayAmount = response.data.displayAmount
              benefitItem.displayUnit = response.data.displayUnit
              benefitItem.unitName = response.data.unitName
            }
            newBenefitInfo.push(benefitItem)
            setBenefitInfo(newBenefitInfo)
            setCurrentBenefit(currentBenefit)
          })
        }
        const listRemoveId = _.difference(
          newBenefitInfo.map((b) => b.planBenefitId),
          value,
        )
        if (listRemoveId && listRemoveId.length) {
          if (allowToDeleteId === listRemoveId[0]) {
            setAllowToDeleteId("")
            newBenefitInfo = newBenefitInfo.filter((b) => b.planBenefitId !== listRemoveId[0])
            setBenefitInfo(newBenefitInfo)
          }
          allowAddBenefit = true
        }
      }
      setAllowAddBenefit(allowAddBenefit)
    } else {
      message.warn("Hãy khởi tạo claim trước khi chọn benefit này")
    }
  }

  const updateBalanceClaim = async (benefitId: string) => {
    if (benefitInfo) {
      const benefitBalanceUpdate = _.find(benefitInfo, (b) => b.planBenefitId === benefitId)
      if (claimCaseId && benefitBalanceUpdate) {
        let response
        if (benefitBalanceUpdate.claimDetailId) {
          response = await ClaimServices.updateClaimBenefitBalance(claimCaseId, benefitId, benefitBalanceUpdate)
        } else {
          response = await ClaimServices.createClaimBenefitBalance(claimCaseId, benefitId, benefitBalanceUpdate)
        }
        if (response.isSuccess) {
          setAllowAddBenefit(true)
          setAllowToDeleteId("")
          getPlanBenefitInfo().then()
          onUpdate && onUpdate()
          message.success(`Cập nhật benefit thành công`)
        } else {
          setError({
            isError: true,
            benefitId: benefitId,
            balanceId: response.data,
          })
          if (response.code === RESPONSE_CODE.ERROR_022) {
            notification.error({
              message: `Sai hạn mức tiền trên ${benefitBalanceUpdate.unitName}`,
              description: `Hạn mức tiền vượt quá trên ${benefitBalanceUpdate.unitName}`,
            })
          } else if (response.code === RESPONSE_CODE.ERROR_023) {
            notification.error({
              message: `Sai hạn mức ${benefitBalanceUpdate.unitName}`,
              description: `Hạn mức ${benefitBalanceUpdate.unitName} vượt quá giới hạn`,
            })
          } else if (response.code === RESPONSE_CODE.ERROR_024) {
            notification.error({
              message: `Sai hạn mức tổng`,
              description: `Hạn mức tổng vượt quá giới hạn`,
            })
          } else {
            message.error(`Cập nhật benefit không thành công`)
          }
        }
      }
    }
  }

  const benefitTag = (props: any) => {
    // eslint-disable-next-line react/prop-types
    const {label, value} = props
    return (
      <Tag
        color={"blue"}
        closable={allowToDeleteId === value}
        onClose={() => handleChange(benefitInfo.map((b) => b.planBenefitId).filter((b) => b !== value))}
        className={styles.tag}
      >
        {label}
      </Tag>
    )
  }

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
    >
      <Panel header="Điền quyền lợi" key="1">
        <div className={styles.selectBenefitContainer}>
          <Select
            ref={benefitSelection}
            showSearch
            filterOption={(input, option) =>
              option && option.key ? option.key.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
            }
            mode="multiple"
            size={"large"}
            placeholder="Chọn quyền lợi"
            onChange={handleChange}
            disabled={!allowAddBenefit}
            style={{width: "100%"}}
            tagRender={(props) => benefitTag(props)}
            value={benefitInfo && benefitInfo.length ? benefitInfo.map((b) => b.planBenefitId || "") : []}
            onSelect={() => {
              benefitSelection && benefitSelection.current && benefitSelection.current.blur()
            }}
          >
            {claimBenefits &&
              claimBenefits.length &&
              claimBenefits.map((benefit) => (
                <Option value={benefit.id} key={benefit.type + benefit.code + benefit.name}>
                  <BenefitTag text={benefit.type} isSmall />
                  <Tag color={"geekblue"}>{benefit.code}</Tag>
                  {benefit.isDirectBilling ? <Tag color={"geekblue"}>Có bảo lãnh</Tag> : ''}
                  {benefit.name} 
                </Option>
              ))}
          </Select>
        </div>
        <div className={styles.benefitClaimListContainer}>
          {(benefitInfo &&
            benefitInfo.length &&
            claimBenefits &&
            claimBenefits.length &&
            benefitInfo
              .slice(0)
              .reverse()
              .map((benefit) => (
                <Collapse
                  bordered={false}
                  key={"benefit_" + benefit.planBenefitId}
                  defaultActiveKey={["1"]}
                  className={styles.childCollapse}
                  expandIcon={({isActive}) => (isActive ? <MinusOutlined size={24} /> : <PlusOutlined size={24} />)}
                >
                  <Panel
                    header={
                      <div className={styles.panelContainer}>
                        <div className={styles.childPanelHeader}>
                          <div>{claimBenefits.find((b: IBenefit) => b.id === benefit.planBenefitId)?.name}</div>
                          <div>
                            <ButtonColor
                              onClick={(e) => {
                                unlockFocusAndLockAll(
                                  currentBenefit === benefit.planBenefitId ? "" : benefit.planBenefitId,
                                )
                                e.stopPropagation()
                              }}
                              type={(currentBenefit !== benefit.planBenefitId && "danger") || "default"}
                              shape="circle"
                              icon={
                                (currentBenefit !== benefit.planBenefitId && <LockOutlined />) || <UnlockOutlined />
                              }
                              size={"middle"}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    key="1"
                  >
                    <Form
                      className={(currentBenefit !== benefit.planBenefitId && styles.blur) || ""}
                      layout="vertical"
                      onFinish={() => updateBalanceClaim(benefit.planBenefitId)}
                      requiredMark={true}
                    >
                      <div className={styles.cardList}>
                        {benefit.balanceDetails && benefit.balanceDetails.length
                          ? benefit.balanceDetails.map((balanceDetail) => (
                              <div
                                className={[
                                  styles.balanceDetailCard,
                                  error &&
                                  error.isError &&
                                  error.benefitId === benefit.planBenefitId &&
                                  error.balanceId === balanceDetail.planBalanceId
                                    ? styles.error
                                    : "",
                                ].join(" ")}
                                key={balanceDetail.name}
                              >
                                <Space direction={"vertical"} size={4}>
                                  <div>{balanceDetail.name}</div>
                                  <Space direction={"horizontal"} size={4}>
                                    <span>
                                      <strong>Tổng hạn mức:</strong>{" "}
                                      {balanceDetail.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                    <span>{balanceDetail.type.replace("Tiền", "đ")}</span>
                                  </Space>
                                  <div>
                                    <strong>Đã sử dụng:</strong>{" "}
                                    {balanceDetail.balanceUsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                    {balanceDetail.type.split("/")[0].replace("Tiền", "đ")}
                                  </div>
                                  <div>
                                    <strong>Còn lại:</strong>{" "}
                                    {balanceDetail.balanceRemain.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                    {balanceDetail.type.split("/")[0].replace("Tiền", "đ")}
                                  </div>
                                </Space>
                              </div>
                            ))
                          : ""}
                      </div>
                      <Row className={styles.infoInputContainer}>
                        <Col span={12}>
                          <PageHeader className={styles.pageHeader} title="Yêu cầu" subTitle="Giá trị yêu cầu" />
                        </Col>
                        <Col span={12}>
                          <PageHeader className={styles.pageHeader} title="Chi trả" subTitle="Giá trị chi trả" />
                        </Col>
                        {(benefit.displayAmount && (
                          <Col span={12}>
                            <Form.Item
                              label={
                                <div>
                                  Số tiền yêu cầu <span style={{color: "red"}}>*</span>
                                </div>
                              }
                            >
                              <InputNumber
                                name={"requestAmount"}
                                className={styles.inputNumber}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                size={"large"}
                                min={0}
                                disabled={currentBenefit !== benefit.planBenefitId}
                                onChange={(value) =>
                                  handleBenefitBalanceChange(benefit.planBenefitId, "requestAmount", value)
                                }
                                value={
                                  _.find(benefitInfo, (b) => b.planBenefitId === benefit.planBenefitId)
                                    ?.requestAmount || undefined
                                }
                                placeholder="Nhập số tiền yêu cầu"
                              />
                            </Form.Item>
                          </Col>
                        )) ||
                          ""}
                        {(benefit.displayAmount && (
                          <Col span={12}>
                            <Form.Item
                              help={
                                error && error.isError && error.benefitId === benefit.planBenefitId
                                  ? "Sai số tiền chi trả hãy kiểm tra lại"
                                  : ""
                              }
                              validateStatus={
                                error && error.isError && error.benefitId === benefit.planBenefitId ? "error" : ""
                              }
                              label={<div>Số tiền chi trả </div>}
                            >
                              <InputNumber
                                name={"paidAmount"}
                                className={styles.inputNumber}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                size={"large"}
                                min={0}
                                disabled={currentBenefit !== benefit.planBenefitId}
                                onChange={(value) =>
                                  handleBenefitBalanceChange(benefit.planBenefitId, "paidAmount", value)
                                }
                                value={
                                  _.find(benefitInfo, (b) => b.planBenefitId === benefit.planBenefitId)?.paidAmount ||
                                  undefined
                                }
                                placeholder="Nhập số tiền chi trả"
                              />
                            </Form.Item>
                          </Col>
                        )) ||
                          ""}
                        {(benefit.displayUnit && (
                          <Col span={12}>
                            <Form.Item
                              label={
                                <div>
                                  Số {benefit.unitName} yêu cầu <span style={{color: "red"}}>*</span>
                                </div>
                              }
                            >
                              <InputNumber
                                name={"requestTime"}
                                className={styles.inputNumber}
                                size={"large"}
                                min={0}
                                disabled={currentBenefit !== benefit.planBenefitId}
                                onChange={(value) =>
                                  handleBenefitBalanceChange(benefit.planBenefitId, "requestTime", value)
                                }
                                value={
                                  _.find(benefitInfo, (b) => b.planBenefitId === benefit.planBenefitId)?.requestTime ||
                                  undefined
                                }
                                placeholder={`Nhập số ${benefit.unitName} yêu cầu`}
                              />
                            </Form.Item>
                          </Col>
                        )) ||
                          ""}
                        {(benefit.displayUnit && (
                          <Col span={12}>
                            <Form.Item
                              help={
                                error && error.isError && error.benefitId === benefit.planBenefitId
                                  ? `Sai số ${benefit.unitName} chi trả hãy kiểm tra lại`
                                  : ""
                              }
                              validateStatus={
                                error && error.isError && error.benefitId === benefit.planBenefitId ? "error" : ""
                              }
                              label={<div>Số {benefit.unitName} chi trả</div>}
                            >
                              <InputNumber
                                name={"paidTime"}
                                className={styles.inputNumber}
                                size={"large"}
                                min={0}
                                disabled={currentBenefit !== benefit.planBenefitId}
                                onChange={(value) =>
                                  handleBenefitBalanceChange(benefit.planBenefitId, "paidTime", value)
                                }
                                value={
                                  _.find(benefitInfo, (b) => b.planBenefitId === benefit.planBenefitId)?.paidTime ||
                                  undefined
                                }
                                placeholder={`Nhập số ${benefit.unitName} chi trả`}
                              />
                            </Form.Item>
                          </Col>
                        )) ||
                          ""}
                        <Col span={12}>
                          <label>Lý do</label>
                          <TextArea
                            name={"note"}
                            onChange={(e) => handleBenefitBalanceChange(benefit.planBenefitId, "note", e.target.value)}
                            placeholder="Nhập lý do"
                            value={_.find(benefitInfo, (b) => b.planBenefitId === benefit.planBenefitId)?.note || ""}
                            disabled={currentBenefit !== benefit.planBenefitId}
                            allowClear
                          />
                        </Col>
                        <Col span={12} className={styles.statsContainer}>
                          <Row className={styles.stats}>
                            <Col span={12}>Số tiền yêu cầu</Col>
                            <Col span={12}>
                              {benefit.totalRequestAmount ? (
                                <>
                                  <NumberFormat
                                    value={benefit.totalRequestAmount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />{" "}
                                  đ
                                </>
                              ) : (
                                "Chưa có kết quả"
                              )}
                            </Col>
                          </Row>
                          <Row className={styles.stats}>
                            <Col span={12}>Số tiền dự kiến chi trả</Col>
                            <Col span={12}>
                              {benefit.totalRequestAmount ? (
                                <>
                                  <NumberFormat
                                    value={(benefit.totalPaidAmount || 0) + (benefit.coPayAmount || 0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />{" "}
                                  đ
                                </>
                              ) : (
                                "Chưa có kết quả"
                              )}
                            </Col>
                          </Row>
                          <Row className={styles.stats}>
                            <Col span={12}>Đồng chi trả</Col>
                            <Col span={12}>
                              {benefit.totalRequestAmount ? (
                                <>
                                  <NumberFormat
                                    value={benefit.coPayAmount ? 0 - benefit.coPayAmount : 0}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />{" "}
                                  đ ({(benefit.coPayment || 0) * 100}%)
                                </>
                              ) : (
                                "Chưa có kết quả"
                              )}
                            </Col>
                          </Row>
                          <Row className={styles.stats}>
                            <Col span={12}>Số tiền thực trả</Col>
                            <Col span={12}>
                              {benefit.totalPaidAmount ? (
                                <>
                                  <NumberFormat
                                    value={benefit.totalPaidAmount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />{" "}
                                  đ
                                </>
                              ) : (
                                "Chưa có kết quả"
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={"action"}>
                            <Button
                              loading={loading}
                              type={"primary"}
                              size={"large"}
                              htmlType="submit"
                              disabled={currentBenefit !== benefit.planBenefitId}
                              icon={<SaveFilled />}
                            >
                              Lưu
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Panel>
                </Collapse>
              ))) ||
            ""}
          {totalBenefit ? (
            <Row gutter={16} align={"stretch"} className={styles.totalContainer}>
              <Col span={12} />
              <Col span={12}>
                <Row className={styles.stats}>
                  <Col span={12}>Tổng tiền yêu cầu</Col>
                  <Col span={12}>
                    {totalBenefit ? (
                      <>
                        <NumberFormat
                          value={totalBenefit.requestAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />{" "}
                        đ
                      </>
                    ) : (
                      "Chưa có kết quả"
                    )}
                  </Col>
                </Row>
                <Row className={styles.stats}>
                  <Col span={12}>Tổng tiền dự kiến trả</Col>
                  <Col span={12}>
                    {totalBenefit ? (
                      <>
                        <NumberFormat
                          value={totalBenefit.paidAmount + totalBenefit.coPayAmount || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                        />{" "}
                        đ
                      </>
                    ) : (
                      "Chưa có kết quả"
                    )}
                  </Col>
                </Row>
                <Row className={styles.stats}>
                  <Col span={12}>Đồng chi trả</Col>
                  <Col span={12}>
                    {totalBenefit ? (
                      <>
                        <NumberFormat
                          value={totalBenefit.coPayAmount ? 0 - totalBenefit.coPayAmount : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                        />{" "}
                        đ
                      </>
                    ) : (
                      "Chưa có kết quả"
                    )}
                  </Col>
                </Row>
                <Row className={styles.stats}>
                  <Col span={12}>Tổng tiền thực trả</Col>
                  <Col span={12}>
                    {totalBenefit ? (
                      <>
                        <NumberFormat value={totalBenefit.paidAmount} displayType={"text"} thousandSeparator={true} /> đ
                      </>
                    ) : (
                      "Chưa có kết quả"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      </Panel>
    </Collapse>
  )
}

export default ClaimBenefit
