import {Tabs} from "antd"
import React from "react"
import {Route, Switch, useHistory, useLocation} from "react-router-dom"
import styles from "./ClaimCaseScreen.module.css"
import routes from "./routes"
import topbar from "./topbar"

const {TabPane} = Tabs

const ClaimCaseScreen = () => {
  const history = useHistory()
  const location = useLocation()
  return (
    <div className={styles.container}>
      {topbar
        .map((t) => t.subPath)
        .includes("/" + location.pathname.split("/")[location.pathname.split("/").length - 1]) ? (
        <Tabs
          className={styles.topBar}
          defaultActiveKey={
            topbar.find(
              (top) => "/" + location.pathname.split("/")[location.pathname.split("/").length - 1] === top.subPath,
            )?.key || ""
          }
          onChange={(key) => {
            let path = location.pathname
            const pathArray = path.split("/")
            pathArray[pathArray.length - 1] = ""
            path = pathArray.join("/")
            if (topbar) {
              const currentTopbar = topbar.find((top) => top.key === key)
              if (currentTopbar) {
                history.replace(path + currentTopbar.subPath.split("/")[1])
              }
            }
          }}
        >
          {topbar.map((tb) => (
            <TabPane tab={tb.title} disabled={tb.isLock} key={tb.key} />
          ))}
        </Tabs>
      ) : (
        <div style={{marginTop: 55}} />
      )}
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            render={() => (
              <>
                <div>
                  <h1 className={styles.titleHeader}>{route.title}</h1>
                  {!route.extendProps ? <route.comp /> : <route.comp {...route.extendProps} />}
                </div>
              </>
            )}
          />
        ))}
      </Switch>
    </div>
  )
}

export default ClaimCaseScreen
