import React, {useState} from "react"
import styles from "./ClaimCaseInfoScreen.module.css"
import ClaimServices from "services/ClaimServices"
import {Col, message, notification, Row, Space, Typography} from "antd"
import {CloseOutlined} from "@ant-design/icons"
import moment from "moment"
import {KEYS, TYPE} from "config/constants"
import _ from "lodash"
import {useParams} from "react-router"
import BottomBar from "./components/BottomBar"
import DetailForm from "./components/DetailForm"
import SignOff from "./components/SignOff"
import {utils} from "config/utils"
import PendingForm from "./components/PendingForm"
import WaitingTime from "./components/WaitingTime"
import RemarkCopay from "./components/RemarkCopay"
import {useMountEffect} from "@react-hookz/web"
import ClaimBenefit from "./components/ClaimBenefit"
import InsuredInfo from "./components/InsuredInfo"
import DocumentLink from "./components/DocumentLink"

const {Paragraph} = Typography

interface IStates {
  claimCaseId?: string
  //Loading
  loadingCertificate: boolean
  loadingClaim: boolean
  loadingMedicalProvider: boolean
  loadingICDCode: boolean
  loadingSaveClaim: boolean
  checkHospital: boolean
  currentBenefit: string
  certificate?: ICertificate
  claimCaseInfo?: IClaimCaseInfo
  allowAddBenefit: boolean
}

export interface IBenefit {
  id: string
  balance: string
  code: string
  name: string
  type: string
  isDirectBilling: boolean
}

export interface ICertificate {
  benefitTypes: {name: string; value: string}[]
  benefits: IBenefit[]
  effectiveDueDate: string
  insuredPersonName: string
  insuredPersonParentName: string
  insurer: string
  planId: string
  policyId: string
}

export interface IClaimCaseInfo {
  code: string
  email: string
  diagnosis: string
  benefitType?: string
  diseases:
    | {
        name?: string
        key: string
        value: string
        label: string
      }[]
    | {key: string; value: string; label: string}[]
  eventDates: string
  eventDateRanges: string[]
  treatmentMethod: string
  medicalProviderId: string
  medicalProviderName: string
  medicalProviderItem: {
    key: string
    value: string
    label: string
  }
  pendings: PendingType[]
  dueDate: string
  effectiveDate: string
  insurerName: string
  lifeAssuredName: string
  lifeAssuredParentName: string
  planBenefitIds: string[]
  status: string
  isDirectBilling: boolean
  coPayAmount: number
  paidAmount: number
  requestAmount: number
  remarkCopay?: string
  claimWaitingTimes?: {
    description: string
    isInWaitingTime: boolean
    value: number
  }[]
  notes?: {
    content: string
    createdAt: string
    createdBy: string
    type: string
  }[]
  insuredCertificateId?: string
}

export type PendingType = {
  claimPendingCodeId?: string
  code: string
  createdAt?: string
  extendedText: string
  removedAt?: string
  startedAt?: string
  status?: string
  isEditable?: boolean
}

export interface IClaimCaseUpdate {
  source: string
  email?: string
  diseases?: string
  eventDates?: string
  treatmentMethod?: string
  medicalProviderId?: string
  medicalProviderName?: string
  diagnosis?: string
  status?: string
}

export interface IBenefitUpdate {
  planBenefitId: string
  balanceDetails?: {
    planBalanceId: string
    balance: number
    balanceUsed: number
    balanceRemain
    name: string
    type: string
  }[]
  coPayAmount?: number
  coPayment?: number
  requestAmount?: number
  requestTime?: number
  paidAmount?: number
  paidTime?: number
  claimDetailId?: string
  note?: string
  unitName?: string
  displayAmount?: boolean
  displayUnit?: boolean
  totalPaidAmount?: number
  totalRequestAmount?: number
}

const ClaimCaseInfoScreen = () => {
  const {claimCaseId} = useParams<{claimCaseId: string}>()
  const [claimCaseInfo, setClaimCaseInfo] = useState<IStates["claimCaseInfo"]>()

  const [certificate, setCertificate] = useState<IStates["certificate"]>()
  const [loadingSaveClaim, setLoadingSaveClaim] = useState<IStates["loadingSaveClaim"]>(false)
  const [loadingCertificate, setLoadingCertificate] = useState<IStates["loadingCertificate"]>(true)
  const [loadingClaim, setLoadingClaim] = useState<IStates["loadingClaim"]>(true)

  const [isDateRangeMode, setDateRangeMode] = useState<boolean>()

  const getCertificateInfo = async (insuredCertificateId: string) => {
    const response = await ClaimServices.getCertificateDetail(insuredCertificateId)
    if (response.isSuccess) {
      setCertificate(response.data)
    }
    setLoadingCertificate(false)
  }

  const getClaimCaseInfo = async () => {
    let newLoadingClaim = _.clone(loadingClaim)
    const response = await ClaimServices.getClaimCaseDetail(claimCaseId)
    if (response.isSuccess) {
      getCertificateInfo(response.data.insuredCertificateId)
      const newclaimCaseInfo: IClaimCaseInfo = response.data
      if (newclaimCaseInfo && newclaimCaseInfo.diseases) {
        newclaimCaseInfo.diseases = newclaimCaseInfo?.diseases.map((d) => ({
          key: d.value,
          label: d.name,
          value: d.value,
          name: d.name,
        }))
      }
      if (newclaimCaseInfo.eventDates && newclaimCaseInfo.eventDates.includes("&")) {
        newclaimCaseInfo.eventDateRanges = [
          utils.parseLocalTime(newclaimCaseInfo.eventDates.split("&")[0]),
          utils.parseLocalTime(newclaimCaseInfo.eventDates.split("&")[1]),
        ]
        newclaimCaseInfo.eventDates = ""
      } else {
        newclaimCaseInfo.eventDates = utils.parseLocalTime(newclaimCaseInfo.eventDates)
      }
      newLoadingClaim = false
      setClaimCaseInfo(newclaimCaseInfo)
      setLoadingClaim(newLoadingClaim)
    }
  }

  const getFirstLoad = async () => {
    getClaimCaseInfo().then()
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })

  const handleChangeData = async (key: string, value: any, name?: string) => {
    switch (key) {
      case KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.STATUS: {
        const newclaimCaseInfo = _.clone(claimCaseInfo)
        if (newclaimCaseInfo) {
          newclaimCaseInfo.status = value
          setClaimCaseInfo(newclaimCaseInfo)
        }
        break
      }
      case KEYS.CREATE_CLAIM.BENEFITS: {
        break
      }
      case KEYS.CREATE_CLAIM.CLAIM_INFO: {
        let newclaimCaseInfo = _.clone(claimCaseInfo)
        if (!newclaimCaseInfo) {
          newclaimCaseInfo = {
            code: "",
            dueDate: "",
            effectiveDate: "",
            insurerName: "",
            lifeAssuredName: "",
            lifeAssuredParentName: "",
            diagnosis: "",
            email: "",
            diseases: [],
            eventDates: "",
            eventDateRanges: [],
            treatmentMethod: "",
            medicalProviderId: "",
            medicalProviderItem: {
              key: "",
              value: "",
              label: "",
            },
            pendings: [],
            status: "",
            medicalProviderName: "",
            planBenefitIds: [],
            isDirectBilling: true,
            coPayAmount: 0,
            requestAmount: 0,
            paidAmount: 0,
          }
        }
        if (name) {
          newclaimCaseInfo[name] = value
          setClaimCaseInfo(newclaimCaseInfo)
        }
        break
      }
      default:
        break
    }
  }

  const updateClaimCaseStatus = async (status: string) => {
    const response = await ClaimServices.updateClaimCaseStatus(claimCaseId, status)
    if (!response.isSuccess) {
      message.error("Cập nhật trạng thái không thành công")
    }
    setLoadingCertificate(false)
  }

  const updateClaimDetail = async () => {
    const updateClaimData: IClaimCaseUpdate = {source: ""}
    setLoadingSaveClaim(true)
    if (claimCaseInfo) {
      updateClaimData.eventDates = !isDateRangeMode
        ? moment.isDate(new Date(claimCaseInfo.eventDates))
          ? utils.parseLocalTime(claimCaseInfo.eventDates)
          : ""
        : moment.isDate(new Date(claimCaseInfo.eventDateRanges[0])) &&
          moment.isDate(new Date(claimCaseInfo.eventDateRanges[1]))
        ? `${utils.parseLocalTime(claimCaseInfo.eventDateRanges[0])}&${utils.parseLocalTime(
            claimCaseInfo.eventDateRanges[1],
          )}`
        : ""
      if (updateClaimData.eventDates === "Invalid date") updateClaimData.eventDates = ""
      updateClaimData.medicalProviderId =
        (claimCaseInfo.medicalProviderItem && claimCaseInfo.medicalProviderItem.value) ||
        claimCaseInfo.medicalProviderId ||
        ""
      updateClaimData.medicalProviderName =
        (claimCaseInfo.medicalProviderItem && claimCaseInfo.medicalProviderItem.label) ||
        claimCaseInfo.medicalProviderName ||
        ""
      updateClaimData.diseases = claimCaseInfo.diseases.map((d) => d.value).join("|")
      updateClaimData.email = claimCaseInfo.email
      updateClaimData.treatmentMethod = claimCaseInfo.treatmentMethod
      updateClaimData.diagnosis = claimCaseInfo.diagnosis
      updateClaimData.status = claimCaseInfo.status
      updateClaimData.source = TYPE.SOURCE_TYPE.ASSESSOR
      updateClaimCaseStatus(claimCaseInfo.status)
      const response = await ClaimServices.updateClaimCaseDetail(claimCaseId, updateClaimData)
      if (response.isSuccess) {
        message.success("Cập nhật thông tin thành công")
        getFirstLoad().then()
      } else {
        if (response.data && typeof response.data === "object") {
          for (let i = 0; i < Object.keys(response.data).length; i++) {
            setTimeout(() => {
              const title = <strong>Validate field {Object.keys(response.data)[i].split("$.")[1]}</strong>
              notification.error({
                message: title,
                description: response.data[Object.keys(response.data)[i]],
                icon: <CloseOutlined style={{color: "red"}} />,
              })
            }, 1200 * i)
          }
        } else {
          message.error(response.messages)
        }
      }
      setLoadingSaveClaim(false)
    }
  }

  return (
    <div className={styles.container}>
      {claimCaseId && claimCaseInfo ? (
        <div className={styles.claimNo}>
          <Paragraph copyable={{tooltips: false}}>{claimCaseInfo.code}</Paragraph>
        </div>
      ) : (
        ""
      )}
      <BottomBar
        claimCaseId={claimCaseId}
        value={claimCaseInfo?.status || ""}
        handleChange={(value) => handleChangeData(KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.STATUS, value)}
        disabledUpdate={!claimCaseInfo || !claimCaseInfo.benefitType || false}
        onUpdate={() => updateClaimDetail()}
      />
      <Row gutter={16}>
        <Col span={claimCaseInfo?.claimWaitingTimes || claimCaseInfo?.remarkCopay ? 20 : 24}>
          <Space direction={"vertical"} size={18} className={styles.collapseContainer}>
            <InsuredInfo loading={loadingCertificate} data={certificate} />
            <DetailForm
              loading={(claimCaseId && loadingClaim) || false}
              data={claimCaseInfo}
              isDateRangeMode={(isDateRangeMode) => setDateRangeMode(isDateRangeMode)}
              onChange={handleChangeData}
            />
            <ClaimBenefit
              loading={loadingSaveClaim}
              claimCaseId={claimCaseId}
              benefits={!claimCaseId ? certificate?.benefits : undefined}
              benefitTypeId={claimCaseInfo?.benefitType || ""}
              totalBenefit={
                claimCaseInfo
                  ? {
                      coPayAmount: claimCaseInfo.coPayAmount,
                      paidAmount: claimCaseInfo.paidAmount,
                      requestAmount: claimCaseInfo.requestAmount,
                    }
                  : undefined
              }
              onUpdate={() => getClaimCaseInfo()}
            />
            <PendingForm loading={loadingClaim} data={claimCaseInfo?.pendings} onAdd={() => getClaimCaseInfo()} />
            <SignOff
              claimCaseId={claimCaseId}
              data={claimCaseInfo?.notes?.filter((note) => note.type === TYPE.NOTE_TYPE.SIGN_OFF) || []}
              reloadData={() => getFirstLoad()}
              loading={loadingClaim}
            />
          </Space>
        </Col>
        <Col span={claimCaseInfo?.claimWaitingTimes || claimCaseInfo?.remarkCopay ? 4 : 0}>
          <div className={styles.moreDetailContainer}>
            <WaitingTime waitingType={claimCaseInfo?.claimWaitingTimes} />
            <RemarkCopay remarkCopay={claimCaseInfo?.remarkCopay} />
            {certificate && certificate.planId && certificate.policyId ? (
              <DocumentLink planId={certificate.planId} policyId={certificate.policyId} />
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ClaimCaseInfoScreen
