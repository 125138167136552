import {QuestionCircleTwoTone} from "@ant-design/icons"
import {Col, DatePicker, Input, Popover, Row, Select, Spin, Tag, Tooltip} from "antd"
import styles from "./DetailClaimForm.module.css"
import React, {useCallback, useEffect, useState} from "react"
import {FORMATTER, KEYS, SETTINGS} from "config/constants"
import moment from "moment"
import _ from "lodash"
import ListOptionsServices from "services/OptionListServices"
import TableList from "app/common/components/TableList"

const {Option} = Select
const {RangePicker} = DatePicker

export type IClaimInfo =
  | {
      email?: string
      diagnosis?: string
      diseases?:
        | {
            name?: string
            key: string
            value: string
            label: string
          }[]
        | {key: string; value: string; label: string}[]
      eventDates?: string
      eventDateRanges?: string[]
      treatmentMethod?: string
      medicalProviderAssessmentId?: string
      medicalProviderAssessmentName?: string
      benefitType?: string
      medicalProviderItem?: {
        key: string
        value: string
        label: string
      }
      dueDate?: string
      effectiveDate?: string
      isDirectBilling?: boolean
    }
  | any
  | undefined

const DetailClaimForm = (props: {
  loading: boolean
  data?: IClaimInfo
  onChange: (value: any, name?: string) => void
}) => {
  const {loading, onChange, data} = props
  const [loadingMedicalProvider, setLoadingMedicalProvider] = useState<boolean>(false)
  const [loadingICDCode, setLoadingICDCode] = useState<boolean>(false)
  const [diseasesOptions, setDiseasesOptions] = useState<any[]>([])
  const [medicalProviderOptions, setMedicalProviderOptions] = useState<any[]>([])
  const [dateRangeMode, setDateRangeMode] = useState<boolean>(true)

  useEffect(() => {
    if (data?.eventDateRanges) {
      setDateRangeMode(true)
    }
  }, [data?.eventDateRanges])

  // GET METADATA
  const getMedicalProvider = useCallback(async (search = "") => {
    setLoadingMedicalProvider(true)
    const response = await ListOptionsServices.getMedicalProvider(search)
    if (response.isSuccess) {
      setMedicalProviderOptions(response.data.collection)
      setLoadingMedicalProvider(false)
    }
  }, [])

  const searchMedicalProvider = _.debounce(getMedicalProvider, SETTINGS.SEARCH_DEBOUNCE_TIME)

  const getDisease = useCallback(async (search = "") => {
    setLoadingICDCode(true)

    const response = await ListOptionsServices.getDisease(search)
    if (response.isSuccess) {
      setDiseasesOptions(response.data.collection)
      setLoadingICDCode(false)
    }
  }, [])

  const searchSearchDisease = _.debounce(getDisease, SETTINGS.SEARCH_DEBOUNCE_TIME)

  useEffect(() => {
    getMedicalProvider().then()
    getDisease().then()
  }, [getMedicalProvider, getDisease])

  const tagRender = (props) => {
    const {label, onClose} = props
    return (
      <Tag color={"blue"} closable={true} onClose={onClose} className={styles.tag}>
        {label}
      </Tag>
    )
  }

  return (
    <Spin spinning={loading}>
      <Row className={styles.infoInputContainer}>
        <Col span={24}>
          <label>Bệnh viện</label>
          <Select
            className={styles.autoComplete}
            showSearch
            autoClearSearchValue={true}
            size={"large"}
            labelInValue
            onChange={(value) => onChange(value, KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.MEDICAL_PROVIDER_ITEM)}
            optionFilterProp="children"
            placeholder="Chọn bệnh viện"
            onSearch={(search: string) => searchMedicalProvider(search)}
            notFoundContent={loadingMedicalProvider ? <Spin size="small" /> : null}
            value={
              data
                ? data.medicalProviderItem
                  ? data.medicalProviderItem
                  : (data.medicalProviderAssessmentId &&
                      data.medicalProviderAssessmentName && {
                        key: data.medicalProviderAssessmentId,
                        value: data.medicalProviderAssessmentId,
                        label: data.medicalProviderAssessmentName,
                      }) ||
                    undefined
                : undefined
            }
          >
            {medicalProviderOptions &&
              medicalProviderOptions.length &&
              medicalProviderOptions
                .filter((med) => (data?.isDirectBilling ? med.isDirectBilling === data.isDirectBilling : true))
                .map((med: {name: string; medicalProviderId: string; province: string}) => (
                  <Option key={med.medicalProviderId} value={med.medicalProviderId}>
                    {`${med.name} - ${med.province}`}
                  </Option>
                ))}
          </Select>
        </Col>
        <Col span={24}>
          <label>Email bệnh viện</label>
          <Input
            value={data?.email}
            onChange={(e) => onChange(e.target.value, KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.EMAIL)}
            size={"large"}
            placeholder="Nhập Email bệnh viện"
          />
        </Col>
        <Col span={24}>
          <Popover
            placement="leftTop"
            trigger="click"
            content={
              <div className={styles.popOverContainer}>
                <TableList
                  title={"Mã ICD đã chọn"}
                  onView={() => {}}
                  data={data?.diseases}
                  search={["value", "label"]}
                  schema={[
                    {
                      title: "Mã",
                      dataIndex: "value",
                      key: "code",
                      render: (text: string) => <Tag color={"geekblue"}>{text}</Tag>,
                    },
                    {
                      title: "Tên",
                      dataIndex: "label",
                      key: "name",
                    },
                  ]}
                />
              </div>
            }
          >
            <Tooltip mouseEnterDelay={0.5} mouseLeaveDelay={0.5} placement={"topLeft"} title={"Xem danh sách chọn"}>
              <label style={{cursor: "pointer"}}>
                ICD Code <QuestionCircleTwoTone style={{fontSize: "18px"}} twoToneColor="#eb2f96" />
              </label>
            </Tooltip>
          </Popover>

          <Select
            className={styles.autoComplete}
            mode="multiple"
            autoClearSearchValue={true}
            size={"large"}
            maxTagCount="responsive"
            labelInValue
            filterOption={false}
            placeholder="Nhập ICD code"
            notFoundContent={loadingICDCode ? <Spin size="small" /> : null}
            tagRender={(props) => tagRender(props)}
            onChange={(value) => onChange(value, KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.DISEASES)}
            value={data?.diseases || undefined}
            onSearch={(search: string) => searchSearchDisease(search)}
          >
            {diseasesOptions.length &&
              diseasesOptions.map((disease: {name: string; value: string}) => (
                <Option key={disease.value} value={disease.value}>
                  {`${disease.value} - ${disease.name}`}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={24}>
          <label style={{cursor: "pointer"}}>Ngày xảy ra sự kiện bảo hiểm</label>
          {dateRangeMode ? (
            <RangePicker
              showTime
              format={FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC}
              size={"large"}
              className={styles.datePicker}
              placeholder={["Nhập khoảng ngày", "Nhập khoảng ngày"]}
              onChange={(value) => {
                onChange(
                  [moment(value && value[0]), moment(value && value[1])],
                  KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.EVENT_DATE_RANGE,
                )
              }}
              value={
                (data?.eventDateRanges &&
                  moment(data?.eventDateRanges[0], FORMATTER.API_DATE_FORMAT).isValid() &&
                  moment(data?.eventDateRanges[1], FORMATTER.API_DATE_FORMAT).isValid() && [
                    moment(data?.eventDateRanges[0], FORMATTER.API_DATE_FORMAT),
                    moment(data?.eventDateRanges[1], FORMATTER.API_DATE_FORMAT),
                  ]) ||
                undefined
              }
            />
          ) : (
            <DatePicker
              format={FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC}
              showTime
              onChange={(value) => {
                onChange(moment(value), KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.EVENT_DATE)
              }}
              value={
                (data?.eventDates &&
                  moment(data?.eventDates, FORMATTER.API_DATE_FORMAT).isValid() &&
                  moment(data?.eventDates, FORMATTER.API_DATE_FORMAT)) ||
                undefined
              }
              size={"large"}
              className={styles.datePicker}
              placeholder={"Nhập ngày"}
            />
          )}
        </Col>
        <Col span={24}>
          <label>Chẩn đoán bệnh</label>
          <Input
            onChange={(e) => onChange(e.target.value, KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.DIAGNOSIS)}
            size={"large"}
            value={data?.diagnosis}
            placeholder="Nhập chẩn đoán bệnh"
          />
        </Col>
        <Col span={24}>
          <label>Phương thức điều trị</label>
          <Input
            onChange={(e) => onChange(e.target.value, KEYS.CREATE_CLAIM.CLAIM_INFO_TYPE.TREATMENT_METHOD)}
            value={data?.treatmentMethod}
            size={"large"}
            placeholder="Nhập phương thức điều trị"
          />
        </Col>
      </Row>
    </Spin>
  )
}

export default DetailClaimForm
