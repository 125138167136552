import {RightSquareOutlined} from "@ant-design/icons"
import {Col, Collapse, Row, Spin} from "antd"
import styles from "./InsuredInfo.module.css"
import React from "react"
import {FORMATTER} from "config/constants"
import {utils} from "config/utils"
import {InsuredInfoData} from "../../index"
import {gql, useQuery} from "@apollo/client"
import _ from "lodash"
import BenefitTag from "app/common/components/BenefitTag"

const {Panel} = Collapse
const GET_BENEFIT_TYPE = gql`
  query benefitType {
    insured_benefit_types {
      comment
      value
    }
  }
`
const InsuredInfo = (props: {loading: boolean; data?: InsuredInfoData; benefitType: string}) => {
  const {loading, data, benefitType} = props
  const {data: BenefitType} = useQuery(GET_BENEFIT_TYPE)

  const getValueBenefitType = (type) => {
    const value = BenefitType && _.find(BenefitType.insured_benefit_types, ["value", type])
    return value;
  }
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse"
    >
      <Panel header="Thông tin hợp đồng BH" key="1">
        <Spin spinning={loading}>
          <Row className={styles.box}>
            <Col span={5}>
              <div>Công ty bảo hiểm</div>
              <div>{data?.insurer}</div>
            </Col>
            <Col span={5}>
              <div>Nhân viên</div>
              <div>{data?.insuredPersonParentName}</div>
            </Col>
            <Col span={4}>
              <div>Người được BH</div>
              <div>{data?.insuredPersonName}</div>
            </Col>
            <Col span={6}>
              <div>Hiệu lực / kết thúc</div>
              <div>
              {utils.parseToStringLocalDateTime(data?.effectiveDueDate, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}
              </div>
            </Col>
            <Col span={3}>
              <div>Quyền lợi</div>
              <BenefitTag
                text={getValueBenefitType(benefitType)?.value || "---"}
                textReplace={getValueBenefitType(benefitType)?.comment || "---"}
              />
            </Col>
          </Row>
        </Spin>
      </Panel>
    </Collapse>
  )
}

export default InsuredInfo
