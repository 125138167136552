import React, {useState} from "react"
import styles from "./UploadComponent.module.css"
import DragAndDrop from "../DragAndDrop"
import {DeleteTwoTone, FileTwoTone} from "@ant-design/icons"
import {Select} from "antd"
import _ from "lodash"

interface IFileData {
  file?: File
  type?: string
}

const UploadComponent = (props: {onChange: (any) => void; type: string}) => {
  const [files, setFiles] = useState<IFileData[]>([])

  const handleDrop = (fileDrop) => {
    const fileList = _.clone(files)
    const newFileDrop = [...fileDrop]
    newFileDrop.forEach((file) => {
      const newObject = {
        file: file,
        type: props.type || "",
      }
      fileList.push(newObject)
    })
    setFiles(fileList)
    if (props.onChange) {
      props.onChange(fileList)
    }
  }

  const handleChange = (value) => {
    if (props.onChange) {
      setFiles(value)
      props.onChange(value)
    }
  }

  const UploadList = (props: {data: IFileData[]; onChange: (any) => void}) => {
    const {data} = props
    const handleChange = (value, file) => {
      if (value && file && data) {
        const newFile = _.clone(file)
        const fileListData = _.clone(data)
        newFile.type = value
        const index = _.findIndex(fileListData, file)
        fileListData.splice(index, 1, newFile)
        if (props.onChange) {
          props.onChange(fileListData)
        }
      }
    }

    const onRemoveItem = (file, index) => {
      const fileListData = _.clone(data)
      fileListData.splice(index, 1)
      if (props.onChange) {
        props.onChange(fileListData)
      }
    }

    const option = [
      {
        label: "Giấy ra viện",
        value: "DischargePaper",
      },
      {
        label: "Hóa đơn",
        value: "InvoicePaper",
      },
      {
        label: "Toa thuốc",
        value: "PrescriptionPaper",
      },
      {
        label: "Giấy tờ khác",
        value: "OtherPaper",
      },
    ]

    return (
      <div className={styles.uploadList}>
        {data &&
          data.map((document, index) => (
            <div className={styles.itemContainer} key={document.file?.name}>
              <div>
                <FileTwoTone />
              </div>
              <div className={styles.fileName}>{document.file?.name}</div>
              <div className={styles.selection}>
                <Select
                  className={styles.selectionStyles}
                  placeholder={"Chọn loại giấy tờ"}
                  size={"small"}
                  options={option}
                  value={document.type || undefined}
                  onChange={(value) => handleChange(value, document)}
                />
              </div>
              <div className={styles.deleteIcon} onClick={() => onRemoveItem(document, index)}>
                <DeleteTwoTone />
              </div>
            </div>
          ))}
      </div>
    )
  }

  return (
    <div className={styles.uploadContainer}>
      <DragAndDrop handleDrop={handleDrop} />
      <UploadList data={files} onChange={(value) => handleChange(value)} />
    </div>
  )
}

export default UploadComponent
