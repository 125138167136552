import React, {useCallback, useEffect, useState} from "react"
import styles from "./PlanScreen.module.css"
import PlanServices from "services/PlanServices"
import {PORTAL_PATH} from "app/portal/config/routes"
import {listSchema} from "./schema"
import TableList from "app/common/components/TableList"
import {useHistory, useParams} from "react-router"
import {TYPE} from "config/constants"

interface IPlanData {
  key?: string
  code?: string
  createdAt?: string
  planId: string
  name?: string
  updatedAt?: string
}

const PlanScreen = () => {
  const [data, setData] = useState<IPlanData[]>([])
  const [loading, setLoading] = useState(true)
  const {companyId} = useParams<{companyId: string}>()
  const history = useHistory()
  const companyType = new URL(window.location.href).searchParams.get("role") || ""

  const getFirstLoad = useCallback(async () => {
    setLoading(true)
    const response = await PlanServices.getPlans()
    if (response.isSuccess) {
      setData(
        response.data.collection.map((item: IPlanData) => {
          item.key = item.code
          return item
        }),
      )
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getFirstLoad().then()
  }, [getFirstLoad])

  const mapPlanDataByCompanyType = useCallback(
    (planList, companyType) => {
      if (companyType) {
        let newPlanData = []
        switch (companyType) {
          case TYPE.COMPANY_TYPE_GQL.CORPORATE:
            newPlanData = planList.filter((policy) => policy.corporateId === companyId)
            break
          case TYPE.COMPANY_TYPE_GQL.INSURER:
            newPlanData = planList.filter((policy) => policy.insurerId === companyId)
            break
        }
        return newPlanData
      }
      return planList
    },
    [companyId],
  )

  const goToPlanDetail = (id: string) => {
    let path = PORTAL_PATH.PLAN_DETAIL
    path = path.replace(":planId", id)
    history.push(path)
  }

  return (
    <div className={styles.planList}>
      <TableList
        title={"sản phẩm"}
        onView={(record) => goToPlanDetail(record.planId)}
        data={mapPlanDataByCompanyType(data, companyType)}
        schema={listSchema}
        loading={loading}
      />
    </div>
  )
}

export default PlanScreen
