import React, {useState} from "react"
import styles from "./PlanBalanceScreen.module.css"
import BaseForm from "app/common/components/Form"
import {balanceSchema} from "./schema"
import _ from "lodash"
import PlanServices from "services/PlanServices"
import {PORTAL_PATH} from "app/portal/config/routes"
import {useHistory, useParams} from "react-router"
import {useSelector} from "react-redux"
import {TypesType} from "store/interface"
import {TYPE} from "config/constants"

interface IStates {
  loading: boolean
  data?: IBalanceData | any
  option: {
    [key: string]: {name: string; value: string | boolean | number}[]
  }
}

export interface IBalanceData {
  name?: string
  value?: string
  type?: string
  description?: string
}

const PlanBalanceScreen = () => {
  const planBalanceTypes = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.PLAN_BALANCE_TYPE],
  )
  const [loading, setLoading] = useState(false)
  const [balanceData, setBalanceData] = useState<IStates["data"]>({})
  const {planId} = useParams<{planId: string}>()
  const history = useHistory()

  const handleChange = (key: string, value: string) => {
    let newData = _.clone(balanceData)
    if (value) {
      if (!newData) {
        newData = {
          code: "",
          name: "",
        }
      }
      newData[key] = value
      setBalanceData(newData)
    } else {
      delete newData[key]
      setBalanceData(newData)
    }
  }

  const createPlanBalance = async () => {
    setLoading(true)
    if (balanceData) {
      balanceData.value = parseInt(balanceData.value)
      const response = await PlanServices.createPlanBalance(planId, balanceData)
      if (response.isSuccess && response.data) {
        let path = PORTAL_PATH.PLAN_BALANCES_DETAIL.replace(":planId", planId)
        path = path.replace(":planBalanceId", response.data)
        history.push(path)
      }
    }
    setLoading(false)
  }

  const onSave = async () => {
    await createPlanBalance()
  }

  return (
    <div className={styles.balanceCreateContainer}>
      <div className={styles.detail}>
        <BaseForm
          title={"Thông tin chi tiết" || undefined}
          className={styles.containerForm}
          schemas={balanceSchema}
          option={{type: planBalanceTypes}}
          loading={loading}
          onChange={(key: string, value: string) => handleChange(key, value)}
          data={balanceData || {}}
          onCancel={() => history.goBack()}
          onSave={() => onSave()}
        />
      </div>
    </div>
  )
}

export default PlanBalanceScreen
