import { FORMATTER } from "../../../../../../../config/constants"
import { utils } from "../../../../../../../config/utils"

const LAListSchema = [
  {
    title: "Người được bảo hiểm",
    dataIndex: "insuredPersonName",
    key: "insuredPersonName",
    width: 180,
    fixed: "left",
  },
  {
    title: "Mã thẻ",
    dataIndex: "certificateCode",
    key: "certificateCode",
    width: 250,
    fixed: "left",
  },
  {
    title: "Tên gói",
    dataIndex: "planName",
    key: "planName",
    width: 200,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 150,
  },
  {
    title: "Hiệu lực",
    dataIndex: "issuedAt",
    key: "issuedAt",
    width: 150,
    render: (text: string) =>
      utils.parseToStringLocalDateTime(
        text,
        FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC,
      ),
  },
  {
    title: "Hết hiệu lực",
    dataIndex: "duedAt",
    key: "duedAt",
    width: 150,
    render: (text: string) =>
      utils.parseToStringLocalDateTime(
        text,
        FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC,
      ),
  },
]

const PolicyOwnerSchema = [
  {
    title: "Tên chủ hợp đồng",
    dataIndex: "insuredPersonName",
    key: "insuredPersonName",
    width: 180,
    fixed: "left",
  },
  {
    title: "Mã thẻ",
    dataIndex: "certificateCode",
    key: "certificateCode",
    width: 250,
    fixed: "left",
  },
  {
    title: "Tên gói",
    dataIndex: "planName",
    key: "planName",
    width: 200,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 150,
  },
  {
    title: "Lương",
    dataIndex: "salary",
    key: "salary",
    width: 150,
    render: (text) =>
      (text && text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || "",
  },
  {
    title: "Hiệu lực",
    dataIndex: "issuedAt",
    key: "issuedAt",
    width: 150,
    render: (text: string) =>
      utils.parseToStringLocalDateTime(
        text,
        FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC,
      ),
  },
  {
    title: "Hết hiệu lực",
    dataIndex: "duedAt",
    key: "duedAt",
    width: 150,
    render: (text: string) =>
      utils.parseToStringLocalDateTime(
        text,
        FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC,
      ),
  },
]

const SalaryFormSchema = [
  {
    key: "value",
    label: "Giá trị",
    placeholder: "Giá trị",
    component: "inputNumber",
    required: true,
    type: "currency",
    layout: "24",
  },
  {
    key: "effectiveAt",
    label: "Hiệu lực",
    placeholder: "Ngày hiệu lực",
    component: "date",
    required: true,
    layout: "24",
  },
]

export { LAListSchema, PolicyOwnerSchema, SalaryFormSchema }
