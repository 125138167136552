import {LOGOUT, SET_TOKEN} from '../types'
import { SetTokenAction } from '../interface'

export default function token(state = localStorage.getItem('token') || '', action: SetTokenAction) {
  let newState = state
  if (action.type === SET_TOKEN) {
    newState = action.token;
    localStorage.setItem('token',action.token);
  }
  if (action.type === LOGOUT) {
    newState = "";
    localStorage.removeItem('token');
  }
  return newState
}
