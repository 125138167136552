import ClaimInfoScreen from "./screens/ClaimCaseInfoScreen"
import ClaimPaymentScreen from "./screens/ClaimCasePaymentScreen"
import SampleScreen from "app/common/screens/SampleScreen"
import ClaimCorrespondenceScreen from "./screens/ClaimCaseCorrespondenceScreen"
import ClaimDocumentScreen from "./screens/ClaimCaseDocumentScreen"
import ClaimOriginalScreen from "./screens/ClaimCaseOriginalScreen"

export const CLAIM_CASE_PATH = {
  CHECK_CASE: "/portal/claimCase/:claimCaseId/presentCase",
  GALLERY: "/portal/claimCase/:claimCaseId/gallery",
  ORIGINAL: "/portal/claimCase/:claimCaseId/original",
  HISTORY_ACTIVITY: "/portal/claimCase/:claimCaseId/historyActivity",
  PAYMENT_CLAIM: "/portal/claimCase/:claimCaseId/payment",
  CORRESPONDENCE_CLAIM: "/portal/claimCase/:claimCaseId/correspondence",
  CLAIM_CASE_INFO: "/portal/claimCase/:claimCaseId/info",
}

const routes = [
  {
    key: "presentCase",
    path: CLAIM_CASE_PATH.CHECK_CASE,
    comp: ClaimCorrespondenceScreen,
    title: "Trình case",
    extendProps: {isPresentCase: true},
  },
  {
    key: "gallery",
    path: CLAIM_CASE_PATH.GALLERY,
    comp: ClaimDocumentScreen,
    title: "Hình ảnh",
  },
  {
    key: "original",
    path: CLAIM_CASE_PATH.ORIGINAL,
    comp: ClaimOriginalScreen,
    title: "Claim gốc",
  },
  {
    key: "history",
    path: CLAIM_CASE_PATH.HISTORY_ACTIVITY,
    comp: SampleScreen,
    title: "Lịch sử hoạt động",
  },
  {
    key: "paymentClaim",
    path: CLAIM_CASE_PATH.PAYMENT_CLAIM,
    comp: ClaimPaymentScreen,
    title: "Thanh toán",
  },
  {
    key: "corresponentClaim",
    path: CLAIM_CASE_PATH.CORRESPONDENCE_CLAIM,
    comp: ClaimCorrespondenceScreen,
    title: "Correspondences",
  },
  {
    key: "editClaim",
    path: CLAIM_CASE_PATH.CLAIM_CASE_INFO,
    comp: ClaimInfoScreen,
    title: "Claim Case",
  },
]

export default routes
