import Service from "../services"
import endPoints from "../endpoints"
import {axiosResponseToData, defaultResponse} from "../index"
import {companyType} from "app/portal/screens/AdminPortal/CompanyScreen/screens/CompanyInfo"
import {utils} from "config/utils"
import {policyType} from "app/portal/screens/AdminPortal/PolicyScreen/screens/PolicyDetailScreen"
import {InsuredPersonTypeUpdate} from "app/portal/screens/AdminPortal/InsuredPersonScreen/screens/detail"
import {InsuredPersonTypeCreate} from "app/portal/screens/AdminPortal/InsuredPersonScreen/screens"
import {MedicalProviderUpdateType} from "../../app/portal/screens/AdminPortal/MedicalProviderScreen/screens/detail"
import {MedicalProviderCreateType} from "../../app/portal/screens/AdminPortal/MedicalProviderScreen/screens"
import {remarkWaitingTimesType} from "../../app/portal/screens/AdminPortal/PolicyScreen/screens/PolicyInfoScreen"

const AdminServices = {
  /**
   *  @description Company services
   */
  getCompanyList: async (type?: string) => {
    const response = await Service(true).get(endPoints.COMPANY_LIST(type))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getCompanyById: async (id: string) => {
    const response = await Service(true).get(endPoints.COMPANY_DETAIL(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createCompany: async (companyData: companyType, type?: string) => {
    const response = await Service(true).post(
      endPoints.COMPANY_LIST(type),
      utils.pick(companyData, "name", "companyCode", "phone", "taxCode", "logo", "description", "types"),
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateCompany: async (id: string, companyData: companyType) => {
    const response = await Service(true).put(
      endPoints.COMPANY_DETAIL(id),
      utils.pick(companyData, "name", "companyCode", "phone", "taxCode", "logo", "description", "types"),
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getCardsByInsurerId: async (insurerId: string) => {
    const response = await Service(true).get(endPoints.COMPANY_CARD_LIST(insurerId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  /**
   *  @description Policy services
   */
  getPolicyList: async () => {
    const response = await Service(true).get(endPoints.POLICY_LIST)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getPolicyById: async (id: string) => {
    const response = await Service(true).get(endPoints.POLICY_DETAIL(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createPolicy: async (policyData: policyType) => {
    const response = await Service(true).post(endPoints.POLICY_LIST, policyData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updatePolicy: async (id: string, policyData: policyType) => {
    const response = await Service(true).put(
      endPoints.POLICY_DETAIL(id),
      utils.pick(policyData, "policyNumber", "effectiveDates", "corporateId", "insurerId", "isConfirm", "remarkCopay"),
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  saveRemarkWaitingTimes: async (id: string, remarkWaitingTimesData: remarkWaitingTimesType[]) => {
    const response = await Service(true).put(endPoints.POLICY_REMARK_WAITING_TIME(id), {models: remarkWaitingTimesData})
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  /**
   *  @description LA services
   */
  getLAByPolicyId: async (id: string) => {
    const response = await Service(true).get(endPoints.POLICY_LA(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  importData: async (file: FormData, type: string, callback?) => {
    file.append("Type", type)
    const response = await Service(true).upload(endPoints.IMPORT, file, callback)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  /**
   *  @description User info services
   */
  getInsuredPersonList: async (search?: string) => {
    const response = await Service(true).get(endPoints.INSURED_PERSON_LIST(search))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getInsuredPersonById: async (id: string) => {
    const response = await Service(true).get(endPoints.INSURED_PERSON_DETAIL(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getInsuredPersonCertificates: async (id: string) => {
    const response = await Service(true).get(endPoints.INSURED_PERSON_CERTIFICATE(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createInsuredPerson: async (InsuredPersonData: InsuredPersonTypeCreate) => {
    const response = await Service(true).post(endPoints.INSURED_PERSON_LIST(), InsuredPersonData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateInsuredPerson: async (id: string, InsuredPersonData: InsuredPersonTypeUpdate) => {
    const response = await Service(true).put(endPoints.INSURED_PERSON_DETAIL(id), InsuredPersonData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  activateCertificate: async (policyId: string, selectedIds: {selectedIds: string[]}) => {
    const response = await Service(true).post(endPoints.ACTIVATE_CERTIFICATE(policyId), selectedIds)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getCertificateDetail: async (certificateId: string) => {
    const response = await Service(true).get(endPoints.CERTIFICATE_DETAILS_BY_ID(certificateId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getListCertificateSalary: async (certificateId: string) => {
    const response = await Service(true).get(endPoints.CERTIFICATE_SALARY(certificateId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createCertificateSalary: async (certificateId: string, salaryData: any) => {
    const response = await Service(true).post(endPoints.CERTIFICATE_SALARY(certificateId), salaryData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getCertificateSalaryDetail: async (certificateId: string, certificateSalaryHistoryId: string) => {
    const response = await Service(true).get(
      endPoints.CERTIFICATE_SALARY_DETAIL(certificateId, certificateSalaryHistoryId),
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateCertificateSalaryDetail: async (certificateId: string, certificateSalaryHistoryId: string, salaryData) => {
    const response = await Service(true).put(
      endPoints.CERTIFICATE_SALARY_DETAIL(certificateId, certificateSalaryHistoryId),
      salaryData,
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  deleteCertificateSalaryDetail: async (certificateId: string, certificateSalaryHistoryId: string) => {
    const response = await Service(true).delete(endPoints.CERTIFICATE_SALARY(certificateId), certificateSalaryHistoryId)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  /**
   * @description Medical Provider services
   */
  getMedicalProviders: async (search: string, limit?: number) => {
    const response = await Service(true).get(endPoints.MEDICAL_PROVIDER(search, limit))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createMedicalProvider: async (medicalProviderData: MedicalProviderCreateType) => {
    const response = await Service(true).post(endPoints.MEDICAL_PROVIDER(""), medicalProviderData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getMedicalProviderDetail: async (id: string) => {
    const response = await Service(true).get(endPoints.MEDICAL_PROVIDER_DETAIL(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateMedicalProvider: async (id: string, medicalProviderData: MedicalProviderUpdateType) => {
    const response = await Service(true).put(endPoints.MEDICAL_PROVIDER_DETAIL(id), medicalProviderData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
}

export default AdminServices
