const paymentSchema = [
  {
    key: "paymentBankName",
    label: "Ngân hàng",
    placeholder: "Nhập ngân hàng",
    component: "select",
    required: true,
    layout: "12",
  },
  {
    key: "paymentBankBranch",
    label: "Chi nhánh",
    placeholder: "Nhập chi nhánh ngân hàng",
    component: "input",
    type: "text",
    required: true,
    layout: "12",
  },
  {
    key: "paymentBankCity",
    label: "Tỉnh/TP",
    placeholder: "Nhập tỉnh/tp",
    component: "select",
    required: true,
    layout: "12",
  },
  {
    key: "paymentAccountNumber",
    label: "Số tài khoản",
    placeholder: "Nhập số tài khoản",
    component: "input",
    type: "text",
    required: true,
    layout: "12",
  },
  {
    key: "paymentAccountName",
    label: "Chủ tài khoản",
    placeholder: "Nhập chủ tài khoản",
    component: "input",
    type: "text",
    layout: "12",
  },
]

export {paymentSchema}
