import React from "react"
import styles from "./MetaDataClaimInfoReadOnly.module.css"
import {RightSquareOutlined} from "@ant-design/icons"
import {Col, Collapse, Row, Spin} from "antd"
import {MetaDataClaimInfoInterface} from "../../index"
const {Panel} = Collapse

const MetaDataClaimInfoReadOnly = (props: {data?: MetaDataClaimInfoInterface[]; loading: boolean}) => {
  const {data, loading} = props
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse"
    >
      <Panel header="Chi phí điều trị" key="1">
        <Spin spinning={loading}>
          <Row className={styles.rowContainer}>
            {data &&
              data.map((item) => (
                <>
                  {(item.isShow && (
                    <Col key={item.name} span={8}>
                      <label>{item.name}</label>
                      <p>{(item && item.value && item.value.toLocaleString()) + " đồng" || "---"}</p>
                    </Col>
                  )) ||
                    ""}
                </>
              ))}
          </Row>
        </Spin>
      </Panel>
    </Collapse>
  )
}

export default MetaDataClaimInfoReadOnly
