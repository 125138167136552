import React from "react"
import styles from "./InfoBlock.module.css"
import {Spin} from "antd"

type dataType = {
  [key: string]: string | number | boolean
}

export type schemaType = {
  key: string
  label: string
  render?: (text, record) => JSX.Element | string
}[]

const InfoBlock = (props: {data?: dataType; schema: schemaType; loading?: boolean}) => {
  const {data, schema, loading} = props
  return (
    <Spin spinning={loading}>
      <div className={styles.container}>
        {schema && schema.length
          ? schema.map((s) => (
              <div key={s.key} className={styles.blockDetail}>
                <div className={styles.title}>{s.label}: </div>
                <div className={styles.content}>
                  {(data && (s.render ? s.render(data[s.key], data) : data[s.key])) || ""}
                </div>
              </div>
            ))
          : ""}
      </div>
    </Spin>
  )
}

export default InfoBlock
