import {SET_ME} from "../types"
import {MeType, SetMeAction} from "../interface"

const me = (state: MeType = null, action: SetMeAction) => {
  let newState = state
  if (action.type === SET_ME) {
    newState = action.me
    newState ? (newState.roles = action.me.companies.map((c) => c.user_role)) : {}
    localStorage.setItem("me", JSON.stringify(newState))
  }
  return newState
}

export default me
