import React, {useState} from "react"
import styles from "./MedicalProvider.module.css"
import {Checkbox, Modal, Popconfirm} from "antd"
import TableList from "app/common/components/TableList"
import OptionListServices from "services/OptionListServices"
import {METADATA, TYPE} from "config/constants"
import _ from "lodash"
import {CheckCircleTwoTone, CloseCircleTwoTone, DeleteTwoTone} from "@ant-design/icons"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../../../../../store/interface"

interface IMedicalProviderListData {
  medicalProviderId: string
  name?: string
  address?: string
  phone?: string
  email?: string
  province?: string
  description?: string
  type?: string
  isDirectBilling?: boolean
}

interface medicalProviderData {
  applyToAllMedicalProvider: boolean
  medicalProviderIds: string[]
}

const MedicalProvider = (props: {
  onChange: (data, checkAll) => void
  data: medicalProviderData
  initialData?: string[]
  onDelete: (data) => void
}) => {
  const {onChange, data, initialData, onDelete} = props
  const [loading, setLoading] = useState<boolean>(false)
  const [medicalProviderListData, setMedicalProviderListData] = useState<IMedicalProviderListData[]>([])
  const [medicalProviderSelectedData, setMedicalProviderSelectedData] = useState<IMedicalProviderListData[]>([])
  const [rowSelectionKeys, setRowSelectionKeys] = useState<string[]>()
  const [cityList, setCityList] = useState<{name: string; value: string}[]>()
  const medicalProviderType = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.MEDICAL_PROVIDER_TYPE],
  )
  const [showModal, setShowModal] = useState<boolean>()

  const getCityList = async () => {
    const city = await OptionListServices.getMetadata(METADATA.CITY)
    if (city && city.data && city.data.collection) {
      setCityList(city.data.collection)
    }
  }

  const getMedicalProviderList = async () => {
    const response = await OptionListServices.getMedicalProvider()
    if (response.isSuccess) {
      setMedicalProviderListData(response.data.collection)
    }
  }

  useMountEffect(() => {
    setLoading(true)
    getMedicalProviderList().then()
    getCityList().then()
    setLoading(false)
  })

  const onCheckBoxAll = (value) => {
    onChange(data.medicalProviderIds, value.target.checked)
  }

  const rowSelectionUpdate = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setRowSelectionKeys(selectedRowKeys.toString().split(","))
      onChange(selectedRowKeys.toString().split(","), data.applyToAllMedicalProvider)
    },
    selectedRowKeys: rowSelectionKeys,
  }

  const updateMedicalProviderSelectedData = (medicalProviderIds) => {
    const newMedicalProviderSelectedData = _.clone(medicalProviderSelectedData) || []
    if (medicalProviderIds && medicalProviderListData) {
      medicalProviderListData.forEach((m) => {
        if (
          medicalProviderIds.includes(m.medicalProviderId) &&
          _.filter(medicalProviderSelectedData, ["medicalProviderId", m.medicalProviderId]).length === 0
        ) {
          newMedicalProviderSelectedData.push(m)
        }
        if (
          !medicalProviderIds.includes(m.medicalProviderId) &&
          _.filter(medicalProviderSelectedData, ["medicalProviderId", m.medicalProviderId]).length != 0
        ) {
          _.remove(newMedicalProviderSelectedData, (n) => n.medicalProviderId === m.medicalProviderId)
        }
      })
      setMedicalProviderSelectedData(newMedicalProviderSelectedData)
    }
  }

  useUpdateEffect(() => {
    updateMedicalProviderSelectedData(data.medicalProviderIds)
    setRowSelectionKeys(data.medicalProviderIds)
  }, [data, medicalProviderListData])

  const listSchema = [
    {
      title: "Tên bệnh viện",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "Tỉnh thành",
      dataIndex: "province",
      key: "province",
      filters:
        cityList && cityList.length
          ? cityList.map((b: {name: string; value: string}) => ({
              text: b.name,
              value: b.value,
            }))
          : [],
      onFilter: (value, record) => record.province === value,
      render: (text: string) => <span>{_.find(cityList, (b: {value: string}) => b.value === text)?.value || ""}</span>,
    },
    {
      title: "Loại",
      dataIndex: "type",
      key: "type",
      filters:
        medicalProviderType && medicalProviderType.length
          ? medicalProviderType.map((b: {name: string; value: string}) => ({
              text: b.name,
              value: b.value,
            }))
          : [],
      onFilter: (value, record) => record.type === value,
      render: (text: string) => (
        <span>{_.find(medicalProviderType, (b: {value: string}) => b.value === text)?.name || ""}</span>
      ),
    },
    {
      title: "Bảo lãnh viện phí",
      dataIndex: "isDirectBilling",
      key: "isDirectBilling",
      width: 200,
      filters: [
        {
          text: "Có",
          value: true,
        },
        {
          text: "Không",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isDirectBilling === value,
      render: (text: boolean) => (
        <span>
          {(text && (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
              <span
                style={{
                  color: "#52c41a",
                  fontWeight: "bold",
                }}
              >
                Có
              </span>
            </>
          )) || (
            <>
              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
              <span
                style={{
                  color: "var(--red-alert)",
                  fontWeight: "bold",
                }}
              >
                Không
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Tác vụ",
      render: (value, row) =>
        (initialData?.includes(row.medicalProviderId) && (
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title="Bạn có chắc muốn xóa"
              onConfirm={() => onDelete(row.medicalProviderId)}
              okButtonProps={{
                danger: true,
              }}
              okText={"Xóa"}
            >
              <DeleteTwoTone
                twoToneColor="#EF5350"
                style={{
                  fontSize: 18,
                  textAlign: "center",
                }}
              />
            </Popconfirm>
          </div>
        )) ||
        "",
    },
  ]

  return (
    <div className={styles.medicalProviderContainer}>
      <div className={styles.checkAll}>
        <Checkbox checked={data.applyToAllMedicalProvider} className={styles.checkBox} onChange={onCheckBoxAll}>
          Áp dụng cho tất cả cơ sở y tế
        </Checkbox>
      </div>
      <div className={styles.containTable}>
        {(data.applyToAllMedicalProvider && <div className={styles.overlay} />) || ""}
        <TableList
          className={styles.tableList}
          title={"Cơ sở y tế được áp dụng"}
          onView={() => {}}
          schema={listSchema}
          search={["name"]}
          loading={loading}
          onCreate={() => setShowModal(true)}
          onCreateTitle={"Cập nhật"}
          data={medicalProviderSelectedData.map((med) => ({
            ...med,
            key: med.medicalProviderId,
          }))}
        />
      </div>
      <Modal
        title="Chọn cơ sở y tế"
        centered
        visible={showModal}
        width={1200}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
      >
        <div className={styles.modalActivateContent}>
          <TableList
            className={styles.tableList}
            rowSelection={rowSelectionUpdate}
            title={"Cơ sở y tế được áp dụng"}
            onView={() => {}}
            schema={_.filter(listSchema, (schema) => {
              return schema.key != "action"
            })}
            search={["name"]}
            loading={loading}
            data={medicalProviderListData.map((med) => ({
              ...med,
              key: med.medicalProviderId,
            }))}
          />
        </div>
      </Modal>
    </div>
  )
}

export default MedicalProvider
