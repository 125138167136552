import Service from "../services"
import endPoints from "../endpoints"
import {axiosResponseToData, defaultResponse} from "../index"

const FileService = {
  getFileList: async (
    type: string,
    claimRequestId?: string,
    policyId?: string,
    planId?: string,
    isWithTrashed?: boolean,
  ) => {
    const response = await Service(true).get(endPoints.FILE(type, claimRequestId, policyId, planId, isWithTrashed))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  uploadFileClaim: async (claimId: string, file: FormData = new FormData(), callback?: (any) => void) => {
    const response = await Service(true).upload(endPoints.DOCUMENT_CLAIM(claimId), file, callback)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getClaimCaseFileList: async (
    type: string,
    claimCaseId?: string,
    policyId?: string,
    planId?: string,
    isWithTrashed?: boolean,
  ) => {
    const response = await Service(true).get(
      endPoints.CLAIM_CASE_FILE(type, claimCaseId, policyId, planId, isWithTrashed),
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  uploadCardBack: async (id: string, file: FormData = new FormData(), callback?: (any) => void) => {
    const response = await Service(true).upload(endPoints.CARD_BACK(id), file, callback)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  uploadFilePlan: async (planId: string, file: FormData = new FormData(), callback?: (any) => void) => {
    const response = await Service(true).upload(endPoints.PLAN_DOCUMENT(planId), file, callback)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  uploadFilePolicy: async (policyId: string, file: FormData = new FormData(), callback?: (any) => void) => {
    const response = await Service(true).upload(endPoints.POLICY_DOCUMENT(policyId), file, callback)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  uploadFileClaimCase: async (claimCaseId: string, file: FormData = new FormData(), callback?: (any) => void) => {
    const response = await Service(true).upload(endPoints.DOCUMENT_CLAIM_CASE(claimCaseId), file, callback)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateFilePlan: async (planId: string, planDocumentId: string) => {
    const response = await Service(true).put(endPoints.PLAN_DOCUMENT_DETAIL(planId, planDocumentId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateFilePolicy: async (policyId: string, policyDocumentId: string) => {
    const response = await Service(true).put(endPoints.POLICY_DOCUMENT_DETAIL(policyId, policyDocumentId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateFileClaimCase: async (claimId: string, claimDocumentId: string) => {
    const response = await Service(true).put(endPoints.CLAIM_CASE_DOCUMENT_DETAIL(claimId, claimDocumentId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
}

export default FileService
