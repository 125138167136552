import Service from "../services"
import endPoints from "../endpoints"
import {axiosResponseToData, defaultResponse} from "../index"

interface CardData {
  insurerId?: string
  content?: string
  name?: string
}

const TemplateCardServices = {
  getList: async () => {
    const response = await Service(true).get(endPoints.CARD_LIST)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getDetail: async (id: string) => {
    const response = await Service(true).get(endPoints.CARD_DETAIL(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createCard: async (cardData: CardData) => {
    const response = await Service(true).post(endPoints.CARD_LIST, cardData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateCard: async (id: string, cardData: CardData) => {
    const response = await Service(true).put(endPoints.CARD_DETAIL(id), cardData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
}

export default TemplateCardServices
