import Service from "../services"
import endPoints from "../endpoints"
import {axiosResponseToData, defaultResponse} from "../index"
import {IBalanceData} from "app/portal/screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBalanceScreen"
import {WaitingTimeType} from "app/portal/screens/AdminPortal/PolicyScreen/screens/PolicyInfoScreen"
import {IPlanDetailData, remarkCopayType} from "../../app/portal/screens/AdminPortal"
import {PlanDetailTypeUpdate} from "../../app/portal/screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanInfoScreen"

const PlanServices = {
  getPlans: async (policyId?: string) => {
    const response = await Service(true).get(endPoints.PLAN_LIST(policyId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createPlanByPolicy: async (policyId: string, planData) => {
    const response = await Service(true).post(endPoints.CREATE_PLAN_BY_POLICY(policyId), planData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createWaitingTime: async (policyId: string, waitingTimeData: WaitingTimeType) => {
    const response = await Service(true).post(endPoints.POLICY_WAITING_TIME(policyId), waitingTimeData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updateWaitingTime: async (policyId: string, policyWaitingTimeId: string, waitingTimeData: WaitingTimeType) => {
    const response = await Service(true).put(
      endPoints.POLICY_WAITING_TIME(policyId, policyWaitingTimeId),
      waitingTimeData,
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  deleteWaitingTime: async (policyId: string, policyWaitingTimeId: string) => {
    const response = await Service(true).delete(endPoints.POLICY_WAITING_TIME(policyId), policyWaitingTimeId)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getInsuredBenefit: async () => {
    const response = await Service(true).get(endPoints.INSURED_BENEFIT)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getPlanBenefit: async (planId: string) => {
    const response = await Service(true).get(endPoints.PLAN_BENEFIT(planId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getPlanBenefitDetail: async (planId: string, planBenefitId: string) => {
    const response = await Service(true).get(endPoints.PLAN_BENEFIT_DETAIL(planId, planBenefitId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updatePlanBenefit: async (planId: string, planBenefits: {insuredBenefitId: string; isDirectBilling: boolean}[]) => {
    const response = await Service(true).post(endPoints.PLAN_BENEFIT(planId), planBenefits)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getPlanDetail: async (id: string) => {
    const response = await Service(true).get(endPoints.PLAN_DETAIL(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updatePlanDetail: async (id: string, planData) => {
    const response = await Service(true).put(endPoints.PLAN_DETAIL(id), planData)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getPlanBalances: async (id: string) => {
    const response = await Service(true).get(endPoints.PLAN_BALANCE_LIST(id))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getPlanBalanceDetail: async (planId: string, planBalanceId: string) => {
    const response = await Service(true).get(endPoints.PLAN_BALANCE_DETAIL(planId, planBalanceId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createPlanBalance: async (planId: string, planBalanceData: IBalanceData) => {
    const response = await Service(true).post(endPoints.PLAN_BALANCE_LIST(planId), {
      ...planBalanceData,
    })
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updatePlanBalance: async (planId: string, planBalanceId: string, planBalanceData: IBalanceData) => {
    const response = await Service(true).put(endPoints.PLAN_BALANCE_DETAIL(planId, planBalanceId), {...planBalanceData})
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  assignBenefitBalancePlan: async (planId: string, planBalanceId: string, planBenefitIdData: Array<string>) => {
    const response = await Service(true).post(
      endPoints.ASSIGN_BENEFIT_BALANCE_PLAN(planId, planBalanceId),
      planBenefitIdData,
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  deleteBenefitOfBalance: async (planId: string, planBalanceId: string, planInsuredBenefitId: string) => {
    const response = await Service(true).delete(
      endPoints.ASSIGN_BENEFIT_BALANCE_PLAN(planId, planBalanceId),
      planInsuredBenefitId,
    )
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  /***
   *
   * @description Plan Detail Services
   */
  getPlanDetailsByPlanIdAsync: async (planId: string) => {
    const response = await Service(true).get(endPoints.PLAN_DETAIL_LIST(planId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getPlanDetailByIdAsync: async (planId: string, planDetailId: string) => {
    const response = await Service(true).get(endPoints.PLAN_DETAIL_INFO(planId, planDetailId))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  createPlanDetail: async (planId: string, planDetailData: IPlanDetailData) => {
    const response = await Service(true).post(endPoints.PLAN_DETAIL_LIST(planId), {
      ...planDetailData,
    })
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  deletePlanDetail: async (id: string, planDetailId: string) => {
    const response = await Service(true).delete(endPoints.PLAN_DETAIL_LIST(id), planDetailId)
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  updatePlanDetailByIdAsync: async (
    planId: string,
    planDetailId: string,
    planDetailTypeUpdate: PlanDetailTypeUpdate,
  ) => {
    const response = await Service(true).put(endPoints.PLAN_DETAIL_INFO(planId, planDetailId), {
      ...planDetailTypeUpdate,
    })
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  saveRemarkCopay: async (id: string, remarkCopayData: remarkCopayType[]) => {
    const response = await Service(true).put(endPoints.PLAN_REMARK_COPAY(id), {models: remarkCopayData})
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
}

export default PlanServices
