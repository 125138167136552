import {RightSquareOutlined} from "@ant-design/icons"
import {Col, Collapse, Row, Skeleton} from "antd"
import styles from "./InsuredInfo.module.css"
import React from "react"
import {FORMATTER} from "config/constants"
import {utils} from "config/utils"
import {ICertificate} from "../../index"

const {Panel} = Collapse

const InsuredInfo = (props: {loading: boolean; data?: ICertificate}) => {
  const {loading, data} = props
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse"
    >
      <Panel header="Thông tin hợp đồng BH" key="1">
        <Row className={styles.box}>
          <Col span={6}>
            <div>Công ty bảo hiểm</div>
            <div>
              {data?.insurer || <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 250}} />}
            </div>
          </Col>
          <Col span={6}>
            <div>Nhân viên</div>
            <div>
              {data?.insuredPersonParentName || (
                <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 250}} />
              )}
            </div>
          </Col>
          <Col span={6}>
            <div>Người được BH</div>
            <div>
              {data?.insuredPersonName || (
                <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 250}} />
              )}
            </div>
          </Col>
          <Col span={6}>
            <div>Hiệu lực / kết thúc</div>
            <div>
              {utils.parseToStringLocalDateTime(data?.effectiveDueDate, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC) || (
                <Skeleton className={"skeletonLoading"} active paragraph={{rows: 0, width: 250}} />
              )}
            </div>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default InsuredInfo
