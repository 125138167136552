import {RightSquareOutlined, SaveFilled} from "@ant-design/icons"
import {Button, Collapse, Input, message, Modal, Popover, Select, Spin, Tag} from "antd"
import React, {useEffect, useState} from "react"
import {IClaimCaseInfo, PendingType} from "../../index"
import OptionListServices from "services/OptionListServices"
import styles from "./PendingForm.module.css"
import TableList from "app/common/components/TableList"
import _ from "lodash"
import ClaimServices from "services/ClaimServices"
import {useParams} from "react-router-dom"
import {utils} from "config/utils"
import moment from "moment"
import {FORMATTER, TYPE} from "config/constants"

const {Panel} = Collapse
const {Option} = Select
const {TextArea} = Input

const PendingForm = (props: {loading: boolean; data?: IClaimCaseInfo["pendings"]; onAdd?: () => void}) => {
  const {loading, data, onAdd} = props
  const {claimCaseId} = useParams<{claimCaseId: string}>()
  const [pendingList, setPendingList] = useState<{name: string; value: string}[]>()
  const [pendingData, setPendingData] = useState<IClaimCaseInfo["pendings"]>(data || [])
  const [createPendingData, setCreatePendingData] = useState<IClaimCaseInfo["pendings"]>([])
  const [currentPendingId, setCurrentPendingId] = useState<string>("")
  const [currentPending, setCurrentPending] = useState<string>("")
  const [currentExtendText, setCurrentExtendText] = useState<string>("")
  const [isShowModal, showModal] = useState<boolean>(false)
  const [updateMode, setUpdateMode] = useState<boolean>(false)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)

  const getPendingList = async () => {
    const response = await OptionListServices.getMetadata("pending_code")
    if (response.isSuccess) {
      setPendingList(response.data.collection)
    }
  }

  useEffect(() => {
    getPendingList().then()
  }, [])

  useEffect(() => {
    if (data) {
      setPendingData(data)
    }
  }, [data])

  const updatePendingData = async () => {
    let response
    if (isUpdate) {
      response = await ClaimServices.updateClaimCasePending(claimCaseId, pendingData, true)
    }
    if (createPendingData.length) {
      response = await ClaimServices.updateClaimCasePending(
        claimCaseId,
        createPendingData.map((p) => ({
          code: p.code,
          extendedText: p.extendedText,
        })),
      )
    }
    //create
    if (response.isSuccess) {
      onAdd && onAdd()
      setCreatePendingData([])
      setIsUpdate(false)
      setCurrentPendingId("")
      setCurrentExtendText("")
      setCurrentPendingId("")
      message.success("Cập nhật pending thành công")
    }
  }

  const handleAddPendingCode = async () => {
    const newCreatePendingData = _.clone(createPendingData)

    if (updateMode) {
      setIsUpdate(true)
      const index = pendingData.findIndex((p) => p.claimPendingCodeId === currentPendingId)
      if (index !== -1) {
        const newPendingData = _.clone(pendingData)
        newPendingData[index].extendedText = currentExtendText
        setPendingData(newPendingData)
      }
      setUpdateMode(false)
      setCurrentPendingId("")
    } else {
      newCreatePendingData.push({
        claimPendingCodeId: "_" + Math.random().toString(36).substr(2, 9),
        code: currentPending,
        extendedText: currentExtendText,
      })
      setCreatePendingData(newCreatePendingData)
    }
    setCurrentExtendText("")
    setCurrentPending("")
    showModal(false)
  }

  const changeStatusPending = (pendingId: string, status: string) => {
    setIsUpdate(true)
    const newPendingData = _.clone(pendingData)
    const index = newPendingData.findIndex((p) => p.claimPendingCodeId === pendingId)
    if (index !== -1) {
      newPendingData[index].status = status
      setPendingData(newPendingData)
    }
  }

  const handleEdit = (record: PendingType) => {
    setCurrentPending(record.code)
    setCurrentExtendText(record.extendedText)
    setCurrentPendingId(record.claimPendingCodeId || "")
    setUpdateMode(true)
    showModal(true)
  }

  const schema = [
    {
      title: "Mã",
      dataIndex: "code",
      key: "code",
      render: (text: string) => <Tag color={"geekblue"}>{text}</Tag>,
    },
    {
      title: "Extended Text",
      dataIndex: "extendedText",
      width: 300,
      key: "extendedText",
    },
    {
      title: "Thời gian tạo",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text: string) =>
        text ? moment(utils.parseLocalTime(text)).format(FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC) : "",
    },
    {
      title: "Thời gian TAT",
      key: "startedAt",
      dataIndex: "startedAt",
      render: (text: string) =>
        text ? moment(utils.parseLocalTime(text)).format(FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC) : "",
    },
    {
      title: "Thời gian gỡ",
      key: "removedAt",
      dataIndex: "removedAt",
      render: (text: string) =>
        text ? moment(utils.parseLocalTime(text)).format(FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC) : "",
    },

    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "extendedText",
      render: (text) => TYPE.VN_PENDING_STATUS_NAME[text],
    },
    {
      title: "Thao tác",
      key: "action",
      render: (text, record) => (
        <div className={styles.actionContainer} onClick={(e) => e.stopPropagation()}>
          {record.isEditable ? (
            <Button type="link" onClick={() => handleEdit(record)}>
              Edit
            </Button>
          ) : (
            ""
          )}
          {record.status ? (
            record.status === TYPE.PENDING_STATUS.OutstandingRequirement || !record.removedAt ? (
              <Popover
                placement="top"
                content={
                  <div className={styles.actionPopover}>
                    <Button
                      type="link"
                      disabled={record.status === TYPE.PENDING_STATUS.RequirementReceived}
                      onClick={() =>
                        changeStatusPending(record.claimPendingCodeId, TYPE.PENDING_STATUS.RequirementReceived)
                      }
                    >
                      Đã nộp
                    </Button>
                    <Button
                      type="link"
                      disabled={record.status === TYPE.PENDING_STATUS.RequirementWaived}
                      onClick={() =>
                        changeStatusPending(record.claimPendingCodeId, TYPE.PENDING_STATUS.RequirementWaived)
                      }
                    >
                      Miễn trừ
                    </Button>
                    <Button
                      type="link"
                      disabled={record.status === TYPE.PENDING_STATUS.Suspended}
                      onClick={() => changeStatusPending(record.claimPendingCodeId, TYPE.PENDING_STATUS.Suspended)}
                    >
                      Hết hạn
                    </Button>
                  </div>
                }
                trigger="click"
              >
                <Button className={styles.removePending} type="link" size={"large"} disabled={!record.status}>
                  Gỡ
                </Button>
              </Popover>
            ) : (
              ""
            )
          ) : (
            <Button
              danger
              type="link"
              size={"large"}
              disabled={!!record.status}
              onClick={() => {
                const newCreatePending = _.clone(createPendingData)
                const index = newCreatePending.findIndex((p) => p.claimPendingCodeId === record.claimPendingCodeId)
                newCreatePending.splice(index, 1)
                setCreatePendingData(newCreatePending)
              }}
            >
              Xóa
            </Button>
          )}
        </div>
      ),
    },
  ]

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
    >
      <Panel header="Chọn Pending" key="1">
        <Spin spinning={loading}>
          <div className={styles.pendingSelectContainer}>
            <Select
              mode={"multiple"}
              size={"large"}
              placeholder="Chọn Pending..."
              onSelect={(code) => {
                setCurrentPending(code)
                showModal(true)
              }}
              className={styles.pendingSelect}
              value={[]}
            >
              {pendingList && pendingList.length
                ? pendingList.map((pendingItem) => (
                    <Option value={pendingItem.value} key={pendingItem.value}>
                      {`${pendingItem.value} - ${pendingItem.name}`}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
          <div className={styles.pendingContainer}>
            <TableList
              title={"Danh sách pending"}
              onView={() => {}}
              schema={schema}
              data={_.union(pendingData, createPendingData).map((p) => ({
                ...p,
                key: p.claimPendingCodeId,
              }))}
              hidePaginate
            />
            <Button
              className={styles.updateButton}
              type={"primary"}
              size={"large"}
              icon={<SaveFilled />}
              onClick={updatePendingData}
            >
              Lưu
            </Button>
          </div>
        </Spin>
        <Modal
          title={`[${currentPending}] ${_.find(pendingList, (p) => p.value === currentPending)?.name}`}
          visible={isShowModal}
          onOk={() => handleAddPendingCode()}
          onCancel={() => {
            showModal(false)
            setUpdateMode(false)
            setCurrentPendingId("")
            setCurrentPending("")
            setCurrentExtendText("")
          }}
          className={styles.modelContainer}
        >
          <label>Extended Text</label>
          <TextArea
            title={"Extended Text"}
            value={currentExtendText}
            placeholder={"Entended Text..."}
            size={"large"}
            onChange={(e) => {
              e.preventDefault()
              setCurrentExtendText(e.target.value)
            }}
          />
        </Modal>
      </Panel>
    </Collapse>
  )
}

export default PendingForm
