import React, {useEffect, useState} from "react"
import AdminServices from "services/AdminServices"
import {PORTAL_PATH} from "app/portal/config/routes"
import TableList from "app/common/components/TableList"
import {listSchema} from "./schema"
import {utils} from "config/utils"
import {SETTINGS, TYPE} from "config/constants"
import {Input, notification} from "antd"
import {WarningOutlined} from "@ant-design/icons"
import styles from "../MedicalProviderScreen/MedicalProviderScreen.module.css"
import _ from "lodash"
import {useHistory} from "react-router"

type ItemType = {
  key?: string
  insuredPersonId: string
  name: string
  paperId: string
  dob: string
  email: string
  genderName: string
}

const InsuredPersonScreen = () => {
  const [data, setData] = useState<ItemType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [percent, setPercent] = useState<number>(0)
  const history = useHistory()

  const getInsuredPersonList = async (search = "") => {
    const response = await AdminServices.getInsuredPersonList(search)

    if (response.isSuccess) {
      const newData = response.data.collection.map((item: ItemType) => {
        item.key = item.insuredPersonId
        return item
      })
      setData(newData)
    }
    setLoading(false)
  }

  useEffect(() => {
    getInsuredPersonList().then()
  }, [])

  const importInsuredPersonByCSV = async (e) => {
    const file: File = e.target.files[0]
    const uploadFile = await utils.base64ToFile(file, file.name)
    const response = await AdminServices.importData(
      uploadFile,
      TYPE.IMPORT_TYPE.InsuredPerson,
      (percent: number) => {
        setPercent(percent)
        if (percent === 100) {
          setPercent(0)
        }
      },
    )
    if (response.isSuccess) {
      await getInsuredPersonList()
      for (let i = 0; i < response.data.length; i++) {
        setTimeout(() => {
          notification.warning({
            message: "Import info",
            description: response.data[i],
            icon: <WarningOutlined style={{color: "orange"}} />,
          })
        }, 1200 * i)
      }
    }
  }

  return (
    <div>
      <Input.Search
        className={styles.search}
        size={"large"}
        placeholder={"Tìm kiếm ..."}
        onChange={_.debounce((e) => getInsuredPersonList(e.target.value), SETTINGS.SEARCH_DEBOUNCE_TIME)}
      />
      <TableList
        title={"người được bảo hiểm"}
        onCreate={() => history.push(`${PORTAL_PATH.INSURED_PERSON_LIST}/create`)}
        onView={(record: ItemType) => history.push(`${location.pathname}/${record.insuredPersonId}`)}
        schema={listSchema}
        loading={loading}
        percent={percent}
        onImport={(e) => importInsuredPersonByCSV(e)}
        data={data}
      />
    </div>
  )
}

export default InsuredPersonScreen
