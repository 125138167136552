import React, {useState} from "react"
import {Avatar, Button, Col, Collapse, Empty, Input, message, notification, Row, Spin, Tooltip} from "antd"
import {RightSquareOutlined} from "@ant-design/icons"
import styles from "./SignOff.module.css"
import ClaimServices from "services/ClaimServices"
import moment from "moment"
import ReactTimeago from "react-timeago"
import {FORMATTER, TYPE} from "config/constants"
import {useSelector} from "react-redux"
import {createSelector} from "reselect"

const {TextArea} = Input
const {Panel} = Collapse

const getNameInMeState = createSelector(
  (state: {me: any}) => state.me,
  (me) => (me ? me.name : ""),
)
const SignOff = (props: {
  claimCaseId: string
  data: {
    content: string
    createdAt: string
    createdBy: string
    type: string
  }[]
  reloadData: () => void
  loading: boolean
}) => {
  const [signOff, setSignOff] = useState<string>()
  const userName = useSelector(getNameInMeState)
  const {data, claimCaseId, reloadData, loading} = props

  const sortByTime = () =>
    data.sort(function compare(
      a: {
        createdBy: string
        createdAt: string
        content: string
      },
      b: {
        createdBy: string
        createdAt: string
        content: string
      },
    ) {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateA - dateB
    })

  const sendSignOff = async () => {
    if (signOff) {
      const response = await ClaimServices.sendClaimCaseSignOffMsg(claimCaseId, {
        type: TYPE.NOTE_TYPE.SIGN_OFF,
        content: signOff,
      })
      if (response.isSuccess) {
        setSignOff("")
        message.success("Gửi Sign Off message thành công")
        reloadData()
      } else {
        message.error("Gửi Sign Off message thất bại")
      }
    } else {
      notification.error({message: "SignOff msg cannot be blank"})
    }
  }

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
    >
      <Panel header={"Ghi chú"} key={"1"}>
        <Row className={styles.signOffContainer}>
          <Col span={24} className={styles.msgSignOffContainer}>
            <Spin spinning={loading}>
              {data && data.length ? (
                sortByTime().map((bubble: {createdBy: string; createdAt: string; content: string}) => (
                  <Row
                    key={bubble.createdAt}
                    className={[styles.bubbleMsg, userName === bubble.createdBy ? styles.myBubble : ""].join(" ")}
                  >
                    <div className={styles.avatarContainer}>
                      <Avatar size={30} className={styles.avatar}>
                        {bubble.createdBy.charAt(0)}
                      </Avatar>
                    </div>
                    <div className={styles.bubbleContent}>
                      <div>
                        <strong>{bubble.createdBy}</strong> ·{" "}
                        <Tooltip title={moment(bubble.createdAt).format(FORMATTER.DATE_TIME_FORMAT)}>
                          <ReactTimeago date={bubble.createdAt} live />
                        </Tooltip>
                      </div>
                      <div>
                        <div>{bubble.content}</div>
                      </div>
                    </div>
                  </Row>
                ))
              ) : (
                <Empty className={styles.empty} description={false} />
              )}
            </Spin>
          </Col>
        </Row>
        <Row className={styles.signOffContainer}>
          <div className={styles.signTextArea}>
            <TextArea
              placeholder={"Nhập nội dung ghi chú..."}
              rows={5}
              className={styles.textArea}
              onChange={(e) => setSignOff(e.target.value)}
              value={signOff}
            />
          </div>
          <div>
            <Button
              className={styles.btnSignOff}
              type={"primary"}
              size={"large"}
              disabled={!signOff}
              onClick={sendSignOff}
            >
              Gửi đi
            </Button>
          </div>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default SignOff
