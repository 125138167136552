import React, {useState} from "react"
import styles from "./PlanInfoScreen.module.css"
import {useParams, useHistory} from "react-router"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"
import PlanServices from "services/PlanServices";
import Button from "antd-button-color";
import {EditOutlined} from "@ant-design/icons";
import TableList from "app/common/components/TableList";
import {PORTAL_PATH} from "app/portal/config/routes";
import BaseForm from "app/common/components/Form";
import {planDetailSchema} from "../../../../schema";
import {message, Modal} from "antd";
import _ from "lodash";

interface IPlanDetailData {
  planDetailId?: string
  code?: string
  name?: string
  description?: string
  coverageDescription?: string
  territorialLimit?: string
  fee?: string
  childrenPlanDetails ?: Array<IPlanDetailData>
  parentPlanDetail?: IPlanDetailData
}

export type PlanDetailTypeUpdate = {
  code?: string
  name?: string
  description?: string
  coverageDescription?: string
  territorialLimit?: string
  fee?: string
}

const childrenPlanDetailColumns = [
  {
    key: "name",
    dataIndex: "name",
    title: "Tên quyền lợi"
  },
  {
    key: "coverageDescription",
    dataIndex: "coverageDescription",
    title: "Mô tả hạn mức"
  },
  {
    key: "fee",
    dataIndex: "fee",
    title: "Phí bảo hiểm"
  }
]

const PlanInfoScreen = () => {
  const {planId, planDetailId} = useParams<{planId: string, planDetailId: string}>()
  const [showModalPlanDetail, setModalPlanDetail] = useState<boolean>(false)
  const [optionParentPlanDetail, setOptionParentPlanDetail] = useState<[]>()
  const history = useHistory()
  const [planDetailData, setPlanDetailData] = useState<IPlanDetailData>({
    planDetailId: ""
  })
  const [updatePlanDetailData, setUpdatePlanDetailData] = useState<PlanDetailTypeUpdate>({})
  
  const getPlanDetail = async () => {
    if(planId && planDetailId) {
      const response = await PlanServices.getPlanDetailByIdAsync(planId, planDetailId)
      if (response.isSuccess) {
        setPlanDetailData(response.data)
        setUpdatePlanDetailData(response.data)
      }
    }
  }

  const getPlanDetails = async () => {
    const planDetails = await PlanServices.getPlanDetailsByPlanIdAsync(planId)
    if(planDetails && planDetails.data) {
      const options = planDetails.data.map(planDetail => ({
        name: planDetail.name,
        value: planDetail.id
      })).filter(function (item) {
        return item.value !== planDetailId;
      })
      setOptionParentPlanDetail(options)
    }
  }

  const onSave = async () => {
    if (updatePlanDetailData && planId && planDetailId) {
      const response = await PlanServices.updatePlanDetailByIdAsync(planId, planDetailId, updatePlanDetailData)
      if (response.isSuccess) {
        message.success("Tạo quyền lợi chi tiết bảo hiểm thành công")
        getPlanDetail().then()
      }
    }
  }

  const handleChange = (key: string, value: string) => {
    // eslint-disable-next-line
    let newData = _.clone(updatePlanDetailData) || {}
    if (value) {
      newData[key] = value
      setUpdatePlanDetailData(newData)
    } else {
      delete newData[key]
      setUpdatePlanDetailData(newData)
    }
  }
  
  const getFirstLoad = async () => {
    getPlanDetail().then()
    getPlanDetails().then()
  }

  useMountEffect(() => {
    getFirstLoad().then()
  })
  
  useUpdateEffect(() => {
    getFirstLoad().then()
  }, [planDetailId])

  return (
    <div className={styles.container}>
      <div className={styles.planInformation}>
        <Button
          icon={<EditOutlined />}
          size={"middle"}
          type={"lightdark"}
          className={styles.editButton}
          onClick={() => setModalPlanDetail(true)}
        >
          Sửa
        </Button>
        <div className={styles.planDetail}>
          <div className={styles.title}>Tên quyền lợi chi tiết:</div>
          <div className={styles.content}>{planDetailData.name}</div>
        </div>

        <div className={styles.planDetail}>
          <div className={styles.title}>Mã quyền lợi chi tiết:</div>
          <div className={styles.content}>{planDetailData.code}</div>
        </div>

        <div className={styles.planDetail}>
          <div className={styles.title}>Mô tả:</div>
          <div className={styles.content}>{planDetailData.description}</div>
        </div>

        <div className={styles.planDetail}>
          <div className={styles.title}>Mô tả hạn mức:</div>
          <div className={styles.content}>{planDetailData.coverageDescription}</div>
        </div>

        <div className={styles.planDetail}>
          <div className={styles.title}>Giới hạn lãnh thổ:</div>
          <div className={styles.content}>{planDetailData.territorialLimit}</div>
        </div>

        <div className={styles.planDetail}>
          <div className={styles.title}>Phí bảo hiểm:</div>
          <div className={styles.content}>{planDetailData.fee}</div>
        </div>
      </div>

      <TableList
        className={styles.planDetailChildrenContainer}
        title={"Danh sách quyền lợi phụ"}
        onView={(record) => {
          let path = PORTAL_PATH.PLAN_DETAIL_INFO
          path = path.replace(":planId", planId)
          path = path.replace(":planDetailId", record.planDetailId)
          history.push(path)
        }}
        schema={childrenPlanDetailColumns}
        data={
          (planDetailData.childrenPlanDetails &&
            planDetailData.childrenPlanDetails.map((c) => ({
              ...c,
              key: c.planDetailId,
            }))) ||
          []
        }
      />
      <Modal title="" centered visible={showModalPlanDetail} width={800} onCancel={() => setModalPlanDetail(false)} footer={[]}>
        <BaseForm
          title={"Cập nhật quyền lợi chi tiết"}
          schemas={planDetailSchema}
          loading={false}
          onChange={(key: string, value: string) => handleChange(key, value)}
          data={updatePlanDetailData || {}}
          option={{parentPlanDetailId: optionParentPlanDetail || [], territorialLimit: [{name: "Việt Nam", value: "Việt Nam"}, {name: "Toàn cầu", value: "Toàn cầu"}]}}
          onSave={() => onSave()}
          onCancel={() => setModalPlanDetail(false)}
        />
      </Modal>
    </div>
  )
}

export default PlanInfoScreen
