import React, {useState} from "react"
import styles from "./ClaimCaseOriginalScreen.module.css"
import {IBenefit} from "../ClaimCaseInfoScreen"
import _ from "lodash"
import {RightSquareOutlined} from "@ant-design/icons"
import {Col, Collapse, Empty, Image, Row, Spin, Tabs} from "antd"
import {utils} from "config/utils"
import {FORMATTER, TYPE} from "config/constants"
import FileService from "services/FileServices"
import {useParams} from "react-router"
import Paragraph from "antd/es/typography/Paragraph"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../../../../../store/interface"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"
import ClaimServices from "../../../../../../../../../services/ClaimServices"
import InsuredInfo from "../ClaimCaseInfoScreen/components/InsuredInfo"

const {Panel} = Collapse
const {TabPane} = Tabs

interface ICertificate {
  benefitTypes: {name: string; value: string}[]
  benefits: IBenefit[]
  effectiveDueDate: string
  insuredPersonName: string
  insuredPersonParentName: string
  insurer: string
  planId: string
  policyId: string
}

interface IData {
  claimCode: string
  certificate: ICertificate
  claimInfos: {name: string; value: string}[]
  diagnosis: string
  diseases: {name: string; value: string}[]
  eventDates: string
  medicalProviderName: string
  benefitType: string
  paymentAccountName: string
  paymentAccountNumber: string
  paymentBankBranch: string
  paymentBankCity: string
  paymentBankName: string
  requestAmount: string
  source: string
  sourceName: string
}

interface IInsuredCertificate {
  insured_person: {name: string}
  policy: {insurer: {name: string}; issued_at: string; dued_at: string}
  parent_insured_certificate: {insured_person: {name: string}}
}

interface IDocumentData {
  id: string
  url: string
  type: string
  source: string
  displayName: string
  fileName: string
  isImage: boolean
  isTrash: boolean
}

const ClaimCaseOriginalScreen = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [fileList, setFileList] = useState<IDocumentData[]>()
  const [data, setData] = useState<IData>()
  const {claimCaseId} = useParams<{claimCaseId: string}>()
  const benefitTypeOption = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const filterListPaperBySource = (fileList: IDocumentData[], source) => {
    return _.filter(fileList, {source: source})
  }

  const getFileList = async (source) => {
    setLoading(true)
    const response = await FileService.getClaimCaseFileList(TYPE.FILE_TYPE.CLAIM_DOCUMENT, claimCaseId, "", "", true)
    if (response.data && response.isSuccess && source) {
      const newDataList = await filterListPaperBySource(response.data.collection, source)
      setFileList(newDataList)
    }
    setLoading(false)
  }

  const getOriginalClaimData = async () => {
    setLoading(true)
    const response = await ClaimServices.getClaimCaseClaimRequest(claimCaseId)
    if (response.data && response.isSuccess) {
      setData(response.data)
    }
    setLoading(false)
  }

  useUpdateEffect(() => {
    if (data && data.source) {
      getFileList(data.source).then()
    }
  }, [data])

  useMountEffect(() => {
    getOriginalClaimData()
  })

  const getListPaper = (type) => {
    return _.filter(fileList, ["type", type])
  }

  const DocumentReader = (props: {dataList: IDocumentData[]}) => {
    const mapPaperType = (type: string) => {
      switch (type) {
        case TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.DISCHARGE_PAPER
        case TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.INVOICE_PAPER
        case TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.PRESCRIPTION_PAPER
        case TYPE.CLAIM_FILE_TYPE.OTHER_PAPER:
          return TYPE.CLAIM_FILE_TYPE_VALUE.OTHER_PAPER
        default:
          break
      }
    }
    const {dataList} = props
    return (
      <Image.PreviewGroup>
        {(dataList &&
          dataList.map((paper) => {
            if (paper.isImage) {
              return (
                <div key={paper.id} className={styles.imageContainer}>
                  <Image width={148} height={108} src={paper.url} />
                  <div className={styles.paperLabel}>{mapPaperType(paper.type)}</div>
                </div>
              )
            } else {
              return (
                <div key={paper.id} className={styles.documentContainer}>
                  <div className={styles.pdfContainer}>
                    <div className={styles.pdfCover} onClick={() => window.open(paper.url)} />
                  </div>
                  <div className={styles.paperLabel}>{mapPaperType(paper.type)}</div>
                </div>
              )
            }
          })) ||
          ""}
      </Image.PreviewGroup>
    )
  }

  return (
    <div className={styles.originalClaimContainer}>
      <div className={styles.claimNo}>
        <Paragraph copyable={{tooltips: false}}>{data?.claimCode}</Paragraph>
      </div>
      <div className={styles.collapseContainer}>
        <InsuredInfo loading={false} data={data?.certificate} />
      </div>

      <div className={[styles.collapseContainer, styles.row].join(" ")}>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          <Panel header="Thông tin điều trị" key="1">
            <Row className={styles.rowContainer}>
              <Col span={8}>
                <label>Bệnh viện</label>
                <p>{data?.medicalProviderName || "---"}</p>
              </Col>
              <Col span={8}>
                <label>Ngày xảy ra sự kiện bảo hiểm</label>
                <p>
                  {utils.parseToStringLocalDateTime(data?.eventDates, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC) || "---"}
                </p>
              </Col>
              <Col span={8}>
                <label>Quyền lợi bảo hiểm</label>
                <p>
                  {(benefitTypeOption && benefitTypeOption.find((b) => b.value === data?.benefitType)?.name) || "---"}
                </p>
              </Col>
              <Col span={8}>
                <label>Số tiền yêu cầu </label>
                <p>{(data?.requestAmount && data.requestAmount.toLocaleString() + " đồng") || "---"}</p>
              </Col>
              <Col span={8}>
                <label>Chẩn đoán bệnh </label>
                <p>{data?.diagnosis || "---"}</p>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>

      <div className={[styles.collapseContainer, styles.row].join(" ")}>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          <Panel header="Thông tin thanh toán" key="1">
            <Row className={styles.rowContainer}>
              <Col span={8}>
                <label>Ngân hàng</label>
                <p>{data?.paymentBankName || "---"}</p>
              </Col>
              <Col span={8}>
                <label>Chi nhánh</label>
                <p>{data?.paymentBankBranch || "---"}</p>
              </Col>
              <Col span={8}>
                <label>Tỉnh/TP</label>
                <p>{data?.paymentBankCity || "---"}</p>
              </Col>
              <Col span={8}>
                <label>Chủ tài khoản </label>
                <p>{data?.paymentAccountName || "---"}</p>
              </Col>
              <Col span={8}>
                <label>Số tài khoản </label>
                <p>{data?.paymentAccountNumber || "---"}</p>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>

      {(data?.claimInfos && (
        <div className={[styles.collapseContainer, styles.row].join(" ")}>
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse"
          >
            <Panel header="Chi phí điều trị" key="1">
              <Spin spinning={loading}>
                <Row className={styles.rowContainer}>
                  {data &&
                    data.claimInfos.map((item) => (
                      <Col key={item.name} span={8}>
                        <label>{item.name}</label>
                        <p>{(item && item.value && item.value.toLocaleString()) + " đồng" || "---"}</p>
                      </Col>
                    ))}
                </Row>
              </Spin>
            </Panel>
          </Collapse>
        </div>
      )) || <div />}

      <div className={[styles.collapseContainer, styles.row].join(" ")}>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          <Panel header="Thông tin giấy tờ" key="1">
            <Spin spinning={loading}>
              <div className={styles.claimDocumentContainer}>
                <Tabs tabPosition={"left"} className={styles.tabBar}>
                  <TabPane tab={`Tất cả (${fileList?.length})`} key="all">
                    <div className={styles.contentPaper}>
                      {(fileList && fileList.length === 0 && <Empty style={{width: "100%"}} />) || (
                        <DocumentReader dataList={fileList || []} />
                      )}
                    </div>
                  </TabPane>
                  <TabPane
                    tab={`Giấy ra viện (${getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER).length})`}
                    key="dischargePaper"
                  >
                    <div className={styles.contentPaper}>
                      {(getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER).length === 0 && (
                        <Empty style={{width: "100%"}} />
                      )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.DISCHARGE_PAPER)} />}
                    </div>
                  </TabPane>
                  <TabPane
                    tab={`Hóa đơn (${getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER).length})`}
                    key="invoicePaper"
                  >
                    <div className={styles.contentPaper}>
                      {(getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER).length === 0 && (
                        <Empty style={{width: "100%"}} />
                      )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.INVOICE_PAPER)} />}
                    </div>
                  </TabPane>
                  <TabPane
                    tab={`Toa thuốc (${getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER).length})`}
                    key="prescriptionPaper"
                  >
                    <div className={styles.contentPaper}>
                      {(getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER).length === 0 && (
                        <Empty style={{width: "100%"}} />
                      )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.PRESCRIPTION_PAPER)} />}
                    </div>
                  </TabPane>
                  <TabPane
                    tab={`Giấy tờ khác (${getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length})`}
                    key="otherPaper"
                  >
                    <div className={styles.contentPaper}>
                      {(getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER).length === 0 && (
                        <Empty style={{width: "100%"}} />
                      )) || <DocumentReader dataList={getListPaper(TYPE.CLAIM_FILE_TYPE.OTHER_PAPER)} />}
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </Spin>
          </Panel>
        </Collapse>
      </div>
    </div>
  )
}

export default ClaimCaseOriginalScreen
