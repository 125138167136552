import { IConfiguration } from "../interface"

const getUrl = (key: string) => localStorage.getItem(key)

export default function config(
  state: IConfiguration = {
    apiUrl: getUrl("api_url") || "",
    appleUrl: getUrl("apple_url") || "",
  },
) {
  let checkConfig
  if (!state.apiUrl && !state.appleUrl) {
    setTimeout(() => window.location.reload(), 1000)
  } else {
    clearTimeout(checkConfig)
  }
  return state
}
