import React, {useState} from "react"
import mustache from "mustache"
import "prismjs"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-javascript"
import "prismjs/themes/prism.css" //Example style, you can use another
import styles from "./DynamicCardDetailScreen.module.css"
import {Button, Col, Collapse, Input, message, Modal, Row, Select, Switch, Upload} from "antd"
import AdminServices from "services/AdminServices"
import _ from "lodash"
import {useHistory, useParams} from "react-router-dom"
import {useMountEffect} from "@react-hookz/web"
import TemplateCardServices from "services/TemplateCardServices"
import {utils} from "config/utils"
import FileService from "services/FileServices"
import {PORTAL_PATH} from "../../../../../config/routes"
import ButtonColor from "antd-button-color"
import {EditOutlined, UploadOutlined} from "@ant-design/icons"
import Editor from "react-simple-code-editor"
import {highlight, languages} from "prismjs"

type companyDataType = {
  companyCode: string
  companyId: string
  name: string
}

type cardDataType = {
  name?: string
  insurerId?: string
  content?: string
  contentBack?: string
  contentBackFile?: string
  contentBackFileBase64?: string
  contentBackUseFile?: boolean
}

const mockupData = {
  name: "Trần Văn A",
  policyName: "Papaya",
  dob: "01/01/1990",
  paperId: "120102198193",
  issuedDate: "01/01/2020",
  duedDate: "01/01/2022",
  lifeAssureName: "Trần Văn A",
  certificateNumber: "11020BL_1900",
}

const uploadProps = {
  accept: "image/*",
  name: "file",
  beforeUpload() {
    return false
  },
}

const FRONT_CARD = "front"
const BACK_CARD = "back"

const DynamicCardDetailScreen = () => {
  const [frontTemplate, setFrontTemplate] = useState("")
  const [backTemplate, setBackTemplate] = useState<string>("")
  const [draftTemplate, setDraftTemplate] = useState<string>("")
  const [templateModal, setTemplateModal] = useState<string>("")
  const [cardBack, setCardBack] = useState<File>()
  const [companyList, setCompanyList] = useState<companyDataType[]>([])
  const [cardData, setCardData] = useState<cardDataType>({})
  const history = useHistory()
  const {id} = useParams<{id: string}>()

  const getListCompany = async () => {
    const companies = await AdminServices.getCompanyList("Insurer")
    if (companies.isSuccess) {
      setCompanyList(companies.data.collection)
    }
  }

  const getCardDetail = async () => {
    const response = await TemplateCardServices.getDetail(id)
    if (response.isSuccess) {
      setCardData(response.data)
      setFrontTemplate(response.data.content || "")
      setBackTemplate(response.data.contentBack || "")
    }
  }

  useMountEffect(() => {
    getListCompany().then()
    if (id) {
      getCardDetail().then()
    }
  })

  const renderPreview = (template: string) => mustache.render(template, mockupData)

  const mapCompanyList = () => {
    const newCompanyList: Array<{label: string; value: string}> = []
    companyList.map((c) =>
      newCompanyList.push({
        label: c.name,
        value: c.companyId,
      }),
    )
    return newCompanyList
  }

  const onSelectChange = (value) => {
    const newCardData = _.clone(cardData)
    newCardData["insurerId"] = value
    setCardData(newCardData)
  }

  const handleChange = (e) => {
    if (e.fileList.length > 1) {
      message.warn("Vui lòng chọn duy nhất 1 hình ảnh mặt sau")
    } else {
      setCardBack(e.fileList[0].originFileObj)
    }
  }

  const uploadCardBack = async (id) => {
    if (cardBack && cardBack.size / (1024 * 1024) <= 2) {
      const file = await utils.base64ToFile(cardBack, cardBack.name)
      const response = await FileService.uploadCardBack(id, file)
      return response.isSuccess
    } else {
      message.warn("Kích thước tập tin vượt quá 2MB")
    }
  }

  const saveCardTemplate = async () => {
    if (cardData && id) {
      const frontPreview = renderPreview(frontTemplate)
      const backPreview = renderPreview(backTemplate)
      cardData.content = frontPreview
      cardData.contentBack = backPreview
      const response = await TemplateCardServices.updateCard(id, cardData)
      let responseUpload
      if (cardBack) {
        responseUpload = await uploadCardBack(id)
      }
      if (response.isSuccess) {
        message.success("Cập nhật mẫu thẻ thành công")
        await getCardDetail()
      } else {
        message.error("Cập nhật mẫu thẻ không thành công")
      }
    }
  }

  const createCardTemplate = async () => {
    const frontPreview = renderPreview(frontTemplate)
    const backPreview = renderPreview(backTemplate)
    if (cardData.insurerId && frontPreview && backPreview) {
      const createCardData = {
        content: frontPreview,
        contentBack: backPreview,
        contentBackUseFile: cardData.contentBackUseFile,
        insurerId: cardData.insurerId,
        name: cardData.name || "",
      }
      const response = await TemplateCardServices.createCard(createCardData)
      if (response.isSuccess && response.data) {
        message.success("Thêm mới thẻ thành công")
        if (cardBack) {
          await uploadCardBack(response.data)
        }
        history.replace(PORTAL_PATH.DYNAMIC_CARD_DETAIL.replace(":id", response.data))
      }
    }
  }

  const handleInputChange = ({target: {value}}) => {
    const newCardData = _.clone(cardData)
    newCardData["name"] = value
    setCardData(newCardData)
  }

  return (
    <div className={styles.container}>
      <Row gutter={[24, 24]}>
        <Col span={18} className={styles.dynamicCardContainer}>
          <Collapse defaultActiveKey={["1"]}>
            <Collapse.Panel header={<span className={styles.textAreaTitle}>Mặt trước</span>} key="1">
              {frontTemplate ? (
                <div className={styles.dynamicCard} dangerouslySetInnerHTML={{__html: renderPreview(frontTemplate)}} />
              ) : (
                <div className={styles.defaultCardContainer}>
                  <img
                    className={[styles.cardBack, styles.cardBackDetault].join(" ")}
                    src={"https://i.stack.imgur.com/y9DpT.jpg"}
                    alt="front"
                  />
                </div>
              )}
            </Collapse.Panel>
          </Collapse>
          <Collapse defaultActiveKey={["1"]}>
            <Collapse.Panel header={<span className={styles.textAreaTitle}>Mặt sau</span>} key="1">
              <div className={styles.dynamicCardBack}>
                {cardData.contentBackUseFile ? (
                  <img
                    className={[
                      styles.cardBack,
                      !cardData.contentBackFile || !cardData.contentBackUseFile ? styles.cardBackDetault : "",
                    ].join(" ")}
                    src={
                      cardData.contentBackUseFile && (cardData.contentBackFile || "https://i.stack.imgur.com/y9DpT.jpg")
                    }
                    alt="back"
                  />
                ) : backTemplate ? (
                  <div className={styles.dynamicCard} dangerouslySetInnerHTML={{__html: renderPreview(backTemplate)}} />
                ) : (
                  <div className={styles.defaultCardContainer}>
                    <img
                      className={[styles.cardBack, styles.cardBackDetault].join(" ")}
                      src={"https://i.stack.imgur.com/y9DpT.jpg"}
                      alt="front"
                    />
                  </div>
                )}
              </div>
            </Collapse.Panel>
          </Collapse>
        </Col>
        <Col span={6} className={styles.formContainer}>
          <h3 className={styles.textAreaTitle}>Thông tin mẫu thẻ</h3>
          <div>
            <label className={styles.textAreaLabel}>
              Tên thẻ bảo hiểm <span>*</span>
            </label>
            <Input
              value={cardData.name}
              className={styles.widthInput}
              placeholder={"Tên thẻ bảo hiểm"}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.selectInfo}>
            <label className={styles.textAreaLabel}>
              Chọn công ty bảo hiểm <span>*</span>
            </label>
            <Select
              showSearch
              value={cardData.insurerId || undefined}
              className={styles.widthInput}
              placeholder="Chọn công ty bảo hiểm"
              onChange={onSelectChange}
              filterOption={(input, option) =>
                (option && option.label && option.label.toString().toLowerCase().includes(input.toLowerCase())) || false
              }
              options={mapCompanyList()}
            />
          </div>
          <div>
            <label className={styles.textAreaLabel}>Mặt trước</label>
            <div className={styles.modalFieldContainer}>
              <label className={styles.textArea}>Template</label>
              <ButtonColor
                icon={<EditOutlined />}
                onClick={() => {
                  setTemplateModal(FRONT_CARD)
                  setDraftTemplate(frontTemplate)
                }}
              >
                Chỉnh sửa
              </ButtonColor>
            </div>
          </div>
          <div>
            <label className={styles.textAreaLabel}>Mặt sau</label>
            <div className={[styles.modalFieldContainer, styles.switch].join(" ")}>
              <label className={styles.textArea}>Sử dụng file</label>
              <Switch
                checked={cardData.contentBackUseFile || false}
                onChange={(checked) => {
                  //eslint-disable-next-line
                  let newCardData = _.clone(cardData)
                  newCardData.contentBackUseFile = checked
                  setCardData(newCardData)
                }}
              />
            </div>
            <div className={styles.modalFieldContainer}>
              <label className={styles.textArea}>Template {backTemplate ? "( Có dữ liệu )" : ""}</label>
              <ButtonColor
                icon={<EditOutlined />}
                onClick={() => {
                  setTemplateModal(BACK_CARD)
                  setDraftTemplate(backTemplate)
                }}
              >
                Chỉnh sửa
              </ButtonColor>
            </div>
            <div className={styles.modalFieldContainer}>
              <label className={styles.textArea}>Sử dụng ảnh {cardData.contentBackFile ? "( Có dữ liệu )" : ""}</label>
              <Upload {...uploadProps} onChange={(e) => handleChange(e)} maxCount={1}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </div>
          </div>

          <Modal
            title="Mã nguồn mẫu thẻ"
            centered
            visible={Boolean(templateModal)}
            onCancel={() => setTemplateModal("")}
            onOk={() => {
              if (templateModal === FRONT_CARD) {
                setFrontTemplate(draftTemplate)
              } else {
                setBackTemplate(draftTemplate)
              }
              setTemplateModal("")
            }}
            okText={""}
          >
            <div className={styles.editorTextAreaContainer}>
              <Editor
                value={draftTemplate}
                onValueChange={(template) => setDraftTemplate(template)}
                highlight={(template) => highlight(template, languages.markup, "markup")}
                padding={10}
                preClassName={styles.editorPreContainer}
              />
            </div>
          </Modal>
          <Button
            className={styles.save}
            type="primary"
            onClick={() => (id ? saveCardTemplate() : createCardTemplate())}
          >
            Lưu
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default DynamicCardDetailScreen
