import Service from "../services"
import endPoints from "../endpoints"
import {axiosResponseToData, defaultResponse} from "../index"
import {METADATA} from "config/constants"

const OptionListServices = {
  getMedicalProvider: async (search = "") => {
    const response = await Service().get(endPoints.MEDICAL_PROVIDER(search))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getDisease: async (search = "") => {
    return await OptionListServices.getMetadata(METADATA.DISEASES, search)
  },
  getMetadata: async (type: string, search = "") => {
    const response = await Service().get(endPoints.METADATA(type, search))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
  getTypes: async (type?: number) => {
    const response = await Service(true).get(endPoints.TYPES(type))
    const data = axiosResponseToData(response)
    return defaultResponse(data)
  },
}

export default OptionListServices
