import React from "react"
import TableList from "app/common/components/TableList"

interface IProps {
  loading: boolean
  data: any[]
  listSchema: Array<schema>
  onCreate?: () => void
  onView?: (any) => void
  title?: string
  createTitle?: string
  search?: string[]
}

interface schema {
  key: string
  dataIndex: string
  title: string
}

const PlanTableList = (props: IProps) => {
  const {title, createTitle, listSchema, loading, search, data} = props

  return (
    <div>
      <TableList
        title={title as string}
        onCreateTitle={createTitle}
        onCreate={() => {
          if (props.onCreate) {
            props.onCreate()
          }
        }}
        onView={(record: any) => {
          if (props.onView) {
            props.onView(record)
          }
        }}
        schema={listSchema}
        loading={loading}
        search={search}
        data={data.map((d) => ({
          ...d,
          key: d.key,
        }))}
      />
    </div>
  )
}

export default PlanTableList
