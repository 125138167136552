import React from "react"
import {Tag} from "antd"

const listSchema = [
  {
    title: "Tên công ty",
    dataIndex: "name",
    key: "name",
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: "Mã",
    dataIndex: "companyCode",
    key: "companyCode",
  },
  {
    title: "Loại",
    dataIndex: "types",
    key: "types",
    render: (text: Array<string>) =>
      text.map((t) => (
        <Tag key={t} color={t === "Insurer" ? "geekblue" : t === "Broker" ? "orange" : "volcano"}>
          <span>{t}</span>
        </Tag>
      )),
  },
]

const formSchema = [
  {
    key: "name",
    label: "Tên công ty",
    placeholder: "Tên công ty",
    component: "input",
    type: "text",
    required: true,
  },
  {
    key: "companyCode",
    label: "Mã công ty",
    placeholder: "Mã công ty",
    component: "input",
    type: "text",
    required: true,
  },
  {
    key: "phone",
    label: "Số điện thoại",
    placeholder: "Số điện thoại công ty",
    component: "input",
    type: "number",
  },
  {
    key: "taxCode",
    label: "Mã tax",
    placeholder: "Mã tax công ty",
    component: "input",
    type: "number",
  },
  {
    key: "types",
    label: "Loại công ty",
    placeholder: "Chọn loại công ty",
    component: "select",
    multiple: true,
    layout: "24",
    required: true,
  },
  {
    key: "description",
    label: "Mô tả",
    placeholder: "Mô tả",
    component: "textArea",
    layout: "24",
  },
]

export {formSchema, listSchema}
