import {Menu, message, Modal, Select} from "antd"
import React, {useCallback, useState} from "react"
import {Route, Switch, useHistory, useParams} from "react-router-dom"
import styles from "../../CompanyScreen.module.css"
import routes from "./routes"
import {AppstoreTwoTone, EditOutlined, FileTextTwoTone} from "@ant-design/icons"
import BaseForm from "app/common/components/Form"
import _ from "lodash"
import AdminServices from "services/AdminServices"
import {formSchema} from "../../schema"
import Button from "antd-button-color"
import {PORTAL_PATH} from "app/portal/config/routes"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../../../store/interface"
import {TYPE} from "../../../../../../../config/constants"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"

const {Option} = Select

export type companyType = {
  key?: string
  companyId?: string
  name: string
  companyCode: string
  phone?: string
  taxCode?: string
  logo?: string
  description?: string
  status?: string
  statusName?: string
  types: string[]
}

const CompanyInfoScreen = () => {
  const history = useHistory()
  const companyRoleTypes = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.COMPANY_ROLE_TYPE],
  )
  const {companyId} = useParams<{companyId: string}>()
  const [loading, setLoading] = useState<boolean>(true)
  const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false)
  const [companyType, setCompanyType] = useState<string>("")
  const [companyData, setCompanyData] = useState<companyType | any>({
    types: [],
  })

  const getFirstLoad = useCallback(async () => {
    if (companyId) {
      const response = await AdminServices.getCompanyById(companyId)
      if (response.isSuccess) {
        setCompanyData(response.data)
      }
    }
    setLoading(false)
  }, [companyId])

  useMountEffect(() => {
    getFirstLoad().then()
  })

  const setRoleParams = useCallback(
    (value?: string) => {
      const newValue = value || companyData.types[0]
      const url = new URL(window.location.href)
      if (window.location.search.indexOf("role") === 1) {
        url.searchParams.set("role", newValue)
      } else {
        url.searchParams.append("role", newValue)
      }
      window.history.replaceState("", "", url.href)
      setCompanyType(newValue)
    },
    [companyData.types],
  )

  useUpdateEffect(() => {
    setRoleParams()
  }, [companyData])

  const handleChange = (key: string, value: string) => {
    let newData = _.clone(companyData)
    if (value) {
      if (!newData) {
        newData = {
          types: [],
          code: "",
          name: "",
        }
      }
      newData[key] = value
      setCompanyData(newData)
    } else {
      delete newData[key]
      setCompanyData(newData)
    }
  }

  const updateCompany = async () => {
    setLoading(true)
    if (companyData) {
      const response = await AdminServices.updateCompany(companyId, companyData)
      if (response.isSuccess) {
        message.success("Cập nhật thành công")
        await getFirstLoad()
      } else {
        message.error(response.messages)
      }
    }
    setLoading(false)
  }

  const onSave = async () => {
    if (companyId) {
      await updateCompany()
    }
  }

  const handleMenuChange = (e) => {
    let path = PORTAL_PATH.COMPANY_DETAIL
    path = path.replace(":companyId", companyId)
    path = path + "/" + e.key
    history.replace(path + "?role=" + companyType)
  }

  const onChange = (value) => {
    setRoleParams(value)
  }

  return (
    <div className={styles.navigationContainer}>
      <div className={styles.companyInformation}>
        <Button
          icon={<EditOutlined />}
          size={"middle"}
          type={"lightdark"}
          className={styles.editButton}
          onClick={() => setShowCompanyModal(true)}
        >
          SỬA
        </Button>
        <div className={styles.selectCompanyType}>
          {(companyData.types.length && (
            <Select
              defaultValue={companyType || companyData.types[0]}
              className={styles.selection}
              placeholder="Chọn role"
              onChange={onChange}
            >
              {companyData.types.map((type) => {
                return (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                )
              })}
            </Select>
          )) ||
            ""}
        </div>
        <div className={styles.companyDetail}>
          <div className={styles.title}>Tên:</div>
          <div className={styles.content}>{companyData.name}</div>
        </div>

        <div className={styles.companyDetail}>
          <div className={styles.title}>Mã công ty</div>
          <div className={styles.content}>{companyData.companyCode}</div>
        </div>

        <div className={styles.companyDetail}>
          <div className={styles.title}>Liên hệ:</div>
          <div className={styles.content}>{companyData.phone}</div>
        </div>

        <div className={styles.companyDetail}>
          <div className={styles.title}>Tax code:</div>
          <div className={styles.content}>{companyData.taxCode}</div>
        </div>

        <div className={styles.companyDetail}>
          <div className={styles.title}>Loại:</div>
          <div className={styles.content}>{companyData.types.join(", ")}</div>
        </div>

        <div className={styles.companyDetail}>
          <div className={styles.title}>Mô tả:</div>
          <div className={styles.content}>{companyData.description}</div>
        </div>
      </div>
      <Modal
        title=""
        centered
        visible={showCompanyModal}
        width={900}
        onCancel={() => setShowCompanyModal(false)}
        footer={[]}
      >
        <BaseForm
          title={""}
          className={styles.containerForm}
          schemas={formSchema}
          option={{types: companyRoleTypes}}
          loading={loading}
          disabled={{companyCode: true}}
          onChange={(key: string, value: string) => handleChange(key, value)}
          data={companyData || {}}
          onSave={() => onSave()}
          onCancel={() => setShowCompanyModal(false)}
        />
      </Modal>
      <div className={styles.navigationContent}>
        <div className={styles.menu}>
          <Menu
            style={{
              width: 200,
              borderRadius: 10,
            }}
            defaultSelectedKeys={["policies"]}
            mode={"inline"}
            onClick={handleMenuChange}
          >
            <Menu.Item key="policies" icon={<FileTextTwoTone />}>
              Hợp đồng bảo hiểm
            </Menu.Item>
            <Menu.Item key="plans" icon={<AppstoreTwoTone />}>
              Sản phẩm bảo hiểm
            </Menu.Item>
          </Menu>
        </div>
        <div className={styles.content}>
          <Switch>
            {routes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                render={(props) => (
                  <div>
                    <route.comp />
                  </div>
                )}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default CompanyInfoScreen
