import React, {useEffect, useState} from "react"
import TableList from "app/common/components/TableList"
import BenefitTag from "app/common/components/BenefitTag"
import styles from "./HospitalClaimScreen.module.css"
import {gql, useLazyQuery} from "@apollo/client"
import {utils} from "config/utils"
import {FORMATTER, TYPE} from "config/constants"
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons"
import {Tag} from "antd"
import {useSelector} from "react-redux"
import {MeType, TypesType} from "store/interface"
import {useHistory} from "react-router-dom"
import {PORTAL_PATH} from "../../../config/routes"

const GET_CLAIM_MEDICAL_PROVIDER = gql`
query getClaimByMedicalProvider {
  claim_requests(order_by: {created_at: desc}) {
    claim_request_id
    diagnosis
    benefit_type
    event_dates
    is_direct_billing
    insured_person {
      email
      insured_certificates {
        insured_certificate_id
        certificate_code
      }
    }
    claim_cases {
      code
      status
    }
  }
}
`

const HospitalClaimScreen = () => {
  const me = useSelector((state: {me: MeType}) => state.me)
  const [loading, setLoading] = useState<boolean>(true)
  const [claimRequestData, setClaimRequestData] = useState<ClaimRequests[]>([])
  const benefitTypes = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const statusTypes = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.CLAIM_CASE_STATUS],
  )

  const history = useHistory()

  // TODO: Remove this when implement hospital portal
  const [getClaims] = useLazyQuery<{claim_requests: ClaimRequests[]}>(GET_CLAIM_MEDICAL_PROVIDER, {
    context: {
      headers: {
        "x-hasura-role": me && me.roles.includes("admin") ? "admin" : "",
      },
    },
    onCompleted: (data: {claim_requests: ClaimRequests[]}) => {
      setClaimRequestData(
        data.claim_requests.map((claim) => ({
          ...claim,
          certificate_id: claim.derived_insured_certificate?.insured_certificate_id,
          certificate_code: claim.derived_insured_certificate?.certificate_code,
        })),
      )
      setLoading(false)
    },
    onError: () => {
      setLoading(false)
    },
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    getClaims()
  }, [getClaims])

  const listSchema = [
    {
      title: "Mã claim",
      dataIndex: [ "claim_cases", "0" ,"code" ],
      key: "claim_code",
    },
    {
      title: "Số thẻ",
      dataIndex: ["insured_person",  "insured_certificates", "0", "certificate_code"],
      key: "certificate_code",
    },
    {
      title: "Thời gian điều trị",
      dataIndex: "event_dates",
      key: "event_dates",
      render: (text) =>
        `${utils.parseToStringLocalDateTime(text.split("&")[0], FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)} -
        ${utils.parseToStringLocalDateTime(text.split("&")[1], FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}`,
    },
    {
      title: "Chẩn đoán",
      dataIndex: "diagnosis",
      key: "diagnosis",
    },
    {
      title: "Quyền lợi",
      dataIndex: "benefit_type",
      key: "benefit_type",
      render: (text) => (
        <BenefitTag text={text} textReplace={benefitTypes && benefitTypes.find((b) => b.value === text)?.name} />
      ),
    },
    {
      key: "is_direct_billing",
      dataIndex: "is_direct_billing",
      title: "Bảo lãnh viện phí",
      width: 200,
      filters: [
        {
          text: "Có",
          value: true,
        },
        {
          text: "Không",
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_direct_billing === value,
      render: (text: boolean) => (
        <span>
          {(text && (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
              <span
                style={{
                  color: "#52c41a",
                  fontWeight: "bold",
                }}
              >
                Có
              </span>
            </>
          )) || (
            <>
              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
              <span
                style={{
                  color: "var(--red-alert)",
                  fontWeight: "bold",
                }}
              >
                Không
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      key: "status",
      dataIndex: [ "claim_cases",  "0" , "status"],
      title: "Trạng thái",
      width: 200,
      render: (text: string, record: any) => (
        <Tag className={"large-font-size"} color={TYPE.STATUS_COLOR[TYPE.STATUS_CLAIM[text]]}>
          {(statusTypes && statusTypes.length && statusTypes.find(sts => sts.value === text )?.name) ||
            text}
        </Tag>
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <TableList
        title={"Các yêu cầu đang chờ kết quả"}
        loading={loading}
        onView={(record) => {
          const path = PORTAL_PATH.HOSPITAL_CLAIM_DETAIL
          history.push(
            path
              .replace(":benefitType", record["benefit_type"])
              .replace(":claimId", record["claim_request_id"])
              .replace(":certificateId", record.insured_person.insured_certificates[0].insured_certificate_id),
          )
        }}
        schema={listSchema}
        data={claimRequestData.map((claim) => ({...claim, key: claim["claim_code"]}))}
      />
    </div>
  )
}

export default HospitalClaimScreen
