import DashboardScreen from "../../common/screens/DashBoardScreen"
//Admin Portal
import CompanyScreen from "../screens/AdminPortal/CompanyScreen"
import CompanyInfoScreen from "../screens/AdminPortal/CompanyScreen/screens/CompanyInfo"
import CompanyCreateScreen from "../screens/AdminPortal/CompanyScreen/screens/CompanyInfo/screens/CompanyCreateScreen"
import PolicyScreen from "../screens/AdminPortal/PolicyScreen"
import PlanScreen from "../screens/AdminPortal/PlanScreen"
import PlanDetailScreen from "../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen"
import PlanBenefitDetailScreen from "../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBenfitScreen/detail"
import PlanBenefitScreen from "../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBenfitScreen"
import PlanBalanceScreen from "../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBalanceScreen"
import PlanBalanceDetailScreen from "../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBalanceScreen/detail"
import PolicyDetailScreen from "../screens/AdminPortal/PolicyScreen/screens/PolicyDetailScreen"
import PolicyInfoScreen from "../screens/AdminPortal/PolicyScreen/screens/PolicyInfoScreen"
import InsuredPersonScreen from "../screens/AdminPortal/InsuredPersonScreen"
import InsuredPersonCreateScreen from "../screens/AdminPortal/InsuredPersonScreen/screens"
import InsuredPersonDetailScreen from "../screens/AdminPortal/InsuredPersonScreen/screens/detail"
import DynamicCardScreen from "../screens/AdminPortal/DynamicCardScreen"
import DynamicCardDetailScreen from "../screens/AdminPortal/DynamicCardScreen/screens/DynamicCardDetailScreen"
import MedicalProviderScreen from "../screens/AdminPortal/MedicalProviderScreen"
import MedicalProviderCreateScreen from "../screens/AdminPortal/MedicalProviderScreen/screens"
import MedicalProviderDetailScreen from "../screens/AdminPortal/MedicalProviderScreen/screens/detail"
import CertificateDetailScreen from "../screens/AdminPortal/PolicyScreen/screens/CertificateDetailScreen"
import PolicyCoPaymentScreen from "../screens/AdminPortal/PolicyScreen/screens/PolicyCoPaymentScreen"
// Admin User Functions
import UserScreen from "app/portal/screens/AdminPortal/UserScreen"
import EditUserScreen from "app/portal/screens/AdminPortal/UserScreen/screens/detail"

//Claim Portal
import CreateClaimRequestScreen from "../screens/ClaimPortal/CreateClaimRequestScreen"
import SampleScreen from "../../common/screens/SampleScreen"
import HistoryClaimScreen from "../screens/ClaimPortal/CreateClaimRequestScreen/screens/HistoryClaimScreen"
import ClaimCaseScreen from "../screens/ClaimPortal/CreateClaimRequestScreen/screens/ClaimCaseScreen"
import ClaimListScreen from "../screens/ClaimPortal/ClaimListScreen"
import ClaimRequestDetailScreen from "../screens/ClaimPortal/CreateClaimRequestScreen/screens/HistoryClaimScreen/screen/ClaimRequestDetailScreen"
//Hospital Portal
import HospitalCertificatesScreen from "../screens/HospitalPortal/CertificatesScreen"
import HospitalClaimListScreen from "../screens/HospitalPortal/HospitalClaimScreen"
import HospitalClaimDetailScreen from "../screens/HospitalPortal/HospitalClaimScreen/screens/ClaimScreen"
import InsurerSelectionScreen from "../screens/HospitalPortal/InsurerSelectionScreen"
import BenefitInsuredScreen from "../screens/HospitalPortal/CertificatesScreen/screens/BenefitInsuredScreen"
import PlanInfoScreen from "../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanInfoScreen";

export const PORTAL_PATH = {
  DASHBOARD: "/portal/dashboard",
  CLAIM_LIST: "/portal/claims",

  CREATE_CLAIM_REQUEST: "/portal/createClaimRequests/InsuredPersons",
  HISTORY_CLAIM: "/portal/createClaimRequests/InsuredPersons/:insuredPersonId",
  CLAIM_REQUEST_DETAIL: "/portal/claims/:claimId/claimDetail",

  CLAIM_CASE: "/portal/claimCase/:id",

  COMPANY_CREATE: "/portal/companies/create",
  COMPANY_LIST: "/portal/companies",
  POLICY_DETAIL: "/portal/policies/:id",
  CERTIFICATE_DETAIL: "/portal/policies/:policyId/certificates/:certificateId",
  POLICY_CREATE_TYPE: "/portal/policies/create/:type",
  POLICY_LIST: "/portal/policies",
  POLICY_INFO: "/portal/policies/info/:id",
  POLICY_COPAYMENT_CREATE: "/portal/policies/:policyId/coPayment/create",
  POLICY_COPAYMENT_DETAIL: "/portal/policies/:policyId/coPayment/:coPaymentId",
  PLAN_LIST: "/portal/plans",
  PLAN_DETAIL: "/portal/plans/:planId",
  PLAN_BENEFIT: "/portal/plans/:planId/benefits",
  PLAN_BENEFIT_DETAIL: "/portal/plans/:planId/benefits/:planInsuredBenefitId",
  DYNAMIC_CARD_DETAIL: "/portal/cardTemplates/:id",
  DYNAMIC_CARD_CREATE: "/portal/cardTemplates/create",
  DYNAMIC_CARD_LIST: "/portal/cardTemplates",
  INSURED_PERSON_LIST: "/portal/insuredPersons",
  INSURED_PERSON_CREATE: "/portal/insuredPersons/create",
  INSURED_PERSON_DETAIL: "/portal/insuredPersons/:id",
  SAMPLE_SCREEN: "/portal/sample",
  PLAN_BALANCES: "/portal/plans/:planId/balances",
  PLAN_BALANCES_DETAIL: "/portal/plans/:planId/balances/:planBalanceId",
  PLAN_COPAYMENT_CREATE: "/portal/plans/:planId/coPayment/create",
  PLAN_COPAYMENT_DETAIL: "/portal/plans/:planId/coPayment/:coPaymentId",
  PLAN_DETAILS: "/portal/plans/:planId/details",
  PLAN_DETAIL_INFO: "/portal/plans/:planId/details/:planDetailId",
  MEDICAL_PROVIDER: "/portal/medicalProvider",
  MEDICAL_PROVIDER_CREATE: "/portal/medicalProvider/create",
  MEDICAL_PROVIDER_DETAIL: "/portal/medicalProvider/:id",
  COMPANY_DETAIL: "/portal/companies/:companyId",
  HOSPITAL_CLAIM_CREATE: "/portal/hospital/claims/:certificateId/:benefitType/create",
  HOSPITAL_CLAIM_DETAIL: "/portal/hospital/claims/:certificateId/:benefitType/:claimId",
  HOSPITAL_CERTIFICATES_BENEFIT: "/portal/hospital/certificates/:certificateId/benefits",
  HOSPITAL_CERTIFICATES_SEARCH: "/portal/hospital/certificates/:insurerId",
  HOSPITAL_CLAIM: "/portal/hospital/claims",
  HOSPITAL_INSURER_SELECTION: "/portal/hospital/insurers",
  ADMIN_USERS_LIST: "/portal/admin/users",
  ADMIN_USER_EDIT: "/portal/admin/users/:userId",
}

export interface IRoute {
  key: string
  path: string
  comp: any
  exact?: boolean
  title: string
  roles?: string[]
  subTitle?: string
}

const routes: IRoute[] = [
  /**
   * Access routes by role
   * Denied if dont have permission
   * */
  /** HospitalPortal here **/
  {
    key: "hospitalClaims",
    path: PORTAL_PATH.HOSPITAL_CLAIM_CREATE,
    comp: HospitalClaimDetailScreen,
    title: "Điền thông tin yêu cầu bảo hiểm",
  },
  {
    key: "hospitalCertificateBenefit",
    path: PORTAL_PATH.HOSPITAL_CERTIFICATES_BENEFIT,
    comp: BenefitInsuredScreen,
    title: "Thông tin khách hàng",
  },
  {
    key: "hospitalCertificate",
    path: PORTAL_PATH.HOSPITAL_CERTIFICATES_SEARCH,
    comp: HospitalCertificatesScreen,
    title: "",
  },
  {
    key: "hospitalClaimsDetail",
    path: PORTAL_PATH.HOSPITAL_CLAIM_DETAIL,
    comp: HospitalClaimDetailScreen,
    title: "Chi tiết yêu cầu bảo hiểm",
  },
  {
    key: "hospitalClaimList",
    path: PORTAL_PATH.HOSPITAL_CLAIM,
    comp: HospitalClaimListScreen,
    title: "",
  },
  {
    key: "hospitalInsurerList",
    path: PORTAL_PATH.HOSPITAL_INSURER_SELECTION,
    comp: InsurerSelectionScreen,
    title: "",
  },
  /** ClaimPortal here **/
  {
    key: "claimDetail",
    path: PORTAL_PATH.CLAIM_REQUEST_DETAIL,
    comp: ClaimRequestDetailScreen,
    title: "Chi tiết YCBT",
  },
  {
    key: "claimList",
    path: PORTAL_PATH.CLAIM_LIST,
    comp: ClaimListScreen,
    title: "",
  },
  {
    key: "historyClaimWithID",
    path: PORTAL_PATH.HISTORY_CLAIM,
    comp: HistoryClaimScreen,
    title: "Thông tin người được bảo hiểm",
  },
  {
    key: "certificateCreateClaimCaseWithoutID",
    path: PORTAL_PATH.CLAIM_CASE,
    comp: ClaimCaseScreen,
    title: "",
  },
  {
    key: "certificateList",
    path: PORTAL_PATH.CREATE_CLAIM_REQUEST,
    comp: CreateClaimRequestScreen,
    title: "",
  },
  {
    key: "medicalProviderCreate",
    path: PORTAL_PATH.MEDICAL_PROVIDER_CREATE,
    comp: MedicalProviderCreateScreen,
    title: "Tạo mới bệnh viện",
  },
  {
    key: "medicalProviderDetail",
    path: PORTAL_PATH.MEDICAL_PROVIDER_DETAIL,
    comp: MedicalProviderDetailScreen,
    title: "Cập nhật thông tin bệnh viện",
  },
  {
    key: "medicalProviderList",
    path: PORTAL_PATH.MEDICAL_PROVIDER,
    comp: MedicalProviderScreen,
    title: "",
  },
  {
    key: "cardTemplateDetail",
    path: PORTAL_PATH.DYNAMIC_CARD_CREATE,
    comp: DynamicCardDetailScreen,
    title: "Tạo Template thẻ",
  },
  {
    key: "cardTemplateDetail",
    path: PORTAL_PATH.DYNAMIC_CARD_DETAIL,
    comp: DynamicCardDetailScreen,
    title: "Chi tiết mẫu thẻ",
  },
  {
    key: "cardTemplateList",
    path: PORTAL_PATH.DYNAMIC_CARD_LIST,
    comp: DynamicCardScreen,
    title: "",
  },
  {
    key: "insuredPersonCreate",
    path: PORTAL_PATH.INSURED_PERSON_CREATE,
    comp: InsuredPersonCreateScreen,
    title: "Tạo mới người được bảo hiểm",
  },
  {
    key: "insuredPersonDetail",
    path: PORTAL_PATH.INSURED_PERSON_DETAIL,
    comp: InsuredPersonDetailScreen,
    title: "Chi tiết người được bảo hiểm",
  },
  {
    key: "userInfos",
    path: PORTAL_PATH.INSURED_PERSON_LIST,
    comp: InsuredPersonScreen,
    title: "",
  },
  {
    key: "policyCoPaymentCreate",
    path: PORTAL_PATH.POLICY_COPAYMENT_CREATE,
    comp: PolicyCoPaymentScreen,
    title: "Tạo mới đồng chi trả hợp đồng BH",
  },
  {
    key: "policyCoPaymentDetail",
    path: PORTAL_PATH.POLICY_COPAYMENT_DETAIL,
    comp: PolicyCoPaymentScreen,
    title: "Chi tiết đồng chi trả hợp đồng BH",
  },
  {
    key: "certificateInfo",
    path: PORTAL_PATH.CERTIFICATE_DETAIL,
    comp: CertificateDetailScreen,
    title: "Chi tiết chứng chỉ bảo hiểm",
  },
  {
    key: "policyCreateWithOwner",
    path: PORTAL_PATH.POLICY_CREATE_TYPE,
    comp: PolicyDetailScreen,
    title: "Tạo mới hợp đồng BH",
  },
  {
    key: "policyInfo",
    path: PORTAL_PATH.POLICY_INFO,
    comp: PolicyInfoScreen,
    title: "Chi tiết hợp đồng",
  },
  {
    key: "policyList",
    path: PORTAL_PATH.POLICY_LIST,
    comp: PolicyScreen,
    title: "",
  },
  {
    key: "companyCreate",
    path: PORTAL_PATH.COMPANY_CREATE,
    comp: CompanyCreateScreen,
    title: "Tạo mới công ty",
  },
  {
    key: "companyDetail",
    path: PORTAL_PATH.COMPANY_DETAIL,
    comp: CompanyInfoScreen,
    title: "Chi tiết công ty",
  },
  {
    key: "companyList",
    path: PORTAL_PATH.COMPANY_LIST,
    comp: CompanyScreen,
    title: "",
  },
  {
    key: "baseForm",
    path: PORTAL_PATH.SAMPLE_SCREEN,
    comp: SampleScreen,
    title: "Base Form",
  },
  {
    key: "planBenefitDetail",
    path: PORTAL_PATH.PLAN_BENEFIT_DETAIL,
    comp: PlanBenefitDetailScreen,
    title: "Chi tiết quyền lợi bảo hiểm",
  },
  {
    key: "planBenefit",
    path: PORTAL_PATH.PLAN_BENEFIT,
    comp: PlanBenefitScreen,
    title: "Cập nhật quyền lợi",
  },
  {
    key: "planBalance",
    path: PORTAL_PATH.PLAN_BALANCES_DETAIL,
    comp: PlanBalanceDetailScreen,
    title: "Plan Balances",
  },
  {
    key: "planBalance",
    path: PORTAL_PATH.PLAN_BALANCES,
    comp: PlanBalanceScreen,
    title: "Plan Balances",
  },
  {
    key: "planInformation",
    path: PORTAL_PATH.PLAN_DETAIL_INFO,
    comp: PlanInfoScreen,
    title: "Thông tin quyền lợi chi tiết",
  },
  {
    key: "planDetail",
    path: PORTAL_PATH.PLAN_DETAIL,
    comp: PlanDetailScreen,
    title: "Thông tin sản phẩm",
  },
  {
    key: "planList",
    path: PORTAL_PATH.PLAN_LIST,
    comp: PlanScreen,
    title: "",
  },
  {
    key: "dash",
    path: PORTAL_PATH.DASHBOARD,
    comp: DashboardScreen,
    title: "Dashboard Screen",
  },
  {
    key: "UserScreen",
    path: PORTAL_PATH.ADMIN_USER_EDIT,
    comp: EditUserScreen,
    title: "Edit User",
  },
  {
    key: "UserScreen",
    path: PORTAL_PATH.ADMIN_USERS_LIST,
    comp: UserScreen,
    title: "",
  },
]

export default routes
