import React, {useCallback, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import HospitalServices from "services/HospitalServices"
import styles from "./BenefitInsuredScreen.module.css"
import TableList from "app/common/components/TableList"
import {Button} from "antd"
import {utils} from "config/utils"
import {FORMATTER} from "config/constants"
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons"
import {useHistory} from "react-router"
import {PORTAL_PATH} from "app/portal/config/routes"
import BenefitTag from "app/common/components/BenefitTag"

const BenefitInsuredScreen = () => {
  const {certificateId} = useParams<{certificateId: string}>()
  const [certificateBenefit, setCertificateBenefit] = useState<{
    benefits: {
      benefitName: string
      benefitType: string
      effectiveDueDate: string
      insuredCertificateId: string
      insuredPersonName: string
      insurer: string
      isDirectBilling: boolean
      isInWaitingTime: boolean
    }[]
    certificateNumber: string
    insurer: string
    paperId: string
  }>()

  const history = useHistory()

  const getCertificateBenefit = useCallback(async () => {
    const response = await HospitalServices.getCertificateBenefit(certificateId)
    if (response.isSuccess) {
      setCertificateBenefit(response.data)
    }
  }, [certificateId])

  useEffect(() => {
    getCertificateBenefit().then()
  }, [getCertificateBenefit])

  const listSchema = [
    {
      title: "Công ty bảo hiểm",
      dataIndex: "insurer",
      key: "insurer",
    },
    {
      title: "Tên NĐBH",
      dataIndex: "insuredPersonName",
      key: "insuredPersonName",
    },
    {
      title: "Hiệu lực",
      dataIndex: "effectiveDueDate",
      key: "effectiveDueDate",
      render: (text) =>
        `${utils.parseToStringLocalDateTime(text.split("&")[0], FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)} - 
        ${utils.parseToStringLocalDateTime(text.split("&")[1], FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}`,
    },
    {
      title: "Quyền lợi",
      dataIndex: "benefitName",
      key: "benefitName",
      render: (text, record) => <BenefitTag text={record.benefitType} textReplace={record.benefitName} />,
    },
    {
      key: "isInWaitingTime",
      dataIndex: "isInWaitingTime",
      title: "Trong thời gian chờ",
      width: 200,
      filters: [
        {
          text: "Có",
          value: true,
        },
        {
          text: "Không",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isInWaitingTime === value,
      render: (text: boolean) => (
        <span>
          {(text && (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
              <span
                style={{
                  color: "#52c41a",
                  fontWeight: "bold",
                }}
              >
                Có
              </span>
            </>
          )) || (
            <>
              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
              <span
                style={{
                  color: "var(--red-alert)",
                  fontWeight: "bold",
                }}
              >
                Không
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      key: "isDirectBilling",
      dataIndex: "isDirectBilling",
      title: "Bảo lãnh viện phí",
      width: 160,
      filters: [
        {
          text: "Có",
          value: true,
        },
        {
          text: "Không",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isDirectBilling === value,
      render: (text: boolean) => (
        <span>
          {(text && (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
              <span
                style={{
                  color: "#52c41a",
                  fontWeight: "bold",
                }}
              >
                Có
              </span>
            </>
          )) || (
            <>
              <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
              <span
                style={{
                  color: "var(--red-alert)",
                  fontWeight: "bold",
                }}
              >
                Không
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Hành động",
      width: 100,
      render: (text, record) => (
        <Button
          type="link"
          disabled={!record.isDirectBilling}
          onClick={() => {
            const path = PORTAL_PATH.HOSPITAL_CLAIM_CREATE
            history.push(path.replace(":certificateId", certificateId).replace(":benefitType", record.benefitType))
          }}
        >
          Tạo mới
        </Button>
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.certificateBenefitInfo}>
        <div className={styles.certificateBenefitDetail}>
          <div className={styles.title}>Số CMND / CCCD:</div>
          <div className={styles.content}>{certificateBenefit?.paperId}</div>
        </div>
        <div className={styles.certificateBenefitDetail}>
          <div className={styles.title}>Số thẻ:</div>
          <div className={styles.content}>{certificateBenefit?.certificateNumber}</div>
        </div>
        <div className={styles.certificateBenefitDetail}>
          <div className={styles.title}>Công ty BH:</div>
          <div className={styles.content}>{certificateBenefit?.insurer}</div>
        </div>
      </div>
      {certificateBenefit ? (
        <h4>Tìm thấy khách hàng có {certificateBenefit.benefits.length} quyền lợi bảo hiểm</h4>
      ) : (
        ""
      )}
      <TableList
        title={""}
        hidePaginate
        onView={() => {}}
        schema={listSchema}
        data={certificateBenefit?.benefits.map((benefit) => ({...benefit, key: benefit.benefitName}))}
      />
    </div>
  )
}

export default BenefitInsuredScreen
