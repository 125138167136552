import {Tag} from "antd"
import moment from "moment"
import React from "react"
import {FORMATTER} from "../../../../../config/constants"
import {utils} from "../../../../../config/utils"

const listSchema = [
  {
    title: "Mã hợp đồng",
    dataIndex: "policyNumber",
    key: "policyNumber",
  },
  {
    title: "Chủ hợp đồng",
    dataIndex: "policyOwner",
    key: "policyOwner",
  },
  {
    title: "Công ty bảo hiểm",
    dataIndex: "insurer",
    key: "insurer",
  },
  {
    key: "effectiveDate",
    title: "Hiệu lực bảo hiểm",
    render: (text: string, record: any) => (
      <div>
        {utils.parseToStringLocalDateTime(record.issueDate, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)} -{" "}
        {utils.parseToStringLocalDateTime(record.dueDate, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)}
      </div>
    ),
  },
  {
    title: "Loại",
    key: "typeName",
    dataIndex: "typeName",
    render: (text: string) => (
      <Tag color={text === "Individual" ? "geekblue" : text === "Corporate" ? "volcano" : "green"}>
        <span>{text}</span>
      </Tag>
    ),
  },
]

const LAListSchema = [
  {
    title: "Chủ thẻ",
    dataIndex: "insuredPersonName",
    key: "insuredPersonName",
    width: 100,
    fixed: "left",
  },
  {
    title: "Mã thẻ",
    dataIndex: "certificateCode",
    key: "certificateCode",
    width: 150,
    fixed: "left",
  },
  {
    title: "Phụ thuộc bởi",
    dataIndex: "parentInsuredPersonName",
    key: "parentInsuredPersonName",
    width: 150,
  },
  {
    title: "Tên gói",
    dataIndex: "planName",
    key: "planName",
    width: 150,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 150,
  },
  {
    title: "Lương",
    dataIndex: "salary",
    key: "salary",
    width: 100,
    render: (text) => text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  },
  {
    title: "Hiệu lực",
    dataIndex: "issuedAt",
    key: "issuedAt",
    width: 100,
    render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
  },
  {
    title: "Hết hiệu lực",
    dataIndex: "duedAt",
    key: "duedAt",
    width: 100,
    render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
  },
  {
    title: "Trạng thái",
    dataIndex: "isActive",
    key: "isActive",
    width: 60,
    fixed: "right",
    filters: [
      {
        text: "Active",
        value: true,
      },
      {
        text: "Inactive",
        value: false,
      },
    ],
    onFilter: (value, record) => record.isActive === value,
    render: (text: boolean) => (
      <>
        <Tag style={{width: 56, height: 22, textAlign: "center"}} color={(text && "green") || "volcano"}>
          <span>{(text && "Active") || "Inactive"}</span>
        </Tag>
        <span>{text}</span>
      </>
    ),
  },
]

const planListSchema = [
  {
    key: "code",
    dataIndex: "code",
    title: "Mã sản phẩm",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Tên sản phẩm",
  },
  {
    key: "insurer",
    dataIndex: "insurer",
    title: "Công ty bảo hiểm",
  },
  {
    key: "createdAt",
    title: "Ngày khởi tạo",
    render: (text: string, record: any) => <div>{moment(record.createdAt).format("DD/MM/YYYY")}</div>,
  },
]

const formWaitingSchema = [
  {
    key: "insuredBenefitTypes",
    label: "Tên quyền lợi",
    placeholder: "Nhập tên quyền lợi thời gian chờ",
    component: "select",
    multiple: true,
    required: true,
    layout: "24",
  },
  {
    key: "value",
    label: "Thời gian áp dụng",
    placeholder: "Nhập thời gian áp dụng thời gian chờ",
    component: "input",
    type: "number",
    required: true,
    layout: "24",
  },
  {
    key: "description",
    label: "Miêu tả",
    placeholder: "Nhập miêu tả của thời gian chờ",
    component: "input",
    type: "text",
    required: true,
    layout: "24",
  },
]

const formCreateSchema = [
  {
    key: "policyNumber",
    label: "Số hợp đồng",
    placeholder: "Số hợp đồng",
    component: "input",
    type: "text",
    required: true,
    layout: "24",
  },
  {
    key: "effectiveDates",
    label: "Hiệu lực",
    placeholder: ["Từ", "Đến"],
    component: "date-range",
    required: true,
    layout: "24",
  },
  {
    key: "insurerId",
    label: "Công ty bảo hiểm",
    placeholder: "Công ty bảo hiểm",
    required: true,
    component: "select",
    layout: "24",
  },

  {
    key: "tpaId",
    label: "Công ty TPA",
    placeholder: "Công ty TPA",
    component: "select",
    required: true,
    layout: "24",
  },
  {
    key: "corporateId",
    label: "Doanh nghiệp",
    required: true,
    placeholder: "Doanh nghiệp",
    component: "select",
    layout: "24",
  },
  {
    key: "brokerId",
    label: "Công ty môi giới",
    placeholder: "Công ty môi giới",
    component: "select",
    layout: "24",
  },
  {
    key: "document",
    label: "Tập tin hợp đồng",
    placeholder: "Chọn tập tin hợp đồng",
    component: "upload",
    layout: "24",
  },
  {
    key: "remarkCopay",
    label: "Đồng chi trả",
    placeholder: "Đồng chi trả",
    component: "textArea",
    layout: "24",
  },
  {
    key: "individualOwnerId",
    required: true,
    label: "Cá nhân",
    placeholder: "Chọn Owner",
    component: "select",
    layout: "24",
  },
]

const formUpdateSchema = [
  {
    key: "policyNumber",
    label: "Số hợp đồng",
    placeholder: "Số hợp đồng",
    component: "input",
    type: "text",
    required: true,
    layout: "24",
  },
  {
    key: "effectiveDates",
    label: "Hiệu lực",
    placeholder: ["Từ", "Đến"],
    component: "date-range",
    required: true,
    layout: "24",
  },
  {
    key: "insurerId",
    label: "Công ty bảo hiểm",
    placeholder: "Công ty bảo hiểm",
    required: true,
    component: "select",
  },
  {
    key: "tpaId",
    label: "Công ty TPA",
    placeholder: "Công ty TPA",
    component: "select",
    required: true,
  },
  {
    key: "corporateId",
    label: "Doanh nghiệp",
    required: true,
    placeholder: "Doanh nghiệp",
    component: "select",
  },
  {
    key: "brokerId",
    label: "Công ty môi giới",
    placeholder: "Công ty môi giới",
    component: "select",
  },
  {
    key: "remarkCopay",
    label: "Đồng chi trả",
    placeholder: "Đồng chi trả",
    component: "textArea",
    layout: "24",
  },
]

const certificateSchema = [
  {
    title: "Chủ thẻ",
    dataIndex: "insuredPersonName",
    key: "insuredPersonName",
    width: 150,
  },
  {
    title: "Mã thẻ",
    dataIndex: "certificateCode",
    key: "certificateCode",
    width: 180,
  },
  {
    title: "Tên gói",
    dataIndex: "planName",
    key: "planName",
    width: 150,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 150,
  },
  {
    title: "Hiệu lực",
    dataIndex: "issuedAt",
    key: "issuedAt",
    width: 100,
    render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
  },
  {
    title: "Hết hiệu lực",
    dataIndex: "duedAt",
    key: "duedAt",
    width: 100,
    render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
  },
]

export {
  formCreateSchema,
  formUpdateSchema,
  listSchema,
  LAListSchema,
  planListSchema,
  formWaitingSchema,
  certificateSchema,
}
