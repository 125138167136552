import React from "react"
import styles from "./BaseForm.module.css"
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Spin, Upload} from "antd"
import {FORMATTER} from "../../../../config/constants"
import moment from "moment"
import {utils} from "../../../../config/utils"
import {UploadOutlined} from "@ant-design/icons"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"

const {RangePicker} = DatePicker

const {Option} = Select
const {TextArea} = Input

interface IProps {
  className?: string
  title?: string
  loading?: boolean

  data: any
  error?: {[key: string]: {isError: boolean; errorMsg: string}}
  option?: {
    [key: string]: {name: string; value: string | boolean | number}[]
  }
  disabled?: {[key: string]: boolean}
  noAction?: boolean

  schemas: any[]
  onChange?: (key: string, value: string) => void
  onSave?: () => void
  onCancel?: () => void
}

const BaseForm = (props: IProps) => {
  const {data, title, className, option, error, schemas, disabled, noAction, loading, onChange, onSave} = props
  const [form] = Form.useForm()
  useMountEffect(() => {
    form.setFieldsValue(data)
  })
  useUpdateEffect(() => {
    if (!Object.keys(data).length) {
      form.resetFields()
    } else {
      form.setFieldsValue(data)
    }
  }, [data])

  const uploadProps = {
    accept: ".pdf",
    name: "file",
    beforeUpload() {
      return false
    },
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }

    return e && e.fileList
  }

  const handleChange = (key: string, value: any, component?: string) => {
    if (component === "upload") {
      if (onChange) {
        onChange(key, value.fileList)
      }
    } else {
      form.setFieldsValue({
        [key]: value,
      })
      if (onChange) {
        onChange(key, value)
      }
    }
  }
  const onFinish = () => {
    if (onSave) {
      onSave()
    }
  }

  return (
    <div className={[styles.container, className || ""].join(" ")}>
      {(title && <div className={styles.formHeader}>{title}</div>) || ""}
      <div className={styles.formContainer}>
        <Spin spinning={loading}>
          <Form form={form} name="BaseForm" layout="vertical" onFinish={() => onFinish()} requiredMark={true}>
            <Row gutter={[16, 16]}>
              {schemas &&
                schemas.length &&
                schemas.map((schema) => (
                  <Col key={schema.key} span={schema.layout || "12"}>
                    <Form.Item
                      className={styles.formItem}
                      label={
                        (schema.component !== "button" && (
                          <label>
                            {schema.label} {(schema.required && <span style={{color: "red"}}>*</span>) || ""}
                          </label>
                        )) ||
                        " "
                      }
                      validateStatus={(error && error[schema.key] && error[schema.key].isError && "error") || undefined}
                      help={
                        (error && error[schema.key] && error[schema.key].isError && error[schema.key].errorMsg) ||
                        undefined
                      }
                    >
                      {/* Input components here */}
                      {(schema.component === "input" && (
                        <Form.Item
                          name={schema.key}
                          validateStatus={
                            (error && error[schema.key] && error[schema.key].isError && "error") || undefined
                          }
                          help={
                            (error && error[schema.key] && error[schema.key].isError && error[schema.key].errorMsg) ||
                            undefined
                          }
                          rules={[
                            (schema.required && {
                              required: true,
                              message: (
                                <span>
                                  Field <strong style={{textTransform: "lowercase"}}>{schema.label}</strong> là field
                                  bắt buộc
                                </span>
                              ),
                            }) ||
                              {},
                          ]}
                          noStyle
                        >
                          <Input
                            size={"large"}
                            addonAfter={schema.afterPrefix}
                            onKeyDown={(e) => {
                              if (schema.type === "number") {
                                const invalidChars = ["-", "+", "e"]
                                if (invalidChars.includes(e.key)) {
                                  e.preventDefault()
                                }
                              }
                            }}
                            disabled={disabled && disabled[schema.key]}
                            placeholder={schema.placeholder}
                            type={schema.type}
                            onChange={(e) => handleChange(schema.key, e.target.value)}
                            value={(data && data[schema.key]) || ""}
                          />
                        </Form.Item>
                      )) ||
                        ""}

                      {/* Input components here */}
                      {(schema.component === "textArea" && (
                        <Form.Item
                          name={schema.key}
                          validateStatus={
                            (error && error[schema.key] && error[schema.key].isError && "error") || undefined
                          }
                          help={
                            (error && error[schema.key] && error[schema.key].isError && error[schema.key].errorMsg) ||
                            undefined
                          }
                          rules={[
                            (schema.required && {
                              required: true,
                              message: (
                                <span>
                                  Field <strong style={{textTransform: "lowercase"}}>{schema.label}</strong> là field
                                  bắt buộc
                                </span>
                              ),
                            }) ||
                              {},
                          ]}
                          noStyle
                        >
                          <TextArea
                            size={"large"}
                            disabled={disabled && disabled[schema.key]}
                            placeholder={schema.placeholder}
                            onChange={(e) => handleChange(schema.key, e.target.value)}
                            value={(data && data[schema.key]) || ""}
                          />
                        </Form.Item>
                      )) ||
                        ""}

                      {/* InputNumber components here */}
                      {(schema.component === "inputNumber" && (
                        <Form.Item
                          name={schema.key}
                          validateStatus={
                            (error && error[schema.key] && error[schema.key].isError && "error") || undefined
                          }
                          help={
                            (error && error[schema.key] && error[schema.key].isError && error[schema.key].errorMsg) ||
                            undefined
                          }
                          rules={[
                            (schema.required && {
                              required: true,
                              message: (
                                <span>
                                  Field <strong style={{textTransform: "lowercase"}}>{schema.label}</strong> là field
                                  bắt buộc
                                </span>
                              ),
                            }) ||
                              {},
                          ]}
                          noStyle
                        >
                          <InputNumber
                            style={{width: "100%"}}
                            disabled={disabled && disabled[schema.key]}
                            //@ts-ignore
                            formatter={(value) =>
                              (schema.type === "currency" && `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")) ||
                              undefined
                            }
                            size={"large"}
                            placeholder={schema.placeholder}
                            type={schema.type}
                            onChange={(value) => handleChange(schema.key, value)}
                            value={(data && data[schema.key]) || ""}
                          />
                        </Form.Item>
                      )) ||
                        ""}

                      {/* Upload document components */}
                      {schema.component === "upload" ? (
                        <Form.Item
                          name={schema.key}
                          label={schema.label}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          noStyle
                        >
                          <Upload
                            {...uploadProps}
                            onChange={(e) => handleChange(schema.key, e, schema.component)}
                            fileList={(data && data[schema.key]) || []}
                          >
                            <Button icon={<UploadOutlined />}>{schema.placeholder}</Button>
                          </Upload>
                        </Form.Item>
                      ) : (
                        ""
                      )}

                      {/* Date Picker components here */}
                      {(schema.component === "date" && (
                        <Form.Item
                          name={schema.key}
                          validateStatus={
                            (error && error[schema.key] && error[schema.key].isError && "error") || undefined
                          }
                          help={
                            (error && error[schema.key] && error[schema.key].isError && error[schema.key].errorMsg) ||
                            undefined
                          }
                          rules={
                            (schema.required && [
                              {
                                required: true,
                                message: (
                                  <span>
                                    Field <strong style={{textTransform: "lowercase"}}>{schema.label}</strong> là field
                                    bắt buộc
                                  </span>
                                ),
                              },
                            ]) ||
                            []
                          }
                          noStyle
                        >
                          {JSON.stringify(data[schema.key]) ? "" : ""}
                          <DatePicker
                            showTime
                            size={"large"}
                            disabled={disabled && disabled[schema.key]}
                            placeholder={schema.placeholder}
                            style={{width: "100%"}}
                            onChange={(date) => {
                              handleChange(schema.key, moment(date))
                            }}
                            format={FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC}
                            value={
                              data && data[schema.key]
                                ? moment(data[schema.key]).isValid()
                                  ? moment(utils.parseLocalTime(data[schema.key]))
                                  : null
                                : null
                            }
                          />
                        </Form.Item>
                      )) ||
                        ""}

                      {/* Date Range Picker components here */}
                      {(schema.component === "date-range" && (
                        <Form.Item
                          name={schema.key}
                          validateStatus={
                            (error && error[schema.key] && error[schema.key].isError && "error") || undefined
                          }
                          help={
                            (error && error[schema.key] && error[schema.key].isError && error[schema.key].errorMsg) ||
                            undefined
                          }
                          rules={
                            (schema.required && [
                              {
                                required: true,
                                message: (
                                  <span>
                                    Field <strong style={{textTransform: "lowercase"}}>{schema.label}</strong> là field
                                    bắt buộc
                                  </span>
                                ),
                              },
                            ]) ||
                            []
                          }
                          noStyle
                        >
                          <RangePicker
                            showTime
                            size={"large"}
                            disabled={disabled && disabled[schema.key]}
                            placeholder={schema.placeholder}
                            style={{width: "100%"}}
                            onChange={(value) => {
                              handleChange(schema.key, [moment(value && value[0]), moment(value && value[1])])
                            }}
                            format={FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC}
                            value={
                              (data &&
                                data[schema.key] &&
                                moment(data[schema.key][0], FORMATTER.API_DATE_FORMAT).isValid() &&
                                moment(data[schema.key][1], FORMATTER.API_DATE_FORMAT).isValid() && [
                                  moment(data[schema.key][0], FORMATTER.API_DATE_FORMAT),
                                  moment(data[schema.key][1], FORMATTER.API_DATE_FORMAT),
                                ]) ||
                              undefined
                            }
                          />
                        </Form.Item>
                      )) ||
                        ""}

                      {/* Button components here */}
                      {(schema.component === "button" && (
                        <Form.Item name={schema.key} noStyle>
                          <Button
                            className={[styles.button, schema.className || ""].join(" ")}
                            size="large"
                            onClick={schema.onClick}
                            type={schema.type || ""}
                          >
                            {schema.label}
                          </Button>
                        </Form.Item>
                      )) ||
                        ""}

                      {/* Checkbox components here */}
                      {(schema.component === "checkbox" && (
                        <Form.Item name={schema.key} noStyle>
                          <Checkbox
                            onChange={(e) => {
                              handleChange(schema.key, e.target.checked)
                            }}
                            checked={data && data[schema.key]}
                          >
                            {schema.placeholder}
                          </Checkbox>
                        </Form.Item>
                      )) ||
                        ""}

                      {/* Selection components here */}
                      {(schema.component === "select" && (
                        <Form.Item
                          name={schema.key}
                          validateStatus={
                            (error && error[schema.key] && error[schema.key].isError && "error") || undefined
                          }
                          help={
                            (error && error[schema.key] && error[schema.key].isError && error[schema.key].errorMsg) ||
                            undefined
                          }
                          rules={[
                            (schema.required && {
                              required: true,
                              message: (
                                <span>
                                  Field <strong style={{textTransform: "lowercase"}}>{schema.label}</strong> là field
                                  bắt buộc
                                </span>
                              ),
                            }) ||
                              {},
                          ]}
                          noStyle
                        >
                          <Select
                            showSearch
                            filterOption={(input: string, option: any) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            size="large"
                            onClick={schema.onClick}
                            disabled={disabled && disabled[schema.key]}
                            value={(data && data[schema.key]) || {}}
                            placeholder={schema.placeholder}
                            mode={(schema.multiple && "multiple") || ""}
                            onSearch={schema.onSearch || null}
                            onChange={(value) => handleChange(schema.key, value)}
                          >
                            {(option &&
                              option[schema.key] &&
                              option[schema.key].length &&
                              option[schema.key].map((o: any) => (
                                <Option key={o.value} value={o.value}>
                                  {o.name}
                                </Option>
                              ))) ||
                              null}
                          </Select>
                        </Form.Item>
                      )) ||
                        ""}
                    </Form.Item>
                  </Col>
                ))}
              {(!noAction && (
                <Col span={"24"} className={styles.actionContainer}>
                  <Form.Item name={"action"}>
                    <Space size={24}>
                      <Button
                        className={styles.actionButton}
                        size="large"
                        type="primary"
                        disabled={!Object.keys(data).length}
                        htmlType="submit"
                      >
                        Lưu lại
                      </Button>
                      <Button
                        className={[styles.actionButton, styles.cancel].join(" ")}
                        size="large"
                        onClick={() => {
                          if (props.onCancel) {
                            props.onCancel()
                          }
                        }}
                        type="default"
                      >
                        Hủy bỏ
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              )) ||
                ""}
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  )
}

export default BaseForm
