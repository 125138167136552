import React from "react"
import styles from "./DetailFormReadOnly.module.css"
import {RightSquareOutlined} from "@ant-design/icons"
import {Col, Collapse, Row, Spin} from "antd"
import {utils} from "../../../../../../../../../config/utils"
import {FORMATTER} from "../../../../../../../../../config/constants"
import {ClaimInfo} from "../../index"
const {Panel} = Collapse

const DetailFormReadOnly = (props: {data?: ClaimInfo; loading: boolean}) => {
  const {data, loading} = props

  const mapDisease = (data) => {
    return data.map((item) => <p key={item.value}>{item.value + " - " + item.name}</p>)
  }
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIcon={({isActive}) => <RightSquareOutlined size={24} rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse"
    >
      <Panel header="Thông tin điều trị" key="1">
        <Spin spinning={loading}>
          <Row className={styles.rowContainer}>
            <Col span={8}>
              <label>Ngày xảy ra sự kiện bảo hiểm</label>
              <p>
                {(data &&
                  data.eventDates &&
                  utils.parseToStringLocalDateTime(data?.eventDates, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)) ||
                  "---"}
              </p>
            </Col>
            <Col span={8}>
              <label>Phương thức điều trị </label>
              <p>{data?.treatmentMethod || "---"}</p>
            </Col>
            <Col span={8}>
              <label>Chẩn đoán bệnh </label>
              <p>{data?.diagnosis || "---"}</p>
            </Col>
            <Col span={8}>
              <label>Số tiền yêu cầu </label>
              <p>{(data?.requestAmount && data.requestAmount.toLocaleString() + " đồng") || "---"}</p>
            </Col>
            <Col span={16}>
              <label>ICD Code ({data?.diseases.length})</label>
              <div className={styles.ICDBlock}>{(data && data.diseases && mapDisease(data.diseases)) || "---"}</div>
            </Col>
          </Row>
        </Spin>
      </Panel>
    </Collapse>
  )
}

export default DetailFormReadOnly
