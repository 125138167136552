import React, {Suspense, useEffect} from "react"
import "./App.css"
import {Redirect, Route, Switch} from "react-router-dom"

import routes, {ROUTES_PATH} from "./routes"
import NotFoundScreen from "./common/screens/404NotFoundScreen"
import {message, Spin} from "antd"
import {THEMES} from "config/constants"
import {loadProgressBar} from "axios-progress-bar"
import Rive from "rive-react"
import {utils} from "config/utils"

Spin.setDefaultIndicator(
  <div className={"globalLoading"}>
    <Rive src={"/logo-animate.riv"} />
    {utils.getRandomText()}
  </div>,
)
message.config({
  top: 10,
  duration: 2,
  maxCount: 3,
})
loadProgressBar()

const App = () => {
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", localStorage.getItem("theme") || THEMES.LIGHT_THEME)
  }, [])

  return (
    <div className="App">
      <Switch>
        {routes &&
          routes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              render={(props) => (
                <Suspense fallback={<div>Loading...</div>}>
                  <route.comp {...props} />
                </Suspense>
              )}
            />
          ))}
        {!window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] && (
          <Redirect from={"/"} to={ROUTES_PATH.LOGIN_SCREEN} />
        )}
        <Route component={NotFoundScreen} />
      </Switch>
    </div>
  )
}

export default App
