import i18next from "translation"
import store from "store/store"
import axios from "axios"

const headers = (token: string) => {
  if (token) {
    return {
      headers: {
        "Accept-Language": i18next.language,
        Authorization: `Bearer ${token}`,
      },
    }
  }
  return {
    headers: {
      "Accept-Language": i18next.language,
    },
  }
}

const Service = (needToken?: boolean) => {
  const apiUrl = store.getState().config.apiUrl || sessionStorage.getItem("api_url")
  const token = (needToken && (store.getState().token || localStorage.getItem("token"))) || ""

  return {
    get: (path: string, params: {[key: string]: string | boolean | number} = {}) => {
      let requestParam = (Object.keys(params).length && "?") || ""
      Object.keys(params).forEach((key) => {
        requestParam += `${key}=${params[key]}&&`
      })
      requestParam = requestParam.substring(requestParam.length - 2, requestParam.length - 1)
      return axios
        .get(apiUrl + path + requestParam, headers(token))
        .then((res: any) => {
          return {
            data: res,
            error: null,
          }
        })
        .catch((error: {response: any}) => ({
          data: null,
          error: error.response,
        }))
    },
    post: (path: string, params: any) => {
      return axios
        .post(apiUrl + path, params, headers(token))
        .then((res: any) => {
          return {
            data: res,
            error: null,
          }
        })
        .catch((error: {response: any}) => ({
          data: null,
          error: error.response,
        }))
    },
    patch: (path: string, params: {[key: string]: string | boolean | number} | string[] = {} || []) => {
      return axios
        .patch(apiUrl + path, params, headers(token))
        .then((res: any) => {
          return {
            data: res,
            error: null,
          }
        })
        .catch((error: {response: any}) => ({
          data: null,
          error: error.response,
        }))
    },
    put: (path: string, params: any = {}) =>
      axios
        .put(apiUrl + path, params, headers(token))
        .then((res: any) => {
          return {
            data: res,
            error: null,
          }
        })
        .catch((error: {response: any}) => ({
          data: null,
          error: error.response,
        })),
    delete: (path: string, id: string) =>
      axios
        .delete(`${apiUrl + path}/${id}`, headers(token))
        .then((res: any) => {
          return {
            data: res,
            error: null,
          }
        })
        .catch((error: {response: any}) => ({
          data: null,
          error: error.response,
        })),
    upload: (path: string, file = new FormData(), callBack?) => {
      return axios
        .post(apiUrl + path, file, {
          ...headers(token),
          onUploadProgress: (progressEvent) =>
            (callBack && callBack(Math.round((progressEvent.loaded * 100) / progressEvent.total))) || "",
        })
        .then((res) => {
          return {
            data: res,
            error: null,
          }
        })
        .catch((error) => {
          if (error.response) {
            return {
              data: null,
              error: error.response,
            }
          }
          return {
            data: null,
            error,
          }
        })
    },
  }
}

export default Service
