import React, {useEffect, useState} from "react"
import styles from "./InsurerSelectionScreen.module.css"
import {PlayCircleTwoTone} from "@ant-design/icons"
import {Card, Spin} from "antd"
import AdminServices from "../../../../../services/AdminServices"
import {TYPE} from "../../../../../config/constants"
import {useHistory} from "react-router-dom"
import {PORTAL_PATH} from "../../../config/routes"

interface ICompanyData {
  companyId: string
  name: string
  logo: string
}

const InsurerSelectionScreen = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ICompanyData[]>([])

  const history = useHistory()

  const getCompanyList = async () => {
    setLoading(true)
    const response = await AdminServices.getCompanyList(TYPE.COMPANY_TYPE_GQL.INSURER)
    setData(response.data.collection)
    setLoading(false)
  }

  useEffect(() => {
    getCompanyList().then()
  }, [])

  const goToNextPage = (id) => {
    //TODO: Go to next page with id
  }

  return (
    <Spin spinning={loading}>
      <div className={styles.insurerContainer}>
        <p className={styles.header}>Chọn công ty bảo hiểm</p>
        <div className={styles.insurerList}>
          {data &&
            data.map((insurer) => (
              <div key={insurer.companyId} className={styles.cardList} onClick={() => goToNextPage(insurer.companyId)}>
                <Card
                  hoverable
                  className={styles.singleCard}
                  style={{width: 260}}
                  onClick={() => {
                    const path = PORTAL_PATH.HOSPITAL_CERTIFICATES_SEARCH
                    history.push(path.replace(":insurerId", insurer.companyId))
                  }}
                  cover={
                    <div className={styles.logoContainer}>
                      <div className={styles.cardLogo}>
                        {(insurer.logo && (
                          <img alt="example" className={styles.imageCardLogo} src={insurer.logo} />
                        )) || <div className={styles.defaultIcon} />}
                      </div>
                    </div>
                  }
                >
                  <p className={styles.nameInsurer}>{insurer.name}</p>
                  <div className={styles.icon}>
                    <PlayCircleTwoTone style={{fontSize: "30px"}} />
                  </div>
                </Card>
              </div>
            ))}
        </div>
      </div>
    </Spin>
  )
}

export default InsurerSelectionScreen
