import React, {useState} from "react"
import ClaimServices from "services/ClaimServices"
import TableList from "app/common/components/TableList"
import {DatePicker, Input, Select, Space, Spin, Tag, Tooltip} from "antd"
import {useHistory} from "react-router-dom"
import {useLocation} from "react-router"
import {PORTAL_PATH} from "../../../config/routes"
import _ from "lodash"
import {utils} from "config/utils"
import {FORMATTER, TYPE} from "config/constants"
import BenefitTag from "app/common/components/BenefitTag"
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons"
import styles from "./ClaimListScreen.module.css"
import {useMountEffect, useUpdateEffect} from "@react-hookz/web"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../store/interface"

const {RangePicker} = DatePicker
const {Option} = Select

export type ClaimItemType = {
  benefitType: string
  certificateNumber: string
  claimCode: string
  claimRequestId: string
  createdAt: string
  insuredNumber: string
  insuredPersonName: string
  isDirectBilling: boolean
  paperId: string
  status: string
  updatedAt: string
  insuredCertificateId: string,
  claimCaseCodes: string,
  claimCaseStatuses: Array<string>
}

const ClaimListScreen = () => {
  const history = useHistory()
  const location = useLocation()
  const useQuery = () => {
    return new URLSearchParams(location.search)
  }
  const query = useQuery()
  const [search, setSearch] = useState<string>(query.get("search") || "")
  const [claimList, setClaimList] = useState<ClaimItemType[]>([])
  const [currentFromToFilter, setCurrentFromToFilter] = useState<any>([null, null])
  const [currentBenefitFilter, setCurrentBenefitFilter] = useState<string>("")
  const [currentStatusFilter, setCurrentStatusFilter] = useState<string>("")
  const [loading, showLoading] = useState<boolean>(true)
  const benefitTypeOption = useSelector(
    (state: { types: TypesType }) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )
  const claimStatusTypeOption = useSelector((state: { types: TypesType }) => state.types[TYPE.METADATA_TYPE_NAME.CLAIM_STATUS])
  const claimCaseStatusTypeOption = useSelector((state: { types: TypesType }) => state.types[TYPE.METADATA_TYPE_NAME.CLAIM_CASE_STATUS])

  const getClaimData = async (
    search?: string,
    filter?: {
      from?: string
      to?: string
      status?: string
      benefitType?: string
    },
  ) => {
    showLoading(true)
    const response = await ClaimServices.getAllClaim(search, filter)
    if (response.isSuccess) {
      setClaimList(response.data.collection)
    }
    showLoading(false)
  }

  useUpdateEffect(() => {
    const filter = {}
    if (currentFromToFilter && currentFromToFilter[0] && currentFromToFilter[1]) {
      filter["from"] = currentFromToFilter && currentFromToFilter[0] ? utils.parseLocalTime(currentFromToFilter[0]) : ""
      filter["to"] = currentFromToFilter && currentFromToFilter[1] ? utils.parseLocalTime(currentFromToFilter[1]) : ""
    }
    if (currentStatusFilter) {
      filter["status"] = currentStatusFilter
    }
    if (currentBenefitFilter) {
      filter["benefitType"] = currentBenefitFilter
    }
    getClaimData(search, filter).then()
  }, [currentStatusFilter, currentFromToFilter, currentBenefitFilter, search])

  useMountEffect(() => {
    getClaimData().then()
  })

  const claimListSchema = [
    {
      title: "Mã YCBT",
      dataIndex: "claimNumber",
      key: "claimNumber",
      fixed: "left",
      width: 50,
    },
    {
      title: "Mã HSBT",
      dataIndex: "claimCaseCodes",
      key: "claimCaseCodes",
      fixed: "left",
      width: 50,
      render: (text: string) => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },
    {
      title: "Mã người được BH",
      dataIndex: "insuredNumber",
      key: "insuredNumber",
      width: 50,
    },
    {
      title: "Tên người được BH",
      dataIndex: "insuredPersonName",
      key: "insuredPersonName",
      width: 80,
    },
    {
      title: "CMT/CCCD",
      dataIndex: "paperId",
      key: "paperId",
      width: 40,
    },
    {
      title: "Tạo từ",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 50,
      render: (text: string) => utils.parseToStringLocalDateTime(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
    },
    {
      title: "Quyền lợi",
      dataIndex: "benefitType",
      key: "benefitType",
      width: 70,
      render: (text: string) => (
        <BenefitTag
          text={text}
          textReplace={
            (benefitTypeOption && _.find(benefitTypeOption, (b: { value: string }) => b.value === text)?.name) || ""
          }
        />
      ),
    },
    {
      key: "isDirectBilling",
      dataIndex: "isDirectBilling",
      title: "Bảo lãnh viện phí",
      width: 80,
      filters: [
        {
          text: "Có",
          value: true,
        },
        {
          text: "Không",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isDirectBilling === value,
      render: (text: boolean) => (
        <span>
          {(text && (
            <>
              <CheckCircleTwoTone twoToneColor="#52c41a"/>{" "}
              <span
                style={{
                  color: "#52c41a",
                  fontWeight: "bold",
                }}
              >
                Có
              </span>
            </>
          )) || (
            <>
              <CloseCircleTwoTone twoToneColor="#db1d1d"/>{" "}
              <span
                style={{
                  color: "var(--red-alert)",
                  fontWeight: "bold",
                }}
              >
                Không
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      title: "Trạng thái YCBT",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      width: 50,
      render: (text: string, record: ClaimItemType) => (
        <Tag className={"large-font-size"} color={TYPE.STATUS_COLOR[TYPE.STATUS_CLAIM[text]]}>
          {(claimStatusTypeOption &&
            claimStatusTypeOption.length &&
            claimStatusTypeOption.find((sts) => sts.value === record.status)?.name) ||
          text}
        </Tag>
      ),
    },
    {
      title: "Trạng thái HSBT",
      dataIndex: "claimCaseStatuses",
      key: "claimCaseStatuses",
      fixed: "right",
      width: 100,
      render: (text: string, record: ClaimItemType) => {
        const status = record.claimCaseStatuses.map(value =>
          claimCaseStatusTypeOption &&
          claimCaseStatusTypeOption.length &&
          claimCaseStatusTypeOption.find((sts) => sts.value === value)?.name || text);
        return (
          <Tooltip placement="top" title={status.join(", ")}>
            {
              status.map(value => (
                <Tag key={value} className={"large-font-size"} color={TYPE.STATUS_COLOR[TYPE.STATUS_CLAIM[text]]}>
                  {value}
                </Tag>
              ))
            }
          </Tooltip>
        )
      },
    },
  ]

  return (
    <Space direction={"vertical"} size={15}>
      <Space direction={"vertical"} size={15} className={styles.filterContainer}>
        <h4>Tìm kiếm Claim</h4>
        <Input.Search
          size={"large"}
          width={"100%"}
          className={styles.searchBar}
          defaultValue={search}
          onChange={_.debounce((e) => {
            setSearch(e.target.value)
            history.replace(PORTAL_PATH.CLAIM_LIST + `?search=${e.target.value}`)
          }, 500)}
          placeholder={"Tìm kiếm claims ..."}
        />
        <div className={styles.filter}>
          <div>
            <label>Lọc theo thời gian</label>
            <RangePicker
              showTime
              size={"middle"}
              placeholder={["Từ ngày", "Đến ngày"]}
              onChange={(value) => setCurrentFromToFilter(value)}
              format={FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC}
              value={currentFromToFilter || [null, null]}
            />
          </div>
          <div>
            <label>Lọc theo trạng thái</label>
            <Select
              size={"middle"}
              allowClear
              value={currentStatusFilter}
              placeholder={"Chọn trạng thái"}
              className={styles.statusSelection}
              onChange={(value) => setCurrentStatusFilter(value)}
            >
              <Option value={""}>Chọn trạng thái...</Option>
              {claimStatusTypeOption && claimStatusTypeOption.length
                ? claimStatusTypeOption.map((sts) => (
                  <Option key={sts.value} value={sts.value}>
                    {sts.name}
                  </Option>
                ))
                : ""}
            </Select>
          </div>
          <div>
            <label>Lọc theo quyền lợi</label>
            <div className={styles.benefitFilter}>
              {benefitTypeOption && benefitTypeOption.length
                ? benefitTypeOption.map((b) => (
                  <BenefitTag
                    key={b.value}
                    onClick={() =>
                      currentBenefitFilter !== b.value
                        ? setCurrentBenefitFilter(b.value)
                        : setCurrentBenefitFilter("")
                    }
                    className={currentBenefitFilter === b.value ? styles.active : ""}
                    text={b.value}
                    textReplace={b.name}
                  />
                ))
                : ""}
            </div>
          </div>
        </div>
      </Space>
      <Spin spinning={loading}>
        <TableList
          title={"Danh sách claim"}
          scroll={{x: 800}}
          schema={claimListSchema}
          className={styles.tableClaimList}
          onView={(record: ClaimItemType) => {
            let path = PORTAL_PATH.CLAIM_REQUEST_DETAIL
            path = path.replace(":claimId", record.claimRequestId)
            history.push(path)
          }}
          data={claimList.map((c) => ({
            ...c,
            key: c.claimRequestId,
          }))}
        />
      </Spin>
    </Space>
  )
}

export default ClaimListScreen
