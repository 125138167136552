import React, {useState} from "react"
import styles from "./Benefit.module.css"
import {Popconfirm, Select, Spin, Tag} from "antd"
import {CheckCircleTwoTone, CloseCircleTwoTone, DeleteTwoTone, GiftTwoTone} from "@ant-design/icons"
import _ from "lodash"
import BenefitTag from "app/common/components/BenefitTag"
import TableList from "app/common/components/TableList"
import {useUpdateEffect} from "@react-hookz/web"
import {BenefitData} from "../../index"
import {TYPE} from "config/constants"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {TypesType} from "../../../../../../../../../store/interface"

const {Option} = Select

const Benefit = (props: {
  onChange: (benefitIds: string[]) => void
  benefitCoPaymentList: BenefitData[]
  onDelete: (id: string) => void
  benefitList: BenefitData[]
}) => {
  const benefitDefaultType = useSelector(
    (state: {types: TypesType}) => state.types[TYPE.METADATA_TYPE_NAME.INSURED_BENEFIT_TYPE],
  )

  const {coPaymentId} = useParams<{policyId: string; coPaymentId: string}>()
  const {onChange, onDelete, benefitCoPaymentList, benefitList} = props
  const [loading, setLoading] = useState<boolean>(false)
  const [benefitType, setBenefitType] = useState<{name: string; value: string}[]>()
  const [selectionType, setSelectionType] = useState<string[]>()
  const [selectionTypeDefault, setSelectionTypeDefault] = useState<string[]>()
  const [benefitTableData, setBenefitTableData] = useState<BenefitData[]>([])
  const [selectedKeysUpdate, setSelectedKeysUpdate] = useState<string[]>([])
  const [selectedKeysDefault, setSelectedKeysDefault] = useState<string[]>([])

  const initialData = () => {
    setLoading(true)
    if (benefitCoPaymentList.length) {
      const newSelectionType: string[] = []
      const newSelectedKeys: string[] = []
      const newBenefitTableData: BenefitData[] = _.clone(benefitCoPaymentList)
      benefitCoPaymentList.forEach((benefit) => {
        if (benefit.planInsuredBenefitId) {
          newSelectedKeys.push(benefit.planInsuredBenefitId)
          if (benefit.type && !newSelectionType.includes(benefit.type)) {
            newSelectionType.push(benefit.type)
          }
        }
      })

      if (benefitList) {
        benefitList.forEach((benefit) => {
          if (
            benefit.type &&
            newSelectionType.includes(benefit.type) &&
            _.filter(newBenefitTableData, ["planInsuredBenefitId", benefit.planInsuredBenefitId]).length === 0
          ) {
            newBenefitTableData.push(benefit)
          }
        })
      }
      setBenefitTableData(newBenefitTableData)
      setSelectionType(newSelectionType)
      setSelectionTypeDefault(newSelectionType)
      setSelectedKeysUpdate(newSelectedKeys)
      setSelectedKeysDefault(newSelectedKeys)
    } else {
      if (coPaymentId) {
        setSelectionTypeDefault([])
        setSelectedKeysDefault([])
        if (selectedKeysUpdate.length == 0) {
          setBenefitTableData([])
          setSelectionType([])
        }
      }
    }
    setLoading(false)
  }

  useUpdateEffect(() => {
    const newBenefitTableData: BenefitData[] = _.clone(benefitCoPaymentList)
    if (benefitList && selectionTypeDefault) {
      benefitList.forEach((benefit) => {
        if (
          benefit.type &&
          selectionTypeDefault.includes(benefit.type) &&
          _.filter(newBenefitTableData, ["planInsuredBenefitId", benefit.planInsuredBenefitId]).length === 0
        ) {
          newBenefitTableData.push(benefit)
        }
      })
    }
    setBenefitTableData(newBenefitTableData)
    setSelectionType(selectionTypeDefault)
  }, [selectionTypeDefault])

  const getBenefitType = () => {
    const listBenefitType: {name: string; value: string}[] = []

    benefitList.forEach((benefit) => {
      if (_.filter(listBenefitType, (type) => type.value == benefit.type).length === 0) {
        const objectType = _.find(benefitDefaultType, ["value", benefit.type])
        if (objectType) {
          listBenefitType.push(objectType)
        }
      }
    })
    setBenefitType(listBenefitType)
  }

  useUpdateEffect(() => {
    initialData()
  }, [benefitCoPaymentList])

  useUpdateEffect(() => {
    if (!selectionTypeDefault) {
      setSelectionType([])
      setBenefitTableData([])
    }
  }, [benefitType])

  const mapListBenefit = () => {
    const newBenefitSelection: JSX.Element[] = []

    if (benefitList.length && benefitType) {
      benefitType.forEach((type) => {
        newBenefitSelection.push(
          <Option key={type.value} value={type.value} name={type.name}>
            {type.name}
          </Option>,
        )
      })
    }
    return newBenefitSelection
  }

  const handleSelectType = (value) => {
    if (value && benefitList) {
      const newValue: string[] = _.clone(selectionTypeDefault) || []
      value.forEach((v) => {
        if (!newValue.includes(v)) {
          newValue.push(v)
        }
      })

      // Add element in to table
      const newBenefitList: BenefitData[] = _.clone(benefitTableData) || []

      benefitList.forEach((benefit) => {
        if (
          newValue.includes(benefit.type as string) &&
          _.filter(newBenefitList, ["planInsuredBenefitId", benefit.planInsuredBenefitId]).length === 0
        ) {
          newBenefitList.push(benefit)
        }
      })

      // Remove element from table
      const finalBenefitList: BenefitData[] = _.clone(newBenefitList)
      newBenefitList.forEach((dataTable) => {
        if (
          dataTable.planInsuredBenefitId &&
          !newValue.includes(dataTable.type as string) &&
          !selectedKeysDefault.includes(dataTable.planInsuredBenefitId)
        ) {
          _.remove(finalBenefitList, (benefit) => benefit.planInsuredBenefitId === dataTable.planInsuredBenefitId)
        }
      })

      // Remove key if value selection of it not exist
      const newSelectedKeysUpdate = _.clone(selectedKeysUpdate)
      if (selectedKeysUpdate) {
        selectedKeysUpdate.forEach((key) => {
          const objectBenefit = _.find(benefitList, (benefit) => benefit.planInsuredBenefitId == key)
          if (objectBenefit && objectBenefit.type && !newValue.includes(objectBenefit.type)) {
            _.remove(newSelectedKeysUpdate, (id) => id === key)
          }
        })
      }

      setSelectionType(newValue)
      setSelectedKeysUpdate(newSelectedKeysUpdate)
      setBenefitTableData(finalBenefitList)
      onChange(_.union(newSelectedKeysUpdate, selectedKeysDefault))
    }
  }

  useUpdateEffect(() => {
    getBenefitType()
    if (selectionTypeDefault) {
      handleSelectType(selectionTypeDefault)
    }
  }, [benefitList])

  const rowSelectionUpdate = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeysUpdate(selectedRowKeys.toString().split(","))
      onChange(_.union(selectedRowKeys.toString().split(","), selectedKeysDefault))
    },
    selectedRowKeys: _.union(selectedKeysDefault, selectedKeysUpdate),
  }

  const removeData = (row) => {
    if (selectedKeysDefault.includes(row.planInsuredBenefitId)) {
      const index = selectedKeysUpdate.indexOf(row.planInsuredBenefitId)
      const newSelectedKeysUpdate = _.clone(selectedKeysUpdate)
      newSelectedKeysUpdate.splice(index, 1)
      setSelectedKeysUpdate(newSelectedKeysUpdate)
      onDelete(row.planInsuredBenefitId)
    }
  }

  const benefitColumns = [
    {
      key: "planName",
      dataIndex: "planName",
      title: "Sản phẩm BH",
      width: 100,
      filters:
        benefitTableData && benefitTableData.length
          ? _.uniqBy(benefitTableData, "planName").map((benefit) => ({
              text: benefit.planName,
              value: benefit.planName,
            }))
          : [],
      onFilter: (value, record) => record.planName === value,
      render: (text: string) => (
        <Tag className={"large-font-size"} color={"cyan"}>
          {text}
        </Tag>
      ),
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Tên quyền lợi",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      key: "code",
      dataIndex: "code",
      title: "Mã",
      width: 100,
      render: (text: string) => (
        <Tag className={"large-font-size"} color={"geekblue"}>
          {text}
        </Tag>
      ),
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Loại quyền lợi",
      filters:
        benefitType && benefitType.length
          ? benefitType.map((b: {name: string; value: string}) => ({
              text: b.name,
              value: b.value,
            }))
          : [],
      onFilter: (value, record) => record.type === value,
      sorter: (a, b) => a.type.length - b.type.length,
      render: (text: string) => (
        <BenefitTag
          text={text}
          textReplace={(benefitType && _.find(benefitType, (b: {value: string}) => b.value === text)?.name) || ""}
        />
      ),
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Trạng thái",
      width: 120,
      render: (value: boolean, row) => (
        <div onClick={(e) => e.stopPropagation()}>
          {(selectedKeysDefault.includes(row.planInsuredBenefitId) && (
            <span style={{color: "#28a745"}}>
              <CheckCircleTwoTone twoToneColor="#2fc351" /> Đã chọn
            </span>
          )) || (
            <span style={{color: "#cf1322"}}>
              <CloseCircleTwoTone twoToneColor="#cf1322" /> Chưa chọn
            </span>
          )}
        </div>
      ),
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Tác vụ",
      render: (value: boolean, row) => (
        <div onClick={(e) => e.stopPropagation()}>
          {selectedKeysDefault.includes(row.planInsuredBenefitId) && (
            <Popconfirm
              title="Bạn có chắc muốn xóa"
              onConfirm={() => removeData(row)}
              okButtonProps={{
                danger: true,
              }}
              okText={"Xóa"}
            >
              <DeleteTwoTone
                twoToneColor="#EF5350"
                style={{
                  fontSize: 18,
                  textAlign: "center",
                }}
              />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  // const countByType = (type) => {
  //   let count = 0
  //   benefitList.forEach((benefit) => {
  //     if (benefit.type === type && selectedKeysDefault.includes(benefit.insuredBenefitId as string)) {
  //       count++
  //     }
  //   })
  //   return count
  // }

  return (
    <div className={styles.benefitContainer}>
      <Spin spinning={loading}>
        <div className={styles.selectBenefit}>
          <div className={styles.label}>
            <GiftTwoTone className={styles.icon} />
            <div className={styles.textLabel}>Loại quyền lợi:</div>
          </div>
          <div className={styles.selection}>
            <Select
              mode="multiple"
              style={{width: "100%"}}
              placeholder="Chọn quyền lợi"
              onChange={handleSelectType}
              size={"large"}
              value={selectionType}
              showArrow
            >
              {mapListBenefit()}
            </Select>
          </div>
        </div>
        <div className={styles.tableBenefit}>
          <TableList
            rowSelection={rowSelectionUpdate}
            title={"Danh sách quyền lợi"}
            onView={() => {}}
            schema={benefitColumns}
            search={["code", "name"]}
            data={benefitTableData.map((benefit) => ({
              ...benefit,
              key: benefit.planInsuredBenefitId,
            }))}
          />
        </div>
      </Spin>
    </div>
  )
}

export default Benefit
